import { AddIcon } from '@chakra-ui/icons'
import {
  Button, Image, Text, VStack,
} from '@chakra-ui/react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { useContext } from 'react'
import { useAuctionContext } from './context'
import { CreatePointsAuction } from './CreatePointsAuction'
import { LoadPointsAuction } from './LoadPointsAuction'

export const LoadOrCreatePointsAuction = () => {
  const { broadcaster } = useContext(BroadcasterContext)

  const { editMode: mode, setEditMode: setMode } = useAuctionContext()
  return (
    <VStack minW='400px' w='100%'>
      <CollapseCenter in={!mode}>
        <VStack pointerEvents='auto'>
          <Button gap={1} width='190px' onClick={() => setMode('create')}>
            <AddIcon color='gray.50' width={4} height={4} mr={1} />
            <Text>CREATE AUCTION</Text>
          </Button>
          <Button width='190px' onClick={() => setMode('load')}>
            <Image
              mr={1}
              src='/assets/images/history.svg'
              filter='invert(100%)'
            />
            <Text>LOAD AUCTION</Text>
          </Button>
        </VStack>
      </CollapseCenter>
      <CollapseCenter in={mode === 'create'}>
        <CreatePointsAuction
          onSwitch={setMode}
          enabled={mode === 'create'}
          broadcaster={broadcaster}
        />
      </CollapseCenter>
      <CollapseCenter in={mode === 'load'}>
        <LoadPointsAuction
          onSwitch={setMode}
          enabled={mode === 'load'}
          broadcaster={broadcaster}
        />
      </CollapseCenter>
    </VStack>
  )
}

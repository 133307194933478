import { Flex, Text } from '@chakra-ui/react'
import { ChatWidgetConfig, WidgetComponent } from '@elan-twitch/shared'
import { PastInterval } from 'components/Widgets/Chat/EmoteStockGraphView/PastInterval'
import { StockGraphTimer } from 'components/Widgets/Chat/EmoteStockGraphView/StockGraphTimer'
import {
  ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useScreen } from 'store/useScreen'
import { useLiveChat } from '../hooks/useLiveChat'
import { StockGraphCurrInterval } from './CurrInterval'
import {
  ROLLING_PERIOD, SECOND_WIDTH, VIEWBOX_HEIGHT, VIEWBOX_WIDTH, emoteScores, stockGraphViewChatConfig,
} from './constants'
import { useOnInterval } from './hooks'
import {
  EmoteStockGraphApi,
  EmoteStockGraphConfig,
  EmoteStockGraphProps,
  EmoteStockGraphViewData,
  StockGraphIntervalApi,
  StockGraphTimerApi,
} from './types'
import { getIntervalData, intervalsFromSeconds, parseCommandType } from './utils'

const StockGraphBody = forwardRef<EmoteStockGraphApi, EmoteStockGraphProps>((_, ref) => {
  const screen = useScreen()
  const { width, height } = useMemo(
    () => ({
      width: screen.width - 40,
      height: screen.height - 40,
    }),
    [screen],
  )
  const [params] = useSearchParams()
  const interval = useMemo(() => {
    const intervalStr = params.get('interval')
    if (!intervalStr) return 10
    const asInt = parseInt(intervalStr, 10)
    if (Number.isNaN(asInt)) return 10
    if (asInt < 1) return 1
    if (asInt > ROLLING_PERIOD / 2) return ROLLING_PERIOD / 2
    return asInt
  }, [params])

  const [bounds, setBounds] = useState<{ min: number; max: number } | null>(null)
  const { range } = useMemo(() => {
    const r = bounds ? Math.max(bounds.max - bounds.min, 1) : 1
    const minY = bounds ? Math.floor(bounds.min - r * 0.1) : -1
    const maxY = bounds ? Math.ceil(bounds.max + r * 0.1) : 1
    const cY = bounds ? (maxY + minY) / 2 : 0
    return {
      minY,
      maxY,
      range: r * 1.1,
      centerY: cY,
    }
  }, [bounds])
  const boundsRef = useRef(bounds)

  const t = useRef(0)
  const seconds = useRef<Array<number>>([])
  const currIntervalSeconds = useRef<Array<number>>([])
  const secondScore = useRef(0)
  const currPrice = useRef(0)
  const [intervals, setIntervals] = useState<
    Array<{ min: number; max: number; start: number; end: number; t: number }>
  >([])
  const maxIntervals = useMemo(() => Math.floor(ROLLING_PERIOD / interval) - 1, [interval])
  const intervalWidth = useMemo(() => Math.min(200, interval * SECOND_WIDTH), [interval])
  useEffect(() => {
    // if interval changes, update intervals using seconds
    setIntervals(intervalsFromSeconds(seconds.current, interval))
  }, [interval])
  // const currInterval = useRef(0)
  const currIntervalRef = useRef<StockGraphIntervalApi>(null)
  const timerViewRef = useRef<StockGraphTimerApi>(null)

  const handleInterval = useCallback(() => {
    // on interval, add current candlestick and reset current candlestick
    const currSeconds: Array<number> = currIntervalSeconds.current.length
      ? currIntervalSeconds.current
      : [currPrice.current]
    const intervalData = getIntervalData(...currSeconds)
    setIntervals((prev) => [...prev, { ...intervalData, t: t.current }].slice(-maxIntervals))
    setTimeout(() => {
      currIntervalSeconds.current = []
      currIntervalRef.current?.reset(currSeconds[currSeconds.length - 1])
      secondScore.current = currPrice.current
    }, 10)
  }, [maxIntervals])

  const handleSecond = useCallback(() => {
    t.current += 1
    // on second, add current score to current candlestick
    seconds.current.push(secondScore.current)
    currIntervalSeconds.current.push(secondScore.current)
    if (seconds.current.length > ROLLING_PERIOD) {
      seconds.current.shift()
    }
    if (t.current % interval === 0) {
      handleInterval()
    }
    secondScore.current = currPrice.current
    currIntervalRef.current?.onSecond(currPrice.current)
    timerViewRef.current?.onSecond(t.current)
  }, [handleInterval, interval])

  const handleMessage = useCallback((message: string) => {
    // on message, update current candlestick
    const emoteMatch = message.split(' ').find((word) => emoteScores[word])
    if (emoteMatch) {
      const score = emoteScores[emoteMatch]
      currPrice.current += score
      secondScore.current += score
      // update bounds
      if (
        !boundsRef.current
        || currPrice.current < boundsRef.current.min
        || currPrice.current > boundsRef.current.max
      ) {
        boundsRef.current = {
          min: boundsRef.current ? Math.min(currPrice.current, boundsRef.current.min) : currPrice.current,
          max: boundsRef.current ? Math.max(currPrice.current, boundsRef.current.max) : currPrice.current,
        }
        setBounds({ ...boundsRef.current })
      }
    }
  }, [])

  useImperativeHandle(ref, () => ({
    onMessage: handleMessage,
  }))

  useOnInterval(handleSecond, 1)
  const svgRef = useRef<SVGSVGElement>(null)
  const viewBox = useMemo(() => `0 ${-VIEWBOX_HEIGHT / 2} ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`, [])

  const scaleY = useMemo(() => Math.max(1, Math.min(6, 200 / range)), [range])

  return (
    <Flex w={width} h={height} align='center' justify='center'>
      <svg ref={svgRef} viewBox={viewBox} style={{ width: `${width}px`, height: `${height}px` }}>
        <defs>
          {/* drop shadow */}
          <filter id='stock-graph-drop-shadow' x='-30%' y='-60%' width='300%' height='300%'>
            <feGaussianBlur in='SourceAlpha' stdDeviation='2' />
            <feOffset dx='0' dy='0' result='offsetblur' />
            <feFlood floodColor='rgba(0,0,0,0.5)' />
            <feComposite in2='offsetblur' operator='in' />
            <feMerge>
              <feMergeNode />
              <feMergeNode in='SourceGraphic' />
            </feMerge>
          </filter>
        </defs>
        <StockGraphTimer scaleY={scaleY} ref={timerViewRef} interval={interval} />
        {/* <StockGraphFrame /> */}
        {intervals.map((intervalData, i) => (
          <PastInterval
            scaleY={scaleY}
            interval={intervalData}
            x={(i + 0.5) * intervalWidth}
            width={intervalWidth}
            key={`${intervalData.t}`}
          />
        ))}
        <StockGraphCurrInterval
          x={(intervals.length + 0.5) * intervalWidth}
          y={0}
          width={intervalWidth}
          scaleY={scaleY}
          ref={currIntervalRef}
        />
      </svg>
    </Flex>
  )
})

export const EmoteStockGraphView: WidgetComponent<EmoteStockGraphViewData, ChatWidgetConfig> = ({ data }) => {
  const { broadcasterId } = data || {}
  const {
    subscribe,
    error,
    emotes: { emotes, isLoading: emotesLoading },
  } = useLiveChat(broadcasterId, stockGraphViewChatConfig)
  const [stockGraphConfig] = useState<EmoteStockGraphConfig | null>(null)

  const stockGraphConfigRef = useRef<EmoteStockGraphConfig | null>(null)
  useEffect(() => {
    stockGraphConfigRef.current = stockGraphConfig
  }, [stockGraphConfig])
  const gameAdmins = useMemo(
    () => (broadcasterId ? [broadcasterId.toLowerCase(), 'tofeezy'] : ['tofeezy']),
    [broadcasterId],
  )
  const stockGraphBodyRef = useRef<EmoteStockGraphApi>(null)
  const [gameError, setGameError] = useState<string | null>(null)
  const endGameTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (emotesLoading) return () => {}
    if (endGameTimeoutRef.current) clearTimeout(endGameTimeoutRef.current)
    endGameTimeoutRef.current = null
    const unsub = subscribe(({ message, username, mod }) => {
      if ((gameAdmins.includes(username.toLowerCase()) || mod) && message.startsWith('!stockgraph')) {
        try {
          const command = parseCommandType(message)
          switch (command) {
            default:
              // handle commands
              // setStockGraphData(parseStartGameCommand(message, emotes))
              setGameError(null)
          }
        } catch (e: any) {
          setGameError(e.message)
        }
      } else stockGraphBodyRef?.current?.onMessage(message)
    })
    return unsub
  }, [subscribe, emotes, gameAdmins, emotesLoading])
  let body: ReactNode | null = null
  if (!broadcasterId) {
    body = (
      <Text p={2} bg='red'>
        Missing broadcaster ID
      </Text>
    )
  } else if (error) {
    body = (
      <Text p={2} color='red'>
        {error}
      </Text>
    )
  } else {
    body = <StockGraphBody config={stockGraphConfig} ref={stockGraphBodyRef} emotes={emotes} />
  }
  return (
    <Flex align='center' justify='center' flexFlow='column' position='absolute' bottom='0' left='0' h='100%' w='100%'>
      {gameError ? (
        <Text p={1} color='red'>
          {gameError}
        </Text>
      ) : null}
      {body}
    </Flex>
  )
}

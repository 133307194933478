import { UseLiveChatConfig } from '../hooks/useLiveChat'

// for now, 1 for positive, -1 for negative
export const emoteScores: Record<string, number> = {
  Pog: 1,
  PogChamp: 1,
  OOOO: 1,
  bbOOOO: 1,
  PogU: 1,
  PagMan: 1,
  MEMONEY: 1,
  MEMONEYING: 1,
  ok: 1,
  pl: 1,
  EZ: 1,
  FeelsOkayMan: 1,
  Okayge: 1,
  yippee: 1,
  Upgrade: 1,
  Loota: 1,
  PoroEZ: 1,
  Lootge: 1,
  Smile: 1,
  Okayeg: 1,
  StocksUp: 1,
  AlienApprove: 1,
  PoroHappy: 1,
  Peace: 1,
  Okey: 1,
  YeahThatsWhatIWouldaDid: 1,
  PogFish: 1,
  clappi: 1,

  pepeLaugh: -1,
  docNotL: -1,
  bbnotL: -1,
  TrollDespair: -1,
  PepeHands: -1,
  Pain: -1,
  NOOOO: -1,
  monkaS: -1,
  monkaW: -1,
  monkaGIGA: -1,
  monkaOMEGA: -1,
  GivenUp: -1,
  ElNoSabe: -1,
  NAILS: -1,
  PoroSad: -1,
  Ohnoge: -1,
  STOCKS: -1,
  StocksDown: -1,
  notok: -1,
  o7: -1,
  Corncerned: -1,
  Concerned: -1,
  FeelsBadMan: -1,
  Sadge: -1,
  NOkey: -1,
  Pointless: -1,
  eww: -1,
  IHATEAI: -1,
  Despairm: -1,
  Aware: -1,
  sadje: -1,
  poroDumbfounded: -1,
  HARAM: -1,
  DidntLikeThat: -1,
  Sludge: -1,
  Copege: -1,
  Copesen: -1,
}

export const stockGraphViewChatConfig: UseLiveChatConfig = {
  emotes: {
    size: 2,
  },
}

// time to wait before rolling the graph (seconds)
export const ROLLING_PERIOD = 5 * 60
export const VIEWBOX_WIDTH = 1000
export const VIEWBOX_HEIGHT = 500
export const SECOND_WIDTH = VIEWBOX_WIDTH / ROLLING_PERIOD

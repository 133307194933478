import { POLL } from '../../constants/db'
import { getBroadcasterConfigPath } from '../../utils/config'
import { fallbackPollConfig } from './constants'
import { PollWidgetConfig } from './types'

export const getPollDocPath = (broadcasterTwitchId: string) => `${POLL}/${broadcasterTwitchId}`
export const getPollConfigPath = (broadcasterTwitchId: string) => `${getBroadcasterConfigPath(broadcasterTwitchId)}/${POLL}`
export const parsePollConfig = (
  theme: PollWidgetConfig | undefined | null,
): PollWidgetConfig => Object.keys(fallbackPollConfig).reduce((acc, curr) => {
  const fromDb = theme?.[curr as keyof PollWidgetConfig]
  return {
    ...acc,
    [curr]:
        fromDb !== undefined
          ? fromDb
          : fallbackPollConfig[curr as keyof PollWidgetConfig],
  }
}, {}) as PollWidgetConfig

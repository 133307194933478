import { Texture } from '@elan-twitch/shared'

export const SvgTexture = ({
  texture,
  id,
  width,
  height,
  x,
  y,
}: {
  texture: Texture
  id: string
  width: number
  height: number
  x?: number
  y?: number
}) => (
  <filter id={id} x='0' y='0' width='100%' height='100%'>
    <feImage
      href={`/assets/textures/${texture.id}.png`}
      result='texture-img'
      preserveAspectRatio={texture.preserveAspectRatio}
      width={width}
      x={`${x || 0}`}
      y={`${y || 0}`}
      height={texture.preserveAspectRatio === 'none' ? height : width}
    />
    <feTile
      in='texture-img'
      x='0'
      y='0'
      width='100%'
      height='100%'
      result='tile'
    ></feTile>
    <feComposite
      in2='SourceGraphic'
      operator='in'
      in='tile'
      result='composite'
    />
    <feBlend in='SourceGraphic' in2='composite' mode='multiply' />
  </filter>
)

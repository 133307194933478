export const getDateTimeString = (date: Date | number | string) => (
  castDate(date)
).toLocaleString('en-US', {
  minute: '2-digit',
  hour: 'numeric',
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
})

export const getTimeStringWithZone = (date: Date | number | string) => (
  castDate(date)
).toLocaleString('en-US', {
  minute: '2-digit',
  hour: 'numeric',
  timeZoneName: 'short',
})

export const getDateTimeStringWithZone = (date: Date | number | string) => (
  castDate(date)
).toLocaleString('en-US', {
  minute: '2-digit',
  hour: 'numeric',
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  timeZoneName: 'short',
})

const castDate = (date: Date | number | string) => (date instanceof Date ? date : new Date(date))

export const getDateString = (
  date: Date | number | string,
  dateStyle: 'long' | 'short' = 'long',
  utc: boolean = true,
) => {
  let options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }
  if (utc) options.timeZone = 'UTC'
  // if (utc) options.timeZone = 'America/New_York'
  if (dateStyle === 'long') {
    options = {
      ...options,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    }
  }

  return (castDate(date)).toLocaleDateString('en-US', options)
}

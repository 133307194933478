import { Rarity } from './types'

export const getRarityString = (rollThreshold: number): Rarity => {
  if (rollThreshold >= 90) {
    return 'legendary'
  }
  if (rollThreshold >= 75) {
    return 'epic'
  }
  if (rollThreshold >= 50) {
    return 'rare'
  }
  if (rollThreshold >= 25) {
    return 'uncommon'
  }
  return 'common'
}

export const getRarityColor = (rarity: Rarity) => {
  switch (rarity) {
    case 'common':
      return '#666666'
    case 'uncommon':
      return '#66FF66'
    case 'rare':
      return '#6666FF'
    case 'epic':
      return '#9900ff'
    case 'legendary':
      return '#FFD700'
    default:
      return '#333333'
  }
}

import { useContext } from 'react'
import {
  TwitchChat, TwitchChatProps, TwitchPlayer, TwitchPlayerProps,
} from 'react-twitch-embed'
import { BroadcasterContext } from '../../App/Broadcasters/context'

export const StreamView = (props: TwitchPlayerProps) => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)

  return (
    <TwitchPlayer channel={broadcasterId} {...props} />
  )
}

export const StreamChatView = (props: Omit<TwitchChatProps, 'channel'>) => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)

  return (
    <TwitchChat title={`${broadcasterId} chat`} channel={broadcasterId} {...props} />
  )
}

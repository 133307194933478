import { OutcomeWithRank } from '@elan-twitch/shared'
import { truncateNumber } from '@elan-twitch/shared/utils'
import { animated, easings, useSpring } from '@react-spring/web'
import { SvgTexture } from 'components/App/Svg/SvgTexture'
import { useBarColors } from 'components/App/Svg/useBarColors'
import { useTexture } from 'components/App/Svg/useTexture'
import { FadeInText } from 'components/shared/FadeIn'
import 'fonts/Friz-Quadrata/style.css'
import { useMounted } from 'hooks/useMounted'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Trophy } from 'icons/Trophy'
import { Voter } from 'icons/Voter'
import { FC, useMemo } from 'react'
import { usePredictionConfig } from '../../Config/hooks'
import { WinnerPanel } from './WinnerPanel'

const OUTCOME_HEIGHT = 40

const OUTER_PATH = 'M 45.852 22.893 L 951.224 22.893 C 969.687 22.913 971.038 24.381 971.038 51.75 C 971.038 82.599 972.163 80.384 951.224 80.605 L 44.29 80.605 C 23.702 81.734 24.071 73.978 24.071 51.75 C 24.071 29.332 24.573 23.811 45.852 22.893 Z'

const INNER_PATH = 'M 45.852 22.893 L 951.224 22.893 C 969.687 22.913 971.038 24.381 971.038 51.75 C 971.038 82.599 972.163 80.384 951.224 80.605 L 44.29 80.605 C 23.702 81.734 24.071 73.978 24.071 51.75 C 24.071 29.332 24.573 23.811 45.852 22.893 Z'

export const WoWPredictionOutcome: FC<{
  outcomes: OutcomeWithRank[]
  index: number
  winner?: string | null
  barsHidden?: boolean
}> = ({
  outcomes, winner, index, barsHidden,
}) => {
  const outcome = useMemo(() => outcomes[index], [outcomes, index]) as
    | OutcomeWithRank
    | undefined

  const { percent = 0 } = outcome || {}
  const { config: theme } = usePredictionConfig()
  const {
    darkMode, scale = 1.6, texture,
  } = theme || {}
  // const { scale = 1.6 } = theme || {}
  const { color1, color2 } = useBarColors(theme, index, outcomes.length, !!outcome)
  const isWinner = useMemo(
    () => !!winner && winner === outcome?.id,
    [outcome, winner],
  )

  const { mask } = useSpring({
    mask: isWinner ? OUTER_PATH : INNER_PATH,
    delay: isWinner ? 500 : 0,
    config: { duration: 333, easing: easings.easeOutCubic },
  })

  const leftVal = useMemo(() => {
    if (!isWinner) return 971 - 940 * percent
    return darkMode ? 1000 : 0
  }, [isWinner, darkMode, percent])

  const mounted = useMounted(1000, !!outcome)
  const trueLeftVal = useMemo(() => {
    if (!mounted) {
      return darkMode ? 0 : 1000
    }
    return leftVal
  }, [mounted, darkMode, leftVal])
  const { left } = useSpring({
    left: trueLeftVal,
    config: { duration: 1000, easing: easings.easeOutCubic },
  })

  const show = useMemo(
    () => outcome && (!winner || outcome.id === winner),
    [winner, outcome],
  )

  const { title = '' } = outcome || {}
  const marginLeft = useMemo(() => `${scale * 0.2}rem`, [scale])
  const displayedHeight = useMemo(() => {
    if (!show) return 0
    return scale * (barsHidden ? OUTCOME_HEIGHT * 0.65 : OUTCOME_HEIGHT)
  }, [show, barsHidden, scale])

  const textureData = useTexture(texture)
  return (
    <div
      style={{
        height: displayedHeight,
        transition: `height 750ms cubic-bezier(0.83, 0, 0.17, 1) ${
          show && !barsHidden ? 600 * (index + 1) : 0
        }ms`,
        display: 'flex',
        flexFlow: 'column',
        overflow: 'visible',
        justifyContent: 'center',
        alignItems: 'flex-end',
        fontFamily: 'Friz Quadrata',
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          // alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          // top: `${scale * 2}px`,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            transition: `all 750ms cubic-bezier(0.83, 0, 0.17, 1) ${
              show ? 600 * (index + 1) + 1000 : 0
            }ms`,
            opacity: show ? 1 : 0,
            fontSize: `${scale * 0.6}rem`,
            height: `${scale * 12}px`,
            transform: `translate(0, ${show ? 0 : 10}px)`,
          }}
        >
          <FadeInText
            style={{
              fontSize: `${scale * 0.7}rem`,
              flex: 1,
              // lineHeight: 1.5,
              textAlign: 'right',
              paddingLeft: `${scale * 0.3}rem`,
              paddingRight: `${scale * 0.3}rem`,
              fontFamily: 'Friz Quadrata',
              transition: `all 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
                show ? 600 * (index + 1) + 333 : 0
              }ms`,
            }}
          >
            {show ? title : ''}
          </FadeInText>
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
              transition: 'width 1000ms',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: barsHidden || isWinner ? '0px' : `${scale * 140}px`,
            }}
          >
            <ChannelPoint
              // fill={color1}
              style={{
                width: `${scale * 8}px`,
                top: '1px',
                marginLeft: `${scale * 0.5}rem`,
                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.9))',
              }}
            />
            <span
              style={{
                opacity: outcome?.users ? 1 : 0,
                width: `${scale * 32}px`,
                marginLeft,
                whiteSpace: 'nowrap',
                lineHeight: 1.5,
                height: `${scale * 13}px`,
                textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.9))',
                color: 'white',
                transition: 'all 500ms',
              }}
            >
              {outcome?.channel_points
                ? truncateNumber(outcome.channel_points)
                : ''}
            </span>

            <Voter
              style={{
                width: `${scale * 15}px`,
                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.8))',
              }}
            />
            <span
              style={{
                opacity: outcome?.users ? 1 : 0,
                width: `${scale * 26}px`,
                color: 'white',
                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.8))',
                textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                lineHeight: 1.5,
                height: `${scale * 13}px`,
                whiteSpace: 'nowrap',
                transition: 'all 500ms',
              }}
            >
              {truncateNumber(outcome?.users || 0)}
            </span>
            <Trophy
              fill='white'
              stroke='none'
              style={{
                width: `${scale * 10}px`,
                top: '1px',
                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.8))',
                opacity: 0.9,
              }}
            />
            <span
              style={{
                opacity: outcome?.percent ? 1 : 0,
                marginLeft,
                whiteSpace: 'nowrap',
                lineHeight: 1.5,
                color: 'white',
                textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.8))',
                height: `${scale * 13}px`,
                width: `${scale * 28}px`,
                transition: 'all 500ms',
              }}
            >
              {outcome?.percent ? `${(1 / outcome.percent).toFixed(1)}x` : ''}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          // width: '100%',
          transition: 'height 500ms ease',
        }}
      >
        <svg
          viewBox='0 0 1000 100'
          style={{
            height: `${(barsHidden ? 14 : 23) * scale}px`,
            opacity: show ? 1 : 0,
            transition: `opacity 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
              show ? 600 * (index + 1) + 333 : 0
            }ms, height 1000ms`,
            position: 'relative',
            overflow: 'visible',
          }}
        >
          <defs>
            {textureData ? (
              <SvgTexture
                width={1000}
                height={100}
                texture={textureData}
                id={`texture-${outcome?.id || ''}`}
              />
            ) : null}

            {/* <filter id='f3' x='-1' y='-1' width='250%' height='250%'>
              <feOffset result='offOut' in='SourceAlpha' dx='5' dy='5' />
              <feGaussianBlur result='blurOut' in='offOut' stdDeviation='10' />
              <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
            </filter> */}
            <filter id='wowblur' x='-50%' y='-50%' width='250%' height='250%'>
              <feOffset result='offOut' in='SourceAlpha' dx='0' dy='0' />
              <feGaussianBlur result='blurOut' in='offOut' stdDeviation='14' />
              <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
            </filter>
            <clipPath id={`cutoff-${index}`}>
              <animated.path d={mask} />
            </clipPath>
            <linearGradient id={`bar-outcome-${outcome?.id}`} x1='0' y1='0' x2='0' y2='1'>
              <animated.stop offset='0%' stopColor={color1} />
              <animated.stop offset='100%' stopColor={color2} />
            </linearGradient>
          </defs>
          <path
            d={INNER_PATH}
            filter={texture ? `url(#texture-${outcome?.id || ''})` : undefined}
            fill='rgb(120,120,120)'
            stroke='none'
            // style={{ opacity: isWinner ? 0 : 1, transition: 'opacity 500ms' }}
          />
          {/* <path
            d='M 0 50 L 1000 50'
            strokeWidth='65'
            stroke={darkMode ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.3)'}
            style={{ opacity: isWinner ? 0 : 1, transition: 'opacity 500ms' }}
            clipPath={`url(#cutoff-${index})`}
          /> */}

          <animated.path
            d={left.to((l) => `M 1200 0 L ${l} 0 L ${l} 100 L 1200 100 Z`)}
            filter={texture ? `url(#texture-${outcome?.id || ''})` : undefined}
            // filter='url(#texture)'
            fill={darkMode ? 'rgba(0,0,0,0.6)' : `url(#bar-outcome-${outcome?.id})`}
            clipPath={`url(#cutoff-${index})`}
          />
          <image
            filter='url(#wowblur)'
            preserveAspectRatio='none'
            href='/assets/images/wow-border1.png'
            x='10'
            y='12'
            width='980'
            height='76'
          />
        </svg>
        <WinnerPanel outcome={outcome} isWinner={isWinner} />
      </div>
    </div>
  )
}

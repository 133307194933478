import { gradientToCssGradientProp, gradients } from '@elan-twitch/shared'
import { ExpandButton } from 'components/shared/Buttons'
import { useMemo } from 'react'
import { useAuctionContext } from './context'

export const ConfirmResults = ({ onConfirm, isLoading }:
  { onConfirm: () => Promise<void>; isLoading: boolean }) => {
  const { auction } = useAuctionContext()
  const canConfirm = useMemo(() => {
    if (!auction) return false
    if (auction.ended_at && !auction.winner && auction.bids.length) return true
    return false
  }, [auction])
  return (
    <>
      <ExpandButton
        active={canConfirm}
        onClick={async () => {
          try {
            await onConfirm()
          } catch (err) {
            console.error(err)
          }
        }}
        isLoading={isLoading}
        opacity={0.8}
        bg={gradientToCssGradientProp(gradients.gold)}
        _hover={{ opacity: 1 }}
        width={160}
      >
        CONFIRM RESULTS
      </ExpandButton>
    </>
  )
}

import { RepeatIcon } from '@chakra-ui/icons'
import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react'
import { FC, forwardRef, useContext } from 'react'
import { ListSortView } from './ListSort'
import { DataListPresetFilters } from './PresetFilters'
import { InfiniteScrollListContext } from './context'

export const ListHeader = forwardRef<HTMLDivElement, { Component?: FC }>(({ Component }, ref) => {
  const borderColor = useColorModeValue('#cdcdcd', '#2d3748')
  // const iconButtonBg = useColorModeValue('blackAlpha.300', 'whiteAlpha.300')
  // const iconButtonColor = useColorModeValue('whiteAlpha.500', '#cdcdcd')
  const {
    data: { refetch },
  } = useContext(InfiniteScrollListContext)
  return (
    // test test
    <Flex p={2} borderBottom={`1px solid ${borderColor}`} w='100%' ref={ref}>
      <Flex gap={2} flex={1} align='center'>
        <DataListPresetFilters />
        <ListSortView />
        <IconButton
          mr={2}
          // color={iconButtonColor}
          borderRadius='full'
          // bg={iconButtonBg}
          ml='auto'
          size='sm'
          aria-label='Refresh'
          icon={<RepeatIcon w={4} h={4} />}
          onClick={refetch}
        />
      </Flex>
      {Component ? (
        <Flex>
          <Component />
        </Flex>
      ) : null}
    </Flex>
  )
})

import { Broadcaster, WheelAuction, WithId } from '@elan-twitch/shared'
import {
  db, getWheelAuctionDocPath,
} from 'backend/db'
import {
  Query,
  collection,
  orderBy,
  query,
} from 'firebase/firestore'
import { useDocument, useQuery } from 'hooks/db'
import { useMemo } from 'react'
import { paletteOptions } from './constants'

export const usePalette = (auction: WheelAuction | null) => useMemo(() => {
  if (!auction?.palette) return paletteOptions[2]
  const p = auction.palette || '2'
  const paletteAsNumber = Number(p)
  return Number.isNaN(paletteAsNumber)
    ? paletteOptions[2]
    : paletteOptions[paletteAsNumber]
}, [auction])

export const useWheelAuction = (broadcaster: WithId<Broadcaster>) => {
  const path = useMemo(
    () => (broadcaster.pointsBankAccountConfig?.currentWheelAuctionId
      ? getWheelAuctionDocPath(
        broadcaster._id,
        broadcaster.pointsBankAccountConfig?.currentWheelAuctionId,
      )
      : null),
    [broadcaster],
  )
  return useDocument<WheelAuction>(path)
}

const getSavedWheelQuery = (broadcaster: WithId<Broadcaster>) => query(
  collection(db, `broadcaster/${broadcaster._id}/wheelAuction`),
  orderBy('created_at', 'desc'),
) as Query<WheelAuction>

export const useSavedWheels = (broadcaster: WithId<Broadcaster>) => {
  const q = useMemo(() => getSavedWheelQuery(broadcaster), [broadcaster])

  return useQuery<WheelAuction>(q)
}

import { a, useSpring } from '@react-spring/web'
import { ROLLING_PERIOD, SECOND_WIDTH } from 'components/Widgets/Chat/EmoteStockGraphView/constants'
import { forwardRef, useImperativeHandle, useMemo } from 'react'
import { StockGraphTimerApi } from './types'

export const StockGraphTimer = forwardRef<StockGraphTimerApi, { interval: number; scaleY: number }>(
  ({ scaleY }, ref) => {
    // const textRef = useRef<SVGTextElement | null>(null)

    const [{ x }, api] = useSpring(() => ({
      x: 0,
    }))

    const lineWidth = useMemo(() => Math.max(scaleY / 3, 1), [scaleY])
    useImperativeHandle(
      ref,
      () => ({
        onSecond: (t) => {
          if (t < ROLLING_PERIOD - 10) {
            api.start({
              x: t * SECOND_WIDTH,
            })
          }
          // if (textRef.current) textRef.current.textContent = `${t}s`
        },
      }),
      [api],
    )
    return (
      <>
        {/* <a.text ref={textRef} x={x.to((v) => v + SECOND_WIDTH * 6)} y={8} fontSize={16} fill='#00000077'/> */}
        <a.path
          d={x.to((v) => `M 5 ${-lineWidth} L ${v + 5} ${-lineWidth} L ${v + 5} ${lineWidth} L 5 ${lineWidth} Z`)}
          filter='url(#stock-graph-drop-shadow)'
          x1={0}
          y1={0}
          x2={x}
          y2={0}
          fill='#ffffff88'
          stroke='none'
        />
      </>
    )
  },
)

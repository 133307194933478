import {
  ArrowForwardIcon, ChevronUpIcon, CloseIcon, MinusIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  Broadcaster,
  BROADCASTER,
  getDateTimeStringWithZone,
  MovieListMovie,
  TextField,
  truncateNumber,
  WithId,
} from '@elan-twitch/shared'
import useResizeObserver from '@react-hook/resize-observer'
import {
  collection, CollectionReference, doc, updateDoc,
} from 'firebase/firestore'
import {
  animate, AnimationPlaybackControls, motion, useDragControls, useMotionValue, useTransform,
} from 'framer-motion'
import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { db } from '../../../backend/db'
import { useIsChannelAdmin } from '../../../hooks/useIsChannelAdmin'
import { BroadcasterContext } from '../../App/Broadcasters/context'
import { StandaloneInput } from '../../forms/input'
import { ModBadge } from '../../shared/Badges/ModBadge'
import { ContentBox } from '../../shared/ContentBox'
import { Loading } from '../../shared/Loading'
import { useTopMovies } from './hooks'
import { MoviePosterView } from './MovieView/MovieSummary'

const MovieTicker = ({
  movie,
  size,
  dragging,
}: {
  movie: WithId<MovieListMovie>
  index: number
  size: number
  dragging: boolean
}) => {
  const { title, vote_score, release_date } = movie
  const year = useMemo(() => (release_date ? release_date.split('-')[0] : 'No release year'), [release_date])
  const { basePath } = useContext(BroadcasterContext)
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)

  const { votesSize, titleSize, yearSize } = useMemo(() => {
    if (size < 160) {
      return { votesSize: 'xl', titleSize: 'sm', yearSize: 'sm' }
    }
    if (size < 200) {
      return { votesSize: '2xl', titleSize: 'md', yearSize: 'md' }
    }
    return { votesSize: '3xl', titleSize: 'xl', yearSize: 'xl' }
  }, [size])

  const px = useMemo(() => (size < 160 ? 3 : 4), [size])
  const py = useMemo(() => (size < 160 ? 4 : 6), [size])

  return (
    <Box pointerEvents={dragging ? 'none' : 'auto'} userSelect='none' w={`${size}px`} h={`${size}px`} p='5px'>
      <Flex
        aria-label={title}
        onClick={() => navigate(`${basePath}/movies/${movie._id}`)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        display='flex'
        cursor='pointer'
        alignItems='center'
        justifyContent='center'
        px={px}
        py={py}
        borderRadius={6}
        w='100%'
        h='100%'
        position='relative'
        flexFlow='column'
        bg='black'
      >
        <MoviePosterView
          opacity={hovered ? 0.6 : 0.4}
          transition='opacity 300ms'
          position='absolute'
          bg='transparent'
          height={size - 10}
          width={size - 10}
          movie={movie}
        />
        <Center
          transform={`scale(${hovered ? 1.04 : 1})`}
          transition='transform 200ms'
          h='100%'
          w='100%'
          borderRadius='full'
          flexFlow='column'
          zIndex={2}
        >
          <Flex align='center'>
            <Text
              color='green.300'
              fontFamily='Encode Sans'
              as='span'
              fontWeight={600}
              lineHeight={1}
              fontSize={votesSize}
              textShadow='1px 1px 3px #000000ff'
            >
              {truncateNumber(vote_score)}
            </Text>
            <ChevronUpIcon color='green.300' w={8} h={8} />
          </Flex>
          <Center flex={1} minH='0'>
            <Text
              lineHeight={1.2}
              flex={1}
              noOfLines={3}
              textAlign='center'
              maxW='100%'
              fontWeight={600}
              fontSize={titleSize}
              color='white'
              textShadow='1px 1px 3px #000000aa'
            >
              {title}
            </Text>
          </Center>
          {year ? (
            <Text
              fontSize={yearSize}
              fontWeight={500}
              lineHeight={1}
              textShadow='1px 1px 3px #00000088'
              color='gray.100'
              fontFamily='Encode Sans'
            >
              {year}
            </Text>
          ) : null}
        </Center>
        {/* index indicator */}
      </Flex>
    </Box>
  )
}

// movie nights every sunday at 6pm GMT+2
const getNextMovieNightTime = () => {
  // Get the current date in the Sweden time zone
  const now = new Date()

  // Calculate the next Sunday in Sweden time zone
  const day = now.getDay()
  const daysUntilSunday = (7 - day) % 7

  const sunday = new Date(now)
  // Set date to next Sunday
  sunday.setTime(sunday.getTime() + daysUntilSunday * 24 * 60 * 60 * 1000)
  // Set the time to 18:00:00 (6 PM) in Sweden time
  sunday.setUTCHours(16, 0, 0, 0)

  return sunday
}

const noMovieNightMessageField: TextField = {
  _type: 'text',
  placeholder: 'No movie night message',
}

const NoMovieNightPopoverContent = ({ onClose }: { onClose: () => void }) => {
  const {
    broadcaster: { _id: broadcasterId, noMovieNightMessage },
  } = useContext(BroadcasterContext)
  const [message, setMessage] = useState(noMovieNightMessage || '')

  useEffect(() => {
    setMessage(noMovieNightMessage || '')
  }, [noMovieNightMessage])

  const toast = useToast()
  const docRef = useMemo(
    () => doc(collection(db, BROADCASTER) as CollectionReference<Broadcaster>, broadcasterId),
    [broadcasterId],
  )
  const [isLoading, setIsLoading] = useState(false)
  const onUpdate = useCallback(
    async (m: string) => {
      // update the message
      setIsLoading(true)
      try {
        await updateDoc(docRef, 'noMovieNightMessage', m)
        toast({
          title: 'Message updated',
          description: 'The message has been updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        onClose()
      } catch (err: any) {
        toast({
          title: 'Error updating message',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      setIsLoading(false)
    },
    [docRef, toast, onClose],
  )

  return (
    <Flex gap={2} p={3} w='100%' flexFlow='column'>
      <Flex align='center' gap={1} w='100%'>
        <Box flex={1} minW='0'>
          <StandaloneInput onChange={setMessage} value={message} field={noMovieNightMessageField} />
        </Box>
        <Button size='sm' onClick={() => onUpdate(message)}>
          Update
        </Button>
      </Flex>
      <Flex align='center' w='100%' gap={2}>
        <Button flex={1} gap={1} onClick={() => onUpdate('')} isLoading={isLoading} loadingText='Updating'>
          <MinusIcon />
          <Text>Remove message</Text>
        </Button>
        <IconButton aria-label='Close' icon={<CloseIcon />} onClick={onClose} size='sm' />
      </Flex>
    </Flex>
  )
}

const NoMovieNightPopover = () => (
  <Popover placement='top' strategy='fixed' isLazy>
    {({ onClose }) => (
      <>
        <PopoverTrigger>
          <Button pl={2} pr={3} ml={2} gap={1} size='sm' minH={0} h={7}>
            <ModBadge />
            <Text>No movie night this week?</Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent w='375px'>
          <NoMovieNightPopoverContent onClose={onClose} />
          <PopoverArrow />
        </PopoverContent>
      </>
    )}
  </Popover>
)

const MovieListButton = () => {
  const { basePath } = useContext(BroadcasterContext)
  return (
    <Link to={`${basePath}/movies`}>
      <IconButton
        ml='auto'
        borderRadius='full'
        size='sm'
        icon={<ArrowForwardIcon w={5} h={5} />}
        aria-label='View full movie list'
        variant='ghost'
      />
    </Link>
  )
}

export const MovieListPreview = () => {
  const {
    broadcaster: { _id: broadcasterId, noMovieNightMessage },
  } = useContext(BroadcasterContext)

  const isAdmin = useIsChannelAdmin()
  const { data, isLoading } = useTopMovies(broadcasterId)

  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const onContainerResize = useCallback((e: ResizeObserverEntry) => {
    setContainerWidth(e.target.clientWidth)
  }, [])

  const mobileLayout = useMemo(() => containerWidth < 769, [containerWidth])

  useResizeObserver(containerRef, onContainerResize)

  const [contentWidth, setContentWidth] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const onContentResize = useCallback((e: ResizeObserverEntry) => {
    setContentWidth(e.target.clientWidth)
  }, [])

  useEffect(() => {
    setContentWidth(contentRef.current?.clientWidth || 0)
  }, [data])

  const offset = useMotionValue(0)
  const transformX = useTransform(offset, (value) => -value)
  const dragControls = useDragControls()

  useResizeObserver(contentRef, onContentResize)
  const itemSize = useMemo(() => (mobileLayout ? 150 : 200), [mobileLayout])

  const maxOffset = useMemo(() => Math.max(0, Math.ceil(contentWidth - containerWidth)), [containerWidth, contentWidth])

  const goNextTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const currAnim = useRef<AnimationPlaybackControls | null>(null)
  const onGoNext = useCallback(() => {
    if (currAnim.current) return
    // offset.set(Math.min(maxOffset, offset.get() + itemSize))
    currAnim.current = animate(offset, Math.min(maxOffset, offset.get() + itemSize * 2), {
      duration: 0.5,
      onComplete: () => {
        currAnim.current = null
      },
    })
  }, [itemSize, offset, maxOffset])

  const onGoPrev = useCallback(() => {
    if (currAnim.current) return
    // offset.set(Math.max(0, offset.get() - itemSize))
    currAnim.current = animate(offset, Math.max(0, offset.get() - itemSize * 2), {
      duration: 0.5,
      onComplete: () => {
        currAnim.current = null
      },
    })
  }, [itemSize, offset])

  const onHoverNext = useCallback(() => {
    if (goNextTimeout.current) {
      clearTimeout(goNextTimeout.current)
    }
    onGoNext()
    goNextTimeout.current = setTimeout(() => {
      onHoverNext()
    }, 800)
  }, [onGoNext])

  const onLeaveNext = useCallback(() => {
    if (goNextTimeout.current) {
      clearTimeout(goNextTimeout.current)
    }
  }, [])

  const onHoverPrev = useCallback(() => {
    if (goNextTimeout.current) {
      clearTimeout(goNextTimeout.current)
    }
    onGoPrev()
    goNextTimeout.current = setTimeout(() => {
      onHoverPrev()
    }, 800)
  }, [onGoPrev])

  const onLeavePrev = useCallback(() => {
    if (goNextTimeout.current) {
      clearTimeout(goNextTimeout.current)
    }
  }, [])
  const [dragging, setDragging] = useState(false)

  const nextMovieNight = useMemo(() => getNextMovieNightTime(), [])

  return (
    <ContentBox
      style={{ touchAction: 'none' }}
      ref={containerRef}
      bg='blackAlpha.400'
      px={0}
      py={0}
      w='100%'
      flexFlow='column'
    >
      <Flex px={3} gap={[0, 0, 1]} flexFlow={['column', 'column', 'row']} justify={['flex-start', 'flex-start', 'space-between']} w='100%' align='center'>
        <Flex w={['100%', '100%', 'unset']} align='center'>
          <a href='https://nymn.gg/contests' target='_blank'>
            <Button variant='link' fontFamily='Noto Sans' fontWeight={600} textShadow='1px 1px 3px #00000077'>
              Movie Night
            </Button>
          </a>
          <Box display={['block', 'block', 'none']} ml='auto'>
            <MovieListButton />
          </Box>
        </Flex>
        <Flex flexFlow={['column', 'column', 'row']} align='center'>
          {noMovieNightMessage ? (
            <Image
              position='relative'
              bottom='3px'
              height='32px'
              alt='alert'
              src='https://cdn.7tv.app/emote/62f9cabd00630d5b2acd66f0/4x.webp'
            />
          ) : null}
          <Text fontSize={['sm', 'sm', 'md']} opacity={0.8}>
            {noMovieNightMessage
              ? `No movie this week: ${noMovieNightMessage}`
              : `Next movie night around ${getDateTimeStringWithZone(nextMovieNight)}`}
          </Text>
          {isAdmin ? (
            <Flex gap={1} align='center' py={2}>
              <NoMovieNightPopover />
            </Flex>
          ) : null}
        </Flex>
        <Box display={['none', 'none', 'block']}>
          <MovieListButton />
        </Box>
      </Flex>
      <Flex position='relative' h={`${itemSize + 10}`} overflow='hidden' w='100%' flexFlow='row'>
        {isLoading ? (
          <Center w='100%' h='100%'>
            <Loading noBox text='Loading movie list' />
          </Center>
        ) : (
          <motion.div
            // drag={mobileLayout ? 'x' : false}
            drag='x'
            dragControls={dragControls}
            dragConstraints={{ left: -maxOffset, right: 0 }}
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
            ref={contentRef}
            style={{
              display: 'flex',
              flexFlow: 'row',
              padding: '0.25rem',
              width: 'auto',
              x: transformX,
            }}
          >
            {data.map((movie, idx) => (
              <MovieTicker dragging={dragging} size={itemSize} index={idx} key={movie._id} movie={movie} />
            ))}
          </motion.div>
        )}
        {mobileLayout ? null : (
          <>
            <Box
              display={['none', 'none', 'block']}
              onMouseEnter={onHoverPrev}
              onMouseLeave={onLeavePrev}
              position='absolute'
              width='100px'
              left={0}
              top={0}
              bottom={0}
              zIndex={1}
            />
            <Box
              display={['none', 'none', 'block']}
              onMouseEnter={onHoverNext}
              onMouseLeave={onLeaveNext}
              position='absolute'
              width='100px'
              right={0}
              top={0}
              bottom={0}
              zIndex={1}
            />
          </>
        )}
      </Flex>
    </ContentBox>
  )
}

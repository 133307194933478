import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react'
import { cssVar } from '@chakra-ui/theme-tools'

import { switchAnatomy } from '@chakra-ui/anatomy'
import { gradients, gradientToCssGradientProp } from '@elan-twitch/shared'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys)

const switchBaseTheme = definePartsStyle({
  // define the part you're going to style
  container: {
    // ...
  },
  thumb: {
    bg: 'whiteAlpha.700',
    _checked: {
      bg: 'whiteAlpha.900',
    },
  },
  track: {
    bg: 'whiteAlpha.400',
    _checked: {
      bg: gradientToCssGradientProp(gradients.blue, 430),
    },
  },
})

export const switchTheme = defineMultiStyleConfig({ baseStyle: switchBaseTheme })
const $arrowBg = cssVar('popper-arrow-bg')
export const theme = extendTheme({
  components: {
    Badge: {
      baseStyle: {
        bg: '#9a9a9a',
        textShadow: 'none',
        color: 'white',
        boxShadow: '1px 1px 3px #00000077',
        _dark: {
          bg: '#555555',
          textShadow: '1px 1px 2px #00000077',
          color: '#efefef',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        bg: '#ababab',
        textShadow: '0 0 3px rgba(0,0,0,0.5)',
        color: 'white',
        [$arrowBg.variable]: '#ababab',
        _dark: {
          bg: '#777777',
          textShadow: 'none',
          color: '#efefef',
          [$arrowBg.variable]: '#777777',
        },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'Encode Sans',
        fontWeight: 500,
        _focus: { boxShadow: 'none' },
      },
      variants: {
        link: {
          color: '#444',
          _dark: { color: 'rgba(230,230,255,0.9)' },
        },
        ghost: {
          _hover: { bg: 'blackAlpha.500' },
        },
        solid: {
          textShadow: '1px 1px 3px #00000077',
          bg: 'blackAlpha.400',
          color: 'whiteAlpha.800',
          _hover: {
            bg: 'blackAlpha.500',
          },
          _dark: {
            bg: 'whiteAlpha.300',
            color: 'whiteAlpha.800',
            _hover: {
              bg: 'whiteAlpha.400',
            },
          },
        },
        outline: {
          border: '1px solid',
          borderColor: '#ababab',
          color: '#656565',
          _hover: {
            bg: 'blackAlpha.100',
          },
          _dark: {
            border: '1px solid #999',
            color: '#999',
            _hover: {
              bg: 'whiteAlpha.300',
            },
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: { _focus: { boxShadow: 'none' } },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'Encode Sans',
      },
    },
    Popover: {
      baseStyle: {
        root: { _focus: { boxShadow: 'none' } },
        content: {
          bg: 'gray.100',
          border: 'none',
          boxShadow: '0 0 4px rgba(0,0,0,0.5)',
          [$arrowBg.variable]: 'gray.100',
          _dark: {
            bg: '#1b1b1b',
            [$arrowBg.variable]: '#1b1b1b',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _focus: { boxShadow: 'none' },
          border: '2px solid #ababab',
          borderColor: '#ababab',
          _checked: {
            bg: '#666666',
            color: '#efefef',
            border: 'none',
          },
          _hover: { bg: '#555', border: 'none' },
          _dark: {
            _checked: {
              bg: '#efefef',
              color: '#444444',
              border: 'none',
            },
            _hover: { bg: '#555', border: 'none' },
          },
        },
        label: {
          color: '#888',
          _dark: { color: '#999999' },
          _checked: {
            color: '#444444',
            _dark: { color: '#cdcdcd' },
          },
        },
      },
      variants: {
        noBox: {
          control: {
            bg: 'transparent',
            _hover: { bg: 'none' },
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          _focus: {
            boxShadow: 'none',
            outline: 'none',
            border: '1px solid #bcbcbc',
          },
        },
      },
      variants: {
        text: {
          field: {
            bg: 'blackAlpha.200',
            borderRadius: 6,
            border: '1px solid #dedede',
            _focus: { boxShadow: 'none', borderColor: '#bcbcbc' },
            _active: { boxShadow: 'none' },
            _placeholder: { color: '#777' },
            _dark: {
              bg: 'blackAlpha.500',
              border: '1px solid #777',
              _focus: { borderColor: '#999', boxShadow: 'none' },
              _placeholder: { color: '#777' },
            },
          },
        },
      },
      defaultProps: {
        variant: 'text',
      },
    },
    Switch: switchTheme,
    Menu: {
      baseStyle: {
        list: {
          bg: '#dedede',
          border: 'none',
          boxShadow: '0 0 6px rgba(0,0,0,0.5)',
          _dark: {
            bg: '#555555',
          },
        },
        item: {
          _hover: {
            bg: 'rgba(0,0,0,0.3)',
          },
          _focus: {
            bg: 'rgba(0,0,0,0.2)',
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: '#00000000',
      },
    },
  },
})

import { Center, CenterProps } from '@chakra-ui/react'

export const CollapseHorizontal = ({
  width,
  children,
  active,
  duration = 400,
  ...props
}: CenterProps & { width: number; active: boolean, duration?: number }) => (
  <Center
    width={`${active ? width : 0}px`}
    opacity={active ? 1 : 0}
    // transition={`width ${d
    // uration}ms ease ${active ? 0 : duration}ms, opacity ${duration}ms ease ${
    //   active ? duration : 0
    // }ms`}
    transition={`all ${duration}ms ease-in-out`}
    overflow='hidden'
    {...props}
  >
    {children}
  </Center>
)

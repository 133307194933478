import React, { FC, SVGProps } from 'react'

const getStyles1 = (color: string) => ({
  fill: 'none',
  stroke: color,
  strokeMiterlimit: 10,
  strokeWidth: '8px',
})
const getStyles2 = (color: string) => ({
  fill: 'none',
  stroke: color,
  strokeWidth: '8px',
})
const getStyles3 = (color: string) => ({ fill: color })
export const Voter: FC<SVGProps<SVGSVGElement> & { color?: string }> = ({
  color = 'white',
  ...props
}) => {
  const s1 = getStyles1(color)
  const s2 = getStyles2(color)
  const s3 = getStyles3(color)
  return (
    <svg viewBox='0 0 91.02 89' {...props}>
      <path
        {...s1}
        d='M19.64,67.56s-1-10.4,2.5-15.06c3-4,6.27-5.67,10.5-5.5,6.17.25,8.69,1.76,11.5,5.5,3,4,3,16,3,16'
      />
      <path
        {...s1}
        d='M32.64,19s10-1,10,10c0,11.61-11.77,9.82-10,10,0,0-9-.39-9-10S32.64,19,32.64,19Z'
      />
      <line {...s2} x1='33.14' y1='39.01' x2='33.14' y2='47.01' />
      <path
        {...s3}
        d='M59.14,31.5s-8-2-8,8c0,7,3.5,8.5,4.5,9.5s2,3,0,8c-2.73,6.81-1.35,12,6,12,7,0,9.17-4.07,6-12-2-5-7.5-4.5-5.5-7.5s6-2,5-9S59.14,31.5,59.14,31.5Z'
      />
    </svg>
  )
}

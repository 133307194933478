import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  HStack,
} from '@chakra-ui/react'
import { gradients, gradientToCssGradientProp } from '@elan-twitch/shared'
import { useRef } from 'react'
import { ShadowText } from './ShadowText'

export const DiscardChangesDialog = ({
  isOpen,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          p={2}
          width='400px'
          boxShadow='0 0 5px black'
          bg='gray.800'
        >
          <AlertDialogHeader py={2} pb={1} fontSize='lg'>
            <Center>
              <ShadowText
                style={{
                  fontWeight: 500,
                  fontSize: '1.2rem',
                  fontFamily: 'Encode Sans',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                DISCARD CHANGES?
              </ShadowText>
            </Center>
          </AlertDialogHeader>
          <AlertDialogBody>
            <HStack w='100%'>
              <Button bg='whiteAlpha.600' flex={1} onClick={onCancel} px={2}>
                Cancel
              </Button>
              <Button
                bg={gradientToCssGradientProp(gradients.red)}
                flex={1}
                onClick={onConfirm}
              >
                Discard Changes
              </Button>
            </HStack>
          </AlertDialogBody>
          <AlertDialogCloseButton />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

import { InputRef, TimeField } from '@elan-twitch/shared'
import {
  FC,
  ForwardedRef, forwardRef, useImperativeHandle,
} from 'react'
// @ts-ignore
import { Input } from '@chakra-ui/react'
import '../input.css'
import { InputProps } from '../types'

const BaseTimeInput = ({
  field,
  input,
}: InputProps<TimeField>, ref: ForwardedRef<InputRef>) => {
  useImperativeHandle(ref, () => ({
    focus: () => {},
    blur: () => {},
  }))
  return (
    <Input
      fontFamily='DM Sans'
      css={`
        ::-webkit-calendar-picker-indicator {
          background: url(/svg/clock.svg) center/80% no-repeat;
          color: white;
        }
      `}
      fontSize='md'
      type='time'
      placeholder={field.placeholder}
      {...input}
      value={input.value || ''}
    />
  )
}

export const TimeInput = forwardRef<InputRef, InputProps<TimeField>>(BaseTimeInput as any) as FC<InputProps<TimeField>>

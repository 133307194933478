import { ArrowLeftIcon } from '@chakra-ui/icons'
import {
  Box, BoxProps, IconButton, StackProps,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { ContentBox } from './ContentBox'

const xor = (i1: boolean, i2: boolean) => (i1 && !i2) || (!i1 && i2)

export const DashboardContent = ({
  expandFrom,
  boxProps,
  width,
  ...props
}: StackProps & { expandFrom?: 'left' | 'right', boxProps?: BoxProps, width: number }) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const [expanded, setExpanded] = useState(true)
  return (
    <Box pointerEvents='auto' position='relative' {...boxProps}>
      <Box
        position='relative'
        overflow='hidden'
        transition={`width 333ms ease ${
          expanded ? 0 : 333
        }ms, opacity 333ms ease ${expanded ? 333 : 0}ms`}
        opacity={expanded ? 1 : 0}
        width={expandFrom ? `${expanded ? width : 0}px` : 'auto'}
      >
        <ContentBox
        // @ts-ignore
          ref={contentRef}
          flexFlow='column'
          p={4}
          width='auto'
          borderRadius={6}
          {...props}
        />
      </Box>
      {expandFrom ? (
        <IconButton
          onClick={() => setExpanded(!expanded)}
          size='sm'
          aria-label='expand/hide'
          top='6px'
          left={expandFrom === 'right' ? '-38px' : 'unset'}
          right={expandFrom === 'left' ? '-38px' : 'unset'}
          position='absolute'
          icon={(
            <ArrowLeftIcon
              transform={
                xor(expandFrom === 'left', expanded)
                  ? 'rotate(180deg)'
                  : undefined
              }
            />
          )}
        />
      ) : null}
    </Box>
  )
}

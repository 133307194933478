import { ColorModeScript } from '@chakra-ui/react'
import { createRoot } from 'react-dom/client'
import App from './components/App'

// After

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
  <>
    <ColorModeScript initialColorMode='dark' />
    <App />
  </>,
)

import { TugOfWarData } from './types'

export const parseStartGameCommand = (
  message: string,
  emotes: Record<string, string>,
): TugOfWarData => {
  const args = message.split(' ').filter((arg) => !!arg.trim())
  if (args.length < 3) throw new Error('Invalid command')
  const emote1 = args[1]
  const emote2 = args[2]
  const duration = args[3]
  if (!emote1) throw new Error('Missing emote 1 in command')
  if (!emote2) throw new Error('Missing emote 2 in command')
  const emote1Url = emotes[emote1]
  const emote2Url = emotes[emote2]
  if (!emote1Url) throw new Error(`Invalid emote 1: ${emote1}`)
  if (!emote2Url) throw new Error(`Invalid emote 2: ${emote2}`)
  return {
    emote1: {
      url: emote1Url,
      name: emote1,
      count: 0,
    },
    emote2: {
      url: emote2Url,
      name: emote2,
      count: 0,
    },
    endsAt: Date.now() + Number(duration || 60) * 1000,
  }
}

export const parseCommandType = (message: string) => {
  const args = message.split(' ').filter((arg) => !!arg.trim())
  if (args.length < 2) throw new Error('Invalid command')
  const command = args[1]
  return command
}

import { Prediction, PredictionOverlayVersion } from '@elan-twitch/shared'
import { FC, useMemo } from 'react'
import { usePredictionConfig } from '../Config/hooks'
import { ClassicPredictionView } from './ClassicPrediction'
import { OWPrediction } from './OWPrediction'
import { RLPrediction } from './RLPrediction'
import { WoWPrediction } from './WoWPrediction'

const versionComponents: Record<PredictionOverlayVersion, FC<{ data?: Prediction | null }>> = {
  ow: OWPrediction,
  classic: ClassicPredictionView,
  rl: RLPrediction,
  wow: WoWPrediction,
}

export const PredictionView = ({ data }: { data?: Prediction | null }) => {
  const {
    config: { version },
  } = usePredictionConfig()

  const Component = useMemo(() => versionComponents[version || 'ow'], [version])

  return <Component data={data} />
}

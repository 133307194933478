import { useToast } from '@chakra-ui/react'
import { FeathersApplication } from '@feathersjs/client'
import {
  createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import { BroadcasterContext } from '../components/App/Broadcasters/context'
import { createSocket } from './client'

export type FeatherContextData = {
  client: FeathersApplication | null
}

const FeathersContext = createContext<FeatherContextData>({
  client: null,
})

export const useFeathers = () => useContext(FeathersContext)

export const FeatherProvider = ({ children }: PropsWithChildren) => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)

  const [client, setClient] = useState<FeathersApplication | null>(null)
  const toast = useToast()
  const clientBroadcasterId = useRef<string | null>(null)
  useEffect(() => {
    if (broadcasterId === clientBroadcasterId.current) return
    if (!broadcasterId) {
      toast({
        title: 'Error in feathers provider: No broadcaster id',
        status: 'error',
        duration: 5000,
      })
      clientBroadcasterId.current = null
      return
    }
    console.log('Creating socket with broadcasterId: ', broadcasterId)
    clientBroadcasterId.current = broadcasterId
    setClient(createSocket(broadcasterId))
  }, [broadcasterId, toast])

  const contextData = useMemo(() => ({ client }), [client])
  return (
    <FeathersContext.Provider value={contextData}>
      {children}
    </FeathersContext.Provider>
  )
}

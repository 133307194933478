import { useEffect, useRef, useState } from 'react'

export const usePrevious = <T>(value: T) => {
  const [prev, set] = useState<T | null>(null)
  const ref = useRef(value)
  useEffect(() => {
    set(ref.current)
    ref.current = value
  }, [value])

  return prev
}

import { twitchEvents, twitchUserScopes } from '../../constants/twitch'
import { Widget } from '../types'
import { pointsAuctionAddOn } from './pointsAuction'
import { randomDropsAddOn } from './randomDrops'
import { wheelAuctionAddOn } from './wheelAuction'

export const pointsBankWidget: Widget = {
  id: 'points-bank',
  name: 'Points Bank',
  requiredScopes: [twitchUserScopes.CHANNEL_BOT, twitchUserScopes.CHANNEL_MANAGE_REDEMPTIONS],
  addOns: [
    pointsAuctionAddOn,
    wheelAuctionAddOn,
    randomDropsAddOn,
  ],
  eventTypes: [
    twitchEvents.CHANNEL_POINTS_CUSTOM_REWARD_REDEMPTION_ADD,
    twitchEvents.CHANNEL_CHAT_MESSAGE,
  ],
}

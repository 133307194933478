import {
  BROADCASTER, DOWNVOTES, MOVIE_LIST, POINTS_BANK, UPVOTES,
} from '../../constants/db'

export const getBroadcasterPointsBankCollectionPath = (broadcasterId: string) => `${BROADCASTER}/${broadcasterId}/${POINTS_BANK}`
export const getBroadcasterMovieListCollectionPath = (broadcasterId: string) => `${BROADCASTER}/${broadcasterId}/${MOVIE_LIST}`
export const getMovieListMoviePath = (broadcasterId: string, movieId: string) => `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${movieId}`

export const getUpvoteDocPath = (broadcasterId: string, movieId: string, userId: string) => `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${movieId}/${UPVOTES}/${userId}`
export const getDownvoteDocPath = (broadcasterId: string, movieId: string, userId: string) => `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${movieId}/${DOWNVOTES}/${userId}`

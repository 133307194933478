import { Center, Flex } from '@chakra-ui/react'
import { getColor, toFixedRounded, truncateNumber } from '@elan-twitch/shared'
import { PredictionContext } from 'components/App/Prediction/context'
import { Overlay } from 'components/shared/Overlay'
import arrow from 'icons/angle-right.svg'
import { ChannelPoint } from 'icons/ChannelPoint'
import { PredictionIcon } from 'icons/PredictionIcon'
import { Voter } from 'icons/Voter'
import { FC, useContext, useMemo } from 'react'
import { usePredictionConfig } from '../../../Config/hooks'
import { Splash } from './Splash'

const staggerTransition = (
  active: boolean,
  delay: number,
  duration: number = 700,
  prop = 'all',
) => `${prop} ${duration}ms cubic-bezier(0.25, 1, 0.5, 1) ${active ? delay : 0}ms`
export const WinnerPanel: FC = () => {
  const { config } = usePredictionConfig()
  const { prediction, totalPoints, totalVoters } = useContext(PredictionContext)
  const { gradient = 'blue', scale = 1.8 } = config || {}
  const { winner, color } = useMemo(() => {
    if (!prediction?.winning_outcome_id) { return { winner: null, color: getColor(gradient, 0) } }
    const idx = prediction.outcomes.findIndex(
      (o) => o.id === prediction.winning_outcome_id,
    )
    const c = getColor(gradient, idx / (prediction?.outcomes?.length || 1))
    return { winner: prediction.outcomes[idx] || null, color: c }
  }, [prediction, gradient])

  return (
    <Overlay
      style={{
        zIndex: 1,
        background: 'rgba(0,0,0,0.8)',
        fontSize: '1.2rem',
        opacity: winner ? 1 : 0,
        color: 'white',
        pointerEvents: 'none',
        overflow: 'hidden',
        flexFlow: 'column',
      }}
    >
      <Flex
        w='100%'
        opacity={winner ? 1 : 0}
        h='100%'
        justifyContent='center'
        transform={`scale(${winner ? 1 : 0.8})`}
        transition={staggerTransition(!!winner, 1500, 500)}
        flexFlow='column'
        pt={4}
      >
        {/* <Flex
          h={winner ? '60px' : 0}
          overflow='hidden'
          transform={`translateX(${winner ? 5 : 0}px) scale(${
            winner ? 1 : 0.5
          })`}
          transition={staggerTransition(!!winner, 3000, 1500)}
          style={{ marginTop: `${scale * 1.5}rem`, justifyContent: 'center' }}
        > */}
        <img
          alt='xqcM'
          style={{
            // height: '60px',
            position: 'absolute',
            height: '100%',
            objectFit: 'cover',
            filter: 'grayscale(50%)',
            width: '100%',
            // opacity: 0.05,
            opacity: winner ? 0.05 : 0,
            transition: staggerTransition(!!winner, 3000, 1000),
          }}
          src='/assets/images/xqcM.png'
        />
        {/* </Flex> */}
        <Flex
          opacity={winner ? 1 : 0}
          style={{
            height: '60px',
            width: '100%',
            justifyContent: 'center',
            zIndex: 2,
            alignItems: 'center',
          }}
        >
          <PredictionIcon
            fill={color}
            style={{
              width: scale * 30,
              marginRight: '0.4rem',
              // transform: `scale(${winner ? 1 : 0.8})`,
              // transition: staggerTransition(!!winner, 3000, 1000, 'opacity'),
              // opacity: winner ? 0.5 : 0,
            }}
          />
          <p
            style={{
              fontSize: '2.6rem',
              zIndex: 1,
              textShadow: '0 0 4px black',
            }}
          >
            {winner?.title || ''}
          </p>
        </Flex>
        <Flex
          style={{
            width: '100%',
            alignItems: 'center',
            marginTop: `${scale * 1}rem`,
            justifyContent: 'space-evenly',
            zIndex: 1,
          }}
        >
          <Center
            opacity={winner ? 1 : 0}
            flex={2}
            flexDirection='column'
            pos='relative'
          >
            <Center>
              <ChannelPoint style={{ height: '15px', marginRight: '0.3rem' }} />
              <p style={{ fontSize: '1.6rem' }}>
                {totalPoints ? truncateNumber(totalPoints) : ''}
              </p>
            </Center>
            <Center>
              <Voter style={{ height: '25px' }} />
              <p>
                {' '}
                {totalVoters ? truncateNumber(totalVoters) : ''}
              </p>
            </Center>
          </Center>
          <Center flexFlow='column' opacity={winner ? 1 : 0} flex={1}>
            <img
              src={arrow}
              alt='arrow'
              style={{
                position: 'absolute',
                height: '52px',
                opacity: 0.15,
              }}
            />
            <span style={{ textShadow: '0 0 6px black', fontSize: '1.4rem' }}>
              {winner
                ? `${toFixedRounded(
                  (totalPoints || 0) / winner.channel_points,
                )} : 1`
                : ''}
            </span>
          </Center>
          <Center
            opacity={winner ? 1 : 0}
            flex={2}
            flexDirection='column'
            pos='relative'
          >
            <Center>
              <ChannelPoint style={{ height: '15px', marginRight: '0.3rem' }} />
              <p style={{ fontSize: '1.6rem' }}>
                {winner ? truncateNumber(winner.channel_points) : ''}
              </p>
            </Center>
            <Center>
              <Voter style={{ height: '25px' }} />
              <p>
                {' '}
                {winner ? truncateNumber(winner.users) : ''}
              </p>
            </Center>
          </Center>
        </Flex>
      </Flex>

      <Splash active={!!winner} />
    </Overlay>
  )
}

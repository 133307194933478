import {
  BROADCASTER,
  Broadcaster,
  WheelAuction,
  WheelAuctionPreviousResult,
  WithId,
} from '@elan-twitch/shared'
import {
  db,
  getCurrentWheelAuctionDocPath,
  getWheelAuctionDocPath,
} from 'backend/db'
import {
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

export const createWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auction: WheelAuction,
) => {
  const broadcasterId = broadcaster._id
  const now = new Date().toISOString()
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const currentAuctionDoc = doc(db, getWheelAuctionDocPath(broadcasterId, now))
  const currAuction = await getDoc(currentAuctionDoc)
  if (currAuction.exists()) {
    throw new Error('Wheel auction already exists')
  }
  await setDoc(currentAuctionDoc, auction)
  await updateDoc(doc(db, `broadcaster/${broadcasterId}`), {
    'pointsBankAccountConfig.currentWheelAuctionId': now,
  })
}

export const updateWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auction: WheelAuction,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  const currAuction = await getDoc(doc(db, path))
  if (!currAuction.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  if (currAuction.data()?.started_at) {
    throw new Error('Wheel auction already started')
  }
  await setDoc(doc(db, path), auction)
}

export const startWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auction: WheelAuction,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  const currAuction = await getDoc(doc(db, path))
  if (!currAuction.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  if (currAuction.data()?.started_at) {
    throw new Error('Wheel auction already started')
  }
  await setDoc(doc(db, path), {
    ...auction,
    started_at: new Date().toISOString(),
  })
}

export const getOutcomesWithThetas = (auction: WheelAuction | null) => {
  if (!auction) return []
  const totalPoints = auction?.outcomes.reduce(
    (acc, outcome) => acc + auction.outcomeBasePoints + outcome.points,
    0,
  )
  let currTheta = 0
  const withThetas = auction.outcomes.map((outcome) => {
    const prevTheta = currTheta
    const weightedPoints = auction.outcomeBasePoints + outcome.points
    currTheta += (weightedPoints / totalPoints) * 360
    return {
      ...outcome,
      thetaA: prevTheta,
      thetaB: currTheta,
    }
  })
  return withThetas
}
function mod(n: number, m: number) {
  return ((n % m) + m) % m
}

export const getResultTheta = () => 360 * (20 + Math.random() * 5) + Math.random() * 360
export const getResultThetaAndWinner = (auction: WheelAuction) => {
  const resultTheta = getResultTheta()
  const normalizedTheta = resultTheta % 360
  const withThetas = getOutcomesWithThetas(auction)
  const invertedTheta = mod(360 - normalizedTheta, 360)
  for (let i = 0; i < withThetas.length; i += 1) {
    const outcome = withThetas[i]
    const { thetaA, thetaB } = outcome
    if (thetaA <= invertedTheta && thetaB >= invertedTheta) {
      return { resultTheta, winningOutcome: i }
    }
  }
  return { resultTheta, winningOutcome: withThetas.length - 1 }
}

export const endWheelBidding = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  if (!path) {
    throw new Error('No current wheel auction')
  }
  const auctionRes = await getDoc(doc(db, path))
  if (!auctionRes.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  const auction = auctionRes.data()
  if (!auction?.started_at) {
    throw new Error('Wheel auction has not started')
  }
  await updateDoc(doc(db, path), {
    ended_at: new Date().toISOString(),
  })
}

export const spinWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auction: WheelAuction,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getCurrentWheelAuctionDocPath(broadcaster)
  if (!path) {
    throw new Error('No current wheel auction')
  }
  const currAuctionRes = await getDoc(doc(db, path))
  if (!currAuctionRes.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  const currAuction = currAuctionRes.data()
  if (!currAuction?.started_at) {
    throw new Error('Wheel auction has not started')
  }
  const { resultTheta, winningOutcome } = getResultThetaAndWinner(auction)
  await setDoc(doc(db, path), {
    ...auction,
    winningOutcomeIndex: winningOutcome,
    resultTheta,
    ended_at: currAuction.ended_at || new Date().toISOString(),
    spun_at: new Date().toISOString(),
    spin_rested_at: null,
  })
}

export const reportSpinRested = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  const wheelDoc = doc(db, path) as DocumentReference<WheelAuction>
  const currAuction = await getDoc(wheelDoc)
  if (!currAuction.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  const { spun_at } = currAuction.data()
  if (!spun_at) {
    throw new Error('Wheel auction has not been spun')
  }
  await updateDoc(wheelDoc, 'spin_rested_at', new Date().toISOString())
}

export const saveWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  const currAuction = await getDoc(doc(db, path))
  if (!currAuction.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  const created_at = currAuction.data()?.created_at || new Date().toISOString()
  await updateDoc(doc(db, path), 'saved_on', created_at)
}

export const unsaveWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  const currAuction = await getDoc(doc(db, path))
  if (!currAuction.exists()) {
    throw new Error('Wheel auction does not exist')
  }
  await updateDoc(doc(db, path), 'saved_on', null)
}

export const setCurrentAuctionId = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string | null,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  await updateDoc(doc(db, `${BROADCASTER}/${broadcasterId}`), {
    'pointsBankAccountConfig.currentWheelAuctionId': auctionId,
  })
}

export const finishWheelAuction = async (
  broadcaster: WithId<Broadcaster>,
  auctionId?: string,
) => {
  const broadcasterId = broadcaster._id
  const _auctionId = auctionId || broadcaster.pointsBankAccountConfig?.currentWheelAuctionId
  if (!_auctionId) {
    throw new Error('Wheel auction id not provided')
  }
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, _auctionId)
  const currAuctionRes = await getDoc<WheelAuction>(
    doc(db, path) as DocumentReference<WheelAuction>,
  )
  const currAuction = currAuctionRes.data()
  if (!currAuction) {
    throw new Error('Wheel auction does not exist')
  }

  await setCurrentAuctionId(broadcaster, null)

  if (!currAuction.saved_on) {
    await deleteDoc(doc(db, path))
  }
}

export const reopenWheelAuctionBidding = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string,
  discardBids: boolean,
) => {
  const broadcasterId = broadcaster._id
  if (!auctionId) {
    throw new Error('Wheel auction id not provided')
  }
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const path = getWheelAuctionDocPath(broadcasterId, auctionId)
  const currAuctionDoc = doc(db, path) as DocumentReference<WheelAuction>
  const currAuctionRes = await getDoc<WheelAuction>(currAuctionDoc)
  const currAuction = currAuctionRes.data()
  if (!currAuction) {
    throw new Error('Wheel auction does not exist')
  }

  const {
    ended_at, spun_at, resultTheta, winningOutcomeIndex, outcomes,
  } = currAuction
  if (!ended_at) {
    throw new Error('Wheel auction has not ended')
  }

  const updated: Partial<WheelAuction> = { ...currAuction }
  if (winningOutcomeIndex !== null && spun_at !== null && resultTheta !== null) {
    const previousResult: WheelAuctionPreviousResult = {
      outcomes: outcomes.map((o) => ({ ...o })),
      winningOutcomeIndex,
      resultTheta,
      spun_at,
      ended_at,
    }
    updated.previousResults = [
      ...(currAuction.previousResults || []),
      previousResult,
    ]
  }

  if (discardBids) { updated.outcomes = outcomes.map((o) => ({ ...o, points: 0, topBids: [] })) }
  updated.ended_at = null
  updated.spun_at = null
  updated.resultTheta = null
  updated.spin_rested_at = null
  updated.winningOutcomeIndex = null
  await updateDoc(currAuctionDoc, updated)
}

import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
  StackProps,
  Tooltip,
} from '@chakra-ui/react'
import { Gradient, gradientToCssGradientProp } from '@elan-twitch/shared'
import { GradientText } from 'components/shared/GradientText'
import { ShadowText } from 'components/shared/ShadowText'

export const InputSlider = ({
  label,
  stackProps,
  gradient,
  value,
  min,
  max,
  ...props
}: {
  label: string
  stackProps?: StackProps
  gradient?: Gradient
} & SliderProps) => {
  const gradientCss = gradient ? gradientToCssGradientProp(gradient) : null
  return (
    <HStack
      // @ts-ignore
      opacity={props.value !== undefined ? 1 : 0.5}
      // filter={`grayscale(${value !== undefined ? 0 : 100}%)`}
      _hover={{
        opacity: 1,
        // filter: 'grayscale(0%)',
      }}
      transition='all 500ms'
      spacing={2}
      width='100%'
      align='center'
      {...stackProps}
    >
      {/* @ts-ignore */}
      <HStack w='100%' align='center'>
        {min !== undefined && gradientCss ? (
          <GradientText fontSize='sm' color={gradientCss}>
            {typeof min === 'number' ? min.toFixed((props?.step || 1) < 1 ? 2 : 0) : ''}
          </GradientText>
        ) : null}
        {min !== undefined && !gradientCss ? (
          <ShadowText>
            {typeof min === 'number' ? min.toFixed((props?.step || 1) < 1 ? 2 : 0) : ''}
          </ShadowText>
        ) : null}
        <Slider
          min={min}
          max={max}
          step={1}
          aria-label={label}
          flex={1}
          {...props}
        >
          <SliderTrack bg='gray.400'>
            <SliderFilledTrack bg={gradientCss || 'blackAlpha.500'} />
          </SliderTrack>
          <Tooltip
            bg='gray.100'
            color='gray.500'
            placement='top'
            hasArrow
            label={typeof value === 'number' ? value.toFixed(0) : ''}
          >
            <SliderThumb width={2} height={4} />
          </Tooltip>
        </Slider>
        {max !== undefined && gradientCss ? (
          <GradientText fontSize='sm' color={gradientCss}>
            {typeof max === 'number' ? max.toFixed((props?.step || 1) < 1 ? 2 : 0) : ''}
          </GradientText>
        ) : null}
        {max !== undefined && !gradientCss ? (
          <ShadowText>
            {typeof max === 'number' ? max.toFixed((props?.step || 1) < 1 ? 2 : 0) : ''}
          </ShadowText>
        ) : null}
      </HStack>
    </HStack>
  )
}

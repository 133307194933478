import {
  limit as limitTo, orderBy, query, where,
} from 'firebase/firestore'
import { useMemo } from 'react'
import { getBroadcasterMovieListCollection } from '../../../backend/db'
import { useQuery } from '../../../hooks/db'

export const useTopMovies = (broadcasterId: string | null) => {
  const dbQuery = useMemo(() => (broadcasterId ? (
    query(getBroadcasterMovieListCollection(broadcasterId), orderBy('vote_score', 'desc'), where('watched', '==', false), limitTo(20))
  ) : null), [broadcasterId])

  return useQuery(dbQuery)
}

import { ExpandButton } from 'components/shared/Buttons'
import { useMemo } from 'react'
import { useAuctionContext } from './context'

export const ReopenBidding = ({ onReopen, isLoading }:
  { onReopen: () => Promise<void>; isLoading: boolean }) => {
  const { auction } = useAuctionContext()
  const canReopen = useMemo(() => {
    if (!auction) return false
    if (auction.ended_at) return true
    return false
  }, [auction])
  return (
    <>
      <ExpandButton
        active={canReopen}
        onClick={async () => {
          try {
            await onReopen()
          } catch (err) {
            console.error(err)
          }
        }}
        isLoading={isLoading}
        width={150}
      >
        REOPEN BIDDING
      </ExpandButton>
    </>
  )
}

import { FC, SVGProps } from 'react'

const pathProps = {
  stroke: 'none',
  fill: 'url(#gold-gradient)',
  strokeWidth: 70,
  strokeMiterlimit: 10,
}
export const Trophy: FC<SVGProps<SVGSVGElement>> = ({
  style,
  fill = pathProps.fill,
  stroke = pathProps.stroke,
}) => (
  <svg viewBox='0 -960 960 960' style={style}>
    <defs>
      <filter id='glow' height='300%' width='300%' x='-75%' y='-75%'>
        <feMorphology
          operator='dilate'
          radius='30'
          in='SourceAlpha'
          result='thicken'
        />
        <feGaussianBlur in='thicken' stdDeviation='20' result='blurred' />
        <feFlood floodColor='rgba(0,0,0, 0.5)' result='glowColor' />
        <feComposite
          in='glowColor'
          in2='blurred'
          operator='in'
          result='softGlow_colored'
        />
        <feMerge>
          <feMergeNode in='softGlow_colored' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
      <linearGradient id='gold-gradient'>
        <stop stopColor='#ffc000' offset='0%' />
        <stop stopColor='#fff800' offset='100%' />
      </linearGradient>
    </defs>
    <path
      filter='url(#glow)'
      stroke={stroke}
      fill={fill}
      d='M294.609-100.782V-180h145.782v-130.477q-54.565-12.131-96.848-47.631t-60.544-87.369q-84.174-2.348-143.088-60.461Q80.998-564.051 80.998-648v-44q0-33.74 23.022-56.762 23.021-23.022 56.762-23.022h104v-88h431.002v88h104q32.913 0 56.066 23.022Q879.002-725.74 879.002-692v44q0 83.949-58.913 142.062-58.914 58.113-143.088 60.461-18.261 51.869-60.544 87.369t-96.848 47.631V-180h145.782v79.218H294.609ZM264.782-526v-166h-104v44q0 45 29.5 78.5t74.5 43.5Zm431.002 0q44.434-10 74.217-43.5t29.783-78.5v-44h-104v166Z'
    />
  </svg>
)

import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react'
import { ForwardedRef, forwardRef } from 'react'

const ContentBoxBase = (props: FlexProps, ref: ForwardedRef<HTMLDivElement>) => {
  const bg = useColorModeValue('gray.100', 'gray.800')
  return (
    <Flex
      // @ts-ignore
      ref={ref}
      bg={bg}
      p={3}
      borderRadius={6}
      boxShadow='1px 1px 4px #00000066'
      {...props}
    />
  )
}

export const ContentBox = forwardRef<HTMLDivElement, FlexProps>(ContentBoxBase)

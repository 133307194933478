import { InfiniteScrollList } from '@elan-twitch/shared'
import { DocumentData } from 'firebase/firestore'
import { createContext, useContext } from 'react'
import { UseInfiniteScrollList } from '../../../hooks/db'
import { FETCH_LIMIT } from './constants'

export type InfiniteScrollListData<T extends DocumentData = DocumentData> = {
  list: InfiniteScrollList<T>
  data: UseInfiniteScrollList<T>
}

export const InfiniteScrollListContext = createContext<InfiniteScrollListData>({
  list: {
    itemHeight: 60,
    basePath: '',
    collectionPath: '__EMPTY__',
    defaultFilters: [],
    defaultSortKey: '',
    filterPresets: [],
    sortPresets: [],
    RenderItem: () => null,
    itemType: '',
  },
  data: {
    data: [],
    error: null,
    limit: FETCH_LIMIT,
    cursors: [],
    fetchMoreData: () => {},
    filterPresets: [],
    hasMoreData: false,
    sort: {
      sortDirection: 'asc',
      sortKey: '',
    },
    isItemLoaded: () => false,
    loading: false,
    setSortFromPreset: () => {},
    totalCount: 0,
    filters: [],
    addFilter: () => {},
    refetch: () => {},
    removeFilter: () => {},
    refetchItem: async () => {},
  },
})

export const useInfiniteScrollListContext = <T extends DocumentData>() => useContext<InfiniteScrollListData<T>>(InfiniteScrollListContext as any)

import {
  Box, Center, CircularProgress, HStack, Text,
} from '@chakra-ui/react'
import { gradients, gradientToCssGradientProp } from '@elan-twitch/shared'
import { useContext } from 'react'
import { useScreen } from '../../../store/useScreen'
import { BroadcasterContext } from '../../App/Broadcasters/context'
import { AuctionContext, useAuctionContextData } from './context'
import { usePointsAuction } from './hooks'
import { LoadOrCreatePointsAuction } from './LoadOrCreate'
import { PointsAuctionVotingStage } from './PointsAuctionVotingStage'

export const PointsAuctionsView = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { _id: broadcasterId } = broadcaster || {}
  const { width = 0 } = useScreen()

  const { data, isLoading } = usePointsAuction(broadcaster)
  const contextData = useAuctionContextData()
  if (!broadcasterId) {
    return <Text>Error: missing broadcaster ID</Text>
  }

  return (
    <AuctionContext.Provider value={contextData}>
      <Center
        bg={gradientToCssGradientProp(gradients.nymn)}
        position='absolute'
        top={0}
        left={0}
        w='100%'
        height='100%'
      >
        <HStack transition='opacity 400ms' pointerEvents='none' opacity={isLoading ? 1 : 0} position='absolute'>
          <CircularProgress size={6} isIndeterminate color='gray.500' />
          <Text color='gray.600'>Loading...</Text>
        </HStack>
        <Box
          width={data ? 0 : `${width}px`}
          opacity={data ? 0 : 1}
          overflow='hidden'
          transition={`opacity 500ms ease ${data ? 400 : 0}ms, width 500ms ease ${data ? 0 : 400}ms`}
        >
          <LoadOrCreatePointsAuction />
        </Box>
        <Box
          width={!data ? 0 : `${width}px`}
          overflow='hidden'
          opacity={!data ? 0 : 1}
          transition={`opacity 500ms ease ${!data ? 400 : 0}ms, width 500ms ease ${!data ? 0 : 400}ms`}
        >
          <PointsAuctionVotingStage />
        </Box>
      </Center>
    </AuctionContext.Provider>
  )
}

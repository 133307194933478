import {
  Box, BoxProps, Flex, FlexProps, Text,
} from '@chakra-ui/react'
import { ChatWidgetConfig, parseEmotes, WidgetComponent } from '@elan-twitch/shared'
import {
  useContext, useEffect, useMemo, useRef,
} from 'react'
import { useBorderColor } from '../../../../hooks/useBorderColor'
import { BroadcasterContext } from '../../../App/Broadcasters/context'
import { useLiveChat } from '../hooks/useLiveChat'
import './styles.css'

export type LiveChatViewData = {
  broadcasterId: string
}

export const LiveChatView = ({
  broadcasterId: propBroadcasterId,
  bodyProps,
  ...props
}: FlexProps & { broadcasterId?: string, bodyProps?: BoxProps }) => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)

  const usedBroadcasterId = useMemo(() => propBroadcasterId || broadcasterId, [propBroadcasterId, broadcasterId])
  const {
    subscribe,
    error,
    emotes: { emotes },
  } = useLiveChat(usedBroadcasterId)
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const unsub = subscribe(({ message, username, userColor }) => {
      if (!containerRef.current) return
      const currCount = containerRef.current.childElementCount
      const { firstChild } = containerRef.current
      if (currCount > 25 && firstChild) {
        containerRef.current.removeChild(firstChild)
        firstChild.remove()
      }
      const el = document.createElement('span')
      el.className = 'chat-message-light'
      el.style.setProperty('color', '#ffffff')
      el.innerHTML = `<span style="color:${userColor};font-weight:700;display:inline-block" >${username}:</span><span>&nbsp;${parseEmotes(
        message,
        emotes,
      )}</span>`
      const isAtBottom = Math.abs(
        containerRef.current.scrollHeight - containerRef.current.scrollTop - containerRef.current.clientHeight,
      ) < 10
      containerRef.current.appendChild(el)
      if (isAtBottom) containerRef.current.scroll({ top: containerRef.current.scrollHeight, behavior: 'smooth' })
    })
    return unsub
  }, [subscribe, emotes])
  const borderColor = useBorderColor()
  if (!usedBroadcasterId) return <Text>Missing broadcaster ID</Text>
  if (error) return <Text color='red'>{error}</Text>
  return (
    // @ts-ignore
    <Flex flexFlow='column' {...props}>
      <Flex py={1} px={2} borderBottom={`1px solid ${borderColor}`} w='100%'>
        <Text fontSize='sm' >CHAT</Text>
      </Flex>
      <Box minH='0' flex={1} overflowX='hidden' overflowY='auto' px={1} ref={containerRef} {...bodyProps}/>
    </Flex>
  )
}

export const LiveChatViewWidget: WidgetComponent<LiveChatViewData, ChatWidgetConfig> = ({ data }) => (
  <LiveChatView
    broadcasterId={data?.broadcasterId}
    m={4}
    height='500px'
    w='500px'
    overflow='hidden'
    className='chat-message-container-light'
    align='flex-start'
  />
)

import {
  Button, Divider, Flex, Text,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../../../store/useUser'
import { SignIn } from '../../Auth/SignIn'
import { DarkModeToggle } from '../../shared/ColorMode/DarkModeToggle'
import { ContentBox } from '../../shared/ContentBox'
import { BroadcasterContext } from '../Broadcasters/context'
import { ChannelSelect } from './ChannelSelect'
import { configProviders } from './constants'
import { Reconnect } from './Reconnect'
import { ConfigView } from './types'

const smallLinks: { path: string; label: string }[] = [
  {
    path: '/bank',
    label: 'Bank Accounts',
  },
  {
    path: '/predictions',
    label: 'Predictions Overlay',
  },
  {
    path: '/polls',
    label: 'Polls Overlay',
  },
  {
    path: '/tugofwar',
    label: 'Tug of War Overlay',
  },
  {
    path: '/wheel',
    label: 'Wheel Auction',
  },
  {
    path: '/wheel/preview',
    label: 'Wheel Auction (Overlay view)',
  },
  {
    path: '/auction',
    label: 'Points Auction',
  },
  {
    path: '/auction/preview',
    label: 'Points Auction (Overlay view)',
  },
]

export const ConfigMenu = ({ views }: { views: ConfigView[] }) => {
  const { basePath, broadcaster } = useContext(BroadcasterContext)
  const { name: broadcasterName } = broadcaster || {}
  const user = useUser((s) => s.user)
  return (
    <ContentBox pointerEvents='auto' borderLeftRadius={0} p={3} flexFlow='column' w='240px'>
      <Flex gap={2} flex={1} w='100%' flexFlow='column'>
        <Flex flexFlow='column' w='100%' gap={1}>
          <Text fontWeight='bold' color='gray.600'>
            Config Links
          </Text>
          {views.map(({ name, path, onMenu }) => (onMenu ? (
              <Link key={path} to={`${basePath}/config${path}`}>
                <Button key={path} variant='link' size='lg'>
                  {name}
                </Button>
              </Link>
          ) : null))}
        </Flex>
        {user ? (
          <Flex py={2} flexFlow='column' w='100%' gap={1}>
            <Text fontWeight='bold' color='gray.600'>
              Quick Links
            </Text>
            {smallLinks.map(({ path, label }) => (
              <Link key={path} to={`${basePath}${path}`}>
                <Button key={path} variant='link' size='sm'>
                  {label}
                </Button>
              </Link>
            ))}
          </Flex>
        ) : null}
        <Flex pt={2} w='100%'>
          {user ? <Reconnect /> : null}
        </Flex>
      </Flex>
      <Flex p={2}>
        <Flex flexFlow='column' justify='center' width='100%'>
          <ChannelSelect />
          {broadcasterName ? (
            <Text textAlign='center' opacity={0.7} fontWeight={600} fontSize='sm'>
              {`CHANNEL: ${broadcasterName}`}
            </Text>
          ) : null}

          <DarkModeToggle />
          <Divider mt={2} mb={2} />
          {user ? (
            <Text opacity={0.7} fontWeight={600} textAlign='center' whiteSpace='nowrap' fontSize='sm'>
              {user?.displayName || ''}
            </Text>
          ) : null}
          <SignIn providers={configProviders} />
        </Flex>
      </Flex>
    </ContentBox>
  )
}

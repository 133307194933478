import { BROADCASTER, CONFIG } from '../constants/db'
import { TwitchUserScope } from '../constants/twitch'
import { BroadcasterScopes } from '../types'
import { BroadcasterConfigSnippet } from '../types/broadcaster'
import { widgets } from '../widgets/constants'
import {
  Widget, WidgetAddOn, WidgetAddOnId, WidgetId,
} from '../widgets/types'

export const getBroadcasterConfigPath = (broadcasterId: string) => `${BROADCASTER}/${broadcasterId}/${CONFIG}`

export type AddOnScopeStatus = {
  addOn: WidgetAddOnId
  scopes: TwitchUserScope[]
  missing: TwitchUserScope[]
  granted: TwitchUserScope[]
  status: 'complete' | 'incomplete'
}

export type WidgetScopeStatus = {
  widget: WidgetId
  scopes: TwitchUserScope[]
  missing: TwitchUserScope[]
  granted: TwitchUserScope[]
  addOns?: AddOnScopeStatus[]
  status: 'complete' | 'incomplete'
}
export type ScopesStatus = {
  summary: Array<WidgetScopeStatus>
  excessScopes: TwitchUserScope[]
  missingScopes: TwitchUserScope[]
  requiredScopes: TwitchUserScope[]
  status: 'complete' | 'incomplete'
}

const getAddOnScopeStatus = (
  addOn: WidgetAddOn,
  grantedScopes: TwitchUserScope[],
): AddOnScopeStatus => {
  const { missing, granted } = addOn.requiredScopes.reduce(
    (acc, scope) => {
      if (grantedScopes.includes(scope)) {
        acc.granted.push(scope)
      } else {
        acc.missing.push(scope)
      }
      return acc
    },
    {
      missing: [] as TwitchUserScope[],
      granted: [] as TwitchUserScope[],
    },
  )
  return {
    addOn: addOn.id,
    scopes: addOn.requiredScopes,
    missing,
    granted,
    status: missing.length ? 'incomplete' : 'complete',
  }
}

const getWidgetScopeStatus = (
  widget: Widget,
  grantedScopes: TwitchUserScope[],
): WidgetScopeStatus => {
  const { missing, granted } = widget.requiredScopes.reduce(
    (acc, scope) => {
      if (grantedScopes.includes(scope)) {
        acc.granted.push(scope)
      } else {
        acc.missing.push(scope)
      }
      return acc
    },
    {
      missing: [] as TwitchUserScope[],
      granted: [] as TwitchUserScope[],
    },
  )
  return {
    widget: widget.id,
    missing,
    addOns:
      widget.addOns?.map((addOn) => getAddOnScopeStatus(addOn, grantedScopes)) || [],
    granted,
    scopes: widget.requiredScopes,
    status: missing.length ? 'incomplete' : 'complete',
  }
}

export const getWidgetEnabled: Record<
  WidgetId,
  (config: BroadcasterConfigSnippet | null) => boolean
> = {
  'movie-list': (config) => !!config?.['movie-list']?.enabled,
  'points-bank': (config) => !!config?.pointsBankAccount?.enabled,
  'poll-overlay': (config) => !!config?.poll?.enabled,
  'prediction-overlay': (config) => !!config?.prediction?.enabled,
}

export const getScopeStatus = (
  config: BroadcasterConfigSnippet | null,
  grantedScopes: BroadcasterScopes | null,
): ScopesStatus => {
  const requiredScopes: TwitchUserScope[] = []
  const missingScopes: TwitchUserScope[] = []
  const summary: Array<WidgetScopeStatus> = []

  Object.values(widgets).forEach((widget) => {
    const widgetEnabled = getWidgetEnabled[widget.id](config)
    if (widgetEnabled) {
      const widgetStatus = getWidgetScopeStatus(
        widget,
        grantedScopes?.scope || [],
      )
      summary.push(widgetStatus)
      missingScopes.push(...widgetStatus.missing)
      requiredScopes.push(
        ...widget.requiredScopes,
        ...(widget.addOns?.flatMap((addOn) => addOn.requiredScopes) || []),
      )
    }
  })

  const excessScopes = grantedScopes?.scope.filter((scope) => !requiredScopes.includes(scope))
    || []
  return {
    summary,
    excessScopes,
    missingScopes,
    status: missingScopes.length ? 'incomplete' : 'complete',
    requiredScopes,
  }
}

import { movieListWidget } from './movieList'
import { pointsBankWidget } from './pointsBank'
import { pollOverlayWidget } from './poll/constants'
import { predictionOverlayWidget } from './prediction/constants'
import { Widget, WidgetId } from './types'

export const widgets: Record<WidgetId, Widget> = {
  'movie-list': movieListWidget,
  'points-bank': pointsBankWidget,
  'poll-overlay': pollOverlayWidget,
  'prediction-overlay': predictionOverlayWidget,
}
export const widgetIds = Object.keys(widgets) as WidgetId[]

import { FC, SVGProps } from 'react'

export const Hourglass: FC<SVGProps<SVGSVGElement> & { fill?: string }> = (
  props,
) => (
  <svg {...props} viewBox='0 0 105.761 122.88'>
    <path
      fill='white'
      d='M 71.931 110.745 C 71.934 92.311 71.398 86.128 66.864 82.342 C 63.335 79.395 58.225 78.955 53.592 78.822 C 49.303 78.699 46.204 78.288 41.467 81.221 C 35.524 84.9 33.374 90.768 33.316 110.745 L 71.931 110.745 Z M 0.909 110.745 L 10.477 110.745 C 10.221 83.201 22.648 68.029 38.025 61.464 C 22.637 54.899 10.205 40.766 10.477 13.191 L 0.909 13.192 C 0.408 13.192 0 12.732 0 12.166 L 0 1.024 C 0 0.46 0.408 0 0.909 0 L 104.851 0 C 105.349 0 105.76 0.46 105.76 1.024 L 105.76 12.166 C 105.76 12.73 105.349 13.191 104.851 13.191 L 95.281 13.191 C 95.506 36.404 86.7 49.25 74.735 57.589 C 72.547 59.111 70.191 60.421 67.741 61.462 C 70.188 62.51 72.549 63.816 74.733 65.341 C 86.688 73.673 95.49 87.563 95.279 110.745 L 104.851 110.746 C 105.349 110.746 105.76 111.209 105.76 111.773 L 105.76 121.852 C 105.76 122.416 105.349 122.879 104.851 122.879 L 0.909 122.879 C 0.408 122.879 0 122.416 0 121.852 L 0 111.774 C 0 111.208 0.408 110.745 0.909 110.745 Z M 73.353 13.192 L 31.179 13.192 C 30.703 31.134 34.614 36.581 37.282 38.581 C 40.583 41.056 46.499 43.295 51.81 43.253 C 57.722 43.206 64.054 41.804 66.974 39.586 C 72.254 35.574 73.099 28.99 73.353 13.192 Z'
    />
  </svg>
)

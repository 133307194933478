import { usePrevious } from '@chakra-ui/react'
import { getPollDocPath, Poll } from '@elan-twitch/shared'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { useDocument } from 'hooks/db'
import { useProcessedPoll } from 'hooks/useProcessedPoll'
import { useTimeout } from 'hooks/useTimeout'
import { useContext, useMemo } from 'react'
import { usePollConfig } from '../../Config/hooks'
import { ChoiceView } from './Choice'
import { PollContext } from './context'
import { Header } from './Header'

const spots = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

const useShowing = (poll?: Poll) => {
  const wasActive = usePrevious(poll && !poll?.ended_at)
  const showWinner = useTimeout(wasActive && poll?.ended_at, 5000)
  const { withRank, totalVotes } = useProcessedPoll(poll)
  const isShowing = useMemo(
    () => !!poll && (!poll.ended_at || !!showWinner),
    [poll, showWinner],
  )

  return { totalVotes, withRank, isShowing }
}

export const OWPollView = ({ data: _poll }: { data?: Poll | null }) => {
  const { broadcaster } = useContext(BroadcasterContext)
  const pollPath = useMemo(
    () => (broadcaster
      ? getPollDocPath(broadcaster.twitchId)
      : undefined),
    [broadcaster],
  )
  const { data } = useDocument<Poll>(pollPath)
  const poll = useMemo(() => _poll || data || undefined, [_poll, data])

  const { config } = usePollConfig()

  const { scale } = config || {}
  const { isShowing, totalVotes, withRank } = useShowing(poll)
  const { choices = [] } = withRank || {}
  const { ended_at } = poll || {}
  const winnerId = useMemo(
    () => (ended_at && poll
      ? poll.choices.sort((a, b) => b.votes - a.votes)[0]?.id || null
      : null),
    [ended_at, poll],
  )
  const pollData = useMemo(() => ({ poll, totalVotes }), [poll, totalVotes])
  return (
    <PollContext.Provider value={pollData}>
      <div
        style={{
          position: 'absolute',
          pointerEvents: 'auto',
          overflow: 'visible',
          bottom: `${scale * 12}px`,
          left: `${scale * 10}px`,
          transition: 'all 500ms',
        }}
      >
        <Header show={isShowing} />
        {spots.map((_, i) => (
          <ChoiceView
            key={i}
            show={
              choices.length > i
              && isShowing
              && (!winnerId || winnerId === choices?.[i].id)
            }
            winner={winnerId}
            index={i}
            choices={choices}
          />
        ))}
      </div>
    </PollContext.Provider>
  )
}

import {
  Box,
  Collapse,
  Flex,
  HStack,
  StackProps,
  Text, useDisclosure,
  VStack,
} from '@chakra-ui/react'
import React, { FC, PropsWithChildren, useCallback } from 'react'
import { useBorderColor } from '../../hooks/useBorderColor'
import { ViewButton } from './Buttons'

export const Expandable = ({
  header,
  children,
  initExpanded = false,
  nested,
  alwaysExpanded,
  headerProps,
  openCallback,
  closeCallback,
  iconColor,
  footer,
  isOpen: isOpenProp,
  onClose: onCloseProp,
  onOpen: onOpenProp,
  ...stackProps
}: PropsWithChildren<
  StackProps & {
    header: string | FC<{ isOpen: boolean; onClose: () => void }>
    footer?: FC<{ isOpen: boolean; onClose: () => void }>
    headerProps?: StackProps
    nested?: boolean
    alwaysExpanded?: boolean
    closeCallback?: () => void
    openCallback?: () => void
    initExpanded?: boolean
    isOpen?: boolean
    onClose?: () => void
    iconColor?: string
    onOpen?: () => void
  }
>) => {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: alwaysExpanded || initExpanded,
    isOpen: isOpenProp,
    onClose: onCloseProp,
    onOpen: onOpenProp,
  })

  const handleOpenClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (alwaysExpanded) return
      if (isOpen) {
        onClose()
        if (closeCallback) closeCallback()
        return
      }
      onOpen()
      if (openCallback) openCallback()
    },
    [alwaysExpanded, onOpen, onClose, isOpen, closeCallback, openCallback],
  )
  const borderColor = useBorderColor()
  return (
    // @ts-ignore
    <VStack spacing={0} borderColor={borderColor} w='100%' {...stackProps}>
      <HStack
        borderBottom={isOpen ? `1px solid ${borderColor}` : undefined}
        px={1}
        spacing={0}
        w='100%'
        {...headerProps}
      >
        <Flex onClick={handleOpenClick} cursor='pointer' aria-label='expand/hide' align='center' flex={1}>
          {typeof header === 'string' ? (
            <Text fontWeight={600} color='gray.500' flex={1}>
              {header}
            </Text>
          ) : (
            header({ isOpen, onClose })
          )}
        </Flex>
        {alwaysExpanded ? null : <ViewButton color={iconColor} onClick={handleOpenClick} isOpen={isOpen} />}
      </HStack>
      <Box w='100%' borderLeft={nested ? '4px solid #00000033' : undefined}>
        <Collapse unmountOnExit style={{ width: '100%' }} in={isOpen}>
          {children as any}
        </Collapse>
      </Box>
      {footer ? footer({ isOpen, onClose }) : null}
    </VStack>
  )
}

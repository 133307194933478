import { VStack } from '@chakra-ui/react'
import { truncateNumber } from '@elan-twitch/shared'
import { FadeInText } from 'components/shared/FadeIn'
import { SVGTimer } from 'components/shared/SVGTimer'
import { Voter } from 'icons/Voter'
import { FC, useContext } from 'react'
import { usePollConfig } from '../../Config/hooks'
import { CHOICE_HEIGHT } from './Choice'
import { PollContext } from './context'

export const Header: FC<{ show: boolean }> = ({ show }) => {
  const { config } = usePollConfig()
  const { scale } = config
  const { poll, totalVotes } = useContext(PollContext)
  const {
    title = '', ended_at, choices = [], started_at, ends_at,
  } = poll || {}
  return (
    <VStack
      spacing={0}
      position='relative'
      // top='0.2rem'
      // position='absolute'
      whiteSpace='nowrap'
      alignItems='flex-start'
      style={{
        opacity: show ? 1 : 0,
        transition: 'opacity 500ms ease',
      }}
    >
      <div
        style={{
          width: `${scale * 250}px`,
          display: 'flex',
          top: '4px',
          position: 'relative',
          paddingLeft: `${scale * 0.5}rem`,
        }}
      >
        <FadeInText
          style={{
            fontSize: `${scale}rem`,
            fontStyle: 'italic',
          }}
        >
          {title}
        </FadeInText>
        <FadeInText
          style={{
            fontSize: `${scale}rem`,
            fontStyle: 'italic',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            opacity: ended_at ? 1 : 0,
            transition: 'all 500ms',
            width: ended_at ? `${scale * 48}px` : 0,
          }}
        >
          - WINNER
        </FadeInText>
      </div>
      <div
        style={{
          width: `${scale * 240}px`,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          // bottom: `${scale * 3}px`,
          left: `${scale * 5}px`,
          transition: 'all 500ms linear',
          height: `${(scale * CHOICE_HEIGHT) / 2}px`,
        }}
      >
        <div style={{ flex: 1 }}>
          <SVGTimer
            endText='POLL ENDED'
            startsAt={ended_at ? 0 : started_at}
            endsAt={ended_at ? 0 : ends_at}
          />
        </div>
        <FadeInText
          style={{
            fontSize: `${scale * 0.6}rem`,
            display: 'flex',
            transition: `all 500ms ease ${
              show ? 1000 + choices.length * 600 : 0
            }ms`,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <span
            style={{
              position: 'relative',
              left: `${scale * 0.1}rem`,
              textShadow: '1px 1px 3px black',
              height: '24px',
              fontSize: '18px',
            }}
          >
            {truncateNumber(totalVotes || 0)}
          </span>
          <Voter
            style={{
              height: `${scale * 15}px`,
              marginLeft: `${0.1 * scale}rem`,
              filter: 'drop-shadow(1px 1px 1px black)',
            }}
          />
        </FadeInText>
      </div>
    </VStack>
  )
}

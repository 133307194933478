import { Text } from '@chakra-ui/react'
import { MovieListMovie, getDateString } from '@elan-twitch/shared'
import { useMemo } from 'react'

export const MovieNominatedStatus = ({ movie }: { movie: MovieListMovie }) => {
  const { nominatedOn, nominatedByUsername } = movie

  const text = useMemo(() => {
    if (!nominatedOn && !nominatedByUsername) return null
    let str = 'Nominated'
    if (nominatedByUsername) str += ` by ${nominatedByUsername}`
    if (nominatedOn) str += ` on ${getDateString(nominatedOn)}`
    return str
  }, [nominatedByUsername, nominatedOn])
  if (!text) return null
  return (
    <Text fontSize='sm'>
      {text}
    </Text>
  )
}

import { OutcomeWithRank } from '@elan-twitch/shared'
import { getColor, truncateNumber } from '@elan-twitch/shared/utils'
import { animated, easings, useSpring } from '@react-spring/web'
import { FadeInText } from 'components/shared/FadeIn'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Trophy } from 'icons/Trophy'
import { Voter } from 'icons/Voter'
import { FC, useMemo } from 'react'
import { usePredictionConfig } from '../../Config/hooks'
import { WinnerPanel } from './WinnerPanel'

const OUTCOME_HEIGHT = 36

export const RLOutcome: FC<{
  outcomes: OutcomeWithRank[]
  index: number
  winner?: string | null
}> = ({ outcomes, winner, index }) => {
  const outcome = useMemo(() => outcomes[index], [outcomes, index]) as
    | OutcomeWithRank
    | undefined

  const { percent = 0 } = outcome || {}
  const { config } = usePredictionConfig()
  const {
    gradient, scale = 1.6, swapColors, darkMode,
  } = config || {}
  // const { scale = 1.6 } = theme || {}
  const { c1, c2 } = useMemo(
    () => ({
      c1: getColor(
        gradient || 'blue',
        outcome ? index / (outcomes.length + 1) : 0.5,
      ),
      c2: getColor(
        gradient || 'blue',
        outcome ? (index + 1) / (outcomes.length + 1) : 0.5,
      ),
    }),
    [outcome, outcomes, index, gradient],
  )
  const { color1, color2 } = useSpring({
    color1: c1,
    color2: c2,
  })
  const isWinner = useMemo(
    () => !!winner && winner === outcome?.id,
    [outcome, winner],
  )

  const leftVal = useMemo(() => {
    if (!isWinner) return 780 * percent
    return swapColors ? 0 : 780
  }, [isWinner, swapColors, percent])
  const { width } = useSpring({
    width: leftVal,
    config: { duration: 1000, easing: easings.easeOutCubic },
  })

  const show = useMemo(
    () => !!(outcome && (!winner || outcome.id === winner)),
    [winner, outcome],
  )

  const { title = '' } = outcome || {}
  const marginLeft = useMemo(() => `${scale * 0.3}rem`, [scale])

  const bgColor = useMemo(() => {
    if (darkMode) return 'rgba(0,0,0,0.4)'
    return swapColors ? `url(#bar-${index})` : 'rgba(250,250,250, 0.5)'
  }, [darkMode, swapColors, index])
  const barColor = useMemo(() => {
    if (darkMode) return `url(#bar-${index})`
    return swapColors ? 'rgb(255,255,255)' : `url(#bar-${index})`
  }, [darkMode, swapColors, index])

  const trackColor = useMemo(() => (darkMode || swapColors ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.3)'), [darkMode, swapColors])
  return (
    <div
      style={{
        height: show ? OUTCOME_HEIGHT * scale : 0,
        transition: `height 750ms cubic-bezier(0.83, 0, 0.17, 1) ${
          show ? 600 * (index + 1) : 0
        }ms`,
        display: 'flex',
        flexFlow: 'column',
        position: 'relative',
        overflow: 'visible',
        alignItems: 'flex-end',
        fontFamily: 'Bakbak One',
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          // width: '100%',
          transition: 'height 500ms ease',
        }}
      >
        <svg
          viewBox='0 0 800 100'
          style={{
            height: `${30 * scale}px`,
            opacity: show ? 1 : 0,
            transition: `opacity 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
              show ? 600 * (index + 1) + 333 : 0
            }ms`,
            position: 'relative',
            overflow: 'visible',
          }}
        >
          <defs>
            <filter id='f3' x='-1' y='-1' width='250%' height='250%'>
              <feOffset result='offOut' in='SourceAlpha' dx='4' dy='4' />
              <feGaussianBlur result='blurOut' in='offOut' stdDeviation='10' />
              <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
            </filter>
            <linearGradient id={`bar-${index}`} x1='0' y1='0' x2='0' y2='1'>
              <animated.stop offset='0%' stopColor={color1} />
              <animated.stop offset='100%' stopColor={color2} />
            </linearGradient>
            <filter
              id={`sofGlow-${index}`}
              height='300%'
              width='300%'
              x='-75%'
              y='-75%'
            >
              <feMorphology
                operator='dilate'
                radius='2'
                in='SourceAlpha'
                result='thicken'
              />

              <feGaussianBlur in='thicken' stdDeviation='4' result='blurred' />

              <feFlood
                floodOpacity={0.7}
                floodColor={
                  !swapColors || darkMode ? c1 : 'rgba(255,255,255,0.6)'
                }
                result='glowColor'
              />
              <feComposite
                in='glowColor'
                in2='blurred'
                operator='in'
                result='softGlow_colored'
              />
              <feMerge>
                <feMergeNode in='softGlow_colored' />
                <feMergeNode in='SourceGraphic' />
              </feMerge>
            </filter>
          </defs>

          {/* <rect
          x={0}
          y={0}
          width={1000}
          height={100}
          fill='url(#bar)'
          clipPath='url(#cutoff)'
        /> */}
          <rect
            x={0}
            width={800}
            y={20}
            rx={15}
            height={90}
            filter={!darkMode && swapColors ? 'url(#f3)' : undefined}
            fill={bgColor}
            // style={{ opacity: isWinner
            // && (!darkMode && swapColors) ? 0 : 1, transition: 'opacity 500ms' }}
          />

          {/* <path
          d={LABEL_PATH}
          fill={swapColors ? 'rgba(0,0,0,0.85)' : 'rgba(255,255,255,0.95)'}
        /> */}
          <rect
            y={85}
            height={12}
            rx={7}
            x={20}
            width={760}
            // filter='url(#f3)'
            fill={trackColor}
            // fill={`url(#bar-${index})`}
            style={{ opacity: isWinner ? 0 : 1, transition: 'opacity 500ms' }}
          />
          <animated.rect
            y={85}
            height={10}
            rx={7}
            x={width.to((w) => 780 - w)}
            width={width}
            filter={`url(#sofGlow-${index})`}
            fill={barColor}
            // fill={`url(#bar-${index})`}
            style={{ opacity: isWinner ? 0 : 1, transition: 'opacity 500ms' }}
          />
        </svg>
        <WinnerPanel outcome={outcome} isWinner={isWinner} />
      </div>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          width: '100%',
          top: `${scale * 6}px`,
          opacity: show && !isWinner ? 1 : 0,
          transition: `opacity 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
            show ? 600 * (index + 1) + 400 : 0
          }ms`,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            transition: 'all 500ms cubic-bezier(0.83, 0, 0.17, 1)',
            filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,0.5))',
            fontSize: `${scale * 0.7}rem`,
            // transform: `translate(0, ${show ? 0 : 10}px)`,
          }}
        >
          <ChannelPoint
            // fill={color1}
            style={{
              width: `${scale * 9}px`,
              top: '1px',
              marginLeft: `${scale * 0.4}rem`,
              filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,0.5))',
            }}
          />
          <span
            style={{
              // opacity: show && outcome?.users ? 1 : 0,
              width: `${scale * 36}px`,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              lineHeight: 1.2,
              height: `${scale * 13}px`,
              textShadow: '1px 1px 3px black',
              color: 'white',
              transition: 'all 500ms',
            }}
          >
            {outcome?.channel_points
              ? truncateNumber(outcome.channel_points)
              : ''}
          </span>

          <Voter
            style={{
              marginLeft,
              position: 'relative',
              top: `${scale}px`,
              width: `${scale * 15}px`,
              filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,0.5))',
            }}
          />
          <span
            style={{
              // opacity: show && outcome?.users ? 1 : 0,
              width: `${scale * 28}px`,
              color: 'white',
              textShadow: '1px 1px 3px black',
              lineHeight: 1.2,
              height: `${scale * 13}px`,
              whiteSpace: 'nowrap',
              textAlign: 'center',
              transition: 'all 500ms',
            }}
          >
            {truncateNumber(outcome?.users || 0)}
          </span>
          <Trophy
            style={{
              marginLeft,
              width: `${scale * 10}px`,
              top: '1px',
              filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,0.5))',
              opacity: 0.9,
            }}
          />
          <span
            style={{
              // opacity: outcome?.percent ? 1 : 0,
              marginLeft,
              whiteSpace: 'nowrap',
              lineHeight: 1.2,
              color: 'white',
              textAlign: 'center',
              textShadow: '1px 1px 3px black',
              height: `${scale * 13}px`,
              width: `${scale * 28}px`,
              transition: 'all 500ms',
            }}
          >
            {outcome?.percent ? `${(1 / outcome.percent).toFixed(1)}x` : ''}
          </span>
        </div>
        <FadeInText
          style={{
            fontSize: `${scale * 0.7}rem`,
            fontFamily: 'Bakbak One',
            position: 'relative',
            flex: 1,
            textAlign: 'right',
            top: `${scale}px`,
            paddingRight: `${scale * 0.5}rem`,
            transition: `all 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
              show ? 600 * (index + 1) + 333 : 0
            }ms`,
          }}
        >
          {show ? title : ''}
        </FadeInText>
      </div>
    </div>
  )
}

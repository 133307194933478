import {
  Broadcaster,
  WithId,
} from '@elan-twitch/shared'
import {
  createContext,
} from 'react'

export type BroadcasterContextData = {
  broadcaster: WithId<Broadcaster>
  domainBroadcaster?: string
  basePath: string
}

export const BroadcasterContext = createContext<BroadcasterContextData>({
  basePath: '',
  broadcaster: {
    _id: '',
    name: '',
    twitchId: '',
  },
})

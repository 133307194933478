import { createContext } from 'react'

export type MovieViewContextValue = {
  movieId: string
  refetch?: () => void
  onClose?: () => void
}
export const MovieViewContext = createContext<MovieViewContextValue>({
  movieId: '',
  refetch: () => {},
})

import {
  Center,
  CircularProgress,
  Flex,
  Switch,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { ShadowText } from './ShadowText'

export const LoadingSwitch = ({
  value,
  label,
  onChange,
}: {
  value: boolean
  label: string
  onChange: (updated: boolean) => Promise<void>
}) => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const reportComplete = useCallback((fetchedOn: number) => {
    const buffer = Math.max(0, 1000 - (Date.now() - fetchedOn))
    setTimeout(() => {
      setIsLoading(false)
    }, buffer)
  }, [])

  return (
    <Flex py={1} pr={2} pl={3} bg='gray.700' borderRadius={12} boxShadow='0 0 4px black' pos='relative' align='center'>
      <Flex
        opacity={isLoading ? 0 : 1}
        transition={`opacity 300ms ${isLoading ? 'ease-out' : 'ease-in'}`}
        pointerEvents={isLoading ? 'none' : 'auto'}
        align='center'
        gap={2}
      >
        <ShadowText style={{ fontSize: '1.2rem', height: '24px' }}>
          {label}
        </ShadowText>
        <Switch
        size='md'
          isChecked={value}
          onChange={async (e) => {
            setIsLoading(true)
            const fetchedOn = Date.now()
            try {
              await onChange(e.target.checked)
            } catch (err: any) {
              console.error(err)
              toast({
                title: 'Error',
                description: err?.message || 'Error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
            }
            reportComplete(fetchedOn)
          }}
        />
      </Flex>
      <Center
        opacity={isLoading ? 1 : 0}
        transition={`opacity 300ms ${!isLoading ? 'ease-out' : 'ease-in'}`}
        pointerEvents={isLoading ? 'auto' : 'none'}
        pos='absolute'
        top='-10%'
        left='-10%'
        w='120%'
        borderRadius={8}
        p={1}
        gap={2}
        h='120%'
      >
        <CircularProgress size={4} color='white' isIndeterminate />
        <ShadowText
          style={{
            fontSize: '1.2rem',
            height: '24px',
            color: 'white',
          }}
        >
          Loading...
        </ShadowText>
      </Center>
    </Flex>
  )
}

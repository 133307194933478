import { useEffect, useRef } from 'react'

export const NumberTicker = ({ value, duration = 30 }: { value: number; duration?: number }) => {
  const spanRef = useRef<HTMLSpanElement>(null)
  const helperValue = useRef(value)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (spanRef.current) {
        if (helperValue.current === value && intervalRef.current) return clearInterval(intervalRef.current)
        if (helperValue.current < value) {
          helperValue.current += 1
          spanRef.current.innerText = helperValue.current.toString()
        } else if (helperValue.current > value) {
          helperValue.current -= 1
          spanRef.current.innerText = helperValue.current.toString()
        }
      }
      return () => {}
    }, duration)
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [value, duration])

  return <span ref={spanRef}>{value}</span>
}

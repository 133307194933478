import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { Button, HStack, Text } from '@chakra-ui/react'
import { ListSortPreset } from '@elan-twitch/shared'
import { useContext } from 'react'
import { CollapseHorizontal } from '../CollapseHorizontal'
import { InfiniteScrollListContext } from './context'

const SortButton = ({ preset }: { preset: ListSortPreset<any> }) => {
  const {
    data: { setSortFromPreset, sort },
  } = useContext(InfiniteScrollListContext)
  const { label, sortKey } = preset
  const isActive = sort.sortKey === sortKey
  const color = isActive ? 'green.500' : 'gray.500'
  const icon = sort.sortDirection === 'asc' ? <ArrowUpIcon w={5} h={5} /> : <ArrowDownIcon w={5} h={5} />

  return (
    <Button
      size='sm'
      variant='outline'
      border='1px solid'
      borderColor={color}
      _dark={{
        borderColor: color,
        color,
      }}
      aria-label={label}
      opacity={isActive ? 1 : 0.8}
      _hover={{ opacity: 1 }}
      gap={1}
      color={color}
      onClick={() => setSortFromPreset(preset)}
    >
      <Text color={color}>{label}</Text>
      <CollapseHorizontal active={isActive} width={20}>
        {icon}
      </CollapseHorizontal>
    </Button>
  )
}

export const ListSortView = () => {
  const {
    list: { sortPresets },
  } = useContext(InfiniteScrollListContext)

  return (
    <HStack spacing={1}>
      {sortPresets.map((preset) => (
        <SortButton key={preset.label} preset={preset} />
      ))}
    </HStack>
  )
}

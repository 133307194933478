import {
  FC, SVGProps, useEffect, useState,
} from 'react'

export const ChannelPointGroup = ({ active }: { active: boolean }) => {
  const [transform, setTransform] = useState('')
  const [opacity, setOpacity] = useState(0)
  // const [posX, setPosX] = useState(0)
  // const [posY, setPosY] = useState(0)
  useEffect(() => {
    if (active) {
      // const theta = (Math.random() - 0.5) * (Math.PI / 4)
      const _posX = 240 + (Math.random() - 0.5) * 500
      const _posY = Math.random() * 450
      const _scale = 0.6 + Math.random() * 0.1
      const _transform = `scale(${_scale}) translate(${_posX}, ${_posY})`
      const _opacity = 0.4 + Math.random() * 0.5
      setTransform(_transform)
      setOpacity(_opacity)
      // setPosX(_posX)
      // setPosY(_posY)
    }
  }, [active])
  return (
    <g
      // scale={scale}
      transform={transform}
      opacity={opacity}
      style={{ transformOrigin: '50% 50%' }}
    >
      <path
        fill='#ffffff'
        d='M 41.517 82.772 C 18.511 82.608 1.586 65.682 1.422 42.841 C 1.258 19.343 19.004 1.925 42.503 2.418 C 65.508 2.911 81.94 20 81.776 43.006 C 81.447 66.011 64.358 82.772 41.517 82.772 Z M 41.517 70.612 C 56.306 70.612 69.287 57.795 69.616 43.17 C 69.78 27.724 56.306 14.249 40.859 14.578 C 26.07 14.906 13.417 28.052 13.582 42.841 C 13.746 57.63 26.728 70.448 41.517 70.612 Z'
      />
      <path
        fill='#ffffff'
        d='M 50.468 28.005 C 56.877 29.484 61.642 33.592 63.121 40.658 C 63.778 44.109 61.971 47.066 58.191 46.573 C 51.125 45.588 46.689 40.658 44.717 33.921 C 43.895 30.305 46.196 28.334 50.468 28.005 Z'
      />
    </g>
  )
}

const points = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
export const ChannelPoints: FC<
  SVGProps<SVGSVGElement> & { active: boolean }
> = ({ active }) => (
  <>
    {points.map((_, i) => (
      <ChannelPointGroup active={active} key={i} />
    ))}
  </>
)

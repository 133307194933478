import { Egg } from '@elan-twitch/shared'

export const defaultEgg: Egg = {
  color: { r: 100, g: 150, b: 220 },
  rank: 0,
  name: '',
  mass: 1,
  price: 1000,
  rollThreshold: 25,
}

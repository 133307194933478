import {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { ChatUserstate, Client } from 'tmi.js'
import { useChatEmotes, UseChatEmotesConfig } from './useChatEmotes'

type TwitchMessageListener = (
  channel: string,
  userstate: ChatUserstate,
  message: string,
  self: boolean
) => void
export type MessageSubscriber = ({
  message,
  username,
}: {
  message: string
  username: string
  mod: boolean
  userColor: string
}) => void

const generateSmallId = () => Math.random().toString(36).substring(2, 5)
const generateSmallUniqueId = (existing: Array<string>) => {
  let id = generateSmallId()
  while (existing.includes(id)) {
    id = generateSmallId()
  }
  return id
}

export type UseLiveChatConfig = {
  emotes?: UseChatEmotesConfig
}

export const useLiveChat = (broadcasterId?: string, config?: UseLiveChatConfig) => {
  const { emotes: emoteConfig } = config || {}
  const clientRef = useRef<Client | null>(null)
  const messages = useRef<Array<string>>([])
  const subscribers = useRef<Record<string, MessageSubscriber>>({})
  const [error, setError] = useState<string | null>(null)
  const messageListener = useRef<TwitchMessageListener | null>(null)
  useEffect(() => {
    if (!broadcasterId) {
      setError('No broadcaster ID provided')
      return
    }
    if (!clientRef.current) {
      const client = new Client({
        connection: {
          // secure: true,
          reconnect: true,
        },
        channels: [broadcasterId],
      })
      client.connect()
      clientRef.current = client
    }
    const cl = clientRef.current

    if (messageListener.current) {
      cl.removeListener('message', messageListener.current)
    }
    messageListener.current = (_channel, tags, message) => {
      if (messages.current.length > 25) {
        messages.current.shift()
      }
      // messages.current.push(displayedMessage)
      Object.values(subscribers.current).forEach((subscriber) => {
        subscriber({
          username: tags['display-name'] || '',
          message,
          mod: !!tags.mod,
          userColor: tags.color || 'white',
        })
      })
    }
    cl.on('message', messageListener.current)
  }, [broadcasterId])
  const subscribe = useCallback((newSubscriber: MessageSubscriber) => {
    const id = generateSmallUniqueId(Object.keys(subscribers.current))
    subscribers.current[id] = newSubscriber
    return () => {
      delete subscribers.current[id]
    }
  }, [])
  const emotes = useChatEmotes(broadcasterId, emoteConfig)
  return {
    messages,
    subscribe,
    error,
    emotes,
  }
}

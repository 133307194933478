import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  PointsAuction,
  formatNumber,
  gradientToCssGradientProp,
  gradients,
  truncateNumber,
} from '@elan-twitch/shared'
import { ConfirmResults } from 'components/Widgets/Auction/ConfirmResults'
import { AddButton, LoadButton, SaveButton } from 'components/shared/Buttons'
import { NumberTicker } from 'components/shared/NumberTicker'
import { SVGTimer } from 'components/shared/SVGTimer'
import { TimeElapsed } from 'components/shared/TimeElapsed'
import { useMounted } from 'hooks/useMounted'
import { useWithLoading } from 'hooks/useWithLoading'
import { Trophy } from 'icons/Trophy'
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useScreen } from 'store/useScreen'
import { ShadowText } from '../../shared/ShadowText'
import { ReopenBidding } from './ReopenBidding'
import { useAuctionContext } from './context'
import {
  confirmAuctionResult,
  endPointsAuctionBidding,
  finishPointsAuction,
  reopenPointsAuctionBidding,
  savePointsAuction,
  setCurrentAuctionId,
  unsavePointsAuction,
} from './utils'

const BID_HEIGHT = 100
const getBidProps = (index: number, gridWidth: number) => {
  if (index === 0) {
    return {
      width: gridWidth,
      height: `${BID_HEIGHT}px`,
      x: 0,
      y: 0,
    }
  }
  const x = (index + 1) % 2
  const y = Math.floor((index + 1) / 2)
  const width = gridWidth / 2
  const transform = `translate(${x * (gridWidth / 2)}px, ${y * BID_HEIGHT}px)`
  return {
    width: `${width}px`,
    height: `${BID_HEIGHT}px`,
    transform,
  }
}

const getBidBackgroundColor = (index: number) => {
  switch (index) {
    case 0:
      return 'gold'
    case 1:
      return 'silver'
    case 2:
      return '#d67427'
    default:
      return '#777777'
  }
}

const BidView = ({
  timestamp,
  points,
  index,
  user_name,
  gridWidth,
}: PointsAuction['bids'][number] & { index: number; gridWidth: number }) => {
  const props = useMemo(() => getBidProps(index, gridWidth), [index, gridWidth])
  const { broadcaster, auction } = useAuctionContext()
  const { pointName, pointIconPath } = broadcaster?.pointsBankAccountConfig || {}
  const backgroundColor = useMemo(() => getBidBackgroundColor(index), [index])
  const opacity = useMemo(() => {
    if (auction?.ended_at) return index === 0 ? 1 : 0.5 - (index - 2) * 0.1
    return index > 2 ? 0.7 - (index - 2) * 0.1 : 1
  }, [index, auction])
  const mounted = useMounted(250)
  return (
    <Center
      position='absolute'
      gap={1}
      flexDir='column'
      left={0}
      top={0}
      px={2}
      py={1}
      cursor='default'
      transition='all 500ms'
      {...props}
    >
      <HStack
        py={2}
        pos='relative'
        opacity={mounted ? opacity : 0}
        transform={`scaleY(${mounted ? 1 : 0})`}
        transition='all 250ms'
        boxShadow={`0px 0px 6px ${auction?.ended_at && index === 0 ? 'gold' : 'black'}`}
        px={3}
        borderRadius={6}
        justify='space-between'
        overflow='hidden'
        w='100%'
        h='100%'
        bg={backgroundColor}
      >
        <Box
          pointerEvents='none'
          w='100%'
          h='100%'
          left={0}
          top={0}
          position='absolute'
          background='linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2906337535014006) 100%)'
        />
        {/* <Center
          bg='blackAlpha.500'
          borderRadius='full'
          w={8}
          h={8}
          color='white'
          boxShadow='inset 0px 0px 3px black'
          fontFamily='Bebas Neue'
          fontSize='xl'
          textShadow='1px 1px 3px black'
        >
          {index + 1}
        </Center> */}
        <HStack zIndex={2} px={1} align='center' filter='drop-shadow(1px 1px 3px #000000ab)'>
          {pointIconPath ? <img style={{ width: '28px' }} src={pointIconPath} alt={pointName} /> : null}
          <Text
            position='relative'
            top='2px'
            fontFamily='Bebas Neue'
            whiteSpace='nowrap'
            lineHeight={1}
            color='white'
            fontSize='2rem'
          >
            {truncateNumber(points)}
          </Text>
        </HStack>
        <VStack align='flex-end' zIndex={2} spacing={0}>
          <Text
            lineHeight={1}
            textShadow='1px 1px 3px black'
            whiteSpace='nowrap'
            color='white'
            fontSize='lg'
            isTruncated
          >
            {user_name}
          </Text>
          <Text color='gray.50' fontWeight={500} fontSize='sm' textShadow='1px 1px 3px black'>
            <TimeElapsed since={timestamp} /> ago
          </Text>
        </VStack>

        <Center
          pointerEvents='none'
          opacity={auction?.winner && index === 0 ? 1 : 0}
          transition='all 1000ms'
          w='100%'
          h='100%'
          position='absolute'
        >
          <Box p={1} width={`${BID_HEIGHT * 0.6}px`}>
            <Trophy />
          </Box>
        </Center>
      </HStack>
    </Center>
  )
}

export const PointsAuctionVotingStage = ({ readOnly }: { readOnly?: boolean }) => {
  const { auction, broadcaster, setEditMode } = useAuctionContext()

  const [isLoading, setIsLoading] = useState(false)

  // const onEndAuction = useCallback(() => {
  //   if (!auction) return
  //   setIsLoading(true)
  //   endPointsAuctionBidding(broadcaster, auction._id)
  //     .then(() => undefined)
  //     .catch(console.error)
  //     .finally(() => setIsLoading(false))
  // }, [auction, broadcaster])

  const { call: onSave, loading: saveLoading } = useWithLoading(savePointsAuction)
  const { call: onUnsave, loading: unsaveLoading } = useWithLoading(unsavePointsAuction)
  const screenWidth = useScreen((s) => s.width)
  const screenHeight = useScreen((s) => s.height)
  const { gridWidth, gridHeight } = useMemo(() => {
    const gWidth = Math.min(600, screenWidth - 30)
    const maxRows = 5
    const gHeight = Math.min(maxRows * BID_HEIGHT, screenHeight - 200)

    return {
      gridWidth: gWidth,
      gridHeight: gHeight,
    }
  }, [screenWidth, screenHeight])

  const [confirmingBackId, setConfirmingBackId] = useState<string | null>(null)
  const handleBack = useCallback(
    (exitMode?: 'load' | 'create') => {
      if (exitMode) setEditMode(exitMode)
      if (auction && !auction?.saved_on) {
        setConfirmingBackId(auction._id)
        return
      }
      setCurrentAuctionId(broadcaster, null)
    },
    [broadcaster, auction, setEditMode],
  )

  const [confirmingBack, setConfirmingBack] = useState(false)
  const handleConfirmBack = useCallback(
    async (withSave: boolean) => {
      setConfirmingBack(true)
      setConfirmingBackId(null)
      if (confirmingBack) {
        if (withSave) await savePointsAuction(broadcaster, auction?._id || '')
        await finishPointsAuction(broadcaster, auction?._id || '')
      }
      await setCurrentAuctionId(broadcaster, null)
      setConfirmingBack(false)
    },
    [broadcaster, auction, confirmingBack],
  )

  const handleReopen = useCallback(async () => {
    if (!auction) return undefined
    setIsLoading(true)
    return reopenPointsAuctionBidding(broadcaster, auction._id)
      .then(() => undefined)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [auction, broadcaster])

  const handleConfirmResults = useCallback(async () => {
    if (!auction) return undefined
    setIsLoading(true)
    return confirmAuctionResult({ broadcasterId: broadcaster._id, auctionId: auction._id })
      .then(() => undefined)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [auction, broadcaster])

  const cancelRef = useRef<HTMLButtonElement>(null)
  const timerStartsAt = useMemo(
    () => (auction?.scheduled_end ? new Date(auction.scheduled_end).getTime() - auction.timeout_seconds * 1000 : 0),
    [auction],
  )

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (auction?.scheduled_end && !auction.ended_at) {
      const timeout = new Date(auction.scheduled_end).getTime() - Date.now()
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        if (auction) {
          endPointsAuctionBidding(broadcaster, auction._id)
            .then(() => undefined)
            .catch(console.error)
            .finally(() => setIsLoading(false))
        }
      }, timeout)
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [auction, broadcaster])

  const { pointName, pointIconPath } = broadcaster?.pointsBankAccountConfig || {}
  return (
    <VStack
      spacing={3}
      p={4}
      pointerEvents={auction ? 'auto' : 'none'}
      borderRadius={4}
      opacity={auction ? 1 : 0}
      transition='opacity 400ms'
      cursor='default'
      align='center'
      w='100%'
      h={`${screenHeight}px`}
    >
      <HStack width={`${gridWidth}px`} spacing={0} flex={1}>
        <Flex flexFlow='column' width='100%' overflow='hidden' maxH='90%' style={{ overflow: 'hidden' }}>

          {!readOnly ? (
            <HStack spacing={0}>
              <ReopenBidding isLoading={isLoading} onReopen={handleReopen} />
              <ConfirmResults isLoading={isLoading} onConfirm={handleConfirmResults} />
            </HStack>
          ) : null}
          <HStack
            px={1}
            w='100%'
            align='center'
            justify='space-between'
            // bg={'linear-gradient(60deg, #29323c 0%, #485563 100%)'}
            // backgroundBlendMode='multiply,multiply'
            // boxShadow='1px 1px 8px rgba(0,0,0,0.8)'
            borderRadius={6}
          >
            <ShadowText
              style={{
                fontFamily: 'Encode Sans',
                fontSize: '1.8rem',
                whiteSpace: 'nowrap',
                flex: 1,
                minWidth: 0,
                lineHeight: 1,
              }}
            >
              {auction?.title || ''}
            </ShadowText>

          </HStack>
          <HStack mb={3} px={2} w='100%' height='20px' justify='space-between'>
            <Box>
              <ShadowText style={{
                position: 'relative', top: '4px', fontSize: '1.4rem', fontFamily: 'Encode Sans',
              }}>
                !auctionbid <span style={{
                color: '#88ddff', fontFamily: 'Bebas Neue', marginLeft: '0.3rem', fontSize: '1.5rem',
              }}>{'<AMOUNT>'}</span>
              </ShadowText>
            </Box>
            <ShadowText style={{ position: 'relative', top: '4px', fontSize: '1.4rem' }}>
              <NumberTicker value={auction?.num_bids || 0} /> Bids
            </ShadowText>
          </HStack>
          <Collapse in={!auction?.ended_at} style={{ width: '100%' }}>
            <SVGTimer endText='' endsAt={auction?.scheduled_end} startsAt={timerStartsAt} />
          </Collapse>
          <Box
            mt={2}
            transition='all 400ms'
            width={`${gridWidth}px`}
            height={`${gridHeight}px`}
            position='relative'
            borderRadius={6}
          >
            {auction?.bids?.length
              ? auction.bids.map((outcome, index) => (
                <BidView gridWidth={gridWidth} key={`${outcome.timestamp}`} index={index} {...outcome} />
              ))
              : null}
            <Collapse style={{ width: '100%' }} in={!auction?.bids?.length} animateOpacity>
              <Center flex={1} w='100%' h='100%' color='white'>
                <HStack
                  position='relative'
                  overflow='hidden'
                  w='100%'
                  borderRadius={6}
                  bg='blackAlpha.500'
                  justify='center'
                  spacing={2}
                  py={10}
                  px={3}
                >
                  {auction?.ended_at ? (
                    <Image opacity={0.5} position='absolute' h='100%' w='100%' src='/assets/images/emotes/lule.webp' />
                  ) : null}
                  <HStack
                    zIndex={2}
                    textShadow='1px 1px 4px black'
                    spacing={0}
                    textAlign='center'
                    fontSize='4xl'
                    fontFamily='Bebas Neue'
                  >
                    {auction?.ended_at ? (
                      <Text>NO BIDS</Text>
                    ) : (
                      <>
                        <span style={{ marginRight: '1rem', lineHeight: 1 }}>Starting bidding at:</span>
                        {pointIconPath ? (
                          <img style={{ width: '28px', marginRight: '0.2rem' }} src={pointIconPath} alt={pointName} />
                        ) : null}
                        <span style={{ lineHeight: 1 }}>{formatNumber(auction?.bid_start_price || 0)}</span>
                      </>
                    )}
                  </HStack>
                </HStack>
              </Center>
            </Collapse>
          </Box>
        </Flex>
      </HStack>
      {readOnly ? null : (
        <HStack spacing={0} pointerEvents='auto' position='absolute' right={8} top={4}>
          <AddButton isLoading={isLoading} onClick={() => handleBack('create')} label='NEW AUCTION' />
          <LoadButton isLoading={isLoading} onClick={() => handleBack('load')} label='LOAD AUCTION' />
          <SaveButton
            // variant='ghost'
            savedOn={auction?.saved_on}
            onClick={() => (auction?.saved_on ? onUnsave(broadcaster, auction?._id || '') : onSave(broadcaster, auction?._id || ''))
            }
            isLoading={saveLoading || unsaveLoading}
          />
        </HStack>
      )}
      <AlertDialog
        isCentered
        isOpen={!!confirmingBackId}
        leastDestructiveRef={cancelRef}
        onClose={() => setConfirmingBackId(null)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent boxShadow='0 0 5px black' bg={gradientToCssGradientProp(gradients.nymn)}>
            <AlertDialogHeader py={2} fontSize='lg'>
              <Center>
                <ShadowText
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: 'Encode Sans',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  GO BACK
                </ShadowText>
              </Center>
            </AlertDialogHeader>
            <AlertDialogBody px={3}>
              <HStack spacing={1} w='100%'>
                <Button
                  flex={1}
                  fontSize='xl'
                  fontFamily='Bebas Neue'
                  isLoading={confirmingBack}
                  colorScheme='red'
                  onClick={() => handleConfirmBack(false)}
                  px={2}
                >
                  Go Back without Saving
                </Button>
                <Button
                  flex={1}
                  fontSize='xl'
                  fontFamily='Bebas Neue'
                  isLoading={confirmingBack}
                  colorScheme='red'
                  onClick={() => handleConfirmBack(true)}
                  px={2}
                >
                  Save Auction and Go Back
                </Button>
              </HStack>
            </AlertDialogBody>
            <AlertDialogCloseButton />
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  )
}

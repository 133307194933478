import { a, useSpring } from '@react-spring/web'
import { StockGraphIntervalApi } from 'components/Widgets/Chat/EmoteStockGraphView/types'
import { getCandlestickPaths } from 'components/Widgets/Chat/EmoteStockGraphView/utils'
import {
  forwardRef, useCallback, useImperativeHandle, useRef,
} from 'react'

export const StockGraphCurrInterval = forwardRef<
  StockGraphIntervalApi,
  { x: number; y: number; width: number; scaleY: number }
>(({
  x, y, width, scaleY,
}, ref) => {
  const metrics = useRef<{ start: number; end: number; min: number; max: number } | null>(null)

  const [{
    linePath, boxPath, color, opacity,
  }, api] = useSpring(() => {
    const data = getCandlestickPaths(width, x, y, scaleY, {
      min: 0,
      max: 0,
      start: 0,
      end: 0,
    })

    return {
      linePath: data.linePath,
      boxPath: data.boxPath,
      color: data.color,
      opacity: data.opacity,
    }
  })

  const updatePaths = useCallback(() => {
    if (!metrics.current) return
    const updatedProps = getCandlestickPaths(width, x, y, scaleY, {
      min: metrics.current.min,
      max: metrics.current.max,
      start: metrics.current.start,
      end: metrics.current.end,
    })
    api.start(updatedProps)
  }, [x, y, api, width, scaleY])

  const resetPaths = useCallback(
    (currPrice: number) => {
      const data = getCandlestickPaths(width, x, 0, scaleY, {
        min: currPrice,
        max: currPrice,
        start: currPrice,
        end: currPrice,
      })
      api.start(data)
    },
    [x, api, width, scaleY],
  )

  const handleSecond = useCallback(
    (score: number) => {
      if (metrics.current) {
        metrics.current.end = score
        metrics.current.min = Math.min(metrics.current.min, score)
        metrics.current.max = Math.max(metrics.current.max, score)
      } else {
        metrics.current = {
          start: score,
          end: score,
          min: score,
          max: score,
        }
      }

      updatePaths()
    },
    [updatePaths],
  )

  const handleReset = useCallback(
    (currPrice: number) => {
      metrics.current = null
      resetPaths(currPrice)
    },
    [resetPaths],
  )

  useImperativeHandle(ref, () => ({
    onSecond: handleSecond,
    reset: handleReset,
  }))

  return (
    <>
      {/* Candlestick line */}
      <a.path
        filter='url(#stock-graph-drop-shadow)'
        d={linePath}
        fill='#ffffff88'
        // fill='red'
        stroke='none'
        fillOpacity={opacity}
      />
      {/* Candlestick box */}
      <a.path
        filter='url(#stock-graph-drop-shadow)'
        d={boxPath}
        fill={color}
        fillOpacity={opacity}
        stroke='#ffffff88'
        strokeWidth={width / 25}
      />
    </>
  )
})

import { CHAT } from '../constants'
import { SevenTvEmoteOptions, TwitchEmoteOptions } from '../types'
import { getBroadcasterConfigPath } from './config'

export const getTwitchEmoteUrl = (id: string, { format, scale, theme_mode }: TwitchEmoteOptions) => `https://static-cdn.jtvnw.net/emoticons/v2/${id}/${format}/${theme_mode}/${scale}`
export const getSevenTvEmoteUrl = (id: string, { format, size }: SevenTvEmoteOptions) => `https://cdn.7tv.app/emote/${id}/${size}.${format}`

/*
takes a twitch message and a record of emotes where the key is the emote name
and the value is the emote url, returns valid html where emote names are replaced by
img components with their urls
*/

export const parseEmotes = (message: string, emotes: Record<string, string>) => {
  const words = message.split(' ')
  const emoteWords = words.map((word) => {
    const emoteUrl = emotes[word]
    if (!emoteUrl) return word
    return `<img title="${word}" src="${emoteUrl}" alt="${word}">`
  })
  return emoteWords.join(' ')
}

export const getEmoteCount = (message: string, emoteName: string) => {
  const words = message.split(' ')
  return words.filter((word) => word === emoteName).length
}

// unused for now
export const getBroadcasterChatConfigPath = (broadcasterId: string) => `${getBroadcasterConfigPath(broadcasterId)}/${CHAT}`

import {
  GetChannelChatEmotesResponse, GetThirdPartyAuthTokenArgs, GetThirdPartyAuthUrlArgs, GetTwitchBroadcasterAuthUrlArgs, HandleBroadcasterAuthRedirectArgs, TwitchBadge,
} from '@elan-twitch/shared'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from './app'

export const functions = getFunctions(app)
export const getChannelChatEmotes = httpsCallable<{channelName: string}, GetChannelChatEmotesResponse>(functions, 'getChannelChatEmotes')
export const getChannelBadges = httpsCallable<{broadcasterId: string}, Record<TwitchBadge['set_id'], TwitchBadge>>(functions, 'getChannelBadges')
export const getThirdPartyAuthUrl = httpsCallable<GetThirdPartyAuthUrlArgs, { url: string }>(
  functions,
  'getThirdPartyAuthUrl',
)
export const getThirdPartyAuthToken = httpsCallable<GetThirdPartyAuthTokenArgs, {token: string, pathname: string}>(
  functions,
  'getThirdPartyAuthToken',
)

export const getTwitchBroadcasterAuthUrl = httpsCallable<GetTwitchBroadcasterAuthUrlArgs, { url: string }>(
  functions,
  'getTwitchBroadcasterAuthUrl',
)

export const handleBroadcasterAuthRedirect = httpsCallable<HandleBroadcasterAuthRedirectArgs, {pathname: string, error?: never} | {error: string, pathname?: never}>(functions, 'handleBroadcasterAuthRedirect')

export const deleteSubscription = httpsCallable<{subscriptionId: string, broadcasterId: string, type: string}, {success: true}>(functions, 'deleteSubscription')

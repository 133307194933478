import { a, useSpring } from '@react-spring/web'
import { getCandlestickPaths } from 'components/Widgets/Chat/EmoteStockGraphView/utils'
import { useMemo } from 'react'
import { IntervalData } from './types'

export const PastInterval = ({
  interval, x, width, scaleY,
}: { interval: IntervalData; x: number; width: number, scaleY: number }) => {
  const pathProps = useMemo(() => getCandlestickPaths(width, x, 0, scaleY, interval), [width, x, interval, scaleY])
  const { linePath, boxPath, color } = useSpring({
    linePath: pathProps.linePath,
    boxPath: pathProps.boxPath,
    color: pathProps.color,
  })
  return (
    <>
      {/* Candlestick line */}
      <a.path filter='url(#stock-graph-drop-shadow)' d={linePath} stroke='none' fill='#ffffffaa' />
      {/* Candlestick box */}
      <a.path filter='url(#stock-graph-drop-shadow)' d={boxPath} fill={color} stroke='#ffffff88' strokeWidth={width / 25} />
    </>
  )
}

import { JSX, ReactNode } from 'react'
import { FileValue, ImageViewOptions } from './files'

type BaseField<T, InputValue = string> = {
  optional?: boolean
  placeholder: string
  value?: T
  validate?: (value?: T) => string | undefined
  condition?: (parentData: any) => boolean
  format?: (value: T) => string
  parse?: (value: InputValue) => T
}
export type TextField = BaseField<string> & {
  _type: 'text'
  type?: 'password' | 'email' | 'text' | 'phone' | 'textarea'
  label?: JSX.Element | string
}

export type DateField = BaseField<string> & {
  _type: 'date'
  minDate?: string
  maxDate?: string
  label?: JSX.Element | string
}

export type TimeField = BaseField<string> & {
  _type: 'time'
  label?: JSX.Element | string
}

export type DateTimeField = BaseField<number> & {
  _type: 'datetime'
  label?: JSX.Element | string
}

export type DefaultSelectOption = {
  id: string
  text: string
}

export type SelectField<T = string, OptionValue = T> = BaseField<T, OptionValue> & {
  _type: 'select'
  options: Array<OptionValue>
  label?: string
  getOptionKey: (opt: OptionValue) => string
  renderOption: (opt: OptionValue, colorMode: 'light' | 'dark') => ReactNode
}

export type BooleanField = BaseField<boolean> & {
  _type: 'boolean'
  type?: 'switch' | 'checkbox'
  label?: string
}

export type FileField = BaseField<FileValue> & {
  _type: 'file'
  getPath?: (user: any) => string
  imageOptions?: ImageViewOptions
  label?: string
  accept?: Array<'image' | 'pdf' | 'audio'>
}

export type NumberField = BaseField<number> & {
  _type: 'number'
  type?: 'input' | 'slider'
  label?: string
  precision?: number
  step?: number
  defaultValue?: number
  min?: number
  max?: number
}

export type CurrencyAmountField = BaseField<string> & {
  _type: 'currency'
  label?: string
}

export type RecordField<T extends Field | FieldMap = Field | FieldMap> = BaseField<Array<Field['value']>> & {
  _type: 'record'
  itemField: T
  label?: JSX.Element
  condition?: (parentData: any) => boolean
}

export type SocialMediaSites = 'instagram' | 'twitter' | 'youtube' | 'facebook' | 'soundcloud'
export type SocialMediaValue = Partial<Record<SocialMediaSites, string>>
export type SocialMediaField = BaseField<SocialMediaValue> & {
  _type: 'socialMedia'
  placeholder?: string
  label?: JSX.Element
  sites?: Partial<Record<SocialMediaSites, true>>
}

export type ColorValue = {
  r: number
  g: number
  b: number
  a?: number
}

export type ColorField = BaseField<ColorValue> & {
  _type: 'color'
  defaultValue: ColorValue
  label?: string
  withAlpha?: boolean
}

type AnyObject = { [key: string]: any }
export type Field =
  | TextField
  | FileField
  | BooleanField
  | SelectField<any>
  | CurrencyAmountField
  | NumberField
  | DateField
  | TimeField
  | DateTimeField
  | SocialMediaField
  | ColorField

export type FieldType = Field['_type']
export type FieldMap = {
  name?: string
  validate?: (v: any) => AnyObject | undefined
  children: { [key: string]: IFormElement }
  condition?: (parentData: any) => boolean
}

export type TypedField<T extends FieldType> = Extract<Field, { _type: T }>

export type InputRef = {
  focus: () => void,
  blur: () => void,
}

export type IFormElement = Field | FieldMap | RecordField
export const isField = (
  fieldOrFieldMap: IFormElement,
): fieldOrFieldMap is Field => !!(fieldOrFieldMap as Field)._type
export const isRecordField = (
  fieldOrFieldMap: IFormElement,
): fieldOrFieldMap is RecordField => !!(fieldOrFieldMap as RecordField).itemField

export type FieldMapValue = AnyObject

export type Validate = (data: any) => string | undefined
export type ValidateField<F extends Field> = (
  field: F,
  data?: F['value']
) => string | undefined
export type ValidateAfterOptionalCheck<F extends Field> = (
  field: F,
  data: Required<F>['value']
) => string | undefined

export type Format<Data extends any> = (data?: Data) => string

export type FormatField<F extends Field> = (
  field: F,
  value: F['value']
) => string

export type Parse<Data extends any> = (value: string | undefined) => Data

export type ParseField<F extends Field> = (
  field: F,
  value?: string
) => F['value']

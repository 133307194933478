import { FileValue, LocalFileValue, OnUploadProgress } from '@elan-twitch/shared'
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { app } from './app'

export const storage = getStorage(app)

export const uploadFileValue = async (
  path: string,
  file: LocalFileValue | FileValue | null | undefined,
  onUploadProgress: OnUploadProgress,
): Promise<FileValue | null> => {
  if (!file) return null
  const { dataUrl, type, filename } = file as LocalFileValue
  if (!dataUrl) return file as FileValue
  const blob = await fetch(dataUrl).then((res) => res.blob())
  const storageRef = ref(storage, path)
  const task = uploadBytesResumable(storageRef, blob)
  task.on('state_changed', (snapshot) => {
    const percent = snapshot.bytesTransferred / snapshot.totalBytes
    onUploadProgress({ total: snapshot.totalBytes, loaded: snapshot.bytesTransferred, percent })
  })
  return new Promise((resolve, reject) => {
    task.then(() => {
      resolve({ storagePath: path, type, filename })
    }).catch(reject)
  })
}

import {
  Broadcaster,
  WheelAuction, WithId,
} from '@elan-twitch/shared'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { useWheelAuction } from 'components/Widgets/Wheel/hooks'
import {
  createContext, useContext, useMemo, useState,
} from 'react'

export type WheelContextData = {
  broadcaster: WithId<Broadcaster>
  editMode: null | 'load' | 'create'
  setEditMode: (mode: null | 'load' | 'create') => void
  auction: WithId<WheelAuction> | null
  isLoading: boolean
}

export const useWheelContextState = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { data, isLoading } = useWheelAuction(broadcaster)
  const [editMode, setEditMode] = useState<null | 'load' | 'create'>(null)
  return useMemo<WheelContextData>(
    () => ({
      broadcaster,
      auction: data,
      editMode,
      setEditMode,
      isLoading,
    }),
    [broadcaster, data, isLoading, editMode],
  )
}

export const WheelContext = createContext<WheelContextData>({
  broadcaster: {
    _id: '',
    name: '',
    twitchId: '',
  },
  auction: null,
  editMode: null,
  setEditMode: () => {},
  isLoading: false,
})
export const useWheelContext = () => useContext(WheelContext)

import {
  AutomaticPointsAuction,
  BROADCASTER,
  Broadcaster,
  FieldMapValue,
  PointsAuction,
  PointsAuctionPreviousResult,
  WithId,
} from '@elan-twitch/shared'
import { db, getBroadcasterAuctionDocRef } from 'backend/db'
import { functions } from 'backend/functions'
import { FORM_ERROR } from 'final-form'
import {
  DocumentReference, deleteDoc, doc, getDoc, setDoc, updateDoc,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

export const createPointsAuction = async (broadcaster: WithId<Broadcaster>, auction: AutomaticPointsAuction) => {
  const broadcasterId = broadcaster._id
  const now = new Date().toISOString()
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const currentAuctionDoc = getBroadcasterAuctionDocRef(broadcasterId, now)
  const currAuction = await getDoc(currentAuctionDoc)
  if (currAuction.exists()) {
    throw new Error('Points auction already exists')
  }
  await setDoc(currentAuctionDoc, auction)
  await updateDoc(doc(db, `broadcaster/${broadcasterId}`), {
    'pointsBankAccountConfig.currentPointsAuctionId': now,
  })
}

export const updatePointsAuction = async (
  broadcaster: WithId<Broadcaster>,
  auction: AutomaticPointsAuction,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, auctionId)
  const currAuction = await getDoc(docRef)
  if (!currAuction.exists()) {
    throw new Error('Points auction does not exist')
  }
  if (currAuction.data()?.started_at) {
    throw new Error('Points auction already started')
  }
  await setDoc(docRef, auction)
}

export const startPointsAuction = async (
  broadcaster: WithId<Broadcaster>,
  auction: PointsAuction,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, auctionId)
  const currAuction = await getDoc(docRef)
  if (!currAuction.exists()) {
    throw new Error('Points auction does not exist')
  }
  if (currAuction.data()?.started_at) {
    throw new Error('Points auction already started')
  }
  await setDoc(docRef, {
    ...auction,
    started_at: new Date().toISOString(),
  })
}

export const endPointsAuctionBidding = async (broadcaster: WithId<Broadcaster>, auctionId: string) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, auctionId)

  const auctionRes = await getDoc(docRef)
  if (!auctionRes.exists()) {
    throw new Error('Points auction does not exist')
  }
  const auction = auctionRes.data()
  if (!auction?.started_at) {
    throw new Error('Points auction has not started')
  }
  await updateDoc(docRef, {
    ended_at: new Date().toISOString(),
  })
}

export const savePointsAuction = async (broadcaster: WithId<Broadcaster>, auctionId: string) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, auctionId)
  const currAuction = await getDoc(docRef)
  if (!currAuction.exists()) {
    throw new Error('Points auction does not exist')
  }
  const created_at = currAuction.data()?.created_at || new Date().toISOString()
  await updateDoc(docRef, 'saved_on', created_at)
}

export const unsavePointsAuction = async (broadcaster: WithId<Broadcaster>, auctionId: string) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, auctionId)
  const currAuction = await getDoc(docRef)
  if (!currAuction.exists()) {
    throw new Error('Points auction does not exist')
  }
  await updateDoc(docRef, 'saved_on', null)
}

export const setCurrentAuctionId = async (broadcaster: WithId<Broadcaster>, auctionId: string | null) => {
  const broadcasterId = broadcaster._id
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  await updateDoc(doc(db, `${BROADCASTER}/${broadcasterId}`), {
    'pointsBankAccountConfig.currentPointsAuctionId': auctionId,
  })
}

export const finishPointsAuction = async (broadcaster: WithId<Broadcaster>, auctionId?: string) => {
  const broadcasterId = broadcaster._id
  const _auctionId = auctionId || broadcaster.pointsBankAccountConfig?.currentPointsAuctionId
  if (!_auctionId) {
    throw new Error('Points auction id not provided')
  }
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, _auctionId)
  const currAuctionRes = await getDoc<PointsAuction>(docRef as DocumentReference<PointsAuction>)
  const currAuction = currAuctionRes.data()
  if (!currAuction) {
    throw new Error('Points auction does not exist')
  }

  await setCurrentAuctionId(broadcaster, null)

  if (!currAuction.saved_on) {
    await deleteDoc(docRef)
  }
}

export const reopenPointsAuctionBidding = async (
  broadcaster: WithId<Broadcaster>,
  auctionId: string,
) => {
  const broadcasterId = broadcaster._id
  if (!auctionId) {
    throw new Error('Points auction id not provided')
  }
  if (!broadcasterId) {
    throw new Error(`Broadcaster id for channel ${broadcaster.name} not found`)
  }
  const docRef = getBroadcasterAuctionDocRef(broadcasterId, auctionId)
  const currAuctionRes = await getDoc<AutomaticPointsAuction>(docRef)
  const currAuction = currAuctionRes.data()
  if (!currAuction) {
    throw new Error('Points auction does not exist')
  }

  const {
    ended_at, title, bids, started_at, description, num_bids, winner,
  } = currAuction
  if (!ended_at) {
    throw new Error('Points auction has not ended')
  }

  const updated: Partial<AutomaticPointsAuction> = { ...currAuction }
  if (ended_at) {
    const previousResult: PointsAuctionPreviousResult = {
      title,
      bids,
      started_at,
      num_bids,
      description,
      winner: winner || null,
      ended_at,
    }
    updated.previousResults = [...(currAuction.previousResults || []), previousResult]
  }

  updated.bids = []
  updated.ended_at = null
  updated.num_bids = 0
  updated.winner = null
  updated.started_at = new Date().toISOString()
  updated.scheduled_end = new Date(Date.now() + currAuction.timeout_seconds * 1000 + 5000).toISOString()
  await updateDoc(docRef, updated)
}

// ------------ USED BY COMPONENTS ----------------

export const handlePointsAuctionSubmit = async (
  broadcaster: WithId<Broadcaster>,
  values: FieldMapValue,
) => {
  if (!values.title) throw new Error('Title is required')

  // might get rid of manual type
  const { type = 'automatic' } = values

  if (type !== 'manual' && type !== 'automatic') {
    throw new Error('Invalid auction type')
  }
  if (type === 'automatic' && !values.timeout_seconds) {
    throw new Error('Timeout seconds is required for automatic auctions')
  }

  if (!values.bid_start_price) throw new Error('Bid start price is required')
  if (!values.bid_min_increment) throw new Error('Bid min increment is required')

  const now = new Date().toISOString()
  const auction: PointsAuction = type === 'manual'
    ? {
      type: 'manual',
      bid_start_price: values.bid_start_price,
      bid_min_increment: values.bid_min_increment,
      created_at: now,
      winner: null,
      bids: [],
      description: values.description || '',
      title: values.title,
      ended_at: null,
      saved_on: null,
      num_bids: 0,
      previousResults: [],
      started_at: now,
      updated_at: now,
    }
    : {
      type: 'automatic',
      created_at: now,
      bids: [],
      winner: null,
      description: values.description || '',
      bid_min_increment: values.bid_min_increment,
      bid_start_price: values.bid_start_price,
      title: values.title,
      ended_at: null,
      num_bids: 0,
      saved_on: null,
      previousResults: [],
      started_at: now,
      updated_at: now,
      scheduled_end: new Date(Date.now() + values.timeout_seconds * 1000 + 5000).toISOString(),
      timeout_seconds: values.timeout_seconds,
    }
  return createPointsAuction(broadcaster, auction as AutomaticPointsAuction)
    .then(() => undefined)
    .catch((err) => {
      console.error(err)
      return { [FORM_ERROR]: err.message }
    })
}

export const confirmAuctionResult = httpsCallable<{broadcasterId: string, auctionId: string}>(functions, 'confirmAuctionResult')

import {
  Center, Flex, HStack, Text, useToast, VStack,
} from '@chakra-ui/react'
import { Egg, FieldMapValue, getPointsName } from '@elan-twitch/shared'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { FormElement } from 'components/forms/input'
import {
  CloseButton, DeleteButton, ResetButton, SaveButton,
} from 'components/shared/Buttons'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { CollapseHorizontal } from 'components/shared/CollapseHorizontal'
import { ShadowText } from 'components/shared/ShadowText'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import {
  useCallback, useContext, useMemo,
} from 'react'
import { Form } from 'react-final-form'
import { RarityBadge } from '../../../Widgets/RandomDrops/Eggs/RarityBadge'
import {
  color, eggName, emissiveIntensity, mass, price, priceVariance, rollThreshold,
} from './fields'
import { deleteEgg, updateEgg, validateEgg } from './utils'

export const EggEdit = ({
  id,
  initialValue,
  onBack,
}: {
  id: string
  initialValue?: Partial<Egg>
  onBack: () => void
}) => {
  const toast = useToast()
  const { broadcaster } = useContext(BroadcasterContext)
  const submit = useCallback(
    async (d: FieldMapValue) => {
      try {
        await updateEgg(broadcaster, id, d as Egg)
      } catch (err: any) {
        console.error(err)
        if (err?.message) {
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        return { [FORM_ERROR]: (err as any).message || 'An error occurred' }
      }
      return undefined
      // return { [FORM_ERROR]: 'todo' }
    },
    [broadcaster, id, toast],
  )

  const pointsName = useMemo(() => getPointsName(broadcaster), [broadcaster])
  const priceField = useMemo(
    () => ({
      ...price,
      placeholder: `Price${pointsName ? ` (${pointsName})` : ''}`,
    }),
    [pointsName],
  )

  return (
    <Form initialValues={initialValue} mutators={{ ...arrayMutators }} onSubmit={submit} validate={validateEgg}>
      {({
        values, handleSubmit, submitting, errors, submitErrors, dirty, form,
      }) => (
        <VStack w='100%' p={1} align='flex-start' spacing={1}>
          <HStack justify='space-between' w='100%' px={2}>
            <ShadowText style={{ fontSize: '1.6rem', flex: 1 }}>
              Egg {(initialValue?.rank || 0) + 1}
              {values?.name && ` - ${values.name}`}
            </ShadowText>

            <Flex justify='flex-end' flex={1}>
              <CollapseHorizontal width={40} active={!!initialValue && !dirty}>
                <Center py={1}>
                  <DeleteButton
                    size='sm'
                    onClick={() => deleteEgg(broadcaster._id, id).then(() => onBack())}
                    itemName={values?.name || 'Egg'}
                  />
                </Center>
              </CollapseHorizontal>
              <CollapseHorizontal width={76} active={dirty}>
                <Center py={1}>
                  <ResetButton ml={1} onClick={() => form.restart()} />
                </Center>
                <Center py={1}>
                  <SaveButton ml={1} isLoading={submitting} onClick={() => handleSubmit()} />
                </Center>
              </CollapseHorizontal>
              <Center py={1}>
                <CloseButton ml={1} shouldConfirm={dirty} label='BACK' onClick={onBack} />
              </Center>
            </Flex>
          </HStack>
          <VStack align='flex-start' spacing={0} columnGap={1} rowGap={1} w='100%'>
            <FormElement field={eggName} name='name' />
            <FormElement field={rollThreshold} name='rollThreshold' />
            <Flex pl={4}>
              <RarityBadge rollThreshold={values?.rollThreshold} />
            </Flex>
            <FormElement field={priceField} name='price' />
            <FormElement field={priceVariance} name='priceVariance' />
            <FormElement field={color} name='color' />
            <FormElement field={emissiveIntensity} name='emissiveIntensity' />
            <FormElement field={mass} name='mass' />
            <CollapseCenter in={!!(errors?.[FORM_ERROR] || submitErrors?.[FORM_ERROR])}>
              <Text color='red'>{errors?.[FORM_ERROR] || submitErrors?.[FORM_ERROR] || ''}</Text>
            </CollapseCenter>
          </VStack>
        </VStack>
      )}
    </Form>
  )
}

import { OutcomeWithRank } from '@elan-twitch/shared'
import { truncateNumber } from '@elan-twitch/shared/utils'
import { useMounted } from 'hooks/useMounted'
import arrow from 'icons/angle-right.svg'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Voter } from 'icons/Voter'
import { FC, useMemo } from 'react'
import { usePredictionConfig } from '../../Config/hooks'

export const WinnerPanel: FC<{
  outcome?: OutcomeWithRank
  isWinner: boolean
}> = ({ isWinner, outcome }) => {
  const { totalPoints } = outcome || {}
  // const { theme: { prediction: theme } } = useContext(BroadcasterContext)
  const { config } = usePredictionConfig()
  const { scale = 1.6 } = config || {}
  const truncatedPoints = useMemo(
    () => truncateNumber(totalPoints || 0),
    [totalPoints],
  )
  const mounted = useMounted(1000, isWinner)
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        top: `${scale * 4}px`,
        alignItems: 'center',
        opacity: mounted ? 1 : 0,
        transition: 'opacity 500ms',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          color: 'white',
          fontSize: `${scale * 0.7}rem`,
          alignItems: 'center',
          display: 'flex',
          filter: 'drop-shadow(0 0 4px black)',
        }}
      >
        <span style={{ height: `${scale * 15}px` }}>{truncatedPoints}</span>
        <ChannelPoint
          style={{ height: `${scale * 9}px`, margin: `0 ${scale * 0.15}rem` }}
          fill='white'
        />
        <img
          alt='arrow'
          src={arrow}
          style={{
            // position: 'absolute',
            height: `${scale * 9}px`,
            margin: `0 ${scale * 0.2}rem`,
            opacity: 0.7,
          }}
        />
        <Voter style={{ height: `${scale * 14}px` }} />
        <span
          style={{
            height: `${scale * 15}px`,
            position: 'relative',
            right: `${scale * 2}px`,
          }}
        >
          {truncateNumber(outcome?.users || 0)}
        </span>
      </div>
    </div>
  )
}

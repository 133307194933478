import { CheckIcon, MinusIcon, WarningTwoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  BroadcasterScopes,
  getBroadcasterEventSubscriptionsPath,
  getBroadcasterScopesPath,
  gradients,
  gradientToCssGradientProp,
  TwitchEvent,
  TwitchEventType,
  TwitchUserScope,
  widgets,
} from '@elan-twitch/shared'
import { getScopeStatus, WidgetScopeStatus } from '@elan-twitch/shared/utils/config'
import {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { deleteSubscription, getTwitchBroadcasterAuthUrl } from '../../../backend/functions'
import { useDocument } from '../../../hooks/db'
import { useBorderColor } from '../../../hooks/useBorderColor'
import { TwitchLogo } from '../../Auth/TwitchLogin'
import { LoadingButton } from '../../shared/Buttons'
import { ContentBox } from '../../shared/ContentBox'
import { Expandable } from '../../shared/Expandable'
import { BroadcasterContext } from '../Broadcasters/context'
import { useConfigSnippet } from './hooks'

const ScopeStatusView = ({ status }: { status: WidgetScopeStatus }) => {
  const { widget, scopes, missing } = status || {}
  const widgetInfo = useMemo(() => widgets[widget], [widget])
  const { name } = widgetInfo || {}

  return (
    <Flex w='100%' flexFlow='column'>
      <Flex w='100%' justify='space-between'>
        <Text fontSize='lg'>{name}</Text>
        <Text fontSize='sm' fontWeight={600} color={status.status === 'complete' ? 'green.400' : 'red.400'}>
          {status.status.toUpperCase()}
        </Text>
      </Flex>
      {scopes.map((s) => (
        <Flex key={s} px={2} w='100%' justify='space-between'>
          <Checkbox pointerEvents='none' isChecked={!missing.includes(s)}>
            {s}
          </Checkbox>
        </Flex>
      ))}
    </Flex>
  )
}

// generates a twitch
const ReconnectLink = ({
  requiredScopes,
  missingScopes,
}: {
  requiredScopes: Array<TwitchUserScope>
  missingScopes: Array<TwitchUserScope>
}) => {
  const { pathname } = useLocation()

  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const handleReconnect = useCallback(async () => {
    setIsLoading(true)
    try {
      const {
        data: { url },
      } = await getTwitchBroadcasterAuthUrl({
        pathname: encodeURIComponent(pathname),
        sourceDomain: `${window.location.protocol}//${window.location.host}`,
        scopes: requiredScopes,
      })

      window.location.href = url
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'Error signing in',
        description: err.message,
      })
    }
    setIsLoading(false)
  }, [toast, requiredScopes, pathname])

  // const bg = missingScopes.length ? 'blue' : 'gray'
  const buttonBg = useMemo(
    () => (missingScopes.length ? gradientToCssGradientProp(gradients.blue) : gradientToCssGradientProp(gradients.gray)),
    [missingScopes],
  )
  const body = (
    <Button
      w='100%'
      isLoading={isLoading}
      onClick={handleReconnect}
      bg={buttonBg}
      _dark={{ bg: buttonBg }}
      // // // bg={`${bg}.400`}
      // // _hover={{ bg: `${bg}.300` }}
      // _dark={{ bg: `${bg}.400`, _hover: { bg: `${bg}.300` } }}
      size='sm'
      borderRadius='full'
    >
      <TwitchLogo filter={'grayscale(100%) brightness(290%) drop-shadow(1px 1px 3px #00000088)'} />
      <Text>{missingScopes.length ? 'Reconnect' : 'Connected'}</Text>
    </Button>
  )
  return (
    <Box ml='auto'>
      {missingScopes.length ? (
        body
      ) : (
        <Tooltip hasArrow placement='top' label='Click to fix issues with auth or events'>
          {body}
        </Tooltip>
      )}
    </Box>
  )
}

type EventSubscriptions = Record<TwitchEventType, TwitchEventType & { id: string }>

const EventSubscriptionView = ({ type, id, broadcasterId }: { type: string; id: string; broadcasterId: string }) => (
  <Flex py={1} w='100%'>
    <Text fontSize='xs' title={id} fontWeight={600} opacity={0.7} isTruncated flex={1} minW='0'>
      {type.toUpperCase()}
    </Text>
    <Tooltip hasArrow label='Delete subscription (if broken)'>
      <LoadingButton
        color='white'
        _hover={{ bg: 'red.300' }}
        _dark={{ bg: 'red.400', _hover: { bg: 'red.300' } }}
        size='xs'
        minW={0}
        w={5}
        h={5}
        borderRadius='full'
        onClick={async () => deleteSubscription({ type, broadcasterId, subscriptionId: id })}
      >
        <MinusIcon />
      </LoadingButton>
    </Tooltip>
  </Flex>
)

const BroadcasterEventSubscriptions = () => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const path = useMemo(() => getBroadcasterEventSubscriptionsPath(broadcasterId), [broadcasterId])
  const { data } = useDocument<EventSubscriptions>(path)
  const { _id, ...subscriptions } = data || {}
  const sorted = useMemo<Array<TwitchEvent & { id: string }>>(
    () => Object.entries(subscriptions || {})
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([key, value]) => ({
        ...(value as any),
        type: key,
      })),
    [subscriptions],
  )
  const borderColor = useBorderColor()
  return (
    <Expandable
      mt={2}
      border={`1px solid ${borderColor}`}
      borderRadius={6}
      header={() => (
        <Flex px={2} py={1} w='100%'>
          <Text fontSize='md'>Event Subscriptions</Text>
        </Flex>
      )}
    >
      <Flex p={2} w='100%' flexFlow='column'>
        {sorted.length ? (
          sorted.map(({ id, type }) => (
            <EventSubscriptionView broadcasterId={broadcasterId} key={id} id={id} type={type} />
          ))
        ) : (
          <Text opacity={0.7} fontStyle='italic' fontSize='sm' textAlign='center'>
            No event subscriptions
          </Text>
        )}
      </Flex>
    </Expandable>
  )
}

// displays whether all permissions required are granted and if not, provides a button to reconnect to the server
export const BroadcasterAuthStatus = () => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const path = useMemo(() => getBroadcasterScopesPath(broadcasterId), [broadcasterId])
  const { config } = useConfigSnippet()
  const { data } = useDocument<BroadcasterScopes>(path)
  const {
    summary, excessScopes, status, missingScopes, requiredScopes,
  } = useMemo(
    () => getScopeStatus(config, data),
    [config, data],
  )
  const borderColor = useBorderColor()
  return (
    <Center w='100%' h='100%'>
      <ContentBox maxH='100%' overflowY='auto' flexFlow='column' w='100%' maxW='500px'>
        <Flex borderBottom={`1px solid ${borderColor}`} px={2} gap={2} align='center' pb={3} mb={2} w='100%'>
          {status === 'incomplete' ? (
            <WarningTwoIcon w={5} h={5} color='red.400' />
          ) : (
            <CheckIcon w={5} h={5} color='green.400' />
          )}
          <Text lineHeight={1} fontFamily='Encode Sans' fontWeight={600} fontSize='lg'>
            {status === 'complete' ? 'Permissions Granted' : 'Permissions Incomplete'}
          </Text>
          <ReconnectLink requiredScopes={requiredScopes} missingScopes={missingScopes} />
        </Flex>
        <VStack divider={<Divider />}>
          {summary.map((widgetStatus) => (
            <ScopeStatusView key={widgetStatus.widget} status={widgetStatus} />
          ))}
        </VStack>
        {excessScopes.length ? (
          <Expandable
            borderRadius={6}
            mt={2}
            headerProps={{ bg: 'blackAlpha.300' }}
            borderStyle='solid'
            borderWidth='1px'
            header={() => (
              <Flex px={2} py={1} w='100%'>
                <Text fontSize='sm'>Excess Permissions</Text>
              </Flex>
            )}
          >
            <Flex py={2} px={3} direction='column' w='100%'>
              <Text fontSize='sm'>The following permissions are not required for any active widgets:</Text>
              <UnorderedList pl={1}>
                {excessScopes.map((s) => (
                  <ListItem fontSize='sm' key={s}>
                    {s}
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </Expandable>
        ) : null}
        <BroadcasterEventSubscriptions />
      </ContentBox>
    </Center>
  )
}

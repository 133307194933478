import { useEffect, useState } from 'react'

export const useMounted = (duration: number = 250, active: boolean = true) => {
  const [mounted, set] = useState(false)
  useEffect(() => {
    if (!active) {
      set(false)
      return () => {}
    }
    const t = setTimeout(() => {
      set(true)
    }, duration)
    return () => {
      clearTimeout(t)
    }
  }, [active, duration])

  return mounted
}

import { WidgetAddOn } from '../../types'
import { DroppedItemConfigKey, DroppedItemType } from './types'

export const droppedItemConfigKeys: Record<
  DroppedItemType,
  DroppedItemConfigKey
> = {
  egg: 'eggsConfig',
}

export const droppedItemConfigKeyToType: Record<
  DroppedItemConfigKey,
  DroppedItemType
> = Object.entries(droppedItemConfigKeys).reduce(
  (acc, [type, key]) => ({
    ...acc,
    [key as DroppedItemConfigKey]: type as DroppedItemType,
  }),
  {} as Record<DroppedItemConfigKey, DroppedItemType>,
)

export const randomDropsAddOn: WidgetAddOn = {
  id: 'random-drops',
  name: 'Random Drops',
  requiredScopes: [],
}

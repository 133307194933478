import {
  Broadcaster,
  droppedItemConfigKeys,
  DroppedItemType,
  Egg,
  EggsConfig,
  FieldMapValue,
  WithId,
} from '@elan-twitch/shared'
import { getBroadcasterRef } from 'backend/db'
import { validateFieldMap } from 'components/forms/input/utils'
import { deleteField, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../../backend/functions'
import { eggField } from './fields'

export const validateEgg = (value: FieldMapValue) => validateFieldMap(eggField, value)

export const saveEggsConfig = async (
  broadcasterId: string,
  eggsConfig: EggsConfig,
) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    'pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig',
    eggsConfig,
  )
}

export const updateEggsDropPeriod = async (
  broadcasterId: string,
  dropPeriod: number | undefined,
) => {
  if (!dropPeriod) throw new Error('Drop period must be a positive number')
  // if (dropPeriod < 60) throw new Error('Drop period must be at least 60 seconds')
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    'pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig.dropPeriod',
    dropPeriod,
  )
}

export const updateEggsExpirationTime = async (
  broadcasterId: string,
  expirationTime: number | undefined,
) => {
  if (!expirationTime) throw new Error('Expiration time must be a positive number')
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    'pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig.expirationTime',
    expirationTime,
  )
}

export const getEgg = (broadcaster: Broadcaster, eggId: string): Egg | null => {
  const egg = broadcaster?.pointsBankAccountConfig?.addOns?.randomDrops?.droppedItems
    ?.eggsConfig?.eggs?.[eggId]
  return egg || null
}

export const updateEgg = async (
  broadcaster: WithId<Broadcaster>,
  eggId: string,
  egg: Egg,
) => {
  let rank = await getEgg(broadcaster, eggId)?.rank
  if (typeof rank !== 'number') rank = Object.keys(broadcaster?.pointsBankAccountConfig?.addOns?.randomDrops?.droppedItems.eggsConfig?.eggs || {}).length

  await updateDoc(
    getBroadcasterRef(broadcaster._id),
    `pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig.eggs.${eggId}`,
    { ...egg, rank },
  )
}

export const deleteEgg = async (broadcasterId: string, eggId: string) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    `pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig.eggs.${eggId}`,
    deleteField(),
  )
}

export const enabledDroppedItem = async (
  broadcasterId: string,
  type: DroppedItemType,
) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    `pointsBankAccountConfig.addOns.randomDrops.droppedItems.${droppedItemConfigKeys[type]}.enabled`,
    true,
  )
}

export const disableDroppedItem = async (
  broadcasterId: string,
  type: DroppedItemType,
) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    `pointsBankAccountConfig.addOns.randomDrops.droppedItems.${droppedItemConfigKeys[type]}.enabled`,
    false,
  )
}

export const enableEggs = async (broadcasterId: string) => enabledDroppedItem(broadcasterId, 'egg')
export const disableEggs = async (broadcasterId: string) => disableDroppedItem(broadcasterId, 'egg')

export const enableEgg = async (broadcasterId: string, eggId: string) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    `pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig.eggs.${eggId}.enabled`,
    true,
  )
}

export const disableEgg = async (broadcasterId: string, eggId: string) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    `pointsBankAccountConfig.addOns.randomDrops.droppedItems.eggsConfig.eggs.${eggId}.enabled`,
    false,
  )
}

export const enableRandomDrops = async (broadcasterId: string) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    'pointsBankAccountConfig.addOns.randomDrops.enabled',
    true,
  )
}

export const disableRandomDrops = async (broadcasterId: string) => {
  await updateDoc(
    getBroadcasterRef(broadcasterId),
    'pointsBankAccountConfig.addOns.randomDrops.enabled',
    false,
  )
}

export const startDropsFunction = httpsCallable(
  functions,
  'manuallyStartBroadcasterRandomDrops',
)
export const stopDropsFunction = httpsCallable(
  functions,
  'manuallyStopBroadcasterRandomDrops',
)

import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import {
  Flex, IconButton, Portal, useColorMode,
} from '@chakra-ui/react'

export const DarkModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <IconButton
      aria-label='Toggle dark mode'
      borderRadius='full'
      icon={colorMode === 'light' ? <SunIcon w={5} h={5} /> : <MoonIcon />}
      onClick={toggleColorMode}
    />
  )
}

export const MobileDarkModeToggle = () => (
  <Portal>
    <Flex position='fixed' borderRadius='full' right={4} bottom={4}>
      <DarkModeToggle />
    </Flex>
  </Portal>
)

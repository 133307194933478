import {
  AutomaticPointsAuction,
  Broadcaster, getBroadcasterAuctionDocPath, PointsAuction, WithId,
} from '@elan-twitch/shared'
import { getBroadcasterAuctionsCollection } from 'backend/db'
import {
  orderBy,
  Query,
  query,
} from 'firebase/firestore'
import { useDocument, useQuery } from 'hooks/db'
import { useMemo } from 'react'

export const usePointsAuction = (broadcaster: WithId<Broadcaster>) => {
  const path = useMemo(
    () => (broadcaster.pointsBankAccountConfig?.currentPointsAuctionId
      ? getBroadcasterAuctionDocPath(
        broadcaster._id,
        broadcaster.pointsBankAccountConfig?.currentPointsAuctionId,
      )
      : null),
    [broadcaster],
  )
  return useDocument<AutomaticPointsAuction>(path)
}

const getSavedPointsAuctionsQuery = (broadcaster: WithId<Broadcaster>) => query(
  getBroadcasterAuctionsCollection(broadcaster._id),
  orderBy('created_at', 'desc'),
) as Query<PointsAuction>

export const useSavedPointsAuctions = (broadcaster: WithId<Broadcaster>) => {
  const q = useMemo(() => getSavedPointsAuctionsQuery(broadcaster), [broadcaster])

  return useQuery<PointsAuction>(q)
}

import {
  Checkbox, Flex, Switch, Text,
} from '@chakra-ui/react'
import { BooleanField } from '@elan-twitch/shared'
import { FC } from 'react'
import { InputProps } from '../types'

const CheckboxBooleanComponent: FC<InputProps<BooleanField>> = ({ field, input: { onChange, value, ...input } }) => {
  const { placeholder } = field
  return (
    <Checkbox isChecked={value} onChange={(e) => onChange(e.target.checked)} {...input}>
      {placeholder}
    </Checkbox>
  )
}

const SwitchBooleanComponent: FC<InputProps<BooleanField>> = ({ field, input: { onChange, value, ...input } }) => {
  const { placeholder } = field
  return (
    <Flex gap={1} align='center'>
      <Switch isChecked={value} onChange={(e) => onChange(e.target.checked)} {...input}/>
      <Text fontWeight={600} opacity={value ? 1 : 0.6}>{placeholder}</Text>
    </Flex>
  )
}

export const BooleanComponent: FC<InputProps<BooleanField>> = (props) => {
  if (props.field.type === 'switch') return <SwitchBooleanComponent {...props} />
  return <CheckboxBooleanComponent {...props} />
}

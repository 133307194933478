import { Outcome } from '@elan-twitch/shared'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { getTestPrediction } from '../testData'

const getRandomPoints = (seed: number) => 5000 + Math.round(seed * 300000)
const getRandomUsers = (seed: number) => 300 + Math.round(seed * 600)
const getAddedStats = () => {
  const seed = Math.random()
  return { points: getRandomPoints(seed), voters: getRandomUsers(seed) }
}
export const useTestPrediction = (active: boolean) => {
  const [showWinner, setShowWinner] = useState(false)
  const [numOutcomes, setNumOutcomes] = useState(2)
  const prediction = useMemo(
    () => getTestPrediction(numOutcomes || 2),
    [numOutcomes],
  )

  const [o, set] = useState(prediction.outcomes) as [Array<Outcome>, any]
  const [numRounds, setNumRounds] = useState(0)
  const createMoreData = useCallback(() => {
    set(
      o.map((outcome) => {
        const { points, voters } = getAddedStats()
        return {
          ...outcome,
          channel_points: outcome.channel_points + points,
          users: outcome.users + voters,
        }
      }),
    )
  }, [o])

  useEffect(() => {
    if (!active || numRounds > 6) return () => {}
    const t = setTimeout(
      () => {
        if (numRounds < 6) {
          createMoreData()
        }
        setNumRounds(numRounds + 1)
      },
      numRounds > 5 ? 5000 : 2000,
    )
    return () => {
      clearTimeout(t)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numRounds, active])
  // useSpring({
  //   loop: true,
  //   to: { val: 1 },
  //   from: { val: 0 },
  //   config: { duration: 2000 },
  //   onRest: () => createMoreData(),
  // })

  const [restarting, setRestarting] = useState(false)
  const data = useMemo(
    () => (!restarting
      ? {
        ...prediction,
        outcomes: o,
        ended_at: showWinner ? '512515' : null,
        locked_at: numRounds > 5 ? '515122' : null,
        winning_outcome_id: showWinner
          ? o[Math.floor(Math.random() * o.length)].id
          : null,
      }
      : null),
    [prediction, showWinner, numRounds, o, restarting],
  )
  useEffect(() => {
    if (restarting) {
      const t = setTimeout(() => {
        setRestarting(false)
      }, 500)
      return () => { clearTimeout(t) }
    }
    return () => {}
  }, [restarting])
  const restart = useCallback(() => {
    set(prediction.outcomes)
    setNumRounds(0)
    setRestarting(true)
    setShowWinner(false)
  }, [prediction])
  useEffect(() => {
    restart()
  }, [restart])
  return {
    data,
    restart,
    numOutcomes,
    setNumOutcomes,
    isLoading: false,
    error: null,
    toggleShowWinner: () => setShowWinner(!showWinner),
    showWinner,
  }
}

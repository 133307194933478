export const customDomains: Record<string, string> = {
  'www.nympts.com': 'nymn',
  'nympts.com': 'nymn',
  'www.nym.pts': 'nymn',
  'nym.pts': 'nymn',
}

export const defaultProdDomain = 'https://elan-twitch-extensions.web.app/'
export const broadcasterCustomDomains: Record<string, string> = {
  nymn: 'https://nympts.com',
}

import { CloseIcon } from '@chakra-ui/icons'
import {
  Flex, IconButton, Modal, ModalContent, ModalOverlay, Text,
} from '@chakra-ui/react'
import { MovieListMovie, getBroadcasterMovieListCollectionPath } from '@elan-twitch/shared'
import { httpsCallable } from 'firebase/functions'
import {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { functions } from '../../../../backend/functions'
import { useDocument } from '../../../../hooks/db'
import { BroadcasterContext } from '../../../App/Broadcasters/context'
import { ContentBox } from '../../../shared/ContentBox'
import { FetchedItemView } from '../../../shared/FetchedItemView'
import { Loading } from '../../../shared/Loading'
import { MovieActivity } from './MovieActivity'
import { MovieSummary } from './MovieSummary'
import { MovieViewContext, MovieViewContextValue } from './context'

const getTmdbMovieDetails = httpsCallable<{ id: number }, MovieListMovie>(functions, 'getTmdbMovieDetails')
export const MovieView = ({ movie }: { movie: MovieListMovie }) => (
  <Flex w='100%' gap={2} flexFlow='column'>
    <MovieSummary movie={movie} />
    <MovieActivity movie={movie} />
  </Flex>
)

const TmdbMovieView = ({ id }: { id: number }) => {
  const [movie, setMovie] = useState<MovieListMovie | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    getTmdbMovieDetails({ id })
      .then((result) => {
        setMovie(result.data)
        setLoading(false)
      })
      .catch((e) => {
        setError(e.message)
        setLoading(false)
      })
  }, [id])

  return (
    <FetchedItemView
      data={movie}
      error={error}
      isLoading={loading}
      itemType='movie'
      Render={(item) => <MovieView movie={item} />}
    />
  )
}

export const MovieIdView = ({ id, onClose }: { id: string; onClose?: () => void }) => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)

  const [shouldRefetch, setShouldRefetch] = useState(false)
  useEffect(() => {
    if (shouldRefetch) setTimeout(() => setShouldRefetch(false), 300)
  }, [shouldRefetch])
  const fetchedId = useMemo(
    () => (shouldRefetch ? null : `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${id}`),
    [id, broadcasterId, shouldRefetch],
  )
  const { data, isLoading, error } = useDocument<MovieListMovie>(fetchedId)
  const idAsInt = useMemo(() => (id ? parseInt(id, 10) : null), [id])

  const contextData = useMemo<MovieViewContextValue>(
    () => ({ movieId: id, refetch: () => setShouldRefetch(true), onClose }),
    [id, onClose],
  )

  let body = <Loading text='Loading movie data...' />
  if (data) body = <MovieView movie={data} />
  else if (!isLoading && !shouldRefetch) {
    body = typeof idAsInt === 'number' && !Number.isNaN(idAsInt) ? (
        <TmdbMovieView id={idAsInt} />
    ) : (
        <ContentBox>
          <Flex>
            <Text flex={1} minW='0' fontWeight={600} color='red.600'>
              Error loading movie{error ? `: ${error.message}` : ''}
            </Text>
            {onClose ? <IconButton aria-label='Retry' icon={<CloseIcon />} onClick={() => onClose()} /> : null}
          </Flex>
        </ContentBox>
    )
  }
  return <MovieViewContext.Provider value={contextData}>{body}</MovieViewContext.Provider>
}

export const MovieViewModal = ({ id, onClose }: { id: string; onClose: () => void }) => (
  <Modal closeOnOverlayClick={false} isCentered size='2xl' isOpen onClose={onClose}>
    <ModalOverlay />
    <ModalContent bg='none' border='none'>
      <MovieIdView onClose={onClose} id={id} />
    </ModalContent>
  </Modal>
)

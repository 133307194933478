import {
  Button, Center, Flex, Portal, Text,
} from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { useCookies } from 'react-cookie'
import { ContentBox } from './ContentBox'

const COOKIE_KEY = 'acceptCookies'

const AcceptCookiesMessage = ({ onAccept }: { onAccept: () => void }) => (
  <Portal>
    <Center position='fixed' onClick={(e) => e.stopPropagation()} top={0} left={0} h='100%' w='100%' bg='blackAlpha.700' borderRadius='md'>
      <ContentBox>
        <Flex gap={2} flexFlow='column' alignItems='center' justifyContent='space-between'>
          <Text>This site uses cookies for anonymous authentication. Please accept cookies to continue.</Text>
          <Button onClick={onAccept}>Accept Cookies</Button>
        </Flex>
      </ContentBox>
    </Center>
  </Portal>
)

export const AcceptCookies = ({ children }: PropsWithChildren) => {
  const [{ [COOKIE_KEY]: cookieValue }, setCookie] = useCookies([COOKIE_KEY])
  return (
    <>
      {children}
      {cookieValue !== true && <AcceptCookiesMessage onAccept={() => setCookie(COOKIE_KEY, true)} />}
    </>
  )
}

import {
  AutomaticPointsAuction,
  BROADCASTER,
  Broadcaster,
  FieldMap, getBroadcasterAuctionDocPath,
  getBroadcasterAuctionsCollectionPath,
  getBroadcasterMovieListCollectionPath, getBroadcasterPointsBankCollectionPath, MovieListMovie,
  PERMISSIONS, PermissionsData,
  PointsBankAccount, POINTS_BANK,
  POLL, Poll, PREDICTION, Prediction, processData, RANDOM_DROPS, WheelAuction, WHEEL_AUCTION, WithId,
} from '@elan-twitch/shared'
import {
  collection, CollectionReference, doc, DocumentReference, initializeFirestore,
  setDoc,
} from 'firebase/firestore'
import { app } from './app'

export const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
})

export const getBroadcasterRef = (broadcasterId: string) => doc(
  db,
  `${BROADCASTER}/${broadcasterId}`,
) as DocumentReference<Broadcaster>

export const getBroadcasterRandomDropsCollection = (broadcasterId: string) => collection(
  db,
  `${BROADCASTER}/${broadcasterId}/${RANDOM_DROPS}`,
) as CollectionReference<WithId<any>>

export const getBroadcasterPointsBankCollection = (broadcasterId: string) => collection(
  db,
  getBroadcasterPointsBankCollectionPath(broadcasterId),
) as CollectionReference<PointsBankAccount>

export const getBroadcasterMovieListCollection = (broadcasterId: string) => collection(
  db,
  getBroadcasterMovieListCollectionPath(broadcasterId),
) as CollectionReference<MovieListMovie>

export const getBroadcasterPointsBankDocRef = (
  broadcasterId: string,
  userName: string,
) => doc(
  db,
  `${BROADCASTER}/${broadcasterId}/${POINTS_BANK}/${userName.toLowerCase()}`,
) as DocumentReference<PointsBankAccount>

export const getBroadcasterAuctionsCollection = (broadcasterId: string) => collection(
  db,
  getBroadcasterAuctionsCollectionPath(broadcasterId),
) as CollectionReference<AutomaticPointsAuction>

export const getBroadcasterAuctionDocRef = (
  broadcasterId: string,
  auctionId: string,
) => doc(
  db,
  `${getBroadcasterAuctionDocPath(broadcasterId, auctionId)}`,
) as DocumentReference<AutomaticPointsAuction>

export const getWheelAuctionDocPath = (
  broadcasterId: string,
  timeStamp: string,
) => `${BROADCASTER}/${broadcasterId}/${WHEEL_AUCTION}/${timeStamp}`

export const getWheelAuctionDocRef = (
  broadcasterId: string,
  timeStamp: string,
) => doc(
  db,
  getWheelAuctionDocPath(broadcasterId, timeStamp),
) as DocumentReference<WheelAuction>

export const getCurrentWheelAuctionDocPath = (
  broadcaster: WithId<Broadcaster>,
) => (broadcaster.pointsBankAccountConfig?.currentWheelAuctionId
  ? `${BROADCASTER}/${broadcaster._id}/${WHEEL_AUCTION}/${broadcaster.pointsBankAccountConfig?.currentWheelAuctionId}`
  : null)
export const getCurrentWheelAuctionDocRef = (
  broadcaster: WithId<Broadcaster>,
) => {
  const path = getCurrentWheelAuctionDocPath(broadcaster)
  return path ? doc(db, path) as DocumentReference<WheelAuction> : null
}

export const getPollDocRef = (broadcasterTwitchId: string) => doc(db, `${POLL}/${broadcasterTwitchId}`) as DocumentReference<Poll>

export const getPredictionDocRef = (broadcasterTwitchId: string) => doc(
  db,
  `${PREDICTION}/${broadcasterTwitchId}`,
) as DocumentReference<Prediction>

export const getPermissionsDoc = (id: string) => doc(db, PERMISSIONS, id || 'test') as DocumentReference<PermissionsData>
export const processAndUpdate = (
  field: FieldMap,
  ref: DocumentReference,
  path: string,
  data: any,
) => processData(field, async () => null, data).then((processed) => {
  const saved: Record<string, any> = path ? { [path]: processed } : processed

  return setDoc(ref, saved, { merge: true })
})

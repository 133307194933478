import { ChoiceWithRank, getColor, truncateNumber } from '@elan-twitch/shared'
import { animated, easings, useSpring } from '@react-spring/web'
import {
  FC,
  useMemo,
} from 'react'
import { FadeInText } from '../../../shared/FadeIn'

import { Voter } from '../../../../icons/Voter'
import { usePollConfig } from '../../Config/hooks'
// import { WinnerPanel } from './WinnerPanel'

export const CHOICE_HEIGHT = 36

const BANNER_PATH = 'M 734.282 83.417 L 23.329 83.417 C 19.831 83.096 17.293 80.172 18.829 77.217 L 48.447 22.919 C 52.147 17.523 56.852 17.018 65.347 16.619 L 773.939 16.619 C 778.947 16.718 780.173 19.627 778.439 22.819 L 751.182 77.117 C 745.865 83.984 741.134 83.135 734.282 83.417 Z'

const INNER_PATH = 'M 725.995 72.717 L 42.984 72.717 C 38.63 72.717 35.299 71.549 37.477 67.524 L 54.698 36.944 C 59.366 28.225 61.233 25.715 69.211 25.715 L 754.871 27.05 C 759.879 27.149 761.105 30.058 759.371 33.25 L 742.895 66.417 C 737.578 73.284 732.847 72.435 725.995 72.717 Z'

export const ChoiceView: FC<{
  choices: ChoiceWithRank[]
  show: boolean
  index: number
  winner?: string | null
}> = ({
  winner, index, choices, show,
}) => {
  const choice = useMemo(() => choices[index], [choices, index])
  const { percent = 0 } = choice || {}
  const { config } = usePollConfig()
  const { gradient, darkMode, scale = 1.6 } = config || {}
  // const { scale = 1.6 } = theme || {}
  const { color1, color2 } = useSpring({
    color1: getColor(
      gradient || 'blue',
      choice ? index / (choices.length + 1) : 0.5,
    ),
    color2: getColor(
      gradient || 'blue',
      choice ? (index + 1) / (choices.length + 1) : 0.5,
    ),
  })

  const isWinner = useMemo(
    () => winner && winner === choice?.id,
    [choice, winner],
  )

  const { right } = useSpring({
    right: 750 * percent,
    config: { duration: 1000, easing: easings.easeOutCubic },
  })

  const { bgOpacity } = useSpring({
    // borderColor: show
    //   ? '#ffffff'
    //   : getColor(
    //     gradient || 'blue',
    //     choice ? index / (choices.length + 1) : 0.5,
    //   ),
    bgOpacity: show || isWinner ? 1 : 0,
    delay: show && !isWinner ? index * 500 + 200 : 0,
  })

  const { fade } = useSpring({
    fade: show && isWinner ? 1 : 0,
    delay: !isWinner ? index * 400 + 250 : 0,
    config: { duration: 2000, easing: easings.easeOutCubic },
  })
  const { title = '' } = choice || {}
  return (
    <div
      style={{
        height: `${show ? CHOICE_HEIGHT * scale : 0}px`,
        display: 'flex',
        // position: 'absolute',
        // transform: `translate(0, ${
        //   (isWinner ? 1 : index + 1) * CHOICE_HEIGHT * scale
        // }px)`,
        flexFlow: 'column',
        overflow: 'visible',
        alignItems: 'flex-end',
        fontFamily: 'Bebas Neue',
        transition: `height ${show ? 500 : 1000}ms ease ${
          show ? (index + 1) * 700 : 0
        }ms`,
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          opacity: show ? 1 : 0,
          transition: `opacity 500ms ease ${show ? (index + 1.5) * 700 : 0}ms`,
        }}
      >
        <svg
          viewBox='0 0 800 100'
          style={{
            height: `${32 * scale}px`,
            position: 'relative',
            overflow: 'visible',
          }}
        >
          <defs>
            <filter id='owpollblur' x='-1' y='-1' width='250%' height='250%'>
              <feOffset result='offOut' in='SourceAlpha' dx='5' dy='5' />
              <feGaussianBlur result='blurOut' in='offOut' stdDeviation='10' />
              <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
            </filter>
            <filter id='owpollglow'>
              <feGaussianBlur stdDeviation='3' result='coloredBlur' />
              <feMerge>
                <feMergeNode in='coloredBlur' />
                <feMergeNode in='SourceGraphic' />
              </feMerge>
            </filter>
            <clipPath id={`poll-cutoff-${index}`}>
              <animated.path d={INNER_PATH} />
            </clipPath>
            <linearGradient id={`poll-bar-${index}`} x1='0' y1='0' x2='0' y2='1'>
              <animated.stop offset='0%' stopColor={color1} />
              <animated.stop offset='100%' stopColor={color2} />
            </linearGradient>
          </defs>

          <animated.path
            // filter='url(#owpollblur)'
            opacity={bgOpacity}
            fill={darkMode ? `url(#poll-bar-${index})` : 'rgba(255,255,255,0.9)'}
            d={BANNER_PATH}
          />
          <path
            d='M 0 50 L 1000 50'
            strokeWidth='65'
            // filter='url(#f3)'
            stroke={darkMode ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.25)'}
            style={{ opacity: isWinner && darkMode ? 0 : 1, transition: 'opacity 500ms' }}
            clipPath={`url(#poll-cutoff-${index})`}
          />
          <animated.path
            d={right.to((r) => `M 0 0 L ${r + 50} 0 L ${r} 100 L 0 100 Z`)}
            // filter='url(#f3)'
            fill={darkMode ? 'rgba(0,0,0,0.4)' : `url(#poll-bar-${index})`}
            opacity={bgOpacity}
            // fill={`url(#bar-${index})`}
            clipPath={`url(#poll-cutoff-${index})`}
          />
          <animated.path
            d={BANNER_PATH}
            // filter='url(#f3)'
            // stroke={borderColor}
            stroke='#ffffff'
            strokeWidth='4'
            filter='url(#owpollglow)'
            fill='none'
            strokeLinecap='round'
            // opacity={fade}
            // strokeDasharray={fade.to((f) => `${50 * f} ${50 - 50 * f}`)}
            // strokeDashoffset={fade.to((f) => 100 * f)}
            strokeDasharray='1630 1630'
            strokeDashoffset={fade.to((f) => 1630 - f * 1630)}
            // fill={`url(#bar-${index})`}
          />
        </svg>
        {/* <WinnerPanel outcome={choice} isWinner={isWinner} /> */}
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            left: 0,
            padding: `0 ${1 * scale}rem`,
            opacity: show ? 1 : 0,
            transition: `all 500ms ease ${
              show ? (index + 1) * 700 + 500 : 0
            }ms`,
            height: '100%',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FadeInText
            style={{
              fontSize: `${scale * 0.7}rem`,
              top: `${scale}px`,
              flex: 1,
              position: 'relative',
              opacity: 1,
              textAlign: 'left',
              filter: 'none',
              paddingLeft: `${scale * 0.3}rem`,
            }}
          >
            {show ? title : ''}
          </FadeInText>

          <span
            style={{
              color: 'white',
              position: 'absolute',
              transition: `left 333ms ease ${
                show ? (index + 1) * 700 + 1200 : 0
              }ms`,
              left: '50%',
              transform: 'translate(-50%, 0)',
              textShadow: '1px 1px 3px black',
              height: `${scale * 14}px`,
              fontSize: `${scale * 11}px`,
              whiteSpace: 'nowrap',
            }}
          >
            {((choice?.percent || 0) * 100).toFixed()}
            %
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: `${scale * 10}px`,
            }}
          >
            <span
              style={{
                width: `${scale * 19}px`,
                color: 'white',
                textShadow: '1px 1px 3px black',
                height: `${scale * 15}px`,
                fontSize: `${scale * 11}px`,
                whiteSpace: 'nowrap',
                textAlign: 'right',
              }}
            >
              {truncateNumber(choice?.votes || 0)}
            </span>
            <Voter
              style={{
                width: `${scale * 15}px`,
                filter: 'drop-shadow(1px 1px 2px black)',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

import { FC, HTMLProps } from 'react'
import { usePrevious } from '../../hooks/usePrevious'
import { ShadowText } from './ShadowText'

const xy = {
  left: '-5px, 0px',
  right: '5px, 0px',
  top: '0px, -5px',
  bottom: '0px, 5px',
}
export const FadeInText: FC<
  HTMLProps<HTMLSpanElement> & { from?: 'left' | 'right' | 'top' | 'bottom' }
> = ({
  style, children, from = 'right', ...props
}) => {
  const prevChildren = usePrevious(children)
  return (
    <ShadowText
      {...props}
      style={{
        opacity: children ? 1 : 0,
        transform: children
          ? 'translate(0px, 0px) scale(1)'
          : `${xy[from]} scale(0.8)`,
        transformOrigin: 'right',
        transition: 'all 500ms',
        userSelect: 'none',
        ...style,
      }}
    >
      {children || prevChildren}
    </ShadowText>
  )
}

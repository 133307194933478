import { useContext, useMemo } from 'react'
import { BroadcasterContext } from '../components/App/Broadcasters/context'
import { useUser } from '../store/useUser'

export const useIsChannelAdmin = () => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)
  const { channelAdminOf, isAdmin } = useUser()

  return useMemo(() => {
    if (isAdmin) return true
    if (channelAdminOf) return channelAdminOf.includes(broadcasterId)
    return false
  }, [channelAdminOf, isAdmin, broadcasterId])
}

import {
  Button,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { DateField, getDateString, InputRef } from '@elan-twitch/shared'
import {
  FC,
  ForwardedRef, forwardRef, useImperativeHandle, useMemo,
} from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { InputProps } from '../types'

const padZeros = (n: number, digits: number) => {
  let v = `${n}`
  for (let i = v.length; i < digits; i += 1) {
    v = `0${v}`
  }
  return v
}
export const parseDate = (date?: Date) => (date
  ? `${padZeros(date.getFullYear(), 4)
  }-${
    padZeros(date.getMonth() + 1, 2)
  }-${
    padZeros(date.getDate(), 2)}`
  : undefined)
const BaseDateInput = (props: InputProps<DateField>, ref: ForwardedRef<InputRef>) => {
  const {
    input,
    field: {
      placeholder, minDate, maxDate, optional,
    },
    meta: { active },
  } = props
  const {
    onChange, value, name, onFocus, onBlur,
  } = input
  const date = useMemo(
    () => (value ? new Date(`${value}T00:00:00.000`) : new Date()),
    [value],
  )
  const disabled = useMemo(() => {
    const res = []
    if (minDate !== undefined) {
      res.push({
        to: minDate === 'now' ? new Date() : new Date(minDate),
        from: new Date(0),
      })
    }
    if (maxDate !== undefined) {
      res.push({
        from: maxDate === 'now' ? new Date() : new Date(maxDate),
        to: new Date(Infinity),
      })
    }
    return res
  }, [minDate, maxDate])

  useImperativeHandle(ref, () => ({
    focus: () => {},
    blur: () => {},
  }))

  return (
    <Popover
      isOpen={active}
      onOpen={onFocus}
      onClose={onBlur}
      closeDelay={100}
      placement='bottom'
    >
      <PopoverTrigger>
        <Button
          w='100%'
          fontFamily='DM Sans'
          fontWeight='normal'
          alignItems='center'
          _hover={{ bg: '#efefef' }}
          lineHeight={1}
          variant='outline'
        >
          <Text fontSize='md' height='16px' align='left' flex={1}>
            {value ? getDateString(value, 'short') : placeholder}
          </Text>
          <Image src='/svg/calendar.svg' height='20px' />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex={2} onFocus={onFocus}>
          <PopoverArrow />
          <DayPicker
            required={!optional}
            disabled={disabled}
            styles={{
              caption: {
                fontFamily: 'DM Sans',
                fontWeight: '400',
                fontSize: '0.9rem',
                color: '#555',
              },
              head: {
                fontFamily: 'DM Sans',
              },
              nav: {
                fontFamily: 'DM Sans',
              },
            }}
            modifiersStyles={{
              selected: {
                background: '#454545',
              },
            }}
            id={name}
            mode='single'
            selected={date}
            onSelect={(updated) => onChange(parseDate(updated))}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const DateInput = forwardRef<InputRef, InputProps<DateField>>(BaseDateInput as any) as FC<InputProps<DateField>>

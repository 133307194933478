import { FlexProps, Text } from '@chakra-ui/react'
import React from 'react'
import { ContentBox } from './ContentBox'
import { Loading } from './Loading'

export const FetchedItemView = <T extends {}>({
  data,
  isLoading,
  error,
  itemType,
  Render,
  flexProps,
}: {
  data: T | null
  isLoading: boolean
  error: string | null
  itemType: string
  Render: (item: T) => React.JSX.Element
  flexProps?: FlexProps
}) => {
  let body = <Loading noBox text={`Loading ${itemType} data...`} />
  if (data) return Render(data)
  if (!isLoading) {
    body = (
        <Text fontWeight={600} color='red.600'>
          Error loading {itemType}
          {error ? `: ${error}` : ''}
        </Text>
    )
  }
  return (
    <ContentBox {...flexProps}>
      {body}
    </ContentBox>
  )
}

import {
  Center,
  Collapse,
  HStack,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  InputRef,
  SocialMediaField,
  SocialMediaSites,
  getHandle,
} from '@elan-twitch/shared'
import {
  FC, ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo,
} from 'react'
import { InputProps } from '../types'
import facebookLogo from './icons/facebook.svg'
import instagramLogo from './icons/instagram.svg'
import soundcloudLogo from './icons/soundcloud.svg'
import twitterLogo from './icons/twitter.svg'
import youtubeLogo from './icons/youtube.svg'

const siteToIcon: Record<SocialMediaSites, any> = {
  facebook: facebookLogo,
  instagram: instagramLogo,
  twitter: twitterLogo,
  youtube: youtubeLogo,
  soundcloud: soundcloudLogo,
}

const SocialMediaSiteInput: FC<{
  site: SocialMediaSites
  value?: string
  onChange: (v?: string) => void
}> = ({ site, value, onChange }) => {
  const { handle, error } = useMemo(() => {
    try {
      const h = getHandle(site, value)
      return { handle: h }
    } catch (e: any) {
      return { error: e?.message || 'Error parsing handle' }
    }
  }, [value, site])
  const displayedText = useMemo(
    () => (handle ? `${site.toUpperCase()}: ${handle}` : site.toUpperCase()),
    [site, handle],
  )
  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <Center
          opacity={handle ? 1 : 0.6}
          borderRadius='full'
          width='34px'
          height='34px'
        >
          <Image
            cursor='pointer'
            filter={`grayscale(${handle ? 0 : 100}%)`}
            height='24px'
            width='24px'
            objectFit='contain'
            src={siteToIcon[site]}
          />
        </Center>
      </PopoverTrigger>
      <PopoverContent w='auto'>
        <PopoverBody overflow='hidden' borderRadius={4} p={0} w='auto'>
          <VStack
            spacing={0}
            p={2}
            w='300px'
            align='flex-start'
          >
            <Text fontSize='md'>{displayedText}</Text>

            <Collapse in={!!error} style={{ width: '100%' }}>
              <Text px={1} bg='red.500' fontSize='sm' color='white'>
                {error}
              </Text>
            </Collapse>
            <Input
              onChange={(e) => onChange(e.target.value)}
              placeholder='Handle or URL'
              width='100%'
              value={value}
              size='md'
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
const SocialMediaComponentBase = (
  props: InputProps<SocialMediaField>,
  ref: ForwardedRef<InputRef>,
) => {
  const {
    input: { value, onChange },
    field: { sites },
  } = props
  const handleChange = useCallback(
    (site: SocialMediaSites, v?: string) => {
      const { [site]: _curr, ...rest } = value || {}
      if (!v && Object.keys(rest).length === 0) {
        onChange({ target: { value: undefined } })
      }
      if (!v) onChange(rest)
      onChange({ ...rest, [site]: v })
    },
    [onChange, value],
  )

  useImperativeHandle(ref, () => ({
    blur: () => {},
    focus: () => {},
  }))

  return (
    <VStack w='100%' p={1}>
      <VStack
        borderRadius={4}
        p={2}
        spacing={0}
        w='100%'
      >
        <Text fontSize='md'>Links</Text>
        <HStack>
          {!sites || sites.facebook ? (
            <SocialMediaSiteInput
              onChange={(v) => handleChange('facebook', v)}
              site='facebook'
              value={value?.facebook}
            />
          ) : null}
          {!sites || sites.instagram ? (
            <SocialMediaSiteInput
              onChange={(v) => handleChange('instagram', v)}
              site='instagram'
              value={value?.instagram}
            />
          ) : null}
          {!sites || sites.twitter ? (
            <SocialMediaSiteInput
              onChange={(v) => handleChange('twitter', v)}
              site='twitter'
              value={value?.twitter}
            />
          ) : null}
          {!sites || sites.youtube ? (
            <SocialMediaSiteInput
              onChange={(v) => handleChange('youtube', v)}
              site='youtube'
              value={value?.youtube}
            />
          ) : null}
          {!sites || sites.soundcloud ? (
            <SocialMediaSiteInput
              onChange={(v) => handleChange('soundcloud', v)}
              site='soundcloud'
              value={value?.soundcloud}
            />
          ) : null}
        </HStack>
      </VStack>
    </VStack>
  )
}

export const SocialMediaComponent = forwardRef<InputRef, InputProps<SocialMediaField>>(
  SocialMediaComponentBase as any,
) as FC<InputProps<SocialMediaField>>

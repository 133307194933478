import {
  Center, HStack, IconButton, Image, Text, useColorModeValue, VStack,
} from '@chakra-ui/react'
import {
  FileField, FileValue,
  ImageViewOptions, InputRef,
} from '@elan-twitch/shared'
import {
  ChangeEventHandler,
  FC,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useObjectUrl } from '../../../backend/hooks'
import LoadingImage from '../../shared/LoadingImage'
import { useImageSize } from '../hooks'
import imageIcon from './icons/image.svg'
import pdfIcon from './icons/pdf.svg'
import { InputProps } from './types'

const generateAccept = (field: FileField) => {
  if (!field.accept) return undefined
  const accept = []
  if (field.accept.includes('image')) {
    accept.push('image/jpg', 'image/jpeg', 'image/png', 'image/svg', 'image/gif')
  }
  if (field.accept.includes('pdf')) accept.push('application/pdf')
  if (field.accept.includes('audio')) {
    accept.push('audio/mp3', 'audio/wav', 'audio/x-wav', 'audio/webm', 'audio/ogg')
  }
  return accept.join(',')
}

export const AudioFileView = ({
  hasUpload,
  value,
  noLabel,
}: {
  value: FileValue
  noLabel?: boolean
  hasUpload?: boolean
}) => (
    <VStack>
      <HStack spacing={4}>
        {/* <PlayButton withThumbnail media={media} /> */}
        {hasUpload ? (
          <IconButton
            w='40px'
            h='40px'
            p={2}
            borderRadius='full'
            boxShadow='0 0 4px black'
            aria-label='upload'
            icon={<Image filter='invert(100%)' src='/svg/upload.svg' opacity={0.8} />}
          />
        ) : null}
      </HStack>
      {!noLabel && value.filename ? <Text fontSize='md'>{value.filename || 'Uploaded file'}</Text> : null}
    </VStack>
)

const ImageFileView = ({ value, options }: { value: FileValue; options?: ImageViewOptions }) => {
  const [loaded, setLoaded] = useState(false)
  const { url, loading } = useObjectUrl(value)
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const { shape, placeholder } = options || {}
  const { src: placeholderSrc = imageIcon, opacity: placeholderOpacity, scale: placeholderScale } = placeholder || {}

  const size = useImageSize(options, imageSize)
  const { width, height } = size || {}

  const opacity = useMemo(() => {
    if (loaded) return url ? 1 : placeholderOpacity || 0.8
    return 0
  }, [loaded, url, placeholderOpacity])

  const imageProps = useMemo(() => {
    if (!url) {
      return {
        width: size ? `${size.width * (placeholderScale || 0.9)}px` : 'auto',
        height: size ? `${size.height * (placeholderScale || 0.9)}px` : '80px',
      }
    }
    return {}
  }, [url, size, placeholderScale])
  return (
    <LoadingImage
      width={typeof width === 'number' ? `${width}px` : 'auto'}
      height={typeof height === 'number' ? `${height}px` : '100px'}
      borderRadius={shape === 'circle' ? 'full' : 4}
      boxShadow={shape ? '1px 1px 3px #00000066' : 'none'}
      opacity={opacity}
      transition='opacity 333ms ease'
      objectFit={shape ? 'cover' : 'contain'}
      loadingOverride={loading}
      imageProps={imageProps}
      onLoad={(e) => {
        setLoaded(true)
        setImageSize({
          width: e.currentTarget.naturalWidth,
          height: e.currentTarget.naturalHeight,
        })
      }}
      src={url || placeholderSrc}
    />
  )
}

const FileViewBody = ({
  value,
  noLabel,
  imageOptions,
  hasUpload,
}: {
  value: FileValue
  imageOptions?: ImageViewOptions
  noLabel?: boolean
  hasUpload?: boolean
}) => {
  const fileType = useMemo(() => value.type?.split('/')?.[0], [value])

  switch (fileType) {
    case 'image':
      return <ImageFileView options={imageOptions} value={value} />
    case 'audio':
      return <AudioFileView noLabel={noLabel} value={value} hasUpload={hasUpload} />
    case 'pdf':
      return <Image src={pdfIcon} />
    default:
      return <Text>File uploaded</Text>
  }
}

const NoFileView = ({ hasUpload, imageOptions }: { hasUpload?: boolean; imageOptions?: ImageViewOptions }) => {
  const imageSize = useImageSize(imageOptions)
  const { shape, placeholder } = imageOptions || {}
  const { src: placeholderSrc, scale: placeholderScale, opacity: placeholderOpacity } = placeholder || {}
  const { width, height } = imageSize || {}
  const filter = useColorModeValue('invert(0)', 'invert(1)')

  const bg = useColorModeValue('whiteAlpha.500', 'whiteAlpha.100')

  const body = imageOptions ? (
    <Center
      height={shape ? `${height}px` : 'auto'}
      width={shape ? `${width}px` : 'auto'}
      bg={shape ? bg : 'transparent'}
      borderRadius={shape === 'circle' ? 'full' : 4}
      overflow='hidden'
      boxShadow={shape ? '1px 1px 4px #00000055' : 'none'}
    >
      <Image
        filter={filter}
        opacity={placeholderOpacity}
        height={typeof height === 'number' ? `${height * (placeholderScale || 0.9)}px` : '50px'}
        src={placeholderSrc}
      />
    </Center>
  ) : (
    <Text p={3} fontSize='sm' textAlign='center' w='100%' opacity={0.8}>
      {hasUpload ? 'Click to upload or drop file here' : 'No file uploaded'}
    </Text>
  )
  return (
    <Center
      width={typeof width === 'number' ? `${width}px` : 'auto'}
      height={typeof height === 'number' ? `${height}px` : 'auto'}
    >
      {body}
    </Center>
  )
}

export const FileView = ({
  value,
  hasUpload,
  noLabel,
  imageOptions,
}: {
  value?: FileValue | null
  hasUpload?: boolean
  noLabel?: boolean
  imageOptions?: ImageViewOptions
}) => {
  const { storagePath, dataUrl } = value || {}

  const missingFile = useMemo(() => !dataUrl && !storagePath, [dataUrl, storagePath])
  if (!value || missingFile) {
    return <NoFileView hasUpload={hasUpload} imageOptions={imageOptions} />
  }
  return <FileViewBody imageOptions={imageOptions} noLabel={noLabel} hasUpload={hasUpload} value={value} />
}
const FileComponentBase = (
  { input: { value, onChange }, field }: InputProps<FileField>,
  ref: ForwardedRef<InputRef>,
) => {
  const { imageOptions } = field || {}
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e.target.files?.[0]
      if (!file) return
      if (value?.dataUrl) URL.revokeObjectURL(value.dataUrl)
      onChange({
        ...value,
        dataUrl: URL.createObjectURL(file),
        type: file.type,
        filename: file.name,
      })
    },
    [onChange, value],
  )

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.click()
    },
    blur: () => {
      inputRef.current?.blur()
    },
  }))

  return (
    <Center height='120px' cursor='pointer' p={2} onClick={() => inputRef.current?.click()} width='100%'>
      <Center
        // maxW='160px'
        width='100%'
        height='100%'
        overflow='hidden'
        boxShadow='md'
      >
        <FileView hasUpload imageOptions={imageOptions} value={value} />
        <input
          onChange={handleChange}
          ref={inputRef}
          accept={generateAccept(field)}
          type='file'
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            height: 0.1,
            width: 0.1,
            opacity: 0,
          }}
        />
      </Center>
    </Center>
  )
}

export const FileComponent = forwardRef<InputRef, InputProps<FileField>>(FileComponentBase as any) as FC<InputProps<FileField>>

import { AnyObject, ValidationErrors } from 'final-form'
import {
  FieldMap,
  FieldType,
  IFormElement,
  SocialMediaSites,
  isField,
  isRecordField,
} from '../types'
import {
  ColorField,
  Field,
  Format,
  FormatField,
  NumberField,
  Parse,
  ParseField,
  TextField,
  TypedField,
  Validate,
  ValidateAfterOptionalCheck,
  ValidateField,
} from '../types/forms'

export type FieldBlueprint<T extends Field> = Omit<T, '_type'>

export const createTextField = (
  blueprint: FieldBlueprint<TextField>,
): TextField => ({
  _type: 'text',
  ...blueprint,
})

const isLink = (input: string): boolean => input.match(
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
) !== null
export const getHandle = (
  socialMediaSite: SocialMediaSites,
  input?: string,
): string | undefined => {
  // if youtube link, parse username from link
  if (!input) return undefined
  let parsed = input
  if (isLink(input)) {
    if (input.includes('http://') && !input.startsWith('http://')) {
      throw new Error('Not a valid link')
    }
    if (input.includes('https://') && !input.startsWith('https://')) {
      throw new Error('Not a valid link')
    }
    if (parsed.includes(`${socialMediaSite}.com`)) {
      const split = input.split('/')
      parsed = split[split.length - 1]
      if (parsed.startsWith('@')) {
        parsed = input.slice(1)
      }
      return parsed
    }
    throw new Error(`Invalid link for ${socialMediaSite}`)
  } else {
    throw new Error('Not a valid link')
  }
}

// eslint-disable-next-line max-len, no-control-regex
export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

// export const validateField = <F extends Field>(field: F, data: any) => {
//   if (field._type === 'boolean') return undefined
//   if (!field.optional && data === undefined) return 'Required'
//   if (field.validate) return field.validate(data)
//   switch (field._type) {
//     case 'text':
//       switch (field.type) {
//         case 'email':
//           if (!(data as string).match(emailRegex)) return 'Invalid email'
//           return undefined
//         default:
//           return undefined
//       }
//     case 'number':
//       if (field.min && data < field.min) return `Minimum value of ${field.min}`
//       if (field.max && data > field.max) return `Maximum value of ${field.max}`
//       return undefined
//     default:
//       return undefined
//   }
// }

const validateOptional = (field: Field, data: any) => {
  if (field.optional && data === undefined) return undefined
  if (!field.optional && data === undefined) return 'Required'
  return undefined
}

const validateNumberField = (field: NumberField, data: number) => {
  if (field.min && data < field.min) return `Minimum value of ${field.min}`
  if (field.max && data > field.max) return `Maximum value of ${field.max}`
  return undefined
}

const validateTextField = (field: TextField, data?: TextField['value']) => {
  switch (field.type) {
    case 'email':
      if (data && !(data as string).match(emailRegex)) return 'Invalid email'
      return undefined
    case 'phone':
      if (data && !data.match(/^\d{10}$/)) return 'Invalid phone number'
      return undefined
    default:
      return undefined
  }
}

const validateColorPart = (label: string, part?: number) => {
  if (!part) return `${label} is required`
  if (part < 0 || part > 255) return `${label} must be between 0 and 255`
  return undefined
}
const validateColorField = (field: ColorField, data: ColorField['value']) => {
  const { r, g, b } = data || {}
  const rErr = validateColorPart('Red', r)
  if (rErr) return rErr
  const gErr = validateColorPart('Green', g)
  if (gErr) return gErr
  const bErr = validateColorPart('Blue', b)
  if (bErr) return bErr
  return undefined
}

const validateWithOptional = <F extends Field>(
  validate?: ValidateAfterOptionalCheck<F>,
): ValidateField<F> => (field: F, data?: F['value']) => {
    const optionalErr = validateOptional(field, data)
    if (optionalErr) return optionalErr
    if (!validate) return undefined
    return validate(field, data!)
  }

export const fieldValidate: Partial<{
  [K in FieldType]: ValidateField<TypedField<K>>
}> = {
  boolean: () => undefined,
  text: validateWithOptional(validateTextField),
  color: validateWithOptional(validateColorField),
  number: validateWithOptional(validateNumberField),
}

export const getFieldValidate = <F extends Field>(
  field: F,
): ValidateField<F> => {
  const validateProp = field.validate as Validate | undefined
  if (validateProp) return (_, val) => validateProp(val as F['value'])
  const defaultValidate = fieldValidate[field._type]
  if (!defaultValidate) return validateWithOptional()

  return defaultValidate as ValidateField<F>
}

export const validateFieldMap = (
  field: FieldMap,
  data: AnyObject,
): ValidationErrors => {
  if (field.validate) return field.validate(data)
  const errors = Object.entries(field.children).reduce(
    (acc, [fieldKey, child]) => {
      if (isRecordField(child)) return acc
      if (isField(child)) {
        const validateChild = getFieldValidate(child)
        const err = validateChild(child, data?.[fieldKey])
        if (err) {
          acc[fieldKey] = err
        }
      } else {
        const err = validateFieldMap(child, data?.[fieldKey])
        if (err) {
          acc[fieldKey] = err
        }
      }
      return acc
    },
    {} as AnyObject,
  )
  return errors && Object.keys(errors).length ? errors : undefined
}

export const defaultValidate = (field: Field | FieldMap, value: any) => {
  if (isField(field)) return getFieldValidate(field)(field, value)
  return validateFieldMap(field, value)
}

function formatPhoneNumber(phoneNumberString?: string) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  if (cleaned.length > 10) {
    const countryCodeLen = Math.min(cleaned.length - 10, 2)
    return `+ ${cleaned.substring(0, countryCodeLen)} (${cleaned.substring(
      countryCodeLen,
      countryCodeLen + 3,
    )}) ${cleaned.substring(
      countryCodeLen + 3,
      countryCodeLen + 6,
    )} ${cleaned.substring(
      countryCodeLen + 6,
      Math.min(countryCodeLen + 10, 12),
    )}`
  }
  let ret = ''
  if (cleaned.length) {
    ret += `(${cleaned.substring(0, 3)}`
  }

  if (cleaned.length > 3) ret += `) ${cleaned.substring(3, 6)}`
  if (cleaned.length > 6) ret += ` ${cleaned.substring(6)}`
  return ret
}

const parsePhoneNumber = (val: string | undefined) => (val || '').replace(/[^0-9.]+/g, '')

export const formatField: Partial<{[F in FieldType]: FormatField<TypedField<F>>}> = {
  text: (field, v) => {
    switch (field.type) {
      case 'phone':
        return formatPhoneNumber(v)
      default:
        return v || ''
    }
  },
}

export const getFieldFormat = <F extends Field>(field: F): Format<F['value']> | undefined => {
  const formatProp = field.format as Format<F['value']> | undefined
  if (formatProp) return formatProp
  const defaultFormat = formatField[field._type] as FormatField<F> | undefined
  if (defaultFormat) return (v) => defaultFormat(field, v)
  return undefined
}

export const parseField: Partial<{[F in FieldType]: ParseField<TypedField<F>>}> = {
  text: (field, v) => {
    switch ((field as TextField).type) {
      case 'phone':
        return parsePhoneNumber(v)
      default:
        return v
    }
  },
}

export const getFieldParse = <F extends Field>(field: F): Parse<F['value']> | undefined => {
  const parseProp = field.parse as Parse<F['value']> | undefined
  if (parseProp) return parseProp
  const defaultParse = parseField[field._type] as ParseField<F> | undefined
  if (defaultParse) return (v) => defaultParse(field, v)
  return undefined
}

export const requiresBaseStoragePath = (field: IFormElement): boolean => {
  if (isField(field)) {
    return field._type === 'file'
  }
  if (isRecordField(field)) {
    return requiresBaseStoragePath(field.itemField)
  }
  return Object.values(field.children).some(requiresBaseStoragePath)
}

export const getFieldName = (field: IFormElement) => {
  if (isField(field)) return field.placeholder
  if (isRecordField(field)) return field.placeholder
  return field.name
}

import { CloseIcon } from '@chakra-ui/icons'
import {
  Badge, Box, Center, CenterProps, Flex, IconButton, Image, Text, Tooltip, useColorModeValue,
} from '@chakra-ui/react'
import { MovieListMovie } from '@elan-twitch/shared'
import { useContext, useMemo } from 'react'
import movieIcon from '../../../../icons/movie.svg'
import { MovieViewContext } from './context'

export const MoviePosterView = ({
  height, width, movie, ...centerProps
}: { height: number; width?: number, movie: MovieListMovie } & CenterProps) => {
  const { title, poster_path } = movie
  const imageBackground = useColorModeValue('blackAlpha.300', 'blackAlpha.600')
  return (
    <Center minW='50px' bg={imageBackground} overflow='hidden' borderRadius={6} {...centerProps}>
      {poster_path ? (
        <Image
        draggable={false}
          h={`${height}px`}
          w={width ? `${width}px` : 'auto'}
          minH='100%'
          objectFit='cover'
          src={`https://image.tmdb.org/t/p/w500${poster_path}`}
          alt={title}
        />
      ) : (
        <Image w='50px' src={movieIcon} alt={title} />
      )}
    </Center>
  )
}

export const MovieReleaseYear = ({ release_date }: { release_date: string }) => {
  const releaseDateYear = useMemo(() => (release_date ? release_date.split('-')[0] : 'No release year'), [release_date])
  const bg = useColorModeValue('#cdcdcd', 'gray.600')
  const textColor = useColorModeValue('#555', '#efefef')
  const textShadow = useColorModeValue('none', '1px 1px 2px black')
  return (
    <Box>
      <Text
        bg={bg}
        color={textColor}
        textShadow={textShadow}
        as='span'
        h='auto'
        lineHeight={1}
        fontSize='sm'
        py={1}
        px={3}
        borderRadius='full'
        fontWeight={600}
      >
        {releaseDateYear}
      </Text>
    </Box>
  )
}

export const MovieDirector = ({ movie }: { movie: MovieListMovie }) => (
  <Flex align='center' gap={1}>
    <Text fontSize='sm' fontWeight={600}>
      Director:
    </Text>
    <Text fontSize='sm'>{movie.director?.name || 'None'}</Text>
  </Flex>
)

export const MovieCast = ({ movie }: { movie: MovieListMovie }) => (
  <Flex align='center' gap={1}>
    <Text fontSize='sm' fontWeight={600}>
      Cast:
    </Text>
    <Flex flexFlow='row wrap'>
      {movie.cast.map((c, idx) => (
        <Flex key={`cast_${idx}`} p={1}>
          {c.character ? (
            <Tooltip placement='top' hasArrow label={`As ${c.character}`}>
              <Badge cursor='pointer'>{c.name}</Badge>
            </Tooltip>
          ) : (
            <Badge cursor='pointer'>{c.name}</Badge>
          )}
        </Flex>
      ))}
    </Flex>
  </Flex>
)

export const MovieSummary = ({ movie }: { movie: MovieListMovie }) => {
  const { title, release_date, overview } = movie
  const bg = useColorModeValue('gray.100', 'gray.800')
  const closeBg = useColorModeValue('blackAlpha.400', 'whiteAlpha.400')
  const { onClose } = useContext(MovieViewContext)
  return (
    <Flex gap={3} bg={bg} borderRadius={6} flexFlow={['column', 'column', 'row']} w='100%' p={3}>
      <MoviePosterView height={200} movie={movie} />
      <Flex flexFlow='column' flex={1} minW='0'>
        <Flex w='100%' gap={2}>
          <Text flex={1} lineHeight={1.1} fontWeight={600} fontSize='2xl'>
            {title}
          </Text>
          <Flex py={1} gap={3} ml='auto'>
            <MovieReleaseYear release_date={release_date} />
            {onClose ? (
              <IconButton
                bg={closeBg}
                borderRadius='full'
                size='sm'
                aria-label='Close'
                icon={<CloseIcon />}
                onClick={onClose}
              />
            ) : null}
          </Flex>
        </Flex>
        <Flex py={1}>
          <Text>{overview}</Text>
        </Flex>
        <MovieDirector movie={movie} />
        <MovieCast movie={movie} />
      </Flex>
    </Flex>
  )
}

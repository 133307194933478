import { create } from 'zustand'

export const useScreen = create(() => ({
  width: typeof window !== 'undefined' ? window.innerWidth : 0,
  height: typeof window !== 'undefined' ? window.innerHeight : 0,
}))

if (typeof window !== 'undefined') {
  window.addEventListener('resize', () => {
    useScreen.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  })
}

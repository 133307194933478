import {
  Center,
  CircularProgress,
  Flex,
  HStack,
  Text,
  usePrevious,
  VStack,
} from '@chakra-ui/react'
import {
  ChatWidgetConfig,
  gradients,
  gradientToCssProp,
  WidgetComponent,
} from '@elan-twitch/shared'
import { a, useSpring } from '@react-spring/web'
import { Trophy } from 'icons/Trophy'
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLiveChat, UseLiveChatConfig } from '../hooks/useLiveChat'
import './styles.css'
import {
  EmoteTugOfWarViewData,
  TugOfWarAPI,
  TugOfWarBodyProps,
  TugOfWarConfig,
  TugOfWarData,
  TugOfWarResults,
} from './types'
import { parseCommandType, parseStartGameCommand } from './utils'
// chat commands from nymn or tofeezy
// !tugofwar <emote1> <emote2> <duration>
// !tugofwar stop

const defaultTugOfWarConfig: TugOfWarConfig = {
  gradient: 'nymn',
}

const getPercentLabelText = (count1: number, count2: number) => {
  const total = count1 + count2
  const percent = Math.round((count1 / total) * 100)
  return `${percent}%`
}
const WinnerIcon = ({ results }: { results?: TugOfWarResults }) => {
  const precentText = useMemo(() => {
    if (!results) return ''
    if (results.winner === 1) {
      return getPercentLabelText(results.emote1.count, results.emote2.count)
    }
    if (results.winner === 2) {
      return getPercentLabelText(results.emote2.count, results.emote1.count)
    }
    return 'TIE'
  }, [results])
  return (
    <Center
      position='relative'
      flexFlow='column'
      height={results ? '64px' : '0px'}
      opacity={results ? 1 : 0}
      animation={results ? 'pulse 2s linear infinite' : undefined}
      transition={`all 300ms ease ${results ? '700ms' : '0ms'}`}
      width='64px'
      overflow='hidden'
    >
      <Trophy style={{ width: '64px' }} />
      <span
        style={{
          transition: results ? 'all 500ms ease 250ms' : 'all 500ms ease 0ms',
        }}
        className={`emote-percent-label${results ? '' : ' hidden-label'}`}
      >
        {precentText}
      </span>
    </Center>
  )
}

const TugOfWarBody = forwardRef<TugOfWarAPI, TugOfWarBodyProps>(
  ({ tugOfWarData, config }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const counts = useRef<[number, number]>([0, 0])
    // const [startsAt, setStartsAt] = useState(Date.now())

    const { gradient } = config || defaultTugOfWarConfig

    const emote1CountLabel = useRef<HTMLSpanElement>(null)
    const emote2CountLabel = useRef<HTMLSpanElement>(null)

    // const image1Ref = useRef<HTMLImageElement>(null)
    // useResizeObserver(image1Ref, (e) => setImage1Width(e.contentRect.width))

    // const image2Ref = useRef<HTMLImageElement>(null)
    // useResizeObserver(image2Ref, (e) => setImage2Width(e.contentRect.width))

    const [{ progress }, api] = useSpring(() => ({
      progress: 0.5,
    }))
    useEffect(() => {
      counts.current = [0, 0]
      // setStartsAt(Date.now())
      api.start({ progress: 0.5 })
      if (emote1CountLabel.current) emote1CountLabel.current.innerText = tugOfWarData?.results ? `${tugOfWarData?.results?.emote1?.count}` : '0'
      if (emote2CountLabel.current) emote2CountLabel.current.innerText = tugOfWarData?.results ? `${tugOfWarData?.results?.emote2?.count}` : '0'
      if (!tugOfWarData) {
        // setImage1Width(0)
        // setImage2Width(0)
      }
    }, [tugOfWarData, api])

    const updateLabels = useCallback(() => {
      if (!emote1CountLabel.current || !emote2CountLabel.current) {
        return
      }
      const [emote1Count, emote2Count] = counts.current
      emote1CountLabel.current.innerText = `${emote1Count}`
      emote2CountLabel.current.innerText = `${emote2Count}`
    }, [])

    const handleProgress = useCallback(() => {
      updateLabels()
      const [emote1Count, emote2Count] = counts.current
      if (!emote1Count || !emote2Count) api.start({ progress: 0.5 })
      if (!emote1Count) api.start({ progress: 0 })
      if (!emote2Count) api.start({ progress: 1 })
      api.start({
        progress: emote1Count / (emote1Count + emote2Count),
      })
    }, [api, updateLabels])
    const handleEmote1 = useCallback(
      (count: number) => {
        counts.current = [count + counts.current[0], counts.current[1]]
        handleProgress()
      },
      [handleProgress],
    )
    const handleEmote2 = useCallback(
      (count: number) => {
        counts.current = [counts.current[0], count + counts.current[1]]
        handleProgress()
      },
      [handleProgress],
    )
    useImperativeHandle(ref, () => ({
      getCounts: () => counts.current,
      onMessage: (message: string) => {
        if (!tugOfWarData) return
        const emote1Count = message.split(' ').includes(tugOfWarData.emote1.name) ? 1 : 0
        const emote2Count = message.split(' ').includes(tugOfWarData.emote2.name) ? 1 : 0
        if (emote1Count) handleEmote1(emote1Count)
        if (emote2Count) handleEmote2(emote2Count)
      },
    }))

    const gradientCssProp = useMemo(
      () => gradientToCssProp(gradients[gradient]),
      [gradient],
    )
    const prevGame = usePrevious(tugOfWarData)

    return (
      <VStack
        borderRadius={6}
        p={4}
        mb={4}
        opacity={tugOfWarData ? 1 : 0}
        transition='opacity 300ms'
        spacing={0}
        ref={containerRef}
      >
        <VStack p={2} borderRadius='8px' bg={gradientCssProp}>
          <VStack
            spacing={0}
            position='relative'
            width='64px'
            height={
              tugOfWarData?.results?.winner === 2 ? '0px' : '64px'
            }
            opacity={tugOfWarData?.results?.winner === 2 ? 0 : 1}
            transition='all 300ms'
            overflow='hidden'
          >
            <img
              style={{ height: '64px', borderRadius: '6px', objectFit: 'cover' }}
              src={tugOfWarData?.emote1.url || prevGame?.emote1?.url}
              alt={tugOfWarData?.emote1.name || prevGame?.emote1?.url}
            />
          </VStack>

          <Flex
            position='relative'
            bg='blackAlpha.700'
            opacity={!tugOfWarData || tugOfWarData?.results ? 0 : 1}
            h={!tugOfWarData || tugOfWarData?.results ? '0px' : '600px'}
            m={1}
            transition='all 700ms'
            overflow='hidden'
            boxShadow='inset 0 0 6px black'
            align='center'
            w='100%'
            direction='column'
            borderRadius='8px'
          >
            <a.div
              style={{
                height: progress.to((p) => `${99 - p * 99}%`),
                width: '90%',
                background:
                  'linear-gradient(272deg, rgba(255,192,0,1) 1%, rgba(255,100,100,1) 100%)',
                // opacity: 0.8,
                borderRadius: '6px',
                position: 'absolute',
                bottom: '0.5%',
              }}
            />
            <span
              className='emote-count-label'
              style={{ top: '5px', fontSize: '1.4rem' }}
              ref={emote1CountLabel}
            >
              0
            </span>
            <span
              className='emote-count-label'
              style={{ bottom: '5px', fontSize: '1.4rem' }}
              ref={emote2CountLabel}
            >
              0
            </span>
          </Flex>

          {/* <VsView/> */}
          <WinnerIcon results={tugOfWarData?.results} />
          <VStack
            spacing={0}
            position='relative'
            height={
              tugOfWarData?.results?.winner === 1 ? '0px' : '64px'
            }
            opacity={tugOfWarData?.results?.winner === 1 ? 0 : 1}
            transition='all 300ms'
            overflow='hidden'
            justify='center'
            align='center'
          >
            <img
              style={{ height: '64px', borderRadius: '6px', objectFit: 'cover' }}
              src={tugOfWarData?.emote2.url || prevGame?.emote2?.url}
              alt={tugOfWarData?.emote2.name || prevGame?.emote2?.name}
            />
          </VStack>
        </VStack>
      </VStack>
    )
  },
)

const tugOfWarConfig: UseLiveChatConfig = {
  emotes: {
    size: 2,
  },
}

const getWinner = (count1: number, count2: number) => {
  if (count1 > count2) return 1
  if (count2 > count1) return 2
  return 0
}

const getWinnerFromCounts = (
  data: TugOfWarData,
  counts: [number, number],
): TugOfWarData => {
  const [count1, count2] = counts

  const results: TugOfWarResults = {
    emote1: { ...data.emote1, count: count1 },
    emote2: { ...data.emote2, count: count2 },
    winner: getWinner(count1, count2),
  }

  return { ...data, results }
}

export const EmoteTugOfWarView: WidgetComponent<
  EmoteTugOfWarViewData,
  ChatWidgetConfig
> = ({ data }) => {
  const { broadcasterId } = data || {}
  const {
    subscribe,
    error,
    emotes: { emotes, isLoading: emotesLoading },
  } = useLiveChat(broadcasterId, tugOfWarConfig)
  const [tugOfWarData, setTugOfWarData] = useState<TugOfWarData | null>(null)

  const tugOfWarDataRef = useRef<TugOfWarData | null>(null)
  useEffect(() => {
    tugOfWarDataRef.current = tugOfWarData
  }, [tugOfWarData])
  const gameAdmins = useMemo(
    () => (broadcasterId ? [broadcasterId.toLowerCase(), 'tofeezy'] : ['tofeezy']),
    [broadcasterId],
  )
  const tugOfWarBodyRef = useRef<TugOfWarAPI>(null)
  const [gameError, setGameError] = useState<string | null>(null)
  const endGameTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (emotesLoading) return () => {}
    if (endGameTimeoutRef.current) clearTimeout(endGameTimeoutRef.current)
    endGameTimeoutRef.current = null
    const unsub = subscribe(({ message, username, mod }) => {
      if (
        (gameAdmins.includes(username.toLowerCase()) || mod)
        && message.startsWith('!tugofwar')
      ) {
        try {
          const command = parseCommandType(message)
          const curr = tugOfWarDataRef.current
          const ref = tugOfWarBodyRef.current
          switch (command) {
            case 'stop':
              setGameError(null)
              if (!curr) return
              if (!ref) return
              setTugOfWarData(getWinnerFromCounts(curr, ref.getCounts()))
              endGameTimeoutRef.current = setTimeout(() => {
                setTugOfWarData(null)
                endGameTimeoutRef.current = null
              }, 5000)
              break
            default:

              setTugOfWarData(parseStartGameCommand(message, emotes))
              setGameError(null)
          }
        } catch (e: any) {
          setGameError(e.message)
        }
      } else tugOfWarBodyRef?.current?.onMessage(message)
    })
    return unsub
  }, [subscribe, emotes, gameAdmins, emotesLoading])
  let body: ReactNode | null = null
  if (!broadcasterId) {
    body = (
      <Text p={2} bg='red'>
        Missing broadcaster ID
      </Text>
    )
  } else if (error) {
    body = (
      <Text p={2} color='red'>
        {error}
      </Text>
    )
  } else if (emotesLoading) {
    body = (
      <HStack bg='gray' p={2}>
        <CircularProgress color='white' size={4} isIndeterminate />
        <Text>Loading emotes...</Text>
      </HStack>
    )
  } else {
    body = (
      <TugOfWarBody
        ref={tugOfWarBodyRef}
        tugOfWarData={tugOfWarData}
        emotes={emotes}
      />
    )
  }
  return (
    <Flex
      align='flex-start'
      justify='center'
      flexFlow='column'
      position='absolute'
      bottom='0'
      left='0'
      h='100%'
      w='100%'
    >
      {gameError ? (
        <Text p={1} color='red'>
          {gameError}
        </Text>
      ) : null}
      {body}
    </Flex>
  )
}

import { ChoiceWithRank, Poll } from '@elan-twitch/shared'
import { useMemo } from 'react'

export const useProcessedPoll = (poll?: Poll) => {
  const { choices = [] } = poll || {}
  return useMemo(() => {
    const sortedByVotes = [...choices].sort((a, b) => b.votes - a.votes)
    let maxVotes = 1
    const { totalVotes } = choices.reduce(
      (acc, curr) => {
        if (curr.votes > maxVotes) maxVotes = curr.votes
        return {
          totalVotes: acc.totalVotes + curr.votes,
        }
      },
      { totalVotes: 0 },
    )
    const withRank = {
      ...poll,
      choices: choices.map((choice) => ({
        ...choice,
        rank: sortedByVotes.findIndex((c) => c.id === choice.id),
        percent: choice.votes / (totalVotes || 1),
        maxPoints: maxVotes,
        totalVotes,
      })) as Array<ChoiceWithRank>,
    }
    return { withRank, totalVotes }
  }, [choices, poll])
}

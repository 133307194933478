import { HStack } from '@chakra-ui/react'
import { DateTimeField, InputRef } from '@elan-twitch/shared'
import {
  FC, ForwardedRef, forwardRef, useCallback, useImperativeHandle, useState,
} from 'react'
import { DateInput, parseDate } from '../date'
import '../input.css'
import { TimeInput } from '../time'
import { InputProps } from '../types'

const parseDateAndTime = (timestamp?: number) => {
  if (!timestamp) return { date: undefined, time: undefined }
  const d = new Date(timestamp)
  return { date: parseDate(d), time: d.toTimeString().substring(0, 5) }
}

const BaseDateTimeInput = (
  {
    input: {
      value, onBlur, onChange, onFocus,
    },
  }: InputProps<DateTimeField>,
  ref: ForwardedRef<InputRef>,
) => {
  const [{ date, time }, set] = useState<{ date?: string; time?: string }>(parseDateAndTime(value))
  const [focusedElement, setFocused] = useState<'date' | 'time' | null>(null)
  const handleChange = useCallback(
    (type: 'date' | 'time', updated?: string) => {
      if (type === 'date') {
        set({ date: updated, time })
        if (updated && time) onChange(new Date(`${updated}T${time}`).getTime())
      } else {
        set({ time: updated, date })
        if (updated && date) {
          onChange(new Date(`${date}T${updated}:00.000`).getTime())
        }
      }
    },
    [date, time, onChange],
  )

  useImperativeHandle(ref, () => ({
    focus: () => {},
    blur: () => {},
  }))

  return (
    <HStack w='100%'>
      <DateInput
        field={{ _type: 'date', placeholder: 'Date' }}
        input={{
          name: '',
          value: date,
          onChange: (dateString: string) => {
            handleChange('date', dateString)
          },
          onBlur: () => {
            setFocused(null)
            onBlur()
          },
          onFocus: () => {
            setFocused('date')
            onFocus()
          },
        }}
        meta={{ active: focusedElement === 'date' }}
      />
      <TimeInput
        field={{ _type: 'time', placeholder: 'Time' }}
        input={{
          name: '',
          value: time,
          onChange: (e) => handleChange('time', e.target.value),
          onBlur: () => {
            setFocused(null)
            onBlur()
          },
          onFocus: () => {
            setFocused('time')
            onFocus()
          },
        }}
        meta={{ active: focusedElement === 'time' }}
      />
    </HStack>
  )
}

export const DateTimeInput = forwardRef<InputRef, InputProps<DateTimeField>>(BaseDateTimeInput as any) as FC<
  InputProps<DateTimeField>
>

import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useAuthRedirect } from '../../hooks/useAuthRedirect'
import { useAuthUpdate } from '../../store/useUser'
import { AuthRedirect } from '../../Views/UserAuthRedirect'
import { Overlay } from '../shared/Overlay'
import './App.css'
import { BroadcasterPanel } from './Broadcasters/BroadcasterPanel'
import { theme } from './theme'

const RedirectOverlay = () => {
  const redirect = useAuthRedirect()
  return (
    <Overlay
      style={{
        opacity: redirect.message ? 1 : 0,
        pointerEvents: 'none',
      }}
    >
      <p style={{ color: redirect.message?.color || 'white' }}>
        {redirect.message?.text || 'WOWOWO'}
      </p>
    </Overlay>
  )
}
const App = () => {
  useAuthUpdate()
  return (
    <div
      style={{
        transition: 'opacity 333ms ease',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Routes>
        <Route path='/user-auth-redirect' element={<AuthRedirect/>} />
        <Route path='/*' element={<BroadcasterPanel />} />
      </Routes>
      <RedirectOverlay />
      {/* <TestVOD /> */}
    </div>
  )
}

const Router = () => (
  <BrowserRouter>
    <ChakraProvider portalZIndex={9} theme={theme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
)

export default Router

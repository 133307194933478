import { PollOverlayVersion } from '@elan-twitch/shared'
import { FC, useMemo } from 'react'
import { usePollConfig } from '../Config/hooks'
import { OWPollView } from './OWPoll'

const versionComponents: Record<PollOverlayVersion, FC> = {
  ow: OWPollView,
}

export const PollView = () => {
  const { config: { version } } = usePollConfig()

  const Component = useMemo(() => versionComponents[version || 'ow'], [version])

  return <Component/>
}

import { lazy, Suspense } from 'react'
import { useBroadcasterRandomDrops } from '../hooks'

const OverlayCanvas = lazy(() => import('./OverlayCanvas'))

const Fallback = () => {
  console.log('Loading random drops overlay...')
  return null
//   return(
//   <Center w='100vw' h='100vh' top='0' left='0'>
//     <Center
//       w='100px'
//       h='100px'
//       bg='blackAlpha.700'
//       borderRadius={6}
//       color='white'
//       fontSize='xl'
//     >
//       <CircularProgress isIndeterminate />
//       <Text>Loading...</Text>
//     </Center>
//   </Center>
// )
}

export const RandomDropsOverlay = () => {
  const { eggs } = useBroadcasterRandomDrops()
  return (
  <Suspense fallback={<Fallback/>}>
    <OverlayCanvas eggs={eggs} />
  </Suspense>
  )
}

/*
NEW Automod Message Hold    automod.message.hold    1    A user is notified if a message is caught by automod for review.
NEW Automod Message Update    automod.message.update    1    A message in the automod queue had its status changed.
NEW Automod Settings Update    automod.settings.update    1    A notification is sent when a broadcaster’s automod settings are updated.
NEW Automod Terms Update    automod.terms.update    1    A notification is sent when a broadcaster’s automod terms are updated. Changes to private terms are not sent.
Channel Update    channel.update    2    A broadcaster updates their channel properties e.g., category, title, content classification labels, broadcast, or language.
Channel Follow    channel.follow    2    A specified channel receives a follow.
Channel Ad Break Begin    channel.ad_break.begin    1    A midroll commercial break has started running.
Channel Chat Clear    channel.chat.clear    1    A moderator or bot has cleared all messages from the chat room.
Channel Chat Clear User Messages    channel.chat.clear_user_messages    1    A moderator or bot has cleared all messages from a specific user.
NEW Channel Chat Message    channel.chat.message    1    Any user sends a message to a specific chat room.
Channel Chat Message Delete    channel.chat.message_delete    1    A moderator has removed a specific message.
Channel Chat Notification    channel.chat.notification    1    A notification for when an event that appears in chat has occurred.
NEW Channel Chat Settings Update    channel.chat_settings.update    1    A notification for when a broadcaster’s chat settings are updated.
NEW Channel Chat User Message Hold    channel.chat.user_message_hold    1    A user is notified if their message is caught by automod.
NEW Channel Chat User Message Update    channel.chat.user_message_update    1    A user is notified if their message’s automod status is updated.
Channel Subscribe    channel.subscribe    1    A notification when a specified channel receives a subscriber. This does not include resubscribes.
Channel Subscription End    channel.subscription.end    1    A notification when a subscription to the specified channel ends.
Channel Subscription Gift    channel.subscription.gift    1    A notification when a viewer gives a gift subscription to one or more users in the specified channel.
Channel Subscription Message    channel.subscription.message    1    A notification when a user sends a resubscription chat message in a specific channel.
Channel Cheer    channel.cheer    1    A user cheers on the specified channel.
Channel Raid    channel.raid    1    A broadcaster raids another broadcaster’s channel.
Channel Ban    channel.ban    1    A viewer is banned from the specified channel.
Channel Unban    channel.unban    1    A viewer is unbanned from the specified channel.
NEW Channel Unban Request Create    channel.unban_request.create     1    A user creates an unban request.
NEW Channel Unban Request Resolve    channel.unban_request.resolve     1    An unban request has been resolved.
Channel Moderate    channel.moderate    1    A moderator performs a moderation action in a channel.
NEW Channel Moderate V2    channel.moderate    2    A moderator performs a moderation action in a channel. Includes warnings.
Channel Moderator Add    channel.moderator.add    1    Moderator privileges were added to a user on a specified channel.
Channel Moderator Remove    channel.moderator.remove    1    Moderator privileges were removed from a user on a specified channel.
BETA Channel Guest Star Session Begin    channel.guest_star_session.begin    beta    The host began a new Guest Star session.
BETA Channel Guest Star Session End    channel.guest_star_session.end    beta    A running Guest Star session has ended.
BETA Channel Guest Star Guest Update    channel.guest_star_guest.update    beta    A guest or a slot is updated in an active Guest Star session.
BETA Channel Guest Star Settings Update    channel.guest_star_settings.update    beta    The host preferences for Guest Star have been updated.
NEW Channel Points Automatic Reward Redemption    channel.channel_points_automatic_reward_redemption.add    1    A viewer has redeemed an automatic channel points reward on the specified channel.
Channel Points Custom Reward Add    channel.channel_points_custom_reward.add    1    A custom channel points reward has been created for the specified channel.
Channel Points Custom Reward Update    channel.channel_points_custom_reward.update    1    A custom channel points reward has been updated for the specified channel.
Channel Points Custom Reward Remove    channel.channel_points_custom_reward.remove    1    A custom channel points reward has been removed from the specified channel.
Channel Points Custom Reward Redemption Add    channel.channel_points_custom_reward_redemption.add    1    A viewer has redeemed a custom channel points reward on the specified channel.
Channel Points Custom Reward Redemption Update    channel.channel_points_custom_reward_redemption.update    1    A redemption of a channel points custom reward has been updated for the specified channel.
Channel Poll Begin    channel.poll.begin    1    A poll started on a specified channel.
Channel Poll Progress    channel.poll.progress    1    Users respond to a poll on a specified channel.
Channel Poll End    channel.poll.end    1    A poll ended on a specified channel.
Channel Prediction Begin    channel.prediction.begin    1    A Prediction started on a specified channel.
Channel Prediction Progress    channel.prediction.progress    1    Users participated in a Prediction on a specified channel.
Channel Prediction Lock    channel.prediction.lock    1    A Prediction was locked on a specified channel.
Channel Prediction End    channel.prediction.end    1    A Prediction ended on a specified channel.
NEW Channel Suspicious User Message    channel.suspicious_user.message    1    A chat message has been sent by a suspicious user.
NEW Channel Suspicious User Update    channel.suspicious_user.update    1    A suspicious user has been updated.
NEW Channel VIP Add    channel.vip.add    1    A VIP is added to the channel.
NEW Channel VIP Remove    channel.vip.remove    1    A VIP is removed from the channel.
NEW Channel Warning Acknowledgement    channel.warning.acknowledge    1    A user awknowledges a warning. Broadcasters and moderators can see the warning’s details.
NEW Channel Warning Send    channel.warning.send    1    A user is sent a warning. Broadcasters and moderators can see the warning’s details.
Charity Donation    channel.charity_campaign.donate    1    Sends an event notification when a user donates to the broadcaster’s charity campaign.
Charity Campaign Start    channel.charity_campaign.start    1    Sends an event notification when the broadcaster starts a charity campaign.
Charity Campaign Progress    channel.charity_campaign.progress    1    Sends an event notification when progress is made towards the campaign’s goal or when the broadcaster changes the fundraising goal.
Charity Campaign Stop    channel.charity_campaign.stop    1    Sends an event notification when the broadcaster stops a charity campaign.
NEW Conduit Shard Disabled    conduit.shard.disabled    1    Sends a notification when EventSub disables a shard due to the status of the underlying transport changing.
Drop Entitlement Grant    drop.entitlement.grant    1    An entitlement for a Drop is granted to a user.
Extension Bits Transaction Create    extension.bits_transaction.create    1    A Bits transaction occurred for a specified Twitch Extension.
Goal Begin    channel.goal.begin    1    Get notified when a broadcaster begins a goal.
Goal Progress    channel.goal.progress    1    Get notified when progress (either positive or negative) is made towards a broadcaster’s goal.
Goal End    channel.goal.end    1    Get notified when a broadcaster ends a goal.
Hype Train Begin    channel.hype_train.begin    1    A Hype Train begins on the specified channel.
Hype Train Progress    channel.hype_train.progress    1    A Hype Train makes progress on the specified channel.
Hype Train End    channel.hype_train.end    1    A Hype Train ends on the specified channel.
Shield Mode Begin    channel.shield_mode.begin    1    Sends a notification when the broadcaster activates Shield Mode.
Shield Mode End    channel.shield_mode.end    1    Sends a notification when the broadcaster deactivates Shield Mode.
Shoutout Create    channel.shoutout.create    1    Sends a notification when the specified broadcaster sends a Shoutout.
Shoutout Received    channel.shoutout.receive    1    Sends a notification when the specified broadcaster receives a Shoutout.
Stream Online    stream.online    1    The specified broadcaster starts a stream.
Stream Offline    stream.offline    1    The specified broadcaster stops a stream.
User Authorization Grant    user.authorization.grant    1    A user’s authorization has been granted to your client id.
User Authorization Revoke    user.authorization.revoke    1    A user’s authorization has been revoked for your client id.
User Update    user.update    1    A user has updated their account.
NEW Whisper Received    user.whisper.message    1    A user receives a whisper.

*/

export const twitchEvents = {
  AUTOMOD_MESSAGE_HOLD: {
    type: 'automod.message.hold',
    version: '1',
    condition: ['moderator_user_id', 'broadcaster_user_id'],
  },
  AUTOMOD_MESSAGE_UPDATE: {
    type: 'automod.message.update',
    version: '1',
    condition: ['moderator_user_id', 'broadcaster_user_id'],
  },
  AUTOMOD_SETTINGS_UPDATE: {
    type: 'automod.settings.update',
    version: '1',
    condition: ['moderator_user_id', 'broadcaster_user_id'],
  },
  AUTOMOD_TERMS_UPDATE: {
    type: 'automod.terms.update',
    version: '1',
    condition: ['moderator_user_id', 'broadcaster_user_id'],
  },
  CHANNEL_UPDATE: {
    type: 'channel.update',
    version: '2',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_FOLLOW: {
    type: 'channel.follow',
    version: '2',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_AD_BREAK_BEGIN: {
    type: 'channel.ad_break.begin',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_CHAT_CLEAR: {
    type: 'channel.chat.clear',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_CLEAR_USER_MESSAGES: {
    type: 'channel.chat.clear_user_messages',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_MESSAGE: {
    type: 'channel.chat.message',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_MESSAGE_DELETE: {
    type: 'channel.chat.message_delete',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_NOTIFICATION: {
    type: 'channel.chat.notification',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_SETTINGS_UPDATE: {
    type: 'channel.chat_settings.update',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_USER_MESSAGE_HOLD: {
    type: 'channel.chat.user_message_hold',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_CHAT_USER_MESSAGE_UPDATE: {
    type: 'channel.chat.user_message_update',
    version: '1',
    condition: ['broadcaster_user_id', 'user_id'],
  },
  CHANNEL_SUBSCRIBE: {
    type: 'channel.subscribe',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_SUBSCRIPTION_END: {
    type: 'channel.subscription.end',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_SUBSCRIPTION_GIFT: {
    type: 'channel.subscription.gift',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_SUBSCRIPTION_MESSAGE: {
    type: 'channel.subscription.message',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_CHEER: {
    type: 'channel.cheer',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_RAID: {
    type: 'channel.raid',
    version: '1',
    condition: ['to_broadcaster_user_id', 'from_broadcaster_user_id'],
  },
  CHANNEL_BAN: {
    type: 'channel.ban',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_UNBAN: {
    type: 'channel.unban',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_UNBAN_REQUEST_CREATE: {
    type: 'channel.unban_request.create',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_UNBAN_REQUEST_RESOLVE: {
    type: 'channel.unban_request.resolve',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_MODERATE: {
    type: 'channel.moderate',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_MODERATE_V2: {
    type: 'channel.moderate',
    version: '2',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_MODERATOR_ADD: {
    type: 'channel.moderator.add',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_MODERATOR_REMOVE: {
    type: 'channel.moderator.remove',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_GUEST_STAR_SESSION_BEGIN: {
    type: 'channel.guest_star_session.begin',
    version: 'beta',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_GUEST_STAR_SESSION_END: {
    type: 'channel.guest_star_session.end',
    version: 'beta',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_GUEST_STAR_GUEST_UPDATE: {
    type: 'channel.guest_star_guest.update',
    version: 'beta',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_GUEST_STAR_SETTINGS_UPDATE: {
    type: 'channel.guest_star_settings.update',
    version: 'beta',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_POINTS_AUTOMATIC_REWARD_REDEMPTION: {
    type: 'channel.channel_points_automatic_reward_redemption.add',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POINTS_CUSTOM_REWARD_ADD: {
    type: 'channel.channel_points_custom_reward.add',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POINTS_CUSTOM_REWARD_UPDATE: {
    type: 'channel.channel_points_custom_reward.update',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POINTS_CUSTOM_REWARD_REMOVE: {
    type: 'channel.channel_points_custom_reward.remove',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POINTS_CUSTOM_REWARD_REDEMPTION_ADD: {
    type: 'channel.channel_points_custom_reward_redemption.add',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POINTS_CUSTOM_REWARD_REDEMPTION_UPDATE: {
    type: 'channel.channel_points_custom_reward_redemption.update',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POLL_BEGIN: {
    type: 'channel.poll.begin',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POLL_PROGRESS: {
    type: 'channel.poll.progress',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_POLL_END: {
    type: 'channel.poll.end',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_PREDICTION_BEGIN: {
    type: 'channel.prediction.begin',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_PREDICTION_PROGRESS: {
    type: 'channel.prediction.progress',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_PREDICTION_LOCK: {
    type: 'channel.prediction.lock',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_PREDICTION_END: {
    type: 'channel.prediction.end',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_SUSPICIOUS_USER_MESSAGE: {
    type: 'channel.suspicious_user.message',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_SUSPICIOUS_USER_UPDATE: {
    type: 'channel.suspicious_user.update',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_VIP_ADD: {
    type: 'channel.vip.add',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_VIP_REMOVE: {
    type: 'channel.vip.remove',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_WARNING_ACKNOWLEDGEMENT: {
    type: 'channel.warning.acknowledge',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_WARNING_SEND: {
    type: 'channel.warning.send',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_HYPE_TRAIN_BEGIN: {
    type: 'channel.hype_train.begin',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_HYPE_TRAIN_PROGRESS: {
    type: 'channel.hype_train.progress',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_HYPE_TRAIN_END: {
    type: 'channel.hype_train.end',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_CHARITY_CAMPAIGN_DONATE: {
    type: 'channel.charity_campaign.donate',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_CHARITY_CAMPAIGN_START: {
    type: 'channel.charity_campaign.start',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_CHARITY_CAMPAIGN_PROGRESS: {
    type: 'channel.charity_campaign.progress',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_CHARITY_CAMPAIGN_STOP: {
    type: 'channel.charity_campaign.stop',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_SHIELD_MODE_BEGIN: {
    type: 'channel.shield_mode.begin',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_SHIELD_MODE_END: {
    type: 'channel.shield_mode.end',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_SHOUTOUT_CREATE: {
    type: 'channel.shoutout.create',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CHANNEL_SHOUTOUT_RECEIVE: {
    type: 'channel.shoutout.receive',
    version: '1',
    condition: ['broadcaster_user_id', 'moderator_user_id'],
  },
  CONDUIT_SHARD_DISABLED: {
    type: 'conduit.shard.disabled',
    version: '1',
    condition: ['client_id', 'conduit_id'],
  },
  DROP_ENTITLEMENT_GRANT: {
    type: 'drop.entitlement.grant',
    version: '1',
    condition: ['organization_id'],
  },
  EXTENSION_BITS_TRANSACTION_CREATE: {
    type: 'extension.bits_transaction.create',
    version: '1',
    condition: ['extension_client_id'],
  },
  CHANNEL_GOAL_BEGIN: {
    type: 'channel.goal.begin',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_GOAL_PROGRESS: {
    type: 'channel.goal.progress',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  CHANNEL_GOAL_END: {
    type: 'channel.goal.end',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  STREAM_ONLINE: {
    type: 'stream.online',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  STREAM_OFFLINE: {
    type: 'stream.offline',
    version: '1',
    condition: ['broadcaster_user_id'],
  },
  USER_AUTHORIZATION_GRANT: {
    type: 'user.authorization.grant',
    version: '1',
    condition: ['client_id'],
  },
  USER_AUTHORIZATION_REVOKE: {
    type: 'user.authorization.revoke',
    version: '1',
    condition: ['client_id'],
  },
  USER_UPDATE: {
    type: 'user.update',
    version: '1',
    condition: ['user_id'],
  },
  USER_WHISPER_MESSAGE: {
    type: 'user.whisper.message',
    version: '1',
    condition: ['user_id'],
  },
} as const

export type TwitchEventType = typeof twitchEvents[keyof typeof twitchEvents]['type']
export type TwitchEventVersion = typeof twitchEvents[keyof typeof twitchEvents]['version']
export type TwitchEventCondition = typeof twitchEvents[keyof typeof twitchEvents]['condition'][number]

export type TwitchEvent = typeof twitchEvents[keyof typeof twitchEvents]
export type TwitchEventMap = Partial<Record<TwitchEventType, TwitchEvent>>

import { Center } from '@chakra-ui/react'
import { AnimatedPoint } from 'icons/AnimatedPoint'
import { useLottie } from 'lottie-react'
import {
  FC,
  useEffect, useState,
} from 'react'
import { usePredictionConfig } from '../../../Config/hooks'
import anim from './splashAnim.json'

export const Splash: FC<{ active: boolean }> = ({ active }) => {
  const [opacity, setOpacity] = useState(1)
  const options = {
    animationData: anim,
    onComplete: () => {
      setOpacity(0)
    },
  }
  const { config } = usePredictionConfig()

  const scale = config?.scale || 1.6

  const { View, goToAndPlay } = useLottie({
    ...options,
    width: '100%',
  })

  useEffect(() => {
    if (active) {
      const t = setTimeout(() => {
        setOpacity(1)
        // setSpeed(0.8)
        goToAndPlay(0)
      }, 800)
      return () => clearTimeout(t)
    }
    return () => {}
  }, [active, goToAndPlay])
  return (
    <>
      <Center
        opacity={opacity}
        zIndex={2}
        position='absolute'
        bottom={0}
        transition='opacity 133ms'
        transform='translateY(25%)'
        w={`${scale * 170}%`}
      >
        {View}
      </Center>
      <AnimatedPoint scale={scale} active={active} />
    </>
  )
}

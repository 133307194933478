import authentication from '@feathersjs/authentication-client'
import { feathers } from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import { CONTESTS_SOCKETIO_HOST, CONTESTS_SOCKETIO_PATH } from './constants'

export const createSocket = (broadcasterId: string) => {
  const socket = io(CONTESTS_SOCKETIO_HOST, { path: `/${broadcasterId}/${CONTESTS_SOCKETIO_PATH}`, retries: 1 })
  const client = feathers()

  client.configure(socketio(socket))
  client.configure(authentication())
  return client
}

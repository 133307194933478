import { getPredictionDocPath, Prediction } from '@elan-twitch/shared'
import { truncateNumber } from '@elan-twitch/shared/utils'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { FadeInText } from 'components/shared/FadeIn'
import { useDocument } from 'hooks/db'
import { usePrevious } from 'hooks/usePrevious'
import { useProcessedPrediction } from 'hooks/useProcessedPrediction'
import { useTimeout } from 'hooks/useTimeout'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Voter } from 'icons/Voter'
import { FC, useContext, useMemo } from 'react'
import { usePredictionConfig } from '../../Config/hooks'
import { RLOutcome } from './RLOutcome'

const spots = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

export const RLPrediction: FC<{ data?: Prediction | null }> = ({
  data: _prediction,
}) => {
  const {
    broadcaster,
  } = useContext(BroadcasterContext)
  const { config } = usePredictionConfig()
  const predictionPath = useMemo(
    () => (broadcaster ? getPredictionDocPath(broadcaster.twitchId) : undefined),
    [broadcaster],
  )
  const { data } = useDocument<Prediction>(predictionPath)
  const prediction = useMemo(
    () => (_prediction !== undefined ? _prediction || undefined : data || undefined),
    [_prediction, data],
  )

  const { scale } = config
  const wasActive = usePrevious(prediction && !prediction?.winning_outcome_id)
  const showWinner = useTimeout(
    wasActive && prediction?.winning_outcome_id,
    7000,
  )
  const displayed = useMemo(
    () => (!!prediction && (!prediction.ended_at || showWinner)
      ? prediction
      : undefined),
    [showWinner, prediction],
  )
  const { title = '', winning_outcome_id } = displayed || {}
  const { withRank, totalPoints, totalVoters } = useProcessedPrediction(displayed)

  return (
    <div
      style={{
        position: 'absolute',
        overflow: 'visible',
        bottom: `${scale * 10}px`,
        right: `${scale * 10}px`,
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
      }}
    >
      <FadeInText
        style={{
          position: 'relative',
          top: `${scale * 0.4}rem`,
          fontSize: `${scale * 0.6}rem`,
          paddingRight: `${scale * 0.4}rem`,
          display: 'flex',
          fontFamily: 'Bakbak One',
          height: displayed ? `${scale * 12}px` : 0,
          transition: `all 500ms ease ${
            displayed ? 1000 + withRank.length * 600 : 0
          }ms`,
          alignItems: 'center',
        }}
      >
        {displayed ? (
          <>
            <ChannelPoint
              style={{
                height: `${scale * 8}px`,
                marginRight: `${scale * 0.2}rem`,
                filter: 'drop-shadow(1px 1px 3px black)',
              }}
            />
            <span style={{ height: `${scale * 13}px` }}>
              {truncateNumber(totalPoints)}
            </span>
            <Voter
              style={{
                height: `${scale * 12}px`,
                marginLeft: `${scale * 0.3}rem`,
                filter: 'drop-shadow(1px 1px 3px black)',
              }}
            />
            <span
              style={{
                height: `${scale * 13}px`,
                position: 'relative',
                right: `${scale * 0.1}rem`,
              }}
            >
              {truncateNumber(totalVoters)}
            </span>
          </>
        ) : null}
      </FadeInText>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          top: `${scale * 0.3}rem`,
          paddingRight: `${scale * 0.4}rem`,
        }}
      >
        <FadeInText
          style={{
            fontSize: `${scale * 0.8}rem`,
            fontStyle: 'italic',
            fontFamily: 'Bakbak One',
          }}
        >
          {title.toUpperCase()}
        </FadeInText>
        <FadeInText
          style={{
            fontSize: `${scale * 0.8}rem`,
            fontStyle: 'italic',
            overflow: 'hidden',
            fontFamily: 'Bakbak One',
            marginLeft: `${scale * 4}px`,
            whiteSpace: 'nowrap',
            opacity: winning_outcome_id ? 1 : 0,
            width: winning_outcome_id ? `${scale * 60}px` : 0,
          }}
        >
          - WINNER
        </FadeInText>
      </div>
      {spots.map((_, i) => (
        <RLOutcome
          key={i}
          winner={winning_outcome_id}
          index={i}
          outcomes={withRank}
        />
      ))}
      <FadeInText
        style={{
          fontSize: `${scale * 0.6}rem`,
          position: 'relative',
          fontFamily: 'Bakbak One',
          top: `${scale * 2}px`,
          paddingRight: `${scale * 0.4}rem`,
          opacity: !winning_outcome_id && displayed?.locked_at ? 0.8 : 0,
          height:
            !winning_outcome_id && displayed?.locked_at ? `${scale * 20}px` : 0,
        }}
      >
        {displayed?.locked_at ? 'PREDICTIONS LOCKED' : ''}
      </FadeInText>
    </div>
  )
}

import { OutcomeWithRank } from '@elan-twitch/shared'
import { truncateNumber } from '@elan-twitch/shared/utils'
import { animated, easings, useSpring } from '@react-spring/web'
import { SvgTexture } from 'components/App/Svg/SvgTexture'
import { useBarColors } from 'components/App/Svg/useBarColors'
import { useTexture } from 'components/App/Svg/useTexture'
import { FadeInText } from 'components/shared/FadeIn'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Trophy } from 'icons/Trophy'
import { Voter } from 'icons/Voter'
import { FC, useMemo } from 'react'
import { usePredictionConfig } from '../../Config/hooks'
import { WinnerPanel } from './WinnerPanel'

const OUTCOME_HEIGHT = 48
const getDividerPath = (
  numBars: number,
  startAt: number,
  endAt: number,
  angle: number,
) => {
  let d = `M ${startAt} 0 `
  const delta = (endAt - startAt) / numBars
  const segmentDelta = 100 * Math.tan(angle)
  for (let i = 1; i < numBars; i += 1) {
    d += `M ${startAt + delta * i + segmentDelta} 0 L ${
      startAt + delta * i
    } 100 `
  }
  return d
}

const OUTER_PATH = 'M 737.762 85.737 L 17.528 85.737 C 14.03 85.416 11.492 82.492 13.028 79.537 L 42.646 20.019 C 46.346 14.623 51.051 14.118 59.546 13.719 L 777.419 13.719 C 782.427 13.818 783.653 16.727 781.919 19.919 L 754.662 79.437 C 749.345 86.304 744.614 85.455 737.762 85.737 Z'

const BANNER_PATH = 'M 734.282 83.417 L 23.329 83.417 C 19.831 83.096 17.293 80.172 18.829 77.217 L 48.447 22.919 C 52.147 17.523 56.852 17.018 65.347 16.619 L 773.939 16.619 C 778.947 16.718 780.173 19.627 778.439 22.819 L 751.182 77.117 C 745.865 83.984 741.134 83.135 734.282 83.417 Z'

const INNER_PATH = 'M 725.995 72.717 L 42.984 72.717 C 38.63 72.717 35.299 71.549 37.477 67.524 L 54.698 36.944 C 59.366 28.225 61.233 25.715 69.211 25.715 L 754.871 27.05 C 759.879 27.149 761.105 30.058 759.371 33.25 L 742.895 66.417 C 737.578 73.284 732.847 72.435 725.995 72.717 Z'

const DIVIDER_PATH = getDividerPath(16, 20, 730, Math.PI / 6.8)

export const OWOutcome: FC<{
  outcomes: OutcomeWithRank[]
  index: number
  winner?: string | null
}> = ({ outcomes, winner, index }) => {
  const outcome = useMemo(() => outcomes[index], [outcomes, index]) as
    | OutcomeWithRank
    | undefined

  const { percent = 0 } = outcome || {}

  const { config } = usePredictionConfig()

  const {
    darkMode, scale = 1.6, texture,
  } = config || {}
  // const { scale = 1.6 } = theme || {}
  const { color1, color2 } = useBarColors(config, index, outcomes.length, !!outcome)
  const isWinner = useMemo(
    () => !!winner && winner === outcome?.id,
    [outcome, winner],
  )

  const { mask } = useSpring({
    mask: isWinner ? OUTER_PATH : INNER_PATH,
    delay: isWinner ? 500 : 0,
    config: { duration: 333, easing: easings.easeOutCubic },
  })

  const leftVal = useMemo(() => {
    if (!isWinner) return 750 - 750 * percent
    return darkMode ? 750 : 0
  }, [isWinner, darkMode, percent])
  const { left } = useSpring({
    left: leftVal,
    config: { duration: 1000, easing: easings.easeOutCubic },
  })

  const show = useMemo(
    () => outcome && (!winner || outcome.id === winner),
    [winner, outcome],
  )

  const { title = '' } = outcome || {}
  const marginLeft = useMemo(() => `${scale * 0.2}rem`, [scale])
  const textureData = useTexture(texture)
  const textureId = useMemo(
    () => (outcome ? `texture-${outcome.id}` : `texture-${texture}`),
    [outcome, texture],
  )
  return (
    <div
      style={{
        height: show ? OUTCOME_HEIGHT * scale : 0,
        transition: `height 750ms cubic-bezier(0.83, 0, 0.17, 1) ${
          show ? 600 * (index + 1) : 0
        }ms`,
        display: 'flex',
        flexFlow: 'column',
        overflow: 'visible',
        pointerEvents: 'none',
        alignItems: 'flex-end',
        fontFamily: 'Bebas Neue',
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          opacity: show ? 1 : 0,
          transition: `all 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
            show ? 600 * (index + 1) + 1000 : 0
          }ms`,
          width: '100%',
          top: `${scale * 5}px`,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            filter: 'drop-shadow(1px 1px 2px black)',
            fontSize: `${scale * 0.7}rem`,
            transform: `translate(0, ${show ? 0 : 10}px)`,
          }}
        >
          <ChannelPoint
            // fill={color1}
            style={{
              width: `${scale * 10}px`,
              top: '1px',
              marginLeft: `${scale * 1.2}rem`,
              filter: 'drop-shadow(1px 1px 2px black)',
            }}
          />
          <span
            style={{
              opacity: outcome?.users ? 1 : 0,
              width: `${scale * 28}px`,
              marginLeft,
              whiteSpace: 'nowrap',
              lineHeight: 1.2,
              height: `${scale * 13}px`,
              textShadow: '1px 1px 3px black',
              color: 'white',
              transition: 'all 500ms',
            }}
          >
            {outcome?.channel_points
              ? truncateNumber(outcome.channel_points)
              : ''}
          </span>

          <Voter
            style={{
              width: `${scale * 16}px`,
              filter: 'drop-shadow(1px 1px 2px black)',
            }}
          />
          <span
            style={{
              opacity: outcome?.users ? 1 : 0,
              width: `${scale * 28}px`,
              color: 'white',
              textShadow: '1px 1px 3px black',
              lineHeight: 1.2,
              height: `${scale * 13}px`,
              whiteSpace: 'nowrap',
              transition: 'all 500ms',
            }}
          >
            {truncateNumber(outcome?.users || 0)}
          </span>
          <Trophy
            fill='white'
            style={{
              width: `${scale * 10}px`,
              top: '1px',
              filter: 'drop-shadow(0 0 2px black)',
              opacity: 0.9,
            }}
          />
          <span
            style={{
              opacity: outcome?.percent ? 1 : 0,
              marginLeft,
              whiteSpace: 'nowrap',
              lineHeight: 1.2,
              color: 'white',
              textShadow: '1px 1px 3px black',
              height: `${scale * 13}px`,
              width: `${scale * 28}px`,
              transition: 'all 500ms',
            }}
          >
            {outcome?.percent ? `${(1 / outcome.percent).toFixed(1)}x` : ''}
          </span>
        </div>
        <FadeInText
          style={{
            fontSize: `${scale * 0.8}rem`,
            flex: 1,
            textAlign: 'right',
            paddingRight: `${scale * 0.5}rem`,
            transition: `all 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
              show ? 600 * (index + 1) + 333 : 0
            }ms`,
          }}
        >
          {show ? title : ''}
        </FadeInText>
      </div>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          // width: '100%',
          transition: 'height 500ms ease',
        }}
      >
        <svg
          viewBox='0 0 800 100'
          style={{
            height: `${30 * scale}px`,
            opacity: show ? 1 : 0,
            transition: `opacity 500ms cubic-bezier(0.83, 0, 0.17, 1) ${
              show ? 600 * (index + 1) + 333 : 0
            }ms`,
            position: 'relative',
            overflow: 'visible',
          }}
        >
          <defs>
            {textureData ? (
              <SvgTexture
                height={60}
                y={20}
                width={800}
                texture={textureData}
                id={textureId}
              />
            ) : null}
            <filter id='owblur' x='-1' y='-1' width='250%' height='250%'>
              <feOffset result='offOut' in='SourceAlpha' dx='5' dy='5' />
              <feGaussianBlur result='blurOut' in='offOut' stdDeviation='10' />
              <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
            </filter>
            <clipPath id={`cutoff-${index}`}>
              <animated.path d={mask} />
            </clipPath>
            <linearGradient id={`bar-${index}`} x1='0' y1='0' x2='0' y2='1'>
              <animated.stop offset='0%' stopColor={color1} />
              <animated.stop offset='100%' stopColor={color2} />
            </linearGradient>
          </defs>
          <path
            filter='url(#owblur)'
            fill={darkMode ? `url(#bar-${index})` : 'rgba(255,255,255,0.9)'}
            d={BANNER_PATH}
          />
          {/* <rect
          x={0}
          y={0}
          width={1000}
          height={100}
          fill='url(#bar)'
          clipPath='url(#cutoff)'
        /> */}
          <path
            d='M 0 50 L 1000 50'
            strokeWidth='65'
            // filter='url(#owblur)'
            stroke={darkMode ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.25)'}
            style={{ opacity: isWinner ? 0 : 1, transition: 'opacity 500ms' }}
            clipPath={`url(#cutoff-${index})`}
          />

          <path
            d={INNER_PATH}
            fill={'rgba(0,0,0,0.4)'}
            filter={textureData ? `url(#${textureId})` : 'none'}
          />

          <animated.path
            d={left.to((l) => `M 800 0 L ${l + 50} 0 L ${l} 100 L 800 100 Z`)}
            // filter='url(#owblur)'
            filter={textureData ? `url(#${textureId})` : 'none'}
            fill={darkMode ? 'rgba(0,0,0,0.6)' : `url(#bar-${index})`}
            // fill={`url(#bar-${index})`}
            clipPath={`url(#cutoff-${index})`}
          />
          <path
            d={INNER_PATH}
            strokeWidth='6'
            fill='none'
            style={{ opacity: isWinner ? 0 : 1, transition: 'opacity 500ms' }}
            // filter='url(#owblur)'
            stroke={darkMode ? 'rgba(0,0,0,0.25)' : 'rgba(0,0,0,0.25)'}
            clipPath={`url(#cutoff-${index})`}
          />
          <path
            d={DIVIDER_PATH}
            strokeWidth='2'
            style={{
              opacity: isWinner ? 0 : 1,
              transition: `opacity 500ms ease ${isWinner ? 250 : 0}ms`,
            }}
            fill='none'
            // stroke='red'
            // filter='url(#owblur)'
            stroke={darkMode ? `url(#bar-${index})` : 'rgba(255,255,255,0.5)'}
            clipPath={`url(#cutoff-${index})`}
          />
        </svg>
        <WinnerPanel outcome={outcome} isWinner={isWinner} />
      </div>
    </div>
  )
}

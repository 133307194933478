import { useEffect, useRef } from 'react'

const formatMsAsHoursMinutesSeconds = (ms: number) => {
  const hours = Math.floor(ms / 3600000)
  const minutes = Math.floor((ms % 3600000) / 60000)
  const seconds = Math.floor(((ms % 3600000) % 60000) / 1000)
  if (hours) return `${hours}h, ${minutes}m, ${seconds}s`
  if (minutes) return `${minutes}m, ${seconds}s`
  return `${seconds}s`
}

export const TimeElapsed = ({ since }: { since?: string | number | null }) => {
  const spanRef = useRef<HTMLSpanElement>(null)

  const helperDate = useRef(new Date())
  useEffect(() => {
    if (!since) return () => {}
    const interval = setInterval(() => {
      if (spanRef.current) {
        helperDate.current = new Date(since)
        spanRef.current.innerText = formatMsAsHoursMinutesSeconds(
          Math.max(0, Date.now() - helperDate.current.getTime()),
        )
      }
    }, 1000)
    if (spanRef.current) {
      helperDate.current = new Date(since)
      spanRef.current.innerText = formatMsAsHoursMinutesSeconds(
        Math.max(0, Date.now() - helperDate.current.getTime()),
      )
    }
    return () => clearInterval(interval)
  }, [since])

  return (
    <span ref={spanRef}>
    </span>
  )
}

import {
  IFormElement, ImageViewOptions, processData, requiresBaseStoragePath, UploadsProgressEvent,
} from '@elan-twitch/shared'
import { useCallback, useMemo, useState } from 'react'
import { uploadFileValue } from '../../backend/storage'

export const useProcessData = (field: IFormElement, baseStoragePath: string | null | undefined) => {
  const [uploads, setUploads] = useState<UploadsProgressEvent>({})

  const process = useCallback((data: any) => {
    if (!baseStoragePath && requiresBaseStoragePath(field)) {
      throw new Error('baseStoragePath is required')
    }
    setUploads({})
    return processData(field, data, uploadFileValue, baseStoragePath, setUploads)
  }, [baseStoragePath, field])
  return { process, uploads }
}
export const useImageSize = (options?: ImageViewOptions, nativeSize?: { width: number; height: number }) => useMemo(
  () => {
    const {
      maxHeight, maxWidth, shape, width: optWidth, height: optHeight,
    } = options || {}

    if (optHeight && optWidth) {
      return {
        width: optWidth,
        height: optHeight,
      }
    }
    const { width, height } = nativeSize || {}

    if (!options && !(height && width)) {
      return null
    }
    if (shape) {
      const size = Math.min(width || Infinity, height || Infinity, maxHeight || Infinity, maxWidth || Infinity, 100)
      return {
        width: size,
        height: size,
      }
    }
    const fallbackHeight = typeof height === 'number' ? height : 100
    const fallbackWidth = typeof width === 'number' ? width : 100
    const cappedHeight = maxHeight ? Math.min(fallbackHeight, maxHeight) : fallbackHeight
    const cappedWidth = maxWidth ? Math.min(fallbackWidth, maxWidth) : fallbackWidth
    const hScale = cappedHeight / fallbackHeight
    const wScale = cappedWidth / fallbackWidth
    const scale = Math.min(hScale, wScale)
    return {
      width: fallbackWidth * scale,
      height: fallbackHeight * scale,
    }
  },
  [options, nativeSize],
)

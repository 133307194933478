import {
  Box,
  HStack,
  IconButton,
  Image, Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  Broadcaster,
  Field,
  FieldMap,
  FieldMapValue,
  NumberField,
  RecordField,
  SelectField,
  WheelAuction,
  WithId,
} from '@elan-twitch/shared'
import { CloseButton } from 'components/shared/Buttons'
import { ShadowText } from 'components/shared/ShadowText'
import { FORM_ERROR } from 'final-form'
import { Form } from '../../forms/Form'
import { outcomeColors, paletteOptions } from './constants'
import { createWheelAuction } from './utils'

const titleField: Field = {
  _type: 'text',
  placeholder: 'Title',
}

const wheelAuctionOutcomesField: RecordField = {
  _type: 'record',
  placeholder: 'Outcomes',
  itemField: {
    name: 'Outcome',
    children: {
      title: titleField,
    },
  },
}

type ColorOption = {hex: string[], id: string}
const colorPaletteField: SelectField<ColorOption> = {
  _type: 'select',
  options: paletteOptions.map((hex, index) => ({ id: `${index}`, hex })),
  placeholder: 'Color palette',
  getOptionKey: ({ id }) => id,
  renderOption: ({ hex }) => (
    <HStack
      _hover={{ boxShadow: '0 0 2px white' }}
      borderRadius={4}
      overflow='hidden'
      spacing={0}
      w='100%'
    >
      {hex.map((color: string) => (
        <Box flex={1} h='30px' key={color} bg={color} />
      ))}
    </HStack>
  ),
}

const outcomeBasePointsField: NumberField = {
  _type: 'number',
  placeholder: 'Outcome base points',
  validate: (v) => {
    const asNum = Number(v)
    if (Number.isNaN(asNum)) return 'Outcome base points must be a number'
    if (!asNum) return 'Outcome base points is required'
    if (asNum < 1) return 'Outcome base points must be at least 1'
    return undefined
  },
}

const wheelAuctionField: FieldMap = {
  name: 'Wheel Auction',
  children: {
    title: titleField,
    outcomes: wheelAuctionOutcomesField,
    outcomeBasePoints: outcomeBasePointsField,
    colorPalette: colorPaletteField,
  },
}

const handleWheelAuctionSubmit = async (
  broadcaster: WithId<Broadcaster>,
  values: FieldMapValue,
) => {
  if (!values.title) throw new Error('Title is required')
  if (!values.outcomeBasePoints) {
    throw new Error('Outcome base points is required')
  }

  const outcomesAsArray = Array.isArray(values.outcomes)
    ? values.outcomes
    : null

  if (!outcomesAsArray) throw new Error('Outcomes are required')
  if (outcomesAsArray.length < 2) {
    throw new Error('At least two outcomes are required')
  }

  const outcomeBasePoints = Number(values.outcomeBasePoints)
  if (Number.isNaN(outcomeBasePoints)) {
    throw new Error('Outcome base points must be a number')
  }

  const outcomeTitles = new Set(outcomesAsArray.map((o) => o.title))
  if (outcomeTitles.size !== outcomesAsArray.length) {
    throw new Error('Outcome titles must be unique')
  }

  const now = new Date().toISOString()
  const auction: WheelAuction = {
    created_at: now,
    outcomeBasePoints,
    spun_at: null,
    title: values.title,
    outcomes: outcomesAsArray.map(({ title }, i) => ({
      title,
      color: outcomeColors[i],
      points: 0,
      numBids: 0,
      topBids: [],
    })),
    duration: values.duration,
    ended_at: null,
    saved_on: null,
    winningOutcomeIndex: null,
    resultTheta: null,
    previousResults: [],
    started_at: now,
    spin_rested_at: null,
    palette: values.colorPalette,
    updated_at: now,
  }
  return createWheelAuction(broadcaster, auction)
    .then(() => undefined)
    .catch((err) => {
      console.error(err)
      return { [FORM_ERROR]: err.message }
    })
}

const initialValues: FieldMapValue = {
  title: '',
  outcomes: [],
  outcomeBasePoints: 1000,
  duration: '',
  colorPalette: '2',
}

export const CreateWheelAuction = ({
  enabled,
  broadcaster,
  onSwitch,
}: {
  enabled: boolean
  broadcaster: WithId<Broadcaster>
  onSwitch: (mode: 'load' | null) => void
}) => (
  <VStack
    w='500px'
    opacity={enabled ? 1 : 0}
    transition='all 400ms'
    pointerEvents={enabled ? 'auto' : 'none'}
  >
    <VStack w='100%' p={4} borderRadius={5} boxShadow='md' bg='#323232'>
      <HStack px={2} spacing={0} w='100%'>
        <ShadowText style={{ flex: 1, fontFamily: 'Bebas Neue', fontSize: '1.4rem' }} >
          New Wheel
        </ShadowText>
        <Tooltip
          bg='blackAlpha.700'
          placement='top'
          hasArrow
          fontSize='lg'
          fontFamily='Bebas Neue'
          label='LOAD SAVED WHEEL'
        >
          <IconButton
            aria-label='Load'
            ml='auto'
            fontFamily='Bebas Neue'
            onClick={() => onSwitch('load')}
            size='sm'
            bg='whiteAlpha.400'
            _hover={{ bg: 'whiteAlpha.600' }}
            opacity={0.8}
            icon={<Image filter='invert(100%)' opacity={0.6} src='/assets/images/history.svg' />}
            fontSize='1rem'
          />
        </Tooltip>
        <CloseButton onClick={() => onSwitch(null)} />
      </HStack>
      <Box w='100%' maxH='600px' overflowY='auto'>
        {enabled ? (
          <Form
            value={initialValues}
            buttonText='Start'
            field={wheelAuctionField}
            onSubmit={(v) => handleWheelAuctionSubmit(broadcaster, v)}
          />
        ) : null}
      </Box>
    </VStack>
  </VStack>
)

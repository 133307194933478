import {
  Button, Flex, Menu, MenuButton, MenuItem, MenuList, Portal, Text, useColorMode, useColorModeValue, useDisclosure,
} from '@chakra-ui/react'
import { getFieldParse, InputRef, SelectField } from '@elan-twitch/shared'
import {
  FC, ForwardedRef, forwardRef, useImperativeHandle, useMemo,
} from 'react'
import { InputProps } from '../types'

const BaseSelectComponent = ({ field, input }: InputProps<SelectField>, ref: ForwardedRef<InputRef>) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    placeholder, label, options, renderOption, getOptionKey,
  } = field
  const { onChange, value } = input
  const { colorMode } = useColorMode()
  const parse = useMemo(() => getFieldParse(field) || ((v: any) => v), [field])
  const selected = useMemo(() => value && options.find((o) => parse(o) === value), [value, options, parse])
  useImperativeHandle(ref, () => ({
    focus: () => {
      onOpen()
    },
    blur: () => {
      onClose()
    },
  }))
  const placeholderBorderColor = useColorModeValue('#ababab', '#777777')
  return (
    <Flex w='100%'>
      {label ? <Text textAlign='left'>{label}</Text> : null}
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          fontWeight={500}
          // height={14}
          w='100%'
          // style={{padding: '0'}}
          onClick={(e) => {
            e.stopPropagation()
            onOpen()
          }}
          opacity={value ? 1 : 0.5}
          variant='unstyled'
          as={Button}
          borderRadius={6}
          border={value ? 'none' : `1px dashed ${placeholderBorderColor}`}
          position='relative'
        >
          {selected ? renderOption(selected, colorMode) : placeholder}
        </MenuButton>
        <Portal>
          <MenuList shadow='md' display='flex' flexFlow='column' maxH='300px' overflowY='auto'>
            {options.map((o) => (
              <MenuItem px={2} py={1} _hover={{ bg: 'whiteAlpha.200' }} bg='transparent' key={getOptionKey ? getOptionKey(o) : o} onClick={() => onChange(o)}>
                {renderOption(o, colorMode)}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  )
}

export const SelectComponent = forwardRef<InputRef, InputProps<SelectField>>(BaseSelectComponent as any) as FC<
InputProps<SelectField>
>

import { DefaultSelectOption, SelectField } from '../types/forms'

import { gradients } from '../constants/colors'
import {
  GradientKey,
} from '../types'
import { getColor } from '../utils/colors'

export const gradientField: SelectField<GradientKey> = {
  _type: 'select',
  placeholder: 'Color Theme',
  options: Object.keys(gradients) as GradientKey[],
  getOptionKey: (o) => o,
  renderOption: (o) => {
    const c0 = getColor(o, 0)
    const c1 = getColor(o, 1)
    return (
          <div
            style={{
              alignItems: 'center',
              padding: '0.25rem 0.5rem',
              borderRadius: '1rem',
              minHeight: '24px',
              width: '100%',
              color: 'white',
              textShadow: '1px 1px 4px black',
              boxShadow: '1px 1px 4px rgba(0,0,0,0.5)',
              background: `linear-gradient(90deg, ${c0} 0%, ${c1} 100%)`,
            }}
          >
            {o.toUpperCase()}
          </div>
    )
  },
}

const textureFieldOptions: DefaultSelectOption[] = [
  { id: 'none', text: 'None' },
  { id: 'fbm', text: 'FBM' },
  { id: 'elevated', text: 'Elevated' },
]

export const textureField: SelectField<string, DefaultSelectOption> = {
  _type: 'select',
  condition: (v) => ['wow', 'ow'].includes(v?.version || ''),
  placeholder: 'Texture',
  getOptionKey: (o) => o.id,
  options: textureFieldOptions,
  parse: (v) => v.id,
  renderOption: ({ text, id }) => (
    <div
      style={{
        alignItems: 'center',
        padding: '0.25rem 0.7rem',
        display: 'flex',
        minHeight: '24px',
        transition: 'background 500ms',
        justifyContent: 'center',
        borderRadius: '1rem',
        overflow: 'hidden',
        background: '#333',
        fontSize: '1rem',
        width: '100%',
        position: 'relative',
      }}
    >
      {id !== 'none' ? (
        <img
          src={`/assets/textures/${id}.png`}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 0,
            objectFit: 'cover',
            mixBlendMode: 'multiply',
            left: 0,
            top: 0,
          }}
        />
      ) : null}
      <span
        style={{
          zIndex: 2,
          // fontFamily: 'Bebas Neue',
          color: 'white',
          textShadow: '1px 1px 4px black',
        }}
      >
        {text}
      </span>
    </div>
  ),
}

import {
  CircularProgress, Flex, StackProps, Text,
} from '@chakra-ui/react'
import { ContentBox } from './ContentBox'

export const Loading = ({
  text = 'Loading...',
  stackProps,
  noBox,
}: {
  text?: string
  stackProps?: StackProps
  noBox?: boolean
}) => {
  const body = (
    <>
      <CircularProgress size={5} isIndeterminate color='gray.500' />
      <Text opacity={0.8}>{text}</Text>
    </>
  )
  return noBox ? (
    <Flex gap={2}>
      {body}
    </Flex>
  ) : (
    <ContentBox gap={2} {...stackProps}>
      {body}
    </ContentBox>
  )
}

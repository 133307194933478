import { Flex } from '@chakra-ui/react'
import { getColor, OutcomeWithRank, truncateNumber } from '@elan-twitch/shared'
import {
  animated, easings, Interpolation, useSpring,
} from '@react-spring/web'
import { useMounted } from 'hooks/useMounted'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Trophy } from 'icons/Trophy'
import { Voter } from 'icons/Voter'
import {
  CSSProperties, PropsWithChildren,
  useMemo,
} from 'react'
import { usePredictionConfig } from '../../../Config/hooks'

const OutcomeBar = ({
  outcome,
  index,
  colorBar,
  total,
}: {
  outcome?: OutcomeWithRank
  index: number
  colorBar: boolean
  total: number
}) => {
  const { config } = usePredictionConfig()
  const { gradient = 'blue', barOpacity = 1 } = config || {}
  // const color = useMemo(() => `rgb(${120-90*(index/total)},
  // ${80 + 36*(index/total)}, ${190 + 45*(index/total)}, 1)`, [index, total])
  const color = useMemo(() => (total ? getColor(gradient, index / total) : '#00000000'), [index, total, gradient])
  const {
    progress,
    bg,
    inner,
    barOpacity: opak,
  } = useSpring({
    // progress: outcome ? (outcome.channel_points /outcome.maxPoints) : 0,
    progress: outcome?.percent || 0,
    inner: outcome && colorBar ? '#00000088' : color,
    bg: outcome && colorBar ? color : '#000000ee',
    barOpacity,
    delay: Math.abs(5 - index) * 66,
  })
  return (
    <svg style={{ width: '100%' }} viewBox='0 -6 100 12'>
      <animated.path strokeLinecap='round' d='M 5 0 L 95 0' opacity={0.6} stroke={bg} strokeWidth='10' />
      <animated.path
        strokeLinecap='round'
        d={progress.to((p) => `M 5 0 L ${5 + p * 85} 0`)}
        stroke={inner}
        opacity={opak}
        strokeWidth='8'
      />
    </svg>
  )
}

const BarText = ({ children, style }: PropsWithChildren<{ style?: CSSProperties }>) => (
  <span
    style={{
      color: textColor,
      fontSize: '1.1rem',
      position: 'relative',
      top: '2px',
      transition: 'opacity 500ms',
      textShadow: '0 0 5px black',
      fontFamily: 'Bebas Neue',
      ...style,
    }}
  >
    {children}
  </span>
)

const textColor = '#ffffff'
export const OutcomeView = ({
  index,
  outcomes,
  width,
  height,
  colorBar,
  isVisible,
  total,
  position,
}: {
  outcomes: Array<OutcomeWithRank>
  colorBar: boolean
  width: Interpolation<number, number>
  height: Interpolation<number, number>
  isVisible: boolean
  total: number
  index: number
  position: { x: number; y: number }
}) => {
  const { config } = usePredictionConfig()

  const { scale = 1.8 } = config || {}
  const outcome = useMemo(() => outcomes[index], [index, outcomes]) as OutcomeWithRank | undefined
  const mounted = useMounted()
  const show = useMemo(() => mounted && !!outcome && isVisible, [mounted, outcome, isVisible])
  const { xy, opacity } = useSpring({
    xy: [position.x, position.y],
    opacity: show ? 1 : 0,
    config: { duration: 1500, easing: easings.easeOutCubic },
  })
  // const textColor = useMemo(() => textColors[outcome?.color || 'BLUE'] || 'white',
  // [outcome])(outcome.channel_points /outcome.maxPoints)
  const fontSize = useMemo(() => `${scale * 0.6}rem`, [scale])
  const marginRight = useMemo(() => `${scale * 3}px`, [scale])

  const textShadow = useMemo(() => `0 0 ${scale * 3}px black`, [scale])
  return (
    <animated.div
      style={{
        width,
        position: 'absolute',
        left: 0,
        transform: xy.to((x, y) => `translate(${x}px,${y}px)`),
        top: 0,
        height: show ? height : 0,
        overflow: 'hidden',
        opacity,
        color: textColor,
      }}
    >
      <Flex
        style={{
          fontSize: `${scale * 0.7}rem`,
          // fontWeight: 600,
          marginLeft: '0.5rem',
          color: textColor,
          textShadow,
        }}
      >
        {/* <Flex
          style={{
            width: '17px',
            marginRight: '4px',
            height: '17px',
            borderRadius: '16px',
            fontSize: '0.8rem',
            // fontWeight: 600,
            color: textColors[outcome?.color || 'BLUE'],
            // color: 'white',
            textShadow: 'none',
            background: colors[outcome?.color || 'BLUE'],
            justifyContent: 'center',
          }}
        >
          {index + 1}
        </Flex> */}
        {outcome?.title || ''}
      </Flex>
      <Flex style={{ width: '100%', justifyContent: 'center', position: 'relative' }}>
        <OutcomeBar total={total} colorBar={colorBar} index={index} outcome={outcome} />
        <Flex
          style={{
            paddingLeft: `${scale * 5.5}px`,
            width: '100%',
            position: 'absolute',
            fontSize,
            // fontWeight: 600,
            top: `${scale * 6}px`,
            alignItems: 'center',
            whiteSpace: 'nowrap',
            color: textColor,
          }}
        >
          <ChannelPoint
            fill={textColor}
            style={{ width: `${scale * 12}px`, marginRight, top: '1px' }}
            filter='drop-shadow(0 0 5px black)'
          />
          <BarText
            style={{
              opacity: outcome?.users ? 1 : 0,
            }}
          >
            {outcome?.channel_points ? truncateNumber(outcome.channel_points) : ''}
          </BarText>
        </Flex>
        <Flex
          style={{
            width: '100%',
            justifyContent: 'center',
            position: 'absolute',
            fontSize,
            top: `${scale * 6}px`,
            // fontWeight: 600,
            whiteSpace: 'nowrap',
            color: textColor,
          }}
        >
          <Trophy
            fill='white'
            style={{
              width: `${scale * 12}px`,
              marginRight,
              top: '1px',
              opacity: 0.9,
            }}
          />
          <BarText
            style={{
              opacity: outcome?.percent ? 1 : 0,
            }}
          >
            {outcome?.percent ? `${(1 / outcome.percent).toFixed(1)}x` : ''}
          </BarText>
        </Flex>
        <Flex
          style={{
            position: 'absolute',
            right: `${scale * 5}px`,
            top: `${scale * 6}px`,
            color: 'white',
            fontSize,
          }}
        >
          <BarText
            style={{
              opacity: outcome?.users ? 1 : 0,
            }}
          >
            {truncateNumber(outcome?.users || 0)}
          </BarText>
          <Voter stroke='white' style={{ marginLeft: `${scale}px`, width: `${scale * 17}px` }} />
        </Flex>
      </Flex>
    </animated.div>
  )
}

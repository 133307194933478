import { animated, useSpring } from '@react-spring/web'
import {
  CSSProperties,
  FC, useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Hourglass } from '../../icons/Hourglass'
import { FadeInText } from './FadeIn'

export const SVGTimer: FC<{
  startsAt?: number | string | Date | null
  endsAt?: number | string | Date | null
  style?: CSSProperties
  endText: string
}> = ({
  startsAt, endsAt, endText,
}) => {
  // const { gradient, darkMode, scale = 1.6 } = theme || {}
  const label = useRef<HTMLSpanElement>(null)
  const [{ percent }, api] = useSpring(() => ({ percent: 0 }))
  const durationMs = useMemo(() => {
    if (!endsAt || !startsAt) return 0
    const end = new Date(endsAt).getTime()
    const start = Date.now()
    return Math.max(0, end - start)
  }, [startsAt, endsAt])
  const [isElapsed, setIsElapsed] = useState(false)
  useEffect(() => {
    setIsElapsed(false)
    const durationS = Math.min(99, durationMs / 1000)
    api.set({ percent: 1 })
    api.start({
      percent: 0,
      config: { duration: durationMs },
      onChange: ({ value: { percent: newVal } }) => {
        if (label.current) {
          const text = `${(newVal * durationS).toFixed(1)}`
          if (text === '0') {
            label.current.style.width = '0'
            label.current.style.opacity = '0'
            return
          }
          label.current.style.opacity = '1'
          label.current.style.width = '32px'
          label.current.innerText = text
        }
      },
      onRest: () => {
        setIsElapsed(true)
      },
    })
  }, [durationMs, api])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        paddingLeft: '0.5rem',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          opacity: isElapsed ? 0 : 1,
          alignItems: 'center',
          transition: 'opacity 500ms ease',
        }}
      >
        <div
          style={{
            // width: '36px',
            // height: '24px',
            // borderRadius: '14px',
            // background: color1,
            position: 'relative',
            marginRight: '0.1rem',
            paddingLeft: '0.2rem',
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
          }}
        >
          <Hourglass
            style={{
              width: '13px',
              position: 'relative',
              top: '0.5px',
              filter: 'drop-shadow(1px 1px 2px black)',
            }}
          />
          <span
            style={{
              color: 'white',
              textAlign: 'center',
              transition: 'all 333ms',
              lineHeight: 1,
              position: 'relative',
              marginLeft: '0.3rem',
              height: '15px',
              // bottom: '1px',
              fontSize: '19px',
              fontFamily: 'Bebas Neue',
              // textShadow: '0 0 3px black',
              filter: 'drop-shadow(1px 1px 2px black)',
              // textShadow: '0 0 3px black',
            }}
            ref={label}
          />
        </div>

        <svg
          style={{
            flex: 1,
            filter: 'drop-shadow(0 0 2px black)',
          }}
          viewBox='0 0 800 50'
        >
          <defs>
            <filter id='f2' x='-1' y='-1' width='250%' height='250%'>
              <feOffset result='offOut' in='SourceAlpha' dx='5' dy='5' />
              <feGaussianBlur result='blurOut' in='offOut' stdDeviation='10' />
              <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
            </filter>
            <filter id='glow-timer'>
              <feGaussianBlur stdDeviation='5' result='coloredBlur' />
              <feMerge>
                <feMergeNode in='coloredBlur' />
                <feMergeNode in='SourceGraphic' />
              </feMerge>
            </filter>
          </defs>

          <line
            x1='25'
            x2='775'
            y1='25'
            y2='25'
            // opacity={0.5}
            strokeWidth='20'
            stroke='#777'
            strokeLinecap='round'
          />
          <animated.line
            x1='25'
            x2={percent.to((p) => 25 + p * 750)}
            y1='25'
            y2='25'
            // opacity={0.5}
            strokeWidth='15'
            stroke='white'
            strokeLinecap='round'
          />
        </svg>
      </div>

      <FadeInText
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          fontFamily: 'Bebas Neue',
          fontSize: '20px',
          opacity: isElapsed ? 1 : 0,
          transition: 'opacity 500ms ease',
          color: 'white',
          // filter: 'drop-shadow(0 0 px black)',
          textShadow: '0 0 3px black',
        }}
      >
        {endText}
      </FadeInText>
    </div>
  )
}

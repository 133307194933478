/*
from OP

REACT_APP_VODS_API_BASE=https://archive.overpowered.tv/nymn
REACT_APP_GITHUB=https://github.com/OP-Archives/Nymn-site
REACT_APP_TWITCH_ID=62300805
REACT_APP_CHANNEL=Nymn
REACT_APP_CONTESTS_API=https://contests.overpowered.tv/nymn
REACT_APP_CONTESTS_SOCKETIO_HOST=https://contests.overpowered.tv
REACT_APP_CONTESTS_SOCKETIO_PATH=/nymn/socket.io
REACT_APP_IMGUR_CLIENT_ID=546c25a59c58ad7
REACT_APP_START_DATE=2020-11-27
REACT_APP_DEFAULT_DELAY=10800

*/

export const VODS_API_BASE = 'https://archive.overpowered.tv/nymn'
export const APP_GITHUB = 'https://github.com/OP-Archives/Nymn-site'
export const CONTESTS_API = 'https://contests.overpowered.tv'
export const CONTESTS_SOCKETIO_HOST = 'https://contests.overpowered.tv'
export const CONTESTS_SOCKETIO_PATH = '/socket.io'
// export const IMGUR_CLIENT_ID = '546c25a59c58ad7'

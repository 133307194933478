import { Poll } from '@elan-twitch/shared'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { getTestPoll } from '../testData'
import { useMounted } from './useMounted'

const getRandomPoints = (seed: number) => 5000 + Math.round(seed * 300000)
const getRandomUsers = (seed: number) => 300 + Math.round(seed * 600)
const getAddedStats = () => {
  const seed = Math.random()
  return { points: getRandomPoints(seed), voters: getRandomUsers(seed) }
}
export const useTestPoll = (active: boolean) => {
  const [isOver, setIsOver] = useState(false)
  const [numChoices, setNumChoices] = useState(2)
  const poll = useMemo(
    () => getTestPoll(numChoices),
    [numChoices],
  )
  const [choices, set] = useState(poll.choices)
  const [numRounds, setNumRounds] = useState(0)
  const createMoreData = useCallback(() => {
    set(
      choices.map((outcome) => {
        const { voters } = getAddedStats()
        return {
          ...outcome,
          votes: outcome.votes + voters,
        }
      }),
    )
  }, [choices])

  useEffect(() => {
    if (!active) return () => {}
    if (numRounds > 6) {
      setIsOver(true)
      return () => {}
    }
    const t = setTimeout(
      () => {
        if (numRounds < 6) {
          createMoreData()
        }
        setNumRounds(numRounds + 1)
      },
      numRounds > 5 ? 5000 : 2000,
    )
    return () => {
      clearTimeout(t)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numRounds, active])
  // useSpring({
  //   loop: true,
  //   to: { val: 1 },
  //   from: { val: 0 },
  //   config: { duration: 2000 },
  //   onRest: () => createMoreData(),
  // })
  const mounted = useMounted(1000)
  const [startsAt, setStartsAt] = useState(new Date(Date.now()).toISOString())
  const [endsAt, setEndsAt] = useState((new Date(Date.now() + 12000)).toISOString())
  const data = useMemo<Poll | undefined>(
    () => (mounted
      ? {
        ...poll,
        choices,
        started_at: startsAt,
        ends_at: endsAt,
        ended_at: isOver ? '512515' : null,
      }
      : undefined),
    [poll, mounted, isOver, choices, endsAt, startsAt],
  )

  const [restarting, setRestarting] = useState(false)
  useEffect(() => {
    if (restarting) {
      const t = setTimeout(() => {
        setRestarting(false)
      }, 500)
      return () => { clearTimeout(t) }
    }
    return () => {}
  }, [restarting])
  const restart = useCallback(() => {
    setRestarting(true)
    set(poll.choices)
    setNumRounds(0)
    setStartsAt(new Date(Date.now()).toISOString())
    setEndsAt((new Date(Date.now() + 12000)).toISOString())
    setIsOver(false)
  }, [poll])

  return {
    data,
    isLoading: false,
    error: null,
    numChoices,
    setNumChoices,
    toggleShowWinner: () => setIsOver(!isOver),
    showWinner: isOver,
    restart,
  }
}

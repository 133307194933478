import {
  Button, Collapse, Text, VStack,
} from '@chakra-ui/react'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useUser } from 'store/useUser'
import { BroadcasterContext } from '../Broadcasters/context'

export const ChannelSelect = () => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)
  const {
    user, isAdmin, loading, channelAdminOf,
  } = useUser()

  const isAuthorized = useMemo(
    () => !!(
      user
        && (isAdmin || (broadcasterId && channelAdminOf?.includes(broadcasterId)))
    ),
    [isAdmin, broadcasterId, channelAdminOf, user],
  )

  return (
    <Collapse in={!!user && !loading && !isAuthorized}>
      <VStack mb={4}>
        <Text fontSize='sm'>{`ID: ${user?.uid || ''}`}</Text>
        <VStack>
          <Text fontFamily='Encode Sans'>CHANNELS</Text>
          {channelAdminOf?.length
            ? channelAdminOf.map((id) => (
              <Link key={id} to={`/${id}/config`}>
                <Button variant='link'>{id.toUpperCase()}</Button>
              </Link>
            ))
            : null}
        </VStack>
        {/* <Text p={2}>
                    Not authenticated Sadge (talk to dev guy he will get you set
                    up)
                  </Text> */}
      </VStack>
    </Collapse>
  )
}

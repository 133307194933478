import { Collapse, CollapseProps } from '@chakra-ui/react'

export const CollapseCenter = ({
  children,
  in: inProp,
  ...props
}: CollapseProps) => (
      <Collapse
        unmountOnExit
        {...props}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...props.style,
        }}
      in={inProp}>{children}</Collapse>
)

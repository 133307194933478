import { Flex, useColorModeValue } from '@chakra-ui/react'
import { InfiniteScrollList } from '@elan-twitch/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { ListBody } from 'components/shared/DataList/ListBody'
import { DocumentData } from 'firebase/firestore'
import { useInfiniteScrollList } from 'hooks/db'
import {
  ReactNode, useCallback, useMemo, useRef, useState,
} from 'react'
import { useMounted } from '../../../hooks/useMounted'
import { InfiniteScrollListContext, InfiniteScrollListData } from './context'
import { ListFooter } from './ListFooter'
import { ListHeader } from './ListHeader'

export const InfiniteLoaderList = <T extends DocumentData>({
  list,
  height,
  modals,
  width,
}: {
  list: InfiniteScrollList<T>
  height: number
  modals?: ReactNode
  width: number
}) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  const handleHeaderResize = useCallback((entry: ResizeObserverEntry) => {
    const rect = entry.target.getBoundingClientRect()
    setHeaderHeight(rect.height)
  }, [])

  const handleFooterResize = useCallback((entry: ResizeObserverEntry) => {
    const rect = entry.target.getBoundingClientRect()
    setFooterHeight(rect.height)
  }, [])

  useResizeObserver(headerRef, handleHeaderResize)
  useResizeObserver(footerRef, handleFooterResize)
  const { Header } = list

  const bodyHeight = useMemo(() => height - headerHeight - footerHeight, [height, headerHeight, footerHeight])
  const data = useInfiniteScrollList(list)
  const bg = useColorModeValue('gray.50', 'gray.800')
  const contextData = useMemo<InfiniteScrollListData<T>>(
    () => ({
      list,
      data,
    }),
    [list, data],
  )
  const mounted = useMounted()
  return (
    <InfiniteScrollListContext.Provider value={contextData as any}>
      <Flex
        overflow='hidden'
        bg={bg}
        flexFlow='column'
        h={`${height}px`}
        w={`${width}px`}
        opacity={mounted ? 1 : 0}
        transition='opacity 300ms'
      >
        <ListHeader Component={Header} ref={headerRef} />
        <ListBody width={width} height={bodyHeight} data={data} />
        <ListFooter ref={footerRef} />
      </Flex>
      {modals || null}
    </InfiniteScrollListContext.Provider>
  )
}

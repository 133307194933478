import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { forwardRef, useContext, useMemo } from 'react'
import { InfiniteScrollListContext } from './context'

export const ListFooter = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const borderColor = useColorModeValue('#cdcdcd', '#2d3748')
  const {
    data: {
      totalCount, loading, data,
    }, list: { itemType },
  } = useContext(InfiniteScrollListContext)
  const text = useMemo(() => {
    if (totalCount === 0 && !loading) return 'No items'
    if (totalCount) return `Displaying ${data.length} of ${totalCount} ${itemType}s`
    return 'Loading...'
  }, [totalCount, loading, data, itemType])
  return (
    <Flex py={2} px={3} borderTop={`1px solid ${borderColor}`} w='100%' ref={ref}>
      <Text fontSize='sm'>
        {text}
      </Text>
    </Flex>
  )
})

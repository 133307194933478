import { useToast } from '@chakra-ui/react'
import { FileValue, WithId } from '@elan-twitch/shared'
import { FirebaseError } from 'firebase/app'
import {
  CollectionReference,
  DocumentData, DocumentReference,
  Query,
  doc,
  onSnapshot,
} from 'firebase/firestore'
import { getDownloadURL, getMetadata, ref } from 'firebase/storage'
import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { storage } from './storage'

export const useDocumentRef = <T extends DocumentData = DocumentData>(
  collectionRef: CollectionReference<T> | null,
  id: string | null,
) => useMemo(() => {
    if (!collectionRef || !id) return null
    return doc(collectionRef, id) as DocumentReference<T>
  }, [collectionRef, id])

export type UseDocument<T extends DocumentData> = {
  isLoading: boolean
  data: WithId<T> | null
  error: FirebaseError | null
}
export const useDocument = <T extends DocumentData>(
  docRef?: DocumentReference<T> | null,
  refetchWithoutClear?: boolean,
): UseDocument<T> => {
  const [data, setData] = useState<WithId<T> | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<FirebaseError | null>(null)
  useEffect(() => {
    if (!docRef) {
      setIsLoading(false)
      return () => {}
    }
    if (!refetchWithoutClear) {
      setIsLoading(true)
      setData(null)
    }
    return onSnapshot(docRef, {
      next: (snap) => {
        setIsLoading(false)
        const fetched = snap.data()
        if (!fetched) setData(null)
        else setData({ ...fetched, _id: snap.id })
      },
      error: (err) => {
        console.error(err)
        setError(err)
      },
    })
  }, [docRef, refetchWithoutClear])

  return { isLoading, data, error }
}

export const useQuery = <T extends DocumentData>(query: Query<T> | null, refetchWithoutClear?: boolean) => {
  const [data, setData] = useState<Array<WithId<T>>>([])
  const [isLoading, setIsLoading] = useState(!!query)
  const [error, setError] = useState<FirebaseError | null>(null)
  const fetchedOn = useRef<number | null>(null)

  useEffect(() => {
    if (!query) return () => {}
    if (!fetchedOn.current || !refetchWithoutClear) {
      setIsLoading(true)
      setData([])
    }
    fetchedOn.current = Date.now()
    return onSnapshot(query, {
      next: (snap) => {
        setIsLoading(false)
        setData(snap.docs.map((fetchedDoc) => ({ _id: fetchedDoc.id, ...fetchedDoc.data() } as WithId<T>)))
      },
      error: (err) => {
        console.error(err)
        setError(err)
      },
    })
  }, [query, refetchWithoutClear])

  return { isLoading, data, error }
}

export const useQueries = <T extends DocumentData>(queries: Array<Query<T>> | null, refetchWithoutClear?: boolean) => {
  const [rawData, setRawData] = useState<Array<Record<string, T>>>([])
  const [isLoading, setIsLoading] = useState(!!queries)
  const [error, setError] = useState<FirebaseError | null>(null)
  const fetchedOn = useRef<number | null>(null)

  useEffect(() => {
    if (!queries) return () => {}
    if (!fetchedOn.current && !refetchWithoutClear) {
      setIsLoading(true)
      setRawData([])
    }
    fetchedOn.current = Date.now()
    const unsubs = queries.map((query, idx) => onSnapshot(query, {
      next: (snap) => {
        setIsLoading(false)
        setRawData((prev) => {
          const newData = snap.docs.reduce((acc, fetchedDoc) => {
            acc[fetchedDoc.id] = { id: fetchedDoc.id, ...fetchedDoc.data() } as T
            return acc
          }, {} as Record<string, T>)
          const newRawData = [...prev]
          newRawData[idx] = newData
          return newRawData
        })
      },
      error: (err) => {
        console.error(err)
        setError(err)
      },
    }))
    return () => unsubs.forEach((unsub) => unsub())
  }, [queries, refetchWithoutClear])

  const data = useMemo(
    () => {
      const merged = rawData.reduce((acc, curr) => {
        Object.entries(curr).forEach(([key, value]) => {
          acc[key] = value
        })
        return acc
      }, {} as Record<string, T>)
      return Object.entries(merged).map(([id, d]) => ({ id, ...d }))
    },
    [rawData],
  )

  return { isLoading, data, error }
}

export const useFirstDocument = <T extends DocumentData>(
  query: Query<T> | null,
  refetchWithoutClear?: boolean,
): UseDocument<T> => {
  const data = useQuery(query, refetchWithoutClear)
  return useMemo(
    () => ({
      isLoading: data.isLoading,
      data: data.data[0],
      error: data.error,
    }),
    [data],
  )
}

export const getObjectUrl = async (storagePath: string | null) => {
  if (!storagePath) return null
  const storageRef = ref(storage, storagePath)
  return getDownloadURL(storageRef)
}

export const getObjectMetadata = async (storagePath: string | null) => {
  if (!storagePath) return null
  const storageRef = ref(storage, storagePath)
  return getMetadata(storageRef)
}

export const useObjectUrl = (value: FileValue | null | undefined) => {
  const { storagePath, dataUrl } = value || {}
  const [url, setUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  useEffect(() => {
    if (dataUrl) {
      setUrl(dataUrl)
      return
    }
    if (!storagePath) return
    setLoading(true)
    getObjectUrl(storagePath)
      .then((fetchedUrl) => {
        setUrl(fetchedUrl)
        setLoading(false)
      })
      .catch((err) => {
        if (err.code !== 'storage/object-not-found') {
          console.error(err)
          toast({ status: 'error', title: 'Error fetching image' })
        }
        setUrl(null)
        setLoading(false)
      })
  }, [storagePath, toast, dataUrl])

  return { url, loading }
}

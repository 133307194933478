import { Center, HStack, Text } from '@chakra-ui/react'
import { AuctionContext, useAuctionContextData } from 'components/Widgets/Auction/context'
import { usePointsAuction } from 'components/Widgets/Auction/hooks'
import { PointsAuctionVotingStage } from 'components/Widgets/Auction/PointsAuctionVotingStage'
import { useContext } from 'react'
import { useScreen } from '../../../store/useScreen'
import { BroadcasterContext } from '../../App/Broadcasters/context'
import { ShadowText } from '../../shared/ShadowText'

export const PointsAuctionPreview = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { _id: broadcasterId } = broadcaster || {}
  const { width = 0 } = useScreen()
  const { data, isLoading } = usePointsAuction(broadcaster)
  const contextData = useAuctionContextData()
  if (!broadcasterId) {
    return <Text>Error: missing broadcaster ID</Text>
  }

  return (
    <AuctionContext.Provider value={contextData}>
    <Center
      position='absolute'
      top={0}
      left={0}
      w='100%'
      height='100%'
    >
      <HStack
        transition='opacity 400ms'
        pointerEvents='none'
        opacity={isLoading ? 1 : 0}
        position='absolute'
      >
        <ShadowText style={{ fontSize: '1.6rem' }}>Loading...</ShadowText>
      </HStack>
      <Center
        zIndex={2}
        w={isLoading || data ? 0 : '300px'}
        opacity={isLoading || data ? 0 : 1}
        transition={`opacity 400ms ${!data ? '500ms' : '0ms'}, width 400ms ${
          !data ? '0ms' : '500ms'
        }`}
      >
        <HStack>
          <ShadowText
            style={{ fontSize: '1.6rem', whiteSpace: 'nowrap', lineHeight: 1 }}
          >
            WAITING FOR WHEEL AUCTION...
          </ShadowText>
        </HStack>
      </Center>
      <Center
        zIndex={2}
        width={data ? `${width}px` : 0}
        maxW='1000px'
        borderRadius={6}
        opacity={data ? 1 : 0}
        transition={`opacity 400ms ${data ? '500ms' : '0ms'}, width 400ms ${
          data ? '0ms' : '500ms'
        }`}
      >
        <PointsAuctionVotingStage
          readOnly
        />
      </Center>
    </Center>
    </AuctionContext.Provider>
  )
}

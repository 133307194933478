import { usePrevious } from '@chakra-ui/react'
import { getPredictionDocPath, Prediction } from '@elan-twitch/shared'
import { OUTCOME_HEIGHT, OUTCOME_WIDTH } from '@elan-twitch/shared/constants'
import { animated, easings, useSpring } from '@react-spring/web'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { PredictionContext } from 'components/App/Prediction/context'
import { useDocument } from 'hooks/db'
import { useProcessedPrediction } from 'hooks/useProcessedPrediction'
import { useTimeout } from 'hooks/useTimeout'
import {
  CSSProperties,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import { usePredictionConfig } from '../../Config/hooks'
import { OutcomeView } from './Outcome'
import { getOutcomeHeight, getOutcomeWidth } from './Outcome/utils'
import { WinnerPanel } from './WinnerPanel'

const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const getDims = (
  isPortrait: boolean,
  scale: number,
  prediction?: Prediction,
) => {
  const length = prediction?.outcomes?.length || 0
  if (!length) return { width: OUTCOME_WIDTH * scale, height: 0 }
  if (prediction?.ended_at) {
    return { width: OUTCOME_WIDTH * scale, height: OUTCOME_WIDTH * scale }
  }
  const w = isPortrait
    ? scale * OUTCOME_WIDTH
    : Math.ceil(length / 2) * (scale * OUTCOME_WIDTH + 5)
  const h = isPortrait
    ? length * scale * OUTCOME_HEIGHT
    : scale * OUTCOME_HEIGHT * 2 + 30
  return { width: w, height: h }
}

const CollapsePanel: FC<
  PropsWithChildren<{ visible: boolean; style?: CSSProperties }>
> = ({ visible, children, style }) => (
  <div
    style={{
      width: '100%',
      height: visible ? 30 : 0,
      opacity: visible ? 1 : 0,
      transition: 'all 500ms',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      ...style,
    }}
  >
    {children}
  </div>
)

// prop used for testing
export const ClassicPredictionView: FC<{ data?: Prediction | null }> = ({
  data: _prediction,
}) => {
  const {
    broadcaster,
  } = useContext(BroadcasterContext)

  const { config } = usePredictionConfig()

  const predictionPath = useMemo(
    () => (broadcaster
      ? getPredictionDocPath(broadcaster.twitchId)
      : undefined),
    [broadcaster],
  )
  const { data } = useDocument<Prediction>(predictionPath)
  const prediction = useMemo(
    () => (_prediction !== undefined ? _prediction || undefined : data || undefined),
    [_prediction, data],
  )

  const {
    isPortrait, scale, bgOpacity, bgVisible, darkMode,
  } = config
  // const prediction = testPrediction
  const { title = '' } = prediction || {}

  const { withRank, totalPoints, totalVoters } = useProcessedPrediction(prediction)

  // const [showControls, setShowControls] = useState(false)
  const dims = useMemo(
    () => getDims(isPortrait, scale, prediction),
    [isPortrait, scale, prediction],
  )
  const { width, height, s } = useSpring({
    s: scale,
    ...dims,
    config: { duration: 1500, easing: easings.easeOutCubic },
  })
  const outcomeWidth = s.to(getOutcomeWidth)
  const outcomeHeight = s.to(getOutcomeHeight)

  const positions = useMemo(() => {
    let lastIndexWithPrediction = 0
    const oHeight = getOutcomeHeight(scale)
    const oWidth = getOutcomeWidth(scale)
    const returned = arr.map((_i, i) => {
      const res = isPortrait
        ? { y: lastIndexWithPrediction * oHeight, x: 0 }
        : {
          y: (lastIndexWithPrediction % 2) * oHeight + 30,
          x: Math.floor(lastIndexWithPrediction / 2) * (oWidth + 5),
        }

      if (withRank[i]) {
        lastIndexWithPrediction += 1
      }
      return res
    })

    return returned
  }, [withRank, isPortrait, scale])
  const wasActive = usePrevious(prediction && !prediction?.winning_outcome_id)
  const showWinner = useTimeout(
    wasActive && prediction?.winning_outcome_id,
    7000,
  )
  const background = useMemo(() => {
    if (!bgVisible) return 'rgba(0,0,0,0)'
    return `rgba(0,0,0,${bgOpacity})`
    // : 'rgba(255,255,255,0.5)'
  }, [bgVisible, bgOpacity])
  const show = useMemo(
    () => !!prediction && (!prediction.ended_at || showWinner),
    [prediction, showWinner],
  )

  const contextData = useMemo(
    () => ({ prediction, totalPoints, totalVoters }),
    [prediction, totalPoints, totalVoters],
  )
  return (
    <PredictionContext.Provider value={contextData}>
      <div
        // onMouseEnter={() => setShowControls(true)}
        // onMouseLeave={() => setShowControls(false)}
        style={{
          background,
          pointerEvents: 'auto',
          position: 'absolute',
          borderRadius: '14px',
          overflow: 'hidden',
          opacity: show ? 1 : 0,
          minHeight: '40px',
          transition: 'all 660ms',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          right: 0,
          bottom: 0,
          margin: '0.5rem',
          padding: `${0}rem ${scale * 0.4}rem`,
          paddingTop: `${scale * 0.15}rem`,
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: isPortrait ? '36px' : '0',
            transition: 'height 1000ms',
          }}
        />

        <animated.div
          style={{
            width,
            height,
            position: 'relative',
          }}
        >
          {arr.map((_, i) => (
            <OutcomeView
              total={withRank.length}
              colorBar={darkMode}
              height={outcomeHeight}
              width={outcomeWidth}
              index={i}
              position={positions[i]}
              isVisible={!prediction?.ended_at}
              outcomes={withRank}
              key={`${i}`}
            />
          ))}
        </animated.div>
        <WinnerPanel />
        <CollapsePanel
          visible={!!prediction?.locked_at && !prediction?.ended_at}
        >
          <p
            style={{
              color: 'white',
              width: '100%',
              opacity: '0.7',
              paddingBottom: `${0.4 * scale}rem`,
              fontSize: `${0.6 * scale}rem`,
              textAlign: 'center',
            }}
          >
            PREDICTIONS LOCKED
          </p>
        </CollapsePanel>
        <h1
          style={{
            pointerEvents: 'none',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            fontSize: `${scale * 0.8}rem`,
            textShadow: `0 0 ${scale * 5}px black`,
            padding: '0.5rem 0.8rem',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            // maxWidth: '200px',
            color: 'white',
          }}
        >
          {title || ''}
        </h1>
        {/* <CollapsePanel style={{justifyContent: 'space-evenly'}} visible={showControls}>
        <button onClick={toggleSize}>{bigItems ? 'small' : 'BIG'}</button>
        <button onClick={toggleDarkMode}>{darkMode ? 'LIGHT' : 'DARK'}</button>
        <button onClick={toggleBackground}>{bgVisible ? 'NO BG' : 'BG'}</button>
        <button onClick={togglePortrait}>
          {isPortrait ? 'HORIZ' : 'VERT'}
        </button>
      </CollapsePanel> */}
      </div>
    </PredictionContext.Provider>
  )
}

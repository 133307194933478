import { Choice, Poll, Prediction } from '@elan-twitch/shared'

const positions = ['1', '2', '3', '4', '5', '6', '7', '8', '9-10', '11-12']
const names = ['1-3', '4-6', '7-12']
const testOutcome1 = {
  id: '021e9234-5893-49b4-982e-cfe9a0aaddd9',
  title: 'Yes',
  users: 0,
  channel_points: 0,
  top_predictors: null,
  color: 'BLUE',
} as const

export const testPrediction: Prediction = {
  id: 'd6676d5c-c86e-44d2-bfc4-100fb48f0656',
  broadcaster_id: '71092938',
  broadcaster_name: 'Big Juicer',
  broadcaster_login: 'xqcow',
  title: 'Fake prediction',
  winning_outcome_id: null,
  outcomes: names.map((p, i) => ({
    ...testOutcome1,
    title: p,
    id: `${testOutcome1.id}${i}`,
  })),
  prediction_window: 600,
  status: 'ACTIVE',
  created_at: '2021-04-28T16:03:06.320848689Z',
  ended_at: null,
  locked_at: null,
}

export const testPrediction10: Prediction = {
  ...testPrediction,
  outcomes: positions.map((p, i) => ({
    ...testOutcome1,
    title: `Test outcome ${p}`,
    id: `${testOutcome1.id}${i}`,
  })),
}

export const getTestPrediction = (numOutcomes: number) => ({
  ...testPrediction10,
  outcomes: testPrediction10.outcomes.slice(0, numOutcomes),
})

const testPollChoice1: Choice = {
  id: '021e9234-5893-49b4-982e-cfe9a0aaddd9',
  title: 'Yes',
  votes: 0,
  bits_votes: 0,
  channel_points_votes: 0,
} as const

export const testPoll: Poll = {
  id: 'd6676d5c-c86e-44d2-bfc4-100fb48f0656',
  broadcaster_user_id: '71092938',
  broadcaster_user_name: 'Big Juicer',
  broadcaster_user_login: 'xqcow',
  choices: names.map((p, i) => ({
    ...testPollChoice1,
    title: p,
    id: `${testPollChoice1.id}${i}`,
  })),
  title: 'Mock data poll',
  channel_points_voting: { amount_per_vote: 2, is_enabled: false },
  ends_at: '2021-04-28T16:03:06.320848689Z',
  ended_at: null,
  started_at: '2021-04-28T16:03:06.320848689Z',
}

export const testPoll10: Poll = {
  id: 'd6676d5c-c86e-44d2-bfc4-100fb48f0656',
  broadcaster_user_id: '71092938',
  broadcaster_user_name: 'Big Juicer',
  broadcaster_user_login: 'xqcow',
  choices: positions.map((p, i) => ({
    ...testPollChoice1,
    title: p,
    id: `${testPollChoice1.id}${i}`,
  })),
  title: 'Mock data poll',
  channel_points_voting: { amount_per_vote: 2, is_enabled: false },
  ends_at: '2021-04-28T16:03:06.320848689Z',
  ended_at: null,
  started_at: '2021-04-28T16:03:06.320848689Z',
}

export const getTestPoll = (numChoices: number): Poll => ({
  ...testPoll10,
  choices: testPoll10.choices.slice(0, numChoices),
})

import {
  Box, Center, Text, VStack,
} from '@chakra-ui/react'
import { a, useSpring } from '@react-spring/web'
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react'
import { useWheelContext } from './context'
import { usePalette } from './hooks'
import { getOutcomesWithThetas } from './utils'

const getPieChartSegmentPath = (
  thetaA: number,
  thetaB: number,
  radius: number,
) => {
  const x1 = radius * Math.cos(thetaA * (Math.PI / 180))
  const y1 = radius * Math.sin(thetaA * (Math.PI / 180))
  const x2 = radius * Math.cos(thetaB * (Math.PI / 180))
  const y2 = radius * Math.sin(thetaB * (Math.PI / 180))

  const largeArcFlag = thetaB - thetaA <= 180 ? 0 : 1

  return `M 0 0 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`
}

const WheelSpinnerLabel = ({
  thetaA,
  thetaB,
  title,
  size,
  opacity,
}: {
  size: number
  thetaA: number
  opacity: number
  thetaB: number
  title: string
}) => {
  const baseTheta = thetaA + (thetaB - thetaA) / 2 + 2.5
  // const flipped = baseTheta > 180 && baseTheta < 270
  const flipped = baseTheta < 180
  const thetaRange = thetaB - thetaA
  const fontSize = size ? `${size * thetaRange ** 0.35 * 0.01}px` : '24px'
  const avgTheta = (thetaA + thetaB) / 2
  return (
    <Box
      position='absolute'
      width={'36%'}
      overflow='visible'
      fontFamily='Encode Sans'
      transform={`translate(${size / 2}px, ${
        size / 2
      }px) rotate(${avgTheta}deg)`}
      transformOrigin='left top'
      top={0}
      left={0}
      opacity={opacity}
      color='white'
      filter='drop-shadow(1px 1px 8px rgba(0,0,0,0.8))'
      // paddingLeft='160px'
      // border='1px solid green'
    >
      <Text
        w='100%'
        textAlign='center'
        transform={`translate(${size / 15}px, -40%)  rotate(${
          flipped ? 180 : 0
        }deg)`}
        fontSize={fontSize}
        transition='all 400ms'
        textShadow='1px 1px 5px rgba(0,0,0,0.5)'
        p={1}
        lineHeight={1}
        // border='1px solid red'
      >
        {title}
      </Text>
    </Box>
  )
}

const hexToGrayscale = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  const avg = (r + g + b) / 3
  const avgHex = Math.round(avg).toString(16)
  return `#${avgHex}${avgHex}${avgHex}`
}

const WheelSpinSegment = ({
  thetaA,
  thetaB,
  winner,
  index,
  color,
}: {
  thetaA: number
  thetaB: number
  winner: number | null
  index: number
  color: string
}) => {
  const { fill, bounds } = useSpring({
    fill: winner === null || winner === index ? color : hexToGrayscale(color),
    bounds: [thetaA, thetaB],
  })
  return (
    <>
      <a.path
        stroke='#00000033'
        strokeWidth={0.2}
        d={bounds.to((tA, tB) => getPieChartSegmentPath(tA, tB, 47))}
        fill={fill}
        z={0}
      />
    </>
  )
}

const WheelSpinDivider = ({ thetaA }: { thetaA: number }) => {
  const { tA } = useSpring({
    tA: thetaA,
  })
  return (
    <a.path
      fill='#efefef'
      filter='url(#drop-shadow)'
      style={{ transform: tA.to((v) => `rotate(${v}deg)`) }}
      d='M 0 0.25 L 47 0.25 L 47 -0.25 L 0 -0.25 Z'
      z={-1}
    />
  )
}

export const WheelSpinner = ({
  size,
  onSpinComplete,
}: {
  size: number
  onSpinComplete?: () => void
}) => {
  const { auction } = useWheelContext()
  const winner = useMemo(
    () => (auction?.spin_rested_at ? auction.winningOutcomeIndex : null),
    [auction],
  )
  const outcomesWithThetas = useMemo(
    () => getOutcomesWithThetas(auction),
    [auction],
  )

  const { resultTheta } = auction || {}
  const [{ armTheta }, api] = useSpring<{ armTheta: number }>(() => ({
    armTheta: resultTheta || 0,
  }))

  const respin = useCallback(
    (delay = 0) => {
      if (typeof resultTheta !== 'number') return
      const currTheta = armTheta.get()
      const normalizedCurrTheta = currTheta % 360
      armTheta.set(normalizedCurrTheta).finish()

      armTheta.start(resultTheta, {
        delay,
        config: {
          mass: 1,
          tension: 100,
          friction: 110,
          precision: 0.15,
        },
        onRest: () => {
          if (onSpinComplete) onSpinComplete()
        },
      })
    },
    [armTheta, resultTheta, onSpinComplete],
  )

  const auctionRef = useRef(auction)
  useEffect(() => {
    if (auction?.spun_at && !auction.spin_rested_at) {
      respin(600)
    } else if (auction?.spin_rested_at) {
      api.set({
        armTheta: auction?.resultTheta ? auction.resultTheta : 0,
      })
    }
    auctionRef.current = auction
  }, [auction, respin, api])

  const palette = usePalette(auction)

  return (
    <VStack
      w={size ? `${size}px` : '100%'}
      h={size ? `${size}px` : '100%'}
      transition='width 400ms, height 400ms, opacity 300ms'
      pointerEvents={auction ? 'auto' : 'none'}
    >
      <Center
        w='100%'
        h='100%'
        position='relative'
        maxW={`${size || 600}px`}
        maxH={`${size || 600}px`}
        opacity={auction ? 1 : 0}
      >
        <svg
          viewBox='-50 -50 100 100'
          pointerEvents='none'
          style={{
            width: '100%',
            height: '100%',
            filter: 'drop-shadow(0px 0px 6px black)',
          }}
        >
          {/* drop shadow definition */}
          <defs>
            <filter width='200%' height='200%' id='drop-shadow'>
              <feGaussianBlur in='SourceAlpha' stdDeviation='0.3' />
              <feOffset dx='0' dy='0' />
              <feMerge>
                <feMergeNode />
                <feMergeNode in='SourceGraphic' />
              </feMerge>
            </filter>
          </defs>
          <a.g style={{ transform: armTheta.to((v) => `rotate(${v}deg)`) }}>
            {outcomesWithThetas.map((outcome, index) => (
              <WheelSpinSegment
                thetaA={outcome.thetaA}
                thetaB={outcome.thetaB}
                index={index}
                winner={winner}
                color={palette[index % palette.length]}
                key={outcome.title}
              />
            ))}
            {outcomesWithThetas.map((outcome) => (
              <WheelSpinDivider thetaA={outcome.thetaA} key={outcome.title} />
            ))}
          </a.g>
          <circle
            cx='0'
            cy='0'
            r='47'
            fill='none'
            stroke='#cdcdcd'
            strokeWidth={1.5}
            filter='url(#drop-shadow)'
          />
          <a.path
            d='M 40.866 0.226 C 40.386 0.068 40.446 0.05 40.866 -0.055 C 44.008 -0.842 45.287 -2.255 45.773 -3.664 C 45.839 -3.855 46.149 -4.142 46.635 -4.144 C 47.097 -4.114 47.965 -4.432 47.867 0.178 C 47.771 4.714 47.118 4.103 46.585 4.129 C 46.135 4.08 46.026 4.002 45.87 3.71 C 45.199 2.456 43.9 1.227 40.866 0.226 Z'
            stroke='none'
            fill='#dedede'
            strokeLinecap='round'
            filter='url(#drop-shadow)'
          />
          <circle
            cx='0'
            cy='0'
            r='4'
            fill='#cdcdcd'
            filter='url(#drop-shadow)'
          />
        </svg>
        <a.div
          style={{
            position: 'absolute',
            width: '100%',
            pointerEvents: 'none',
            transform: armTheta.to((v) => `rotate(${v}deg)`),
            height: '100%',
            left: 0,
            top: 0,
          }}
        >
          {/* labels for outcomes */}
          {outcomesWithThetas.map((outcome, index) => (
            <WheelSpinnerLabel
              size={size}
              opacity={winner === null || winner === index ? 1 : 0.5}
              thetaA={outcome.thetaA}
              key={outcome.title}
              thetaB={outcome.thetaB}
              title={outcome.title}
            />
          ))}
        </a.div>
      </Center>
    </VStack>
  )
}

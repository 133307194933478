// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBaXh9NUI5ucT20HM62lsu9ro0eEy3zGh8',
  authDomain: 'elan-twitch-extensions.firebaseapp.com',
  projectId: 'elan-twitch-extensions',
  storageBucket: 'elan-twitch-extensions.appspot.com',
  messagingSenderId: '537454581355',
  appId: '1:537454581355:web:4d11d5e5e274acef87832e',
  measurementId: 'G-JHKQ9KCN3E',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button, Divider, Flex, Image, Text,
} from '@chakra-ui/react'
import { toPlural } from '@elan-twitch/shared'
import { ReactNode, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { SignIn } from '../../../components/Auth/SignIn'
import gamesIcon from '../../../icons/games.svg'
import movieIcon from '../../../icons/movie.svg'
import reviewsIcon from '../../../icons/reviews.svg'
import { Trophy } from '../../../icons/Trophy'
import vodsIcon from '../../../icons/vods.svg'
import { useUser } from '../../../store/useUser'

const getSmallLinks = (pointName: string, pointIconPath: string | undefined) : { path: string; label: string; external?: boolean, icon: ReactNode }[] => [
  {
    path: '/bank',
    label: toPlural(pointName),
    icon: (
      <Image h='24px' src={pointIconPath || movieIcon}/>
    ),
  },
  {
    path: '/movies',
    label: 'Movie Night',
    icon: (
      <Image h={['24px', '24px', '26px']} filter='brightness(200%) drop-shadow(1px 1px 4px #00000077)' src={movieIcon} />
    ),
  },
  {
    path: 'https://nymn.gg/contests',
    label: 'Contests',
    external: true,
    icon: (
      <Trophy style={{ width: '24px', filter: 'grayscale(100%)' }}/>
    ),
  },
  {
    path: 'https://nymn.gg/review',
    label: 'Friday Reviews',
    external: true,
    icon: (
      <Image h={['24px', '24px', '26px']} filter='brightness(200%) drop-shadow(1px 1px 4px #00000077)' src={reviewsIcon} />
    ),
  },
  {
    path: 'https://nymn.gg/games',
    label: 'Games',
    external: true,
    icon: (
      <Image h={['24px', '24px', '26px']} filter='brightness(200%) drop-shadow(1px 1px 4px #00000077)' src={gamesIcon} />
    ),
  },
  {
    path: 'https://nymn.gg/vods',
    label: 'VODs',
    external: true,
    icon: (
      <Image h={['24px', '24px', '26px']} filter='brightness(200%) drop-shadow(1px 1px 4px #00000077)' src={vodsIcon} />
    ),
  },
]

export const Links = () => {
  const { basePath, broadcaster } = useContext(BroadcasterContext)
  const user = useUser((s) => s.user)

  const links = useMemo(() => getSmallLinks(broadcaster?.pointsBankAccountConfig?.pointName || 'point', broadcaster?.pointsBankAccountConfig?.pointIconPath), [broadcaster])

  return (
    <Flex w='100%' pointerEvents='auto' borderLeftRadius={0} px={4} py={2} flexFlow={['column', 'column', 'row']}>
      <Flex flex={['unset', 'unset', 1]} align={['flex-start', 'flex-start', 'center']} flexFlow={['column', 'column', 'row']} w='100%' gap={[0, 0, 1]}>
        <Text display={['block', 'block', 'none']} size='sm' fontWeight='bold' color='#f3f3f3' textShadow='1px 1px 3px #00000066'>
          Quick Links
        </Text>
        {links.map(({
          path, label, external, icon,
        }) => {
          const body = (
            <Button px={[1, 1, 1, 2, 3]} gap={2} textShadow='1px 1px 3px #00000077' key={path} size={['sm', 'sm', 'sm', 'sm', 'lg']} variant='link'>
              <Box display={['block', 'block', 'none', 'block', 'block']}>
              {icon}
              </Box>
              {label}
              {external ? <ExternalLinkIcon /> : null}
            </Button>
          )
          return external ? (
            <a href={path} key={path} target='_blank' rel='noreferrer'>
              {body}
            </a>
          ) : (
            <Link key={path} to={`${basePath}${path}`}>
              {body}
            </Link>
          )
        })}
      </Flex>
      <Flex p={2} gap={3} flexFlow={['column', 'column', 'row']} align='center' justify='center' width={['100%', '100%', 'auto']}>
        <Divider display={['block', 'block', 'none']} mt={2} />
        {user ? (
          <Text opacity={0.7} fontWeight={600} textAlign='center' whiteSpace='nowrap' fontSize='sm'>
            Signed in as {user?.displayName || ''}
          </Text>
        ) : null}
        <SignIn />
      </Flex>
    </Flex>
  )
}

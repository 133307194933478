import { useColorModeValue } from '@chakra-ui/react'
import './styles.css'

export const TypingIndicator = () => {
  const className = useColorModeValue('dot', 'dot-dark')
  return (
    <svg height='40' width='40' className='loader'>
      <circle className={className} cx='10' cy='20' r='3' />
      <circle className={className} cx='20' cy='20' r='3' />
      <circle className={className} cx='30' cy='20' r='3' />
    </svg>
  )
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  HStack,
} from '@chakra-ui/react'
import { gradients, gradientToCssGradientProp } from '@elan-twitch/shared'
import { ExpandButton } from 'components/shared/Buttons'
import { ShadowText } from 'components/shared/ShadowText'
import { useMemo, useRef, useState } from 'react'
import { useWheelContext } from './context'

export const ReopenBidding = ({
  onReopen,
  isLoading,
}: {
  onReopen: (discardBids: boolean) => Promise<void>
  isLoading: boolean
}) => {
  const { auction } = useWheelContext()
  const canReopen = useMemo(() => {
    if (!auction) return false
    if (auction.spun_at && !auction.spin_rested_at) return false
    if (auction.ended_at) return true
    if (auction.spun_at && auction.spin_rested_at) return true
    return false
  }, [auction])
  const [isOpen, setIsOpen] = useState(false)
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <>
      <ExpandButton
        active={canReopen}
        onClick={() => setIsOpen(true)}
        isLoading={isLoading}
        width={140}
      >
        REOPEN BIDDING
      </ExpandButton>
      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            width='300px'
            boxShadow='0 0 5px black'
            bg={gradientToCssGradientProp(gradients.nymn)}
          >
            <AlertDialogHeader py={2} pb={1} fontSize='lg'>
              <Center>
                <ShadowText
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: 'Encode Sans',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  REOPEN BIDDING
                </ShadowText>
              </Center>
            </AlertDialogHeader>
            <AlertDialogBody>
              <HStack w='100%'>
                <Button
                  flex={1}
                  isLoading={isLoading}
                  colorScheme='red'
                  onClick={async () => {
                    try {
                      await onReopen(true)
                    } catch (err) {
                      console.error(err)
                    }
                    setIsOpen(false)
                  }}
                  px={2}
                >
                  Discard Bids
                </Button>
                <Button
                  flex={1}
                  isLoading={isLoading}
                  colorScheme='red'
                  onClick={async () => {
                    try {
                      await onReopen(false)
                    } catch (err) {
                      console.error(err)
                    }
                    setIsOpen(false)
                  }}
                  px={2}
                >
                  Keep Bids
                </Button>
              </HStack>
            </AlertDialogBody>
            <AlertDialogCloseButton />
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

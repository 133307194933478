import { Center } from '@chakra-ui/react'
import {
  getBroadcasterMovieListCollectionPath, gradients, gradientToCssGradientProp, InfiniteScrollList,
  MovieListMovie,
} from '@elan-twitch/shared'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { MobileDarkModeToggle } from 'components/shared/ColorMode/DarkModeToggle'
import { InfiniteLoaderList } from 'components/shared/DataList/InfiniteLoaderList'
import { MovieListHeader } from 'components/Widgets/MovieList/Header'
import { useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useScreen } from 'store/useScreen'
import { AbsoluteLogin } from '../../Auth/SignIn'
import { AcceptCookies } from '../../shared/AcceptCookies'
import { MovieViewModal } from './MovieView'
import { MovieIdPreview } from './MovieView/MoviePreview'

const getList = (basePath: string, broadcasterId: string): InfiniteScrollList<MovieListMovie> => ({
  basePath: `${basePath}/movies`,
  itemType: 'movie',
  collectionPath: getBroadcasterMovieListCollectionPath(broadcasterId),
  defaultFilters: [
    {
      firestoreFilter: ['watched', '==', false],
      label: 'Unwatched movies',
    },
  ],
  defaultSortKey: 'vote_score',
  filterPresets: [
    {
      firestoreFilter: ['watched', '==', false],
      label: 'Unwatched movies',
    },
    {
      firestoreFilter: ['watched', '==', true],
      label: 'Watched movies',
    },
  ],
  sortPresets: [
    {
      defaultDirection: 'desc',
      sortKey: 'vote_score',
      label: 'Score',
    },
    {
      defaultDirection: 'desc',
      sortKey: 'nominatedOn',
      label: 'Date Nominated',
    },
  ],
  Header: MovieListHeader,
  RenderItem: ({ item }) => <MovieIdPreview id={item._id} />,
  itemHeight: 140,
  noItemsMessage: 'No movies added yet, search for a movie to nominate it',
})

export const MovieList = ({ width, height }: {width: number, height: number}) => {
  const {
    broadcaster: { _id: broadcasterId },
    basePath,
  } = useContext(BroadcasterContext)
  const navigate = useNavigate()
  const { movieId } = useParams<{ movieId: string }>()
  const list = useMemo(() => getList(basePath, broadcasterId), [broadcasterId, basePath])
  return (
      <InfiniteLoaderList
        height={height}
        list={list}
        width={width}
        modals={
          <>
            {movieId ? <MovieViewModal onClose={() => navigate(`${basePath}/movies`)} id={movieId} /> : null}
            <MobileDarkModeToggle />
            <AbsoluteLogin providers={['google', 'twitch']} />
          </>
        }
      />
  )
}

const MovieListViewBody = () => {
  const screen = useScreen()

  const dims = useMemo(
    () => ({ height: Math.min(screen.height * 0.9, 1000), width: Math.min(screen.width * 0.95, 1000) }),
    [screen],
  )
  return (
    <Center
      bg={gradientToCssGradientProp(gradients.nymn)}
      position='absolute'
      top={0}
      left={0}
      pointerEvents='auto'
      w='100%'
      pb={8}
      height='100%'
    >
      <MovieList width={dims.width} height={dims.height} />
    </Center>
  )
}

export const MovieListView = () => (
  <AcceptCookies>
      <MovieListViewBody />
  </AcceptCookies>
)

import {
  Box,
  BoxProps, CloseButton, Flex, HStack,
} from '@chakra-ui/react'
import {
  PropsWithChildren, ReactElement, useCallback, useState,
} from 'react'
import { SaveButton } from './Buttons'
import { CollapseCenter } from './CollapseCenter'
import { CollapseHorizontal } from './CollapseHorizontal'
import { DiscardChangesDialog } from './DiscardChangesDialog'
import { ShadowText } from './ShadowText'

export const EditorWrapper = ({
  children,
  active,
  title,
  dirty,
  onBack,
  header,
  onSave,
  boxProps,
  submitting,
}: PropsWithChildren<{
  active: boolean
  title: string
  header?: ReactElement
  dirty?: boolean
  onBack?: () => void
  onSave?: () => void
  boxProps?: BoxProps
  submitting?: boolean
}>) => {
  const [confirmingClose, setConfirmingClose] = useState(false)
  const handleBack = useCallback(() => {
    if (!onBack) return
    if (dirty) {
      setConfirmingClose(true)
      return
    }
    onBack()
    // onBack()
  }, [onBack, dirty])

  const onConfirmBack = useCallback(() => {
    if (!onBack) return
    setConfirmingClose(false)
    onBack()
  }, [onBack])
  return (
    <CollapseCenter unmountOnExit in={active}>
      <Flex w='100%' flexFlow='column'>
        <HStack spacing={0} pb={1} justify='space-between' w='100%'>
          <ShadowText style={{ fontSize: '1.6rem' }}>{title}</ShadowText>
          {header || null}
          <HStack>
            <CollapseHorizontal width={36} active={!!onSave}>
              <SaveButton onClick={onSave} isLoading={submitting} />
            </CollapseHorizontal>
            <CollapseHorizontal width={36} active={!!onBack}>
              <CloseButton onClick={handleBack} />
            </CollapseHorizontal>
          </HStack>
        </HStack>
        <Box w='100%' p={3} borderRadius={6} bg='blackAlpha.500' {...boxProps}>
          {children}
        </Box>
      </Flex>
      <DiscardChangesDialog
        isOpen={confirmingClose}
        onCancel={() => setConfirmingClose(false)}
        onConfirm={onConfirmBack}
      />
    </CollapseCenter>
  )
}

// rounded status button with loading spinner
import {
  Center, CircularProgress, Flex, HStack, Text, useColorModeValue, useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { CoolTooltip } from './CoolTooltip'
import { ShadowText } from './ShadowText'

export const IsActiveButton = ({
  enable,
  disable,
  initStatus,
}: {
  enable: () => Promise<void>
  disable: () => Promise<void>
  initStatus: 'active' | 'inactive' | 'loading'
}) => {
  const toast = useToast()

  const [status, setStatus] = useState<'active' | 'inactive' | 'loading'>(initStatus)

  const reportComplete = useCallback((fetchedOn: number, updatedStatus: 'active' | 'inactive') => {
    const buffer = Math.max(0, 1000 - (Date.now() - fetchedOn))
    setTimeout(() => {
      setStatus(updatedStatus)
    }, buffer)
  }, [])

  useEffect(() => {
    setStatus(initStatus)
  }, [initStatus])

  const handleClick = useCallback(async () => {
    if (status === 'loading') return
    setStatus('loading')
    const fetchedOn = Date.now()
    try {
      if (status === 'active') await disable()
      else await enable()
      reportComplete(fetchedOn, status === 'active' ? 'inactive' : 'active')
    } catch (err: any) {
      console.error(err)
      toast({
        title: 'Error',
        description: err?.message || 'Error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [enable, disable, reportComplete, toast, status])

  const loading = status === 'loading'
  const bg = useColorModeValue('gray.200', 'gray.700')

  const color = useColorModeValue('gray.600', 'gray.300')
  return (
    <CoolTooltip label={loading ? '' : `Click to ${status === 'active' ? 'turn off' : 'turn on'}`}>
      <Flex
        py={1}
        pr={2}
        pl={3}
        bg={bg}
        borderRadius={12}
        cursor='pointer'
        onClick={handleClick}
        boxShadow='0 0 4px black'
        pos='relative'
        align='center'
      >
        <Flex
          opacity={loading ? 0 : 1}
          transition={`opacity 300ms ${loading ? 'ease-out' : 'ease-in'}`}
          pointerEvents={loading ? 'none' : 'auto'}
          align='center'
          gap={2}
          px={1}
        >
          <Text
            fontFamily='Bebas Neue'
            height='22px'
            opacity={status === 'active' ? 1 : 0.5}
            color={status === 'active' ? 'green.500' : 'gray.400'}
            textShadow={`0 0 4px ${status === 'active' ? '#bbeebbff' : '#ffffff00'}`}
          >
            {status}
          </Text>
          <Center
            bg={status === 'active' ? 'green.500' : 'blackAlpha.400'}
            borderRadius='10px'
            w='10px'
            h='10px'
            boxShadow={`0 0 5px ${status === 'active' ? 'green' : '#ffffff77'}`}
          />
        </Flex>
        <Center
          opacity={loading ? 0.7 : 0}
          transition={`opacity 300ms ${loading ? 'ease-in' : 'ease-out'}`}
          pointerEvents={loading ? 'auto' : 'none'}
          pos='absolute'
          top={0}
          left={0}
          w='100%'
          h='100%'
        >
          <HStack spacing={1}>
            <CircularProgress size={4} color={color} isIndeterminate />
            <ShadowText style={{ height: '22px' }}>Loading...</ShadowText>
          </HStack>
        </Center>
      </Flex>
    </CoolTooltip>
  )
}

import {
  Button,
  Image,
  ImageProps,
  Text, useColorModeValue,
} from '@chakra-ui/react'

import { useTwitchUserSignIn } from '../../hooks/useTwitchLogin'
import twitchIcon from '../../icons/twitch_logo.svg'

export const TwitchLogo = (props: ImageProps) => {
  const filter = useColorModeValue(
    'grayscale(100%) brightness(90%) drop-shadow(1px 1px 4px #00000055)',
    'grayscale(100%) brightness(290%) drop-shadow(1px 1px 4px #00000055)',
  )
  // @ts-ignore
  return <Image src={twitchIcon} w={8} alt='Twitch' filter={filter} {...props} />
}

export const TwitchSignIn = ({ noText }: { noText?: boolean }) => {
  const { handleSignIn, isSigningIn } = useTwitchUserSignIn()
  return (
    <Button flex={1} onClick={handleSignIn} isLoading={isSigningIn} gap={1.5} size='sm'>
      <TwitchLogo />
      {noText ? null : <Text color='inherit'>SIGN IN</Text>}
    </Button>
  )
}

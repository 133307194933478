export type Validator<InputType> = (val: InputType) => string | undefined

export const validatePositiveNumber = (val: number): string | undefined => {
  if (Number.isNaN(val)) return 'Must be a number'
  if (val <= 0) return 'Must be greater than 0'
  return undefined
}

export const validateNonNegativeNumber = (val: number): string | undefined => {
  if (Number.isNaN(val)) return 'Must be a number'
  if (val < 0) return 'Must be greater than or equal to 0'
  return undefined
}

export const validateInteger = (val: number): string | undefined => {
  if (Number.isNaN(val)) return 'Must be a number'
  if (val !== parseInt(`${val}`, 10)) return 'Must be a whole number'
  return undefined
}

export const validateRequired = (val: any | undefined): string | undefined => {
  if (val === undefined || val === '') return 'Required'
  return undefined
}

export const combineValidators = <InputType>(
  ...validators: Array<Validator<InputType>>
) => (val: any) => {
    for (let i = 0; i < validators.length; i += 1) {
      const err = validators[i](val)
      if (err) return err
    }
    return undefined
  }

import {
  InfiniteScrollList, ListFilterPreset, ListSort, ListSortPreset, SortKey, WithId,
} from '@elan-twitch/shared'
import { FirebaseError } from '@firebase/util'
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit as limitTo,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { db } from '../backend/db'

export const useDocument = <T extends DocumentData>(path?: string | null) => {
  const [data, setData] = useState<WithId<T> | null>(null)
  const [isLoading, setIsLoading] = useState(!!path)
  const [error, setError] = useState<FirebaseError | null>(null)
  useEffect(() => {
    if (!path) {
      setIsLoading(false)
      setData(null)
      setError(null)
      return () => {}
    }
    const ref = doc(db, path) as DocumentReference<T>
    setIsLoading(true)
    return onSnapshot(ref, (snap) => {
      setIsLoading(false)
      const fetched = snap.data()
      if (!fetched) setData(null)
      else setData({ ...fetched, _id: snap.id })
    })
  }, [path])

  return { isLoading, data, error }
}

export const useQuery = <T extends DocumentData>(ref: Query<T> | null, onSnap?: (data: Array<WithId<T>>) => void) => {
  const [data, setData] = useState<Array<WithId<T>>>([])
  const [isLoading, setIsLoading] = useState(!!ref)
  const [error, setError] = useState<FirebaseError | null>(null)

  useEffect(() => {
    if (!ref) return () => {}
    setIsLoading(true)
    setData([])
    if (onSnap) onSnap([])
    return onSnapshot(ref, {
      next: (snap) => {
        setIsLoading(false)
        const updated = snap.docs.map((queryDoc) => ({
          ...queryDoc.data(),
          _id: queryDoc.id,
        }))
        if (onSnap) onSnap(updated)
        setData(updated)
      },
      error: (err) => {
        console.error(err)
        console.error(ref)
        setError(err)
      },
    })
  }, [ref, onSnap])

  return { isLoading, data, error }
}

export const usePaginatedQuery = <T extends DocumentData = DocumentData>(
  collectionPath: string,
  limit: number,
  filters: Array<ListFilterPreset>,
  sort: ListSort<T> | null,
) => {
  const [data, setData] = useState<WithId<T>[]>([])
  const [error, setError] = useState<string | null>(null)
  const [cursors, setCursors] = useState<DocumentSnapshot<T>[]>([])
  const [totalCount, setTotalCount] = useState<number | null>(null)
  const [nextCursor, setNextCursor] = useState<DocumentSnapshot<T> | null>(null)
  const [loading, setLoading] = useState(false)
  const fetching = useRef(false)

  const unpaginatedQuery = useMemo(() => {
    const coll = collection(db, collectionPath) as CollectionReference<T>
    let dbQuery = query(coll)
    filters.forEach((f) => {
      dbQuery = query(dbQuery, where(...f.firestoreFilter))
    })
    if (sort) dbQuery = query(dbQuery, orderBy(sort.sortKey, sort.sortDirection || 'desc'))
    return dbQuery
  }, [collectionPath, filters, sort])

  const fetchCount = useCallback(
    async (q: Query) => getCountFromServer(q)
      .then((count) => setTotalCount(count.data().count))
      .catch((e) => setError(e?.message || 'Error fetching data')),
    [],
  )

  const fetch = useCallback(
    (cursor?: DocumentSnapshot<T> | null) => {
      let dbQuery = query(unpaginatedQuery)
      if (cursor) dbQuery = query(dbQuery, startAfter(cursor))
      dbQuery = query(dbQuery, limitTo(limit))
      setLoading(true)
      setError(null)
      fetching.current = true
      fetchCount(unpaginatedQuery)
        .then(() => {
          getDocs(dbQuery)
            .then((res) => {
              setData((d) => [...d, ...res.docs.map((fetchedDoc) => ({ ...fetchedDoc.data(), _id: fetchedDoc.id }))])
              setNextCursor(res.docs[res.docs.length - 1] || null)
            })
            .catch((e) => {
              console.error(e)
              setError(e?.message || 'Error fetching data')
            })
        })
        .catch((e: any) => {
          console.error(e)
          setError(e?.message || 'Error fetching data')
        })
        .finally(() => {
          setLoading(false)
          fetching.current = false
        })
    },
    [unpaginatedQuery, limit, fetchCount],
  )

  const hasMoreData = useMemo(() => totalCount !== null && data.length < totalCount, [data, totalCount])
  const refetch = useCallback(() => {
    setData([])
    setCursors([])
    setNextCursor(null)
    setError(null)
    return fetch()
  }, [fetch])

  const refetchItem = useCallback(
    async (id: string) => {
      const docRef = doc(db, `${collectionPath}/${id}`) as DocumentReference<T>
      return getDoc(docRef).then((docSnap) => {
        const docData = docSnap.data()
        if (!docData) return
        setData((d) => d.map((item) => (item._id === id ? { ...docData, _id: id } : item)))
      })
    },
    [collectionPath],
  )

  useEffect(() => refetch(), [refetch])

  const fetchMoreData = useCallback(() => {
    if (!nextCursor) return
    fetch(nextCursor)
    setCursors((c) => [...c, nextCursor])
    setNextCursor(null)
  }, [nextCursor, fetch])

  return useMemo(
    () => ({
      data,
      loading,
      error,
      hasMoreData,
      totalCount,
      refetchItem,
      fetchMoreData,
      cursors,
      limit,
      refetch,
    }),
    [data, loading, error, fetchMoreData, hasMoreData, refetch, totalCount, limit, cursors, refetchItem],
  )
}

const limit = 15
const getInitialSort = <T extends DocumentData>(
  sortPresets: Array<ListSortPreset<T>>,
  defaultSortKey: SortKey<T>,
): ListSort<T> => {
  const defaultSort = sortPresets.find((s) => s.sortKey === defaultSortKey)
  const { defaultDirection, sortKey } = defaultSort || sortPresets[0]
  return { sortDirection: defaultDirection, sortKey }
}

export type UseInfiniteScrollList<T extends DocumentData> = {
  addFilter: (f: ListFilterPreset) => void
  removeFilter: (f: ListFilterPreset) => void
  setSortFromPreset: (preset: ListSortPreset<T>) => void
  filterPresets: Array<ListFilterPreset>
  filters: Array<ListFilterPreset>
  isItemLoaded: (idx: number) => boolean
  sort: ListSort<T>
} & ReturnType<typeof usePaginatedQuery<T>>
export const useInfiniteScrollList = <T extends DocumentData>(
  list: InfiniteScrollList<T>,
): UseInfiniteScrollList<T> => {
  const {
    collectionPath, defaultFilters, defaultSortKey, filterPresets, sortPresets,
  } = list
  const [sort, setSort] = useState<ListSort<T>>(getInitialSort(sortPresets, defaultSortKey))
  const [filters, setFilters] = useState<ListFilterPreset[]>(defaultFilters)
  const queryData = usePaginatedQuery(collectionPath, limit, filters, sort)
  const { data } = queryData
  const addFilter = useCallback((f: ListFilterPreset) => {
    setFilters((curr) => [...curr.filter((c) => c.firestoreFilter[0] !== f.firestoreFilter[0]), f])
  }, [])

  const removeFilter = useCallback((filter: ListFilterPreset) => {
    setFilters((curr) => curr.filter((f) => f.firestoreFilter[0] !== filter.firestoreFilter[0]))
  }, [])

  const setSortFromPreset = useCallback(
    (preset: ListSortPreset<T>) => {
      if (preset.sortKey === sort.sortKey) setSort({ sortDirection: sort.sortDirection === 'asc' ? 'desc' : 'asc', sortKey: sort.sortKey })
      else {
        setSort({ sortDirection: preset.defaultDirection, sortKey: preset.sortKey })
      }
    },
    [sort],
  )

  return useMemo(
    () => ({
      ...queryData,
      addFilter,
      filters,
      removeFilter,
      setSortFromPreset,
      filterPresets,
      sort,
      isItemLoaded: (idx: number) => !!data[idx],
    }),
    [addFilter, removeFilter, queryData, setSortFromPreset, filterPresets, data, filters, sort],
  )
}

import { useEffect, useState } from 'react'

export const useTimeout = (init: any, duration: number = 1000) => {
  const [val, set] = useState(init)
  useEffect(() => {
    if (init) {
      set(init)
      setTimeout(() => {
        set(null)
      }, duration)
      // return () => {
      //   clearTimeout(t)
      // }
    }
    return () => {}
  }, [init, duration])

  return val
}

import { Gradient, Gradients } from '../types'

export const gradients: Gradients = {
  blue: {
    r: { b: 30, m: 90 },
    g: { b: 115, m: -35 },
    b: { b: 235, m: -45 },
  },
  red: {
    r: { b: 255, m: -150 },
    g: { b: 20, m: 0 },
    b: { b: 20, m: 0 },
  },
  yellow: {
    r: { b: 255, m: -36 },
    g: { b: 191, m: -37 },
    b: { b: 0, m: 0 },
  },
  green: {
    g: { b: 255, m: -36 },
    r: { b: 191, m: -37 },
    b: { b: 0, m: 0 },
  },
  'blue-orange': {
    r: { b: 21, m: 220 },
    g: { b: 62, m: 60 },
    b: { b: 174, m: -150 },
  },
  neon: {
    r: { b: 179, m: -171 },
    g: { b: 255, m: 0 },
    b: { b: 171, m: 76 },
  },
  megalul: {
    r: { b: 135, m: -135 },
    g: { b: 0, m: 0 },
    b: { b: 0, m: 0 },
  },
  nymn: {
    r: { b: 39, m: 21 },
    g: { b: 7, m: 90 },
    b: { b: 93, m: 45 },
  },
  vamp: {
    r: { b: 220, m: -146 },
    g: { b: 36, m: 50 },
    b: { b: 36, m: 121 },
  },
  midnight: {
    r: { b: 75, m: -75 },
    g: { b: 77, m: -77 },
    b: { b: 79, m: -79 },
  },
  christmas: {
    r: { b: 50, m: 170 },
    g: { b: 220, m: -170 },
    b: { b: 50, m: 0 },
  },
  twitch: {
    r: { b: 30, m: 200 },
    g: { b: 115, m: -35 },
    b: { b: 255, m: -60 },
  },
  silver: {
    r: { b: 192, m: -92 },
    g: { b: 192, m: -92 },
    b: { b: 192, m: -92 },
  },
  gold: {
    r: { b: 255, m: -36 },
    g: { b: 191, m: -37 },
    b: { b: 0, m: 0 },
  },
  bronze: {
    r: { b: 229, m: -81 },
    g: { b: 171, m: -65 },
    b: { b: 122, m: -62 },
  },
  gray: {
    r: { b: 128, m: -28 },
    g: { b: 128, m: -28 },
    b: { b: 128, m: -28 },
  },
}

export const gradientToCssGradientProp = (gradient: Gradient, rotation: number = 0) => {
  const { r, g, b } = gradient
  return `linear-gradient(${rotation}deg, rgb(${r.b}, ${g.b}, ${b.b}), rgb(${r.b
    + r.m}, ${g.b + g.m}, ${b.b + b.m}))`
}

export const colors = {
  BLUE: '#387AFF',
  PINK: 'pink',
}
export const textColors = {
  BLUE: 'white',
  PINK: '#444',
}
export const OUTCOME_HEIGHT = 50
export const OUTCOME_WIDTH = 230

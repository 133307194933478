import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Input } from '@chakra-ui/react'
import { TextField } from '@elan-twitch/shared'
import { forwardRef, useState } from 'react'
import { useInputImperativeHandle, useInputStyle } from './hooks'
import { InputProps, InputRef } from './types'

export const TextComponent = forwardRef<InputRef, InputProps<TextField>>(({
  input,
  field: { placeholder, type, optional },
  size,
}, ref) => {
  const [isHidden, setIsHidden] = useState(type === 'password')
  const style = useInputStyle(size)
  const inputRef = useInputImperativeHandle(ref)
  const body = (
    <Input
      border='none'
      borderRadius={0}
      ref={inputRef}
      color='#fafafa'
      type={isHidden ? 'password' : 'text'}
      background='none'
      _focus={{ border: 'none' }}
      _placeholder={{ color: '#efefef' }}
      fontFamily='Encode Sans'
      placeholder={optional ? placeholder : `${placeholder} *`}
      style={style}
      {...input}
    />
  )

  return type === 'password' ? (
    <Flex width='100%' position='relative' align='center'>
      {body}
      <IconButton
        position='absolute'
        right={2}
        onClick={() => setIsHidden(!isHidden)}
        variant='ghost'
        icon={isHidden ? <ViewIcon /> : <ViewOffIcon />}
        aria-label={isHidden ? 'show' : 'hide'}
        title={isHidden ? 'Show' : 'Hide'}
        style={style}
        size='xs'
      />
    </Flex>
  ) : (
    body
  )
})

import { Texture, TextureKey } from 'shared/types/textures'

export const fbmTexture: Texture = {
  id: 'fbm',
  aspectRatio: 1,
}

export const elevatedTexture: Texture = {
  id: 'elevated',
  preserveAspectRatio: 'none',
  aspectRatio: 1,
}

export const textures: Record<TextureKey, Texture> = {
  fbm: fbmTexture,
  elevated: elevatedTexture,
}

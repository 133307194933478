import {
  Box,
  BoxProps,
  Center,
  Image,
  ImageProps,
} from '@chakra-ui/react'
import {
  SyntheticEvent,
  useCallback, useEffect, useRef, useState,
} from 'react'
import { CircularProgress } from './CircularProgress'

interface LoadingImageProps {
  src: string
  loadingOverride?: boolean
  onLoad?: ImageProps['onLoad']
  alt?: string
  imageProps?: Omit<ImageProps, 'onLoad'>
}

const LoadingImage = ({
  src, loadingOverride, alt, onLoad, width, height, imageProps, ...boxProps
}: LoadingImageProps & Omit<BoxProps, 'onLoad'>) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setImageLoaded(true)
    }
  }, [imageRef])

  const handleImageLoad = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    setImageLoaded(true)
    if (onLoad) onLoad(e)
  }, [onLoad])
  const loading = !imageLoaded || loadingOverride
  return (
    <Box w={width || '100%'} h={height || '100%'} position='relative' {...boxProps}>
      <Center w='100%' h='100%' overflow='hidden' borderRadius='full'>
      <Image
        src={src || '/svg/album-green.svg'}
        alt={alt}
        onLoad={handleImageLoad}
        onLoadStart={() => setImageLoaded(false)}
        style={{
          top: 0,
          left: 0,
          objectFit: 'cover',
          opacity: src && loading ? 0 : 1,
          transition: 'opacity 300ms ease-in-out',
        }}
        w='100%'
        h='100%'
        {...imageProps}
      />
      </Center>
      <Center
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: src && loading ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          // zIndex: imageLoaded ? -1 : 1,
        }}
        w='100%'
        h='100%'
      >
        <CircularProgress size={6} isIndeterminate />
      </Center>
    </Box>
  )
}

export default LoadingImage

import {
  Box, Button, CircularProgress, HStack, Text, useToast, VStack,
} from '@chakra-ui/react'
import {
  Broadcaster, getDateTimeString, gradients, gradientToCssGradientProp, WithId,
} from '@elan-twitch/shared'
import { getBroadcasterAuctionDocRef } from 'backend/db'
import { AddButton, CloseButton, DeleteButton } from 'components/shared/Buttons'
import { ShadowText } from 'components/shared/ShadowText'
import { deleteDoc } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useSavedPointsAuctions } from './hooks'
import { setCurrentAuctionId } from './utils'

export const LoadPointsAuction = ({
  onSwitch,
  enabled,
  broadcaster,
}: {
  onSwitch: (mode: 'create' | null) => void
  enabled: boolean
  broadcaster: WithId<Broadcaster>
}) => {
  const { data: savedAuctions, isLoading } = useSavedPointsAuctions(broadcaster)
  const toast = useToast()
  const [loadingAuction, setLoadingAuction] = useState<string | null>(null)
  const handleLoadAuction = useCallback(
    async (updated: string) => {
      try {
        await setCurrentAuctionId(broadcaster, updated)
      } catch (err: any) {
        console.error(err)
        toast({
          title: 'Error loading auction',
          description: err.message,
          status: 'error',
        })
      }
      setLoadingAuction(null)
    },
    [broadcaster, toast],
  )

  return (
    <VStack
      opacity={enabled ? 1 : 0}
      transition='all 400ms'
      w='100%'
      p={1}
      maxW='800px'
      pointerEvents={enabled ? 'auto' : 'none'}
    >
      <VStack spacing={0} w='100%' borderRadius={5} boxShadow='0 0 9px black' bg='#545454'>
        <HStack px={4} py={2} w='100%'>
          <ShadowText style={{ flex: 1, fontFamily: 'Bebas Neue', fontSize: '1.4rem' }}>Load Auction</ShadowText>
          <HStack spacing={0} ml='auto'>
            <AddButton onClick={() => onSwitch('create')} />
            <CloseButton onClick={() => onSwitch(null)} />
          </HStack>
        </HStack>
        <Box
          px={2}
          maxH='500px'
          py={2}
          boxShadow='inset 0 0 4px black'
          bg='#323232'
          minH='200px'
          overflowY='auto'
          w='100%'
        >
          <VStack position='relative' minH='40px' w='100%' height='auto' spacing={2}>
            <HStack
              opacity={isLoading ? 1 : 0}
              position='absolute'
              w='100%'
              top='0'
              transition={`opacity 400ms ease ${isLoading ? 500 : 0}ms, height 400ms ease ${isLoading ? 0 : 500}ms`}
              height={isLoading ? '40px' : '0'}
            >
              <CircularProgress isIndeterminate color='gray.500' size={6} />
              <Text color='gray.50'>Loading...</Text>
            </HStack>
            {savedAuctions.length ? (
              savedAuctions.map((auction) => (
                <HStack
                  key={auction._id}
                  w='100%'
                  bg={gradientToCssGradientProp(gradients.blue, 120)}
                  height='42px'
                  borderRadius={6}
                  boxShadow='md'
                  spacing={0}
                  m={0}
                >
                  <Button
                    w='100%'
                    bg='transparent'
                    borderRightRadius={0}
                    onClick={() => handleLoadAuction(auction._id)}
                    _hover={{ bg: 'whiteAlpha.400' }}
                    isLoading={loadingAuction === auction._id}
                    fontWeight={500}
                  >
                    <ShadowText style={{ fontFamily: 'Bebas Neue', fontSize: '1.2rem', paddingLeft: '0.3rem' }}>
                      {auction.title}
                    </ShadowText>
                    <ShadowText style={{ fontFamily: 'Bebas Neue', marginLeft: 'auto' }}>
                      {getDateTimeString(auction.created_at)}
                    </ShadowText>
                  </Button>
                  <HStack ml='auto'>
                    <DeleteButton
                      size='xs'
                      itemName='auction'
                      onClick={() => deleteDoc(getBroadcasterAuctionDocRef(broadcaster._id, auction._id))}
                    />
                  </HStack>
                </HStack>
              ))
            ) : (
              <Text
                opacity={isLoading ? 0 : 1}
                transition='opacity 500ms'
                color='gray.500'
                fontFamily='Bebas Neue'
                fontSize='1.2rem'
              >
                No saved point auctions
              </Text>
            )}
          </VStack>
        </Box>
      </VStack>
    </VStack>
  )
}

import {
  getColor,
  PollWidgetConfig,
  PredictionWidgetConfig,
} from '@elan-twitch/shared'
import { useSpring } from '@react-spring/web'

export const useBarColors = (
  { gradient }: PredictionWidgetConfig | PollWidgetConfig,
  index: number,
  numItems: number,
  active: boolean,
) => useSpring({
  color1: getColor(gradient || 'blue', active ? index / numItems : 0.5),
  color2: getColor(gradient || 'blue', active ? (index + 1) / numItems : 0.5),
})

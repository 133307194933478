import {
  BroadcasterConfigSnippet,
  MOVIE_LIST,
  parsePollConfig,
  parsePredictionConfig,
  POINTS_BANK,
  PointsBankAccountConfig,
  POLL,
  PollWidgetConfig,
  PREDICTION,
  PredictionWidgetConfig,
} from '@elan-twitch/shared'
import { getBroadcasterConfigPath } from '@elan-twitch/shared/utils/config'
import { WidgetId } from '@elan-twitch/shared/widgets/types'
import { useContext, useMemo } from 'react'
import { useDocument } from '../../../hooks/db'
import { BroadcasterContext } from '../Broadcasters/context'

const widgetConfigPath: Record<WidgetId, string> = {
  'poll-overlay': POLL,
  'prediction-overlay': PREDICTION,
  'movie-list': MOVIE_LIST,
  'points-bank': POINTS_BANK,
}

const parseConfig: Partial<Record<WidgetId, (data: any) => any>> = {
  'poll-overlay': parsePollConfig,
  'prediction-overlay': parsePredictionConfig,
}

const useConfig = <ConfigType extends {}>(id: WidgetId | 'snippet') => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const path = useMemo(
    () => (broadcasterId
      ? `${getBroadcasterConfigPath(broadcasterId)}/${id === 'snippet' ? 'snippet' : widgetConfigPath[id]}`
      : null),
    [broadcasterId, id],
  )
  const { data, isLoading, error } = useDocument<ConfigType>(path)

  const config = useMemo(
    () => {
      const parseFunc = id !== 'snippet' ? parseConfig[id] : null
      return (parseFunc ? parseFunc(data) : data) as ConfigType
    },
    [data, id],
  )

  return useMemo(
    () => ({
      config,
      isLoading,
      error,
      path,
    }),
    [config, isLoading, error, path],
  )
}

export const usePollConfig = () => useConfig<PollWidgetConfig>('poll-overlay')
export const usePredictionConfig = () => useConfig<PredictionWidgetConfig>('prediction-overlay')
export const useMovieListConfig = () => useConfig('movie-list')
export const usePointsBankConfig = () => useConfig<PointsBankAccountConfig>('points-bank')
export const useConfigSnippet = () => useConfig<BroadcasterConfigSnippet>('snippet')

import {
  Flex,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  getPointsName, gradients, gradientToCssGradientProp, toPlural,
} from '@elan-twitch/shared'
import { useContext, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useIsChannelAdmin } from '../../../hooks/useIsChannelAdmin'
import { useUser } from '../../../store/useUser'
import { BroadcasterContext } from '../Broadcasters/context'
import { BroadcasterAuthStatus } from './BroadcasterAuthStatus'
import { ConfigMenu } from './ConfigMenu'
import { NotAllowed } from './NotAllowed'
import { NotSignedIn } from './NotSignedIn'
import { PointsConfig } from './PointsConfig'
import { PollConfig } from './PollConfig'
import { PredictionConfig } from './PredictionConfig'
import { ConfigView } from './types'

const configViews: Array<ConfigView> = [
  {
    name: 'Polls',
    Component: PollConfig,
    path: '/poll',
    onMenu: true,
  },
  {
    name: 'Predictions',
    Component: PredictionConfig,
    path: '/prediction',
    onMenu: true,
  },
  {
    name: 'Reconnect',
    Component: BroadcasterAuthStatus,
    path: '/reconnect',
  },
]

const notSignedInViews: Array<ConfigView> = [
  {
    name: 'Not Signed In',
    Component: NotSignedIn,
    path: '/*',
  },
]

const notAllowedViews: Array<ConfigView> = [
  {
    name: 'Not Allowed',
    Component: NotAllowed,
    path: '/*',
  },
]

export const Config = () => {
  const user = useUser((s) => s.user)
  const isAdmin = useIsChannelAdmin()
  const { broadcaster } = useContext(BroadcasterContext)
  const pointName = useMemo(() => getPointsName(broadcaster), [broadcaster])

  const views = useMemo<ConfigView[]>(() => {
    if (!user) return notSignedInViews
    if (!isAdmin) return notAllowedViews
    return [
      ...configViews,
      {
        name: toPlural(pointName),
        Component: PointsConfig,
        path: `/${toPlural(pointName).toLowerCase()}`,
        onMenu: true,
      },
    ]
  }, [isAdmin, user, pointName])
  const bg = useColorModeValue('linear-gradient(#efefef, #ababab)', gradientToCssGradientProp(gradients.nymn))
  return (
    <Flex pointerEvents='auto' bg={bg} width='100%' height='100%'>
      <ConfigMenu views={views} />
      <Flex position='relative' minW='0' flex={1}>
        <Routes>
          {views.map(({ Component, path }) => (
            <Route key={path} element={<Component />} path={path} />
          ))}
        </Routes>
      </Flex>
    </Flex>
  )
}

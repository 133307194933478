import { Box } from '@chakra-ui/react'
import { getRarityColor, getRarityString } from '@elan-twitch/shared'
import { ShadowText } from 'components/shared/ShadowText'

export const RarityBadge = ({ rollThreshold }: { rollThreshold: number }) => {
  const rarity = getRarityString(rollThreshold)
  const rarityColor = getRarityColor(rarity)
  return (
    <Box userSelect='none' height='26px' bg={'#00000033'} borderRadius={6}>
      <ShadowText
        style={{
          fontSize: '1.125rem',
          padding: '0 0.5rem',
          paddingTop: '0.2rem',
          filter: `drop-shadow(0 0 2px ${rarityColor})`,
          textShadow: '1px 1px 3px black',
        }}
      >
        {getRarityString(rollThreshold)}
      </ShadowText>
    </Box>
  )
}

import { Center, Text } from '@chakra-ui/react'
import { ContentBox } from '../../shared/ContentBox'

export const NotSignedIn = () => (
  <Center w='100%' h='100%'>
    <ContentBox>
      <Text fontSize='lg'>You must be signed in to view this page</Text>
    </ContentBox>
  </Center>
)

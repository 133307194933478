import {
  Checkbox,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import { ListFilterPreset } from '@elan-twitch/shared'
import { useContext } from 'react'
import filterIcon from '../../../icons/tune.svg'
import { InfiniteScrollListContext } from './context'

const PresetFilterButton = ({
  filter,
  filters,
  toggleFilter,
  isChild,
}: {
  filter: ListFilterPreset
  filters: ListFilterPreset[]
  toggleFilter: (toggled: ListFilterPreset) => void
  isChild?: boolean
}) => {
  const { label } = filter
  const isActive = filters.some((f) => f === filter)
  return (
    // <Button
    //   borderRadius="full"
    //   w='100%'
    //   size="xs"
    //   fontWeight={500}
    //   fontFamily="Hero-New"
    //   onClick={() => (isActive ? removeFilter(filter.filter[0]) : filterBy(filter.filter))
    //   }
    //   bg={isActive ? colors.green.hex : 'gray.200'}
    //   color={isActive ? 'white' : 'gray.800'}
    //   _hover={{ bg: isActive ? 'gray.300' : 'green.300' }}
    // >
    //   {label}
    // </Button>
    <VStack w="100%" spacing={0} align="flex-end">
      {isChild ? null : (
        <Text fontSize="sm" fontWeight={600}>
          {label}
        </Text>
      )}
        <Checkbox
          onChange={() => toggleFilter(filter) }
          colorScheme="green"
          isChecked={isActive}
          textAlign='right'
          gap={2}
          flexFlow="row-reverse"
        >
          {label}
        </Checkbox>
    </VStack>
  )
}

const FilterButton = ({
  filter,
  filters,
  toggleFilter,
}: {
  filter: ListFilterPreset,
  filters: ListFilterPreset[],
  toggleFilter: (toggled: ListFilterPreset) => void,
}) => (
    <PresetFilterButton isChild filters={filters} toggleFilter={toggleFilter} filter={filter} />
)

export const PresetFilters = ({ presetFilters, toggleFilter, currentFilters }: {
  presetFilters: ListFilterPreset[]
  toggleFilter: (filter: ListFilterPreset) => void
  currentFilters: ListFilterPreset[]
}) => {
  const iconBrightness = useColorModeValue(1, 1.5)
  return (presetFilters?.length ? (
    <HStack spacing={1}>
      <Popover
        // closeOnBlur={false}
        placement="right-start"
        // trigger={isMobile ? 'click' : 'hover'}
        // trigger=
        strategy="fixed"
      >
        <PopoverTrigger>
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="Filters"
            icon={
              <Image
                width="20px"
                filter={`brightness(${iconBrightness}) grayscale(${currentFilters.length ? 0 : 100}%)`}
                src={filterIcon}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent borderRadius={6} w="220px" >
          <PopoverArrow />
          <VStack spacing={2} w="100%" py={2} px={3}>
            <HStack justify='center' w='100%' borderBottom='1px solid #cdcdcd'>
            <Text fontSize='sm' fontWeight={600} >FILTERS</Text>
            </HStack>
            {presetFilters.map((filter) => (
              <FilterButton toggleFilter={toggleFilter} filters={currentFilters} key={filter.label} filter={filter} />
            ))}
          </VStack>
        </PopoverContent>
      </Popover>
    </HStack>
  ) : null)
}
export const DataListPresetFilters = () => {
  const {
    list: { filterPresets },
    data: { addFilter, removeFilter, filters },
  } = useContext(InfiniteScrollListContext)

  return (
    <PresetFilters
      presetFilters={filterPresets || []}
      toggleFilter={(filter) => (filters.includes(filter) ? removeFilter(filter) : addFilter(filter))}
      currentFilters={filters}
    />
  )
}

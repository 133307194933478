import { CSSProperties, FC, PropsWithChildren } from 'react'

export const Overlay: FC<PropsWithChildren<{ style?: CSSProperties }>> = ({ children, style }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.8)',
      transition: 'opacity 500ms',
      ...style,
    }}
  >
    {' '}
    {children}
    {' '}

  </div>
)

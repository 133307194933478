import { Tooltip, TooltipProps } from '@chakra-ui/react'

export const CoolTooltip = (props: TooltipProps) => (
  <Tooltip
    // @ts-ignore
    bg='blackAlpha.700'
    placement='top'
    hasArrow
    fontSize='lg'
    fontFamily='Bebas Neue'
    {...props}
  />
)

import {
  Center, Flex, Text, useColorModeValue,
} from '@chakra-ui/react'
import { InfiniteScrollList, WithId } from '@elan-twitch/shared'
import { DocumentData } from 'firebase/firestore'
import { UseInfiniteScrollList } from 'hooks/db'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { ContentBox } from '../ContentBox'
import { useInfiniteScrollListContext } from './context'

type ListItemProps<T extends DocumentData> = {
  list: InfiniteScrollList<T>
  items: Array<WithId<T>>
  onSelect: (item: WithId<T>) => void
  refetch: (id: string) => Promise<void>
}
const ListRow = <T extends DocumentData>({
  data,
  index,
  style,
}: ListChildComponentProps<ListItemProps<T>>) => {
  const {
    list, items, onSelect, refetch,
  } = data || {}
  const { RenderItem } = list
  const item = items[index]
  if (!item) return null
  return (
    <Flex
      display='flex'
      cursor='pointer'
      aria-label='list-item'
      onClick={() => {
        onSelect(items[index])
      }}
      style={style}
      key={index}
      px={2}
      py={1}
    >
      {RenderItem({ index, item, refetch: () => refetch(item._id) })}
    </Flex>
  )
}

export const ListBody = <T extends DocumentData>({
  height,
  width,
  data,
}: {
  height: number
  width: number
  data: UseInfiniteScrollList<T>
}) => {
  const bg = useColorModeValue('blackAlpha.300', 'blackAlpha.500')
  const {
    data: items, isItemLoaded, fetchMoreData, loading, totalCount, refetchItem,
  } = data
  const { list } = useInfiniteScrollListContext<T>()
  const { itemHeight, itemType, noItemsMessage } = list
  const navigate = useNavigate()
  const itemData = useMemo<ListItemProps<T>>(
    () => ({
      list,
      items,
      onSelect: (item: WithId<T>) => navigate(`${list.basePath}/${list.getItemPath ? list.getItemPath(item) : item._id}`),
      refetch: refetchItem,
    }),
    [items, list, navigate, refetchItem],
  )
  return (
    <Flex bg={bg} flexFlow='column' w='100%' h={`${height}px`}>
      {loading || items?.length ? (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={totalCount || 0} loadMoreItems={fetchMoreData}>
          {({ onItemsRendered, ref }) => (
            <FixedSizeList<ListItemProps<T>>
              height={height}
              width={width}
              itemSize={itemHeight}
              itemCount={totalCount || 0}
              itemData={itemData}
              onItemsRendered={onItemsRendered}
              ref={ref}
            >
              {ListRow}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      ) : (
        <Center w='100%' h='100%' px={3} py={4}>
          <ContentBox>
            <Text color='gray.600'>{noItemsMessage || `No ${itemType}s yet`}</Text>
          </ContentBox>
        </Center>
      )}
    </Flex>
  )
}

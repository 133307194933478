import { Center, Text } from '@chakra-ui/react'
import { ContentBox } from '../../shared/ContentBox'

export const NotAllowed = () => (
  <Center w='100%' h='100%'>
    <ContentBox>
      <Text fontSize='lg'>You do not have permissions to view this page</Text>
    </ContentBox>
  </Center>
)

import { TextureKey, textures } from '@elan-twitch/shared'
import { useMemo } from 'react'

export const useTexture = (textureId?: TextureKey | null) => {
  const texture = useMemo(() => (
    textureId ? textures[textureId] : null
  ), [textureId])

  return texture
}

import { getDownvoteDocPath, getUpvoteDocPath } from '@elan-twitch/shared'
import { useContext, useMemo } from 'react'
import { useDocument } from '../../../../hooks/db'
import { useUser } from '../../../../store/useUser'
import { BroadcasterContext } from '../../../App/Broadcasters/context'

export const useMyVote = (movieId: string) => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)
  const user = useUser((s) => s.user)

  const myDownvotePath = useMemo(() => (user ? getDownvoteDocPath(broadcasterId, movieId, user.uid) : null), [user, broadcasterId, movieId])
  const myUpvotePath = useMemo(() => (user ? getUpvoteDocPath(broadcasterId, movieId, user.uid) : null), [user, broadcasterId, movieId])

  const { data: myDownvote, isLoading: downvoteLoading } = useDocument(myDownvotePath)
  const { data: myUpvote, isLoading: upvoteLoading } = useDocument(myUpvotePath)

  const loading = (!myDownvote && downvoteLoading) || (!myUpvote && upvoteLoading) || !user

  return useMemo(() => {
    if (loading) return null
    if (myDownvote) return -1
    if (myUpvote) return 1
    return 0
  }, [myDownvote, myUpvote, loading])
}

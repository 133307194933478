import { useToast } from '@chakra-ui/react'
import { DroppedEgg, DroppedItem, WithId } from '@elan-twitch/shared'
import { Triplet } from '@react-three/cannon'
import { getBroadcasterRandomDropsCollection } from 'backend/db'
import { functions } from 'backend/functions'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { httpsCallable } from 'firebase/functions'
import { useQuery } from 'hooks/db'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

const getEggPosition = (): Triplet => [
  Math.random() * 10 - 5,
  22 + Math.random() * 2 - 1,
  Math.random() - 2,
]

const getEggRotation = (): Triplet => [
  Math.random() * Math.PI * 2,
  Math.random() * Math.PI * 2,
  Math.random() * Math.PI * 2,
]

export type EggWithOrientation = DroppedItem & {
  position: [number, number, number]
  rotation: [number, number, number]
  deleted?: boolean
}

export const useBroadcasterRandomDrops = () => {
  const { broadcaster } = useContext(BroadcasterContext)

  const dropsQuery = useMemo(
    () => (broadcaster._id
      ? getBroadcasterRandomDropsCollection(broadcaster._id)
      : null),
    [broadcaster._id],
  )

  const registeredDropIds = useRef<Array<string>>([])

  const [eggs, setEggs] = useState<Array<WithId<EggWithOrientation>>>([])
  const handleNewEggs = useCallback((allEggs: Array<WithId<DroppedEgg>>) => {
    const newEggs = allEggs
      .filter((egg) => !registeredDropIds.current.includes(egg._id))
      .map((egg) => ({
        ...egg,
        position: getEggPosition(),
        rotation: getEggRotation(),
      }))

    const removedEggIds = registeredDropIds.current.filter((id) => {
      const match = allEggs.find((allE) => id === allE._id)
      if (!match || match.purchase) return true
      return false
    })
    registeredDropIds.current.push(...newEggs.map((egg) => egg._id))
    const updatedEggs = allEggs.filter((egg) => registeredDropIds.current.includes(egg._id))
    if (updatedEggs.length) {
      setEggs((prev) => prev
        .map((i) => ({ ...i, deleted: removedEggIds.includes(i._id) }))
        .map((egg) => {
          const match = updatedEggs.find((u) => u._id === egg._id)
          if (match) return { ...egg, ...match }
          return egg
        }))
    }
    if (newEggs.length) {
      newEggs.forEach((egg, idx) => {
        setTimeout(() => {
          setEggs((prev) => [...prev, egg]
            .map((i) => ({ ...i, deleted: removedEggIds.includes(i._id) }))
            .slice(-10))
        }, (idx + 1) * 5000)
      })
      removedEggIds.forEach((removedId, idx) => {
        setTimeout(() => {
          setEggs((prev) => prev
            .map((i) => ({ ...i, deleted: removedEggIds.includes(i._id) }))
            .filter((e) => e._id !== removedId))
          registeredDropIds.current = registeredDropIds.current.filter((id) => id !== removedId)
        }, (newEggs.length + idx + 1) * 5000)
      })
    }
  }, [])
  const { isLoading } = useQuery<DroppedItem>(dropsQuery, handleNewEggs)

  return { eggs, isLoading }
}

const getDropsActiveFunction = httpsCallable<
  { broadcasterId: string },
  boolean
>(functions, 'getBroadcasterRandomDropsActive')

export const useBroadcasterDropsActive = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const toast = useToast()
  const [dropsActive, setDropsActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    if (broadcaster._id) {
      setIsLoading(true)
      getDropsActiveFunction({ broadcasterId: broadcaster._id })
        .then((result) => {
          setDropsActive(result.data)
          setIsLoading(false)
        })
        .catch((err: any) => {
          console.error(err)
          toast({
            title: 'Failed to get drops active status',
            description: err?.message,
            status: 'error',
          })
        })
    }
  }, [broadcaster, toast])

  return useMemo(() => {
    if (isLoading) return 'loading'
    return dropsActive ? 'active' : 'inactive'
  }, [dropsActive, isLoading])
}

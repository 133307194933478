import {
  Button, ButtonProps, Collapse, Flex, Stack, Text, useToast,
} from '@chakra-ui/react'
import {
  FieldMap, isField, isRecordField, processData,
} from '@elan-twitch/shared'
import { AnyObject, FORM_ERROR, FormApi } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { FC, useCallback, useMemo } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'
import { FormElement } from './input'
import { FieldFormProps, FieldMapFormProps, FormProps } from './input/types'
import { validateFieldMap } from './input/utils'

export const SubmitButton: FC<Pick<FormRenderProps, 'handleSubmit' | 'submitting'> & ButtonProps> = ({
  handleSubmit,
  submitting,
  ...props
}) => (
  // @ts-ignore
  <Button isLoading={submitting} onClick={handleSubmit} ml='auto' {...props} />
)

export const FieldMapForm: FC<FieldMapFormProps> = ({
  onSubmit,
  field,
  value,
  buttonText = 'SUBMIT',
  renderFooter,
}) => {
  const toast = useToast()
  const submitWithToast = useCallback(
    async (data: AnyObject, form: FormApi) => {
      try {
        const processed = await processData(field, data, async () => null)
        const res = await onSubmit(processed, form)
        if (res?.[FORM_ERROR]) {
          toast({
            title: 'Error',
            description: res?.[FORM_ERROR],
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        return res
      } catch (err: any) {
        console.error(err)
        if (err?.message) {
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        return { [FORM_ERROR]: (err as any).message || 'An error occurred' }
      }
    },
    [field, onSubmit, toast],
  )
  return (
    <Stack spacing={3}>
      <FinalForm
        mutators={{ ...arrayMutators }}
        initialValues={value}
        validate={(v) => validateFieldMap(field, v)}
        onSubmit={submitWithToast}
        subscription={{
          values: true,
          submitting: true,
          dirty: true,
          errors: true,
        }}
        render={({
          handleSubmit, submitting, values, errors, dirty, form, submitErrors, hasValidationErrors,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormElement field={field} />
            </form>
            <Collapse in={!!errors?.[FORM_ERROR] || !!submitErrors?.[FORM_ERROR]}>
              <Text color='red'>{errors?.[FORM_ERROR] || submitErrors?.[FORM_ERROR] || ''}</Text>
            </Collapse>
            {renderFooter ? (
              renderFooter({ handleSubmit, submitting, values })
            ) : (
              <Collapse in={dirty} style={{ display: 'flex', width: '100%' }}>
                <Flex px={3} w='100%' pb={3}>
                  <Button onClick={() => form.restart()} size='sm' opacity={0.7}>
                    RESET
                  </Button>
                  <SubmitButton
                    size='sm'
                    opacity={hasValidationErrors ? 0.5 : 1}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                  >
                    {buttonText}
                  </SubmitButton>
                </Flex>
              </Collapse>
            )}
          </>
        )}
      />
    </Stack>
  )
}

export const FieldForm: FC<FieldFormProps> = ({
  onSubmit, field, value, ...props
}) => {
  const fieldMap = useMemo<FieldMap>(() => ({ children: { value: field } }), [field])
  return (
    <FieldMapForm field={fieldMap} value={{ value }} onSubmit={(data, form) => onSubmit(data.value, form)} {...props} />
  )
}

export const Form: FC<FormProps> = ({ field, ...props }) => {
  if (isField(field)) {
    return <FieldForm field={field} {...props} />
  }
  if (isRecordField(field)) {
    return <FieldForm field={field} {...props} />
  }
  return <FieldMapForm field={field} {...props} />
}

import { OutcomeWithRank, Prediction } from '@elan-twitch/shared'
import { useMemo } from 'react'

export const useProcessedPrediction = (prediction?: Prediction) => {
  const { outcomes = [] } = prediction || {}
  return useMemo(() => {
    const sortedByPoints = [...outcomes].sort(
      (a, b) => b.channel_points - a.channel_points,
    )
    let maxPoints = 1
    const { totalPoints, totalVoters } = outcomes.reduce(
      (acc, curr) => {
        if (curr.channel_points > maxPoints) maxPoints = curr.channel_points
        return {
          totalPoints: acc.totalPoints + curr.channel_points,
          totalVoters: acc.totalVoters + curr.users,
        }
      },
      { totalPoints: 0, totalVoters: 0 },
    )
    const withRank = outcomes.map((outcome) => ({
      ...outcome,
      rank: sortedByPoints.findIndex((s) => s.id === outcome.id),
      percent: outcome.channel_points / (totalPoints || 1),
      maxPoints,
      totalPoints,
      totalVoters,
    })) as Array<OutcomeWithRank>
    return { withRank, totalPoints, totalVoters }
  }, [outcomes])
}

import { OUTCOME_WIDTH } from '@elan-twitch/shared/constants'
import { motion } from 'framer-motion'
import {
  FC,
  useEffect, useMemo, useState,
} from 'react'
import { ChannelPoints } from './ChannelPoints'

const items = [1, 1, 1]

const getVariants = (scale: number) => ({
  active: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.03,
      ease: 'circIn',
      duration: 0.8,
    },
    y: scale * OUTCOME_WIDTH,
  }),
  inactive: () => ({
    opacity: 0,
    y: -scale * OUTCOME_WIDTH * 1.5,
    transition: {
      duration: 0,
    },
  }),
})

export const AnimatedPoint: FC<{ active: boolean, scale: number }> = ({
  active,
  scale,
}) => {
  const [stage, setStage] = useState('inactive')
  useEffect(() => {
    setStage(active ? 'active' : 'inactive')
  }, [active])

  const variants = useMemo(() => getVariants(scale), [scale])
  return (
    <>
      {items.map((_, i) => (
        <motion.svg
          key={i}
          custom={i}
          animate={stage}
          variants={variants}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            bottom: 0,
            transformOrigin: '50% 50%',
          }}
          viewBox='0 0 501 501'
          xmlns='http://www.w3.org/2000/svg'
        >
          <ChannelPoints active={active} />
        </motion.svg>
      ))}
    </>
  )
}

import {
  AddIcon, CheckIcon,
  ChevronDownIcon, ChevronUpIcon,
  CloseIcon, DeleteIcon, RepeatIcon,
} from '@chakra-ui/icons'
import {
  Box, Button, ButtonProps, IconButton, IconButtonProps, Image, useToast,
} from '@chakra-ui/react'
import { getDateTimeString } from '@elan-twitch/shared'
import { useWithLoading } from 'hooks/useWithLoading'
import React, { forwardRef, useCallback, useState } from 'react'
import { CollapseHorizontal } from './CollapseHorizontal'
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog'
import { CoolTooltip } from './CoolTooltip'
import { DiscardChangesDialog } from './DiscardChangesDialog'
import { ShadowText } from './ShadowText'

type TooltipButtonProps = Omit<IconButtonProps, 'aria-label' | 'onClick'> & {
  label?: string
  onClick?: (() => Promise<void>) | (() => void)
}

export const CloseButton = ({
  onClick,
  shouldConfirm,
  ...props
}: TooltipButtonProps & { shouldConfirm?: boolean }) => {
  const { loading, call } = useWithLoading(onClick)

  const [confirming, setConfirming] = useState(false)
  const handleClick = useCallback(() => {
    if (shouldConfirm && !confirming) setConfirming(true)
    else {
      setConfirming(false)
      call()
    }
  }, [shouldConfirm, call, confirming])
  return (
    <CoolTooltip label={props.label || 'CLOSE'}>
      <Box>
        <IconButton
          // @ts-ignore
          aria-label='Close'
          fontFamily='Bebas Neue'
          isLoading={loading}
          size='sm'
          boxShadow='md'
          bg='whiteAlpha.400'
          _hover={{ bg: 'red.400' }}
          opacity={0.8}
          icon={<CloseIcon color='white' opacity={0.6} />}
          fontSize='1rem'
          onClick={handleClick}
          {...props}
        />
        {shouldConfirm ? (
          <DiscardChangesDialog
            isOpen={confirming}
            onCancel={() => setConfirming(false)}
            onConfirm={() => {
              setConfirming(false)
              call()
            }}
          />
        ) : null}
      </Box>
    </CoolTooltip>
  )
}

export const AddButton = (props: TooltipButtonProps) => (
  <CoolTooltip label={props.label || 'ADD'}>
    <IconButton
      // @ts-ignore
      aria-label='Add'
      boxShadow='md'
      fontFamily='Bebas Neue'
      filter='drop-shadow(0 0 3px black)'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'green.400' }}
      opacity={0.8}
      icon={<AddIcon color='white' opacity={0.6} />}
      fontSize='1rem'
      {...props}
    />
  </CoolTooltip>
)

export const LoadButton = (props: TooltipButtonProps) => (
  <CoolTooltip label={props.label || 'LOAD'}>
    <IconButton
      // @ts-ignore
      aria-label='Load'
      boxShadow='md'
      filter='drop-shadow(0 0 3px black)'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'whiteAlpha.600' }}
      opacity={0.8}
      icon={<Image opacity={0.6} filter='invert(100%)' src='/assets/images/history.svg' />}
      {...props}
    />
  </CoolTooltip>
)

export const SaveButton = ({
  savedOn,
  ...props
}: TooltipButtonProps & { savedOn?: string | number | null }) => (
  <CoolTooltip label={savedOn ? `Saved on ${getDateTimeString(savedOn)}` : 'SAVE'}>
    <IconButton
      // @ts-ignore
      aria-label='Save'
      boxShadow='md'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'blue.300' }}
      filter='drop-shadow(0 0 3px black)'
      opacity={0.8}
      icon={
        savedOn ? (
          <CheckIcon color='green.300' />
        ) : (
          <Image filter='invert(100%)' width='24px' opacity={0.7} src='/assets/images/save.svg' />
        )
      }
      {...props}
    />
  </CoolTooltip>
)

export const DeleteButton = ({
  onClick,
  itemName,
  ...props
}: TooltipButtonProps & { shouldConfirm?: boolean; itemName: string }) => {
  const { loading, call } = useWithLoading(onClick)

  const [confirming, setConfirming] = useState(false)
  const handleClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!confirming) setConfirming(true)
    else {
      setConfirming(false)
      call()
    }
  }, [call, confirming])
  return (
    <CoolTooltip label={props.label || 'DELETE'}>
      <Box>
        <IconButton
          // @ts-ignore
          aria-label='Delete'
          fontFamily='Bebas Neue'
          isLoading={loading}
          size='sm'
          boxShadow='md'
          bg='red.500'
          _hover={{ bg: 'red.400' }}
          opacity={0.8}
          icon={<DeleteIcon color='white' opacity={0.6} />}
          fontSize='1rem'
          onClick={handleClick}
          {...props}
        />
        <ConfirmDeleteDialog
          itemName={itemName}
          isOpen={confirming}
          onCancel={() => setConfirming(false)}
          onConfirm={() => {
            setConfirming(false)
            call()
          }}
        />
      </Box>
    </CoolTooltip>
  )
}

export const TooltipIconButton = ({ label, ...props }: Omit<IconButtonProps, 'aria-label'> & { label: string }) => (
  <CoolTooltip label={label}>
    <IconButton
      // @ts-ignore
      boxShadow='md'
      aria-label={label}
      size='sm'
      bg='whiteAlpha.400'
      color='whiteAlpha.700'
      _hover={{ bg: 'blue.300' }}
      filter='drop-shadow(0 0 2px black)'
      opacity={0.8}
      {...props}
    />
  </CoolTooltip>
)

export const ResetButton = ({ ...props }: TooltipButtonProps) => (
  <CoolTooltip label='RESET'>
    <IconButton
      aria-label='Reset'
      boxShadow='md'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'blue.300' }}
      filter='drop-shadow(0 0 3px black)'
      opacity={0.8}
      icon={<RepeatIcon color='whiteAlpha.700' />}
      {...props}
    />
  </CoolTooltip>
)

export const ExpandButton = ({
  onClick,
  width,
  children,
  active,
  ...props
}: TooltipButtonProps & { width: number; active: boolean }) => (
  <CollapseHorizontal width={width} active={active}>
    <Box p={2}
      width={`${width}px`}
    >
      <Button
        // @ts-ignore
        width='100%'
        // @ts-ignore
        aria-label='Expand'
        ml='auto'
        boxShadow='md'
        size='sm'
        minW='0'
        padding={0}
        color='white'
        px={2}
        justifyContent='center'
        bg='blackAlpha.300'
        _hover={{ bg: 'blackAlpha.400' }}
        textShadow='0 0 3px black'
        filter='drop-shadow(0px 0px 3px black)'
        onClick={onClick}
        {...props}
      >
        {children}
      </Button>
    </Box>
  </CollapseHorizontal>
)

export const ViewButton: React.FC<{
  onClick: (e: React.MouseEvent) => void
  isOpen: boolean
  viewText?: string
  hideText?: string
  color?: string
}> = ({
  viewText = 'View', hideText = 'Hide', onClick, isOpen, color,
}) => (
  <IconButton
    icon={
      isOpen ? (
        <ChevronUpIcon width={5} height={5} />
      ) : (
        <ChevronDownIcon width={5} height={5} />
      )
    }
    aria-label={isOpen ? hideText : viewText}
    variant='ghost'
    p='2px'
    ml='auto'
    size='xs'
    fontSize='xs'
    borderRadius='full'
    _hover={{ bg: 'blackAlpha.200' }}
    color={color || '#777'}
    onClick={ (e) => {
      e.stopPropagation()
      onClick(e)
    }}
  >
    {/* {isOpen ? hideText || 'HIDE' : viewText || 'VIEW'} */}
  </IconButton>
)

export const SquareButton = (props: ButtonProps) => (
  <Button
    // @ts-ignore
    bg='whiteAlpha.300'
    boxShadow='0 0 7px black'
    _hover={{ bg: 'whiteAlpha.500' }}
    w='100px'
    h='100px'
    {...props}
  />
)

export const SquareShadowTextButton = ({ children, ...props }: ButtonProps) => (
  <SquareButton {...props}>
    <ShadowText style={{ fontSize: '1.6rem', height: '26px' }}>
      {children}
    </ShadowText>
  </SquareButton>
)

export const LoadingButton = forwardRef<HTMLButtonElement, Omit<ButtonProps, 'onClick'> & {onClick:() => Promise<any>} >(({ onClick, ...props }, ref) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      await onClick()
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    }
    setLoading(false)
  }, [onClick, toast])
  return (
    // @ts-ignore
    <Button ref={ref} isLoading={loading} {...props} onClick={handleClick} />
  )
})

import { IntervalData } from './types'

export const parseCommandType = (message: string) => {
  const args = message.split(' ').filter((arg) => !!arg.trim())
  if (args.length < 2) throw new Error('Invalid command')
  const command = args[1]
  return command
}

export const getIntervalData = (...seconds: Array<number>): IntervalData => {
  if (seconds.length === 0) {
    return {
      min: 0,
      max: 0,
      start: 0,
      end: 0,
    }
  }
  const min = Math.min(...seconds)
  const max = Math.max(...seconds)
  const start = seconds[0]
  const end = seconds[seconds.length - 1]

  return {
    min,
    max,
    start,
    end,
  }
}

export const intervalsFromSeconds = (seconds: Array<number>, interval: number): Array<IntervalData & { t: number }> => {
  if (seconds.length === 0) return []
  const numIntervals = Math.floor(seconds.length / interval)
  return Array.from({ length: numIntervals }, (_, i) => i).map((i) => {
    const start = i * interval
    const end = start + interval
    return {
      min: Math.min(...seconds.slice(start, end)),
      max: Math.max(...seconds.slice(start, end)),
      start: seconds[start],
      t: start,
      end: seconds[end - 1],
    }
  })
}

// const Y_SCALE = 5
export const getCandlestickPaths = (width: number, x: number, y: number, scaleY: number, data: IntervalData) => {
  const {
    min, max, start, end,
  } = data
  const yCenter = y - scaleY * ((start + end) / 2)
  const lineHeight = Math.abs(min - max) * scaleY

  const boxWidth = width * 0.5
  const lineWidth = width * 0.04
  const boxHeight = Math.max(scaleY, Math.abs(start - end) * scaleY)
  const boxX = x - boxWidth / 2
  const boxTop = yCenter + boxHeight / 2
  const boxBottom = yCenter - boxHeight / 2

  let newColor = '#777777'
  if (start !== null && end !== null) {
    if (start > end) newColor = '#FF4444'
    else if (start < end) newColor = '#44FF44'
  }

  return {
    linePath: `M ${x - lineWidth / 2} ${yCenter - lineHeight / 2} L ${x + lineWidth / 2} ${
      yCenter - lineHeight / 2
    } L ${x + lineWidth / 2} ${yCenter + lineHeight / 2} L ${x - lineWidth / 2} ${yCenter + lineHeight / 2} Z`,
    boxPath: `M ${boxX} ${boxTop} L ${boxX + boxWidth} ${boxTop} L ${
      boxX + boxWidth
    } ${boxBottom} L ${boxX} ${boxBottom} Z`,
    color: newColor,
    opacity: 1,
  }
}

import { PREDICTION } from '../../constants/db'
import { getBroadcasterConfigPath } from '../../utils/config'
import { fallbackPredictionConfig } from './constants'
import { PredictionWidgetConfig } from './types'

export const getPredictionDocPath = (broadcasterId: string) => `${PREDICTION}/${broadcasterId}`
export const getPredictionConfigPath = (broadcasterId: string) => `${getBroadcasterConfigPath(broadcasterId)}/${PREDICTION}`
export const parsePredictionConfig = (
  theme: PredictionWidgetConfig | undefined | null,
): PredictionWidgetConfig => Object.keys(fallbackPredictionConfig).reduce((acc, curr) => {
  const fromDb = theme?.[curr as keyof PredictionWidgetConfig]
  return {
    ...acc,
    [curr]:
        fromDb !== undefined
          ? fromDb
          : fallbackPredictionConfig[curr as keyof PredictionWidgetConfig],
  }
}, {}) as PredictionWidgetConfig

import { AddIcon } from '@chakra-ui/icons'
import {
  Button, Image, Text, VStack,
} from '@chakra-ui/react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { useContext } from 'react'
import { CreateWheelAuction } from './CreateWheelAuction'
import { LoadWheelAuction } from './LoadWheelAuction'
import { useWheelContext } from './context'

export const LoadOrCreateWheelAuction = () => {
  const { broadcaster } = useContext(BroadcasterContext)

  const { editMode: mode, setEditMode: setMode } = useWheelContext()
  return (
    <VStack minW='400px' w='100%'>
      <CollapseCenter in={!mode}>
        <VStack pointerEvents='auto'>
          <Button gap={1} color='white' width='190px' onClick={() => setMode('create')}>
            <AddIcon width={4} height={4} mr={1} />
            <Text>CREATE WHEEL</Text>
          </Button>
          <Button width='190px' onClick={() => setMode('load')}>
            <Image
              mr={1}
              src='/assets/images/history.svg'
              filter='invert(100%)'
            />
            <Text>LOAD WHEEL</Text>
          </Button>
        </VStack>
      </CollapseCenter>
      <CollapseCenter in={mode === 'create'}>
        <CreateWheelAuction
          onSwitch={setMode}
          enabled={mode === 'create'}
          broadcaster={broadcaster}
        />
      </CollapseCenter>
      <CollapseCenter in={mode === 'load'}>
        <LoadWheelAuction
          onSwitch={setMode}
          enabled={mode === 'load'}
          broadcaster={broadcaster}
        />
      </CollapseCenter>
    </VStack>
  )
}

import { SearchIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  CircularProgress,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Portal,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { MovieSearchResponse, TmdbMovieDetailsResult } from '@elan-twitch/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { httpsCallable } from 'firebase/functions'
import { useDebounce } from 'hooks/useDebounce'
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { functions } from '../../../backend/functions'
import movieIcon from '../../../icons/movie.svg'
import { BroadcasterContext } from '../../App/Broadcasters/context'
import { TypingIndicator } from '../../shared/TypingIndicator'

const searchMovie = httpsCallable<{ query: string }, MovieSearchResponse>(functions, 'searchMovie')

const FallbackMovieBackground = () => {
  const bg = useColorModeValue('blackAlpha.300', 'whiteAlpha.600')
  const imgFilter = useColorModeValue('none', 'invert(100%)')
  return (
    <Center bg={bg} w='100%' h='100%' position='absolute' top={0} left={0} pointerEvents='none'>
      <Image src={movieIcon} h='60%' opacity={0.8} filter={imgFilter} />
    </Center>
  )
}
const MovieSearchResult = ({ result, onSelect }: { result: TmdbMovieDetailsResult; onSelect: () => void }) => {
  const { poster_path, backdrop_path } = result
  const imagePath = backdrop_path || poster_path
  return (
    <Flex w='100%' p={2}>
      <Button
        justifyContent='center'
        alignItems='center'
        variant='unstyled'
        onClick={onSelect}
        display='flex'
        pt={1}
        pb={2}
        h='auto'
        borderRadius={6}
        position='relative'
        overflow='hidden'
        w='100%'
      >
        {imagePath ? (
          <Image
            position='absolute'
            opacity={0.9}
            w='100%'
            h='100%'
            objectFit='cover'
            top={0}
            left={0}
            src={`https://image.tmdb.org/t/p/w500${imagePath}`}
            alt={result.title}
          />
        ) : (
          <FallbackMovieBackground />
        )}
        <Flex w='100%' align='center' px={2} filter='drop-shadow(1px 1px 3px black)' flex={1} flexFlow='column'>
          <Text whiteSpace='pre-wrap' color='white' textAlign='center' fontWeight={600} fontSize='xl'>
            {result.title}
          </Text>
          <Text color='white' textAlign='center' lineHeight={1} fontSize='lg' fontWeight={400}>{`(${
            result.release_date.split('-')[0]
          })`}</Text>
        </Flex>
      </Button>
    </Flex>
  )
}

const MovieSearchBody = ({
  loading,
  onBlur,
  isOpen,
  onFocus,
  parentRef,
  isTyping,
  results,
  query,
}: {
  onFocus: () => void
  onBlur: () => void
  results: TmdbMovieDetailsResult[] | null
  parentRef: React.RefObject<HTMLDivElement>
  loading: boolean
  isOpen: boolean
  isTyping: boolean
  query: string
}) => {
  const bg = useColorModeValue('gray.100', 'gray.700')

  const { basePath } = useContext(BroadcasterContext)
  const navigate = useNavigate()

  const onSelect = useCallback(
    (id: string) => {
      navigate(`${basePath}/movies/${id}`)
    },
    [basePath, navigate],
  )
  const [box, setBox] = useState<{ top: number; left: number; width: number; maxHeight: number } | null>(null)
  const handleParentResize = useCallback(() => {
    const el = parentRef.current
    if (!el) return
    const rect = el.getBoundingClientRect()
    const top = rect.top + rect.height + 10
    const width = Math.min(rect.width, document.body.clientWidth - 20)
    const left = rect.right - width
    const maxHeight = Math.min(500, document.body.clientHeight - top - 10)

    setBox({
      top,
      left,
      width,
      maxHeight,
    })
  }, [parentRef])

  useEffect(() => {
    handleParentResize()
  }, [isOpen, handleParentResize])
  useResizeObserver(parentRef, handleParentResize)
  let body = <></>
  if (isTyping) {
    body = (
      <Flex flexFlow='column' w='100%'>
        <Flex p={2} w='100%' justifyContent='center'>
          <TypingIndicator />
        </Flex>
      </Flex>
    )
  } else if (loading) {
    body = (
      <Flex flexFlow='column' w='100%'>
        <Flex p={3} w='100%' justifyContent='center'>
          <CircularProgress size={6} color='gray' isIndeterminate />
        </Flex>
      </Flex>
    )
  } else if (results) {
    body = (
      <Flex flexFlow='column' w='100%'>
        {results.map((r) => (
          <MovieSearchResult onSelect={() => onSelect(`${r.id}`)} key={r.id} result={r} />
        ))}
      </Flex>
    )
  } else if (query) {
    body = (
      <Flex flexFlow='column' w='100%'>
        <Flex p={2} w='100%' justifyContent='center'>
          <Flex>No results found</Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Portal>
      {box ? (
        <Flex
          overflowY='auto'
          bg={bg}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          borderRadius={6}
          boxShadow='1px 1px 4px #00000066'
          pointerEvents={isOpen ? 'auto' : 'none'}
          opacity={isOpen ? 1 : 0}
          onFocus={onFocus}
          onBlur={onBlur}
          flexFlow='column'
          position='absolute'
          top={`${box.top || 0}px`}
          left={`${box.left || 0}px`}
          w={`${box.width || 0}px`}
          maxH={`${box.maxHeight || 0}px`}
        >
          {body}
        </Flex>
      ) : null}
    </Portal>
  )
}
const MovieSearch = () => {
  const [inputFocused, setInputFocused] = useState(false)
  const [resultsFocused, setResultsFocused] = useState(false)
  const [query, setQuery] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [results, setResults] = useState<null | any[]>(null)

  const isOpen = useMemo(() => inputFocused || resultsFocused, [inputFocused, resultsFocused])
  const [searching, setSearching] = useState(false)
  const debounced = useDebounce(query, 500)
  const isTyping = useMemo(() => debounced !== query, [debounced, query])

  const toast = useToast()

  const onSearch = useCallback(
    async (q: string) => {
      setSearching(true)
      // fetch movies
      try {
        const res = await searchMovie({ query: q })
        setResults(res.data.results.filter((r) => !!r.release_date))
      } catch (err: any) {
        toast({
          title: 'Error searching movies',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      setStatusMessage('')
      setSearching(false)
    },
    [toast],
  )

  useEffect(() => {
    if (!isOpen) return
    if (debounced.length > 3) {
      onSearch(debounced)
    }
  }, [isOpen, debounced, onSearch])

  const inputRef = React.useRef<HTMLInputElement>(null)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!inputFocused) return () => {}
    const inputEl = inputRef.current
    if (!inputEl) return () => {}
    const onEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSearch(inputRef.current?.value || '')
      }
    }
    inputEl.addEventListener('keydown', onEnter)
    return () => inputEl.removeEventListener('keydown', onEnter)
  }, [inputFocused, onSearch])

  return (
    <Flex ref={wrapperRef} position='relative'>
      <InputGroup>
        <InputLeftElement width='36px' pointerEvents='none' children={<SearchIcon w={4} />} />
        <Tooltip label={statusMessage} isOpen={!!statusMessage} placement='left' hasArrow>
          <Input
            w='350px'
            minW='100%'
            ref={inputRef}
            pl={8}
            value={query}
            onChange={(e) => {
              if (e.target.value.endsWith('\n')) {
                onSearch(e.target.value.trim())
              } else setQuery(e.target.value)
            }}
            placeholder='Search movies'
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        </Tooltip>
      </InputGroup>
      <MovieSearchBody
        onFocus={() => setResultsFocused(true)}
        onBlur={() => setResultsFocused(false)}
        query={query}
        loading={searching}
        isTyping={isTyping}
        parentRef={wrapperRef}
        isOpen={isOpen}
        results={results}
      />
    </Flex>
  )
}

export const MovieListHeader = () => (
  <Flex w='100%'>
    <Flex ml='auto'>
      <MovieSearch />
    </Flex>
  </Flex>
)

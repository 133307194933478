import { FC, SVGProps } from 'react'

export const PredictionIcon: FC<
  SVGProps<SVGSVGElement> & { fill?: string }
> = ({ fill, ...props }) => (
  <svg
    version='1.1'
    id='Layer_1'
    x='0px'
    y='0px'
    viewBox='0 0 400 400'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <style type='text/css' />
    <path
      fill={fill}
      d='M206.8,381.9c-43.4,0-86.7-0.1-130.1,0.1c-4,0-5.2-0.8-4.8-5.1c2-20.9,19.6-38.2,41-39.9 c2.9-0.2,4.9-1.2,6.9-3.2c3.8-4.1,7.8-8,11.9-11.8c2.4-2.2,2-3.1-0.7-4.6c-30.5-17-53.4-41.3-67.7-73.2 c-14.8-32.7-17.7-66.7-9.8-101.7c0.7-3.2,1.8-3.7,4.8-2.4c6,2.6,12.2,4.9,18.3,7.2c2.3,0.8,3.6,2.2,4.5,4.5 c4.1,10.7,8.5,21.3,12.5,32c2.3,6.2,1.9,12.9,3.3,19.4c11.5,52.1,57.7,89,110.8,88.5c55.6-0.6,102.6-41.5,110.4-96.1 c7.9-55.8-25.4-108-79.2-124.3c-6.4-1.9-13-3.5-19.7-4c-9-0.7-16.7-5.4-25-8.2c-4.9-1.7-9.6-3.9-14.5-5.6c-2.5-0.9-3.9-2.2-4.8-4.7 c-2.3-6.3-4.7-12.5-7.4-18.7c-1.1-2.6-0.7-3.6,2.1-4.3c6.7-1.5,13.4-2.8,20.2-3.5c36.6-3.6,70.8,3.9,101.6,24.1 c42.2,27.6,67.4,66.7,71.6,117C368.8,230.9,341,282.2,283,317c-3.3,2-3.5,3.1-0.6,5.4c1.7,1.4,3.3,3.1,4.8,4.7 c5.2,5.8,10.8,9.5,19.1,11c19.5,3.3,33.3,19.8,35.4,39.1c0.4,3.8-0.6,4.7-4.3,4.7C293.8,381.9,250.3,381.9,206.8,381.9z'
    />
    <path
      fill={fill}
      d='M127.8,178.8c-4.8-12-9.3-23.4-13.8-34.8c-2.4-6-4.8-11.9-7-18c-0.9-2.4-2.3-3.9-4.7-4.8 c-15.8-6.1-31.5-12.3-47.2-18.6c-1.6-0.6-3.1-1.3-4.8-2c0.9-1.7,2.5-1.8,3.9-2.3C69.8,92.2,85.4,86,101,80c3.2-1.2,5.2-3,6.4-6.4 c6.1-15.9,12.4-31.8,18.7-47.6c0.5-1.2,1-2.3,1.9-4.2c2.7,6.7,5.1,12.7,7.5,18.8c4.4,11.2,8.9,22.3,13.1,33.6 c1.1,2.9,2.8,4.5,5.6,5.5c16.2,6.2,32.3,12.6,48.4,19c1,0.4,2.2,0.5,3,2c-9.5,3.7-18.9,7.5-28.3,11.2c-7.9,3.1-15.8,6.3-23.8,9.3 c-2.4,0.9-3.8,2.4-4.7,4.8c-6.5,16.7-13,33.3-19.6,50C128.9,176.8,128.5,177.5,127.8,178.8z'
    />
    <path
      fill={fill}
      d='M161.7,179.2c9.5-3.7,17.6-7.3,26-10.2c4.8-1.6,7.2-4.4,8.8-9c3-8.4,6.5-16.6,10.2-25.9 c3.4,8.6,6.9,16.2,9.4,24.1c1.9,6,5.1,9.6,11.2,11.5c6.8,2.1,13.3,5.1,19.9,7.7c1.1,0.4,2.5,0.5,3.4,2.2c-7.9,3.1-15.7,6.7-23.7,9.2 c-5.7,1.8-8.7,5.1-10.5,10.5c-2.7,8.1-6.2,16.1-9.7,24.9c-4-10.1-7.7-19.3-11.2-28.5c-1-2.7-2.4-4.3-5.2-5.4 C181.1,187,172.1,183.3,161.7,179.2z'
    />
  </svg>
)

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  IconButton,
  StackDivider,
  StackProps,
  Text, useColorMode,
  useColorModeValue,
  useToast, VStack,
} from '@chakra-ui/react'
import {
  Field, getFieldFormat,
  getFieldName,
  getFieldParse,
  getFieldValidate, IFormElement, isField,
  isRecordField, RecordField,
} from '@elan-twitch/shared'
import {
  CloseButton, ResetButton, SaveButton, TooltipIconButton,
} from 'components/shared/Buttons'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { CollapseHorizontal } from 'components/shared/CollapseHorizontal'
import { ShadowText } from 'components/shared/ShadowText'
import {
  CSSProperties,
  FC,
  ForwardedRef, forwardRef, MutableRefObject, useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { Field as FinalField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { ExpandOnMount } from '../../shared/ExpandOnMount'
import { Condition } from '../Condition'
import { BooleanComponent } from './boolean'
import { ColorComponent } from './color'
import { DateInput } from './date'
import { DateTimeInput } from './datetime'
import { FileComponent } from './file'
import { useInputImperativeHandle, useInputStyle } from './hooks'
import { CurrencyAmountComponent, NumberComponent } from './number'
import { SelectComponent } from './select'
import { SocialMediaComponent } from './socialMedia'
import { TextComponent } from './text'
import { TimeInput } from './time'
import {
  FormProps, InputProps, InputRef, InputSize,
} from './types'

const makeOnDragEndFunction = (fields: any) => (result: DropResult) => {
  // dropped outside the list
  if (!result.destination) {
    return
  }
  fields.move(result.source.index, result.destination.index)
}

export const RecordComponent = forwardRef<InputRef, InputProps<RecordField>>(({ field, input }, ref) => {
  const { name } = input || {}
  const itemName = getFieldName(field.itemField)
  useInputImperativeHandle(ref)
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <Box w='100%'>
                {field.label ? (
                  <Flex color='#dedede'>
                    <ShadowText>{field.label}</ShadowText>
                  </Flex>
                ) : null}
                <VStack
                  ref={provided.innerRef}
                  divider={<StackDivider opacity={0.3} />}
                  w='100%'
                  py={1}
                  align='flex'
                  spacing={2}
                >
                  {fields.map((id, idx) => {
                    const indexedField = isField(field.itemField)
                      ? {
                        ...field.itemField,
                        placeholder: `${itemName} ${idx + 1}`,
                      }
                      : {
                        ...field.itemField,
                        name: `${itemName} ${idx + 1}`,
                      }

                    return (
                      <ExpandOnMount style={{ padding: '0.5rem 0.8rem' }} key={id}>
                        <HStack align='flex-start' p={2} bg='whiteAlpha.100' borderRadius={6} boxShadow='0 0 4px white'>
                          <FormElement validate name={id} field={indexedField} />
                          <IconButton
                            aria-label='Delete field'
                            size='xs'
                            bg='red.600'
                            icon={<DeleteIcon />}
                            onClick={() => fields.remove(idx)}
                          />
                        </HStack>
                      </ExpandOnMount>
                    )
                  })}
                  <Flex>
                    <Button
                      mr='auto'
                      width='auto'
                      size='xs'
                      onClick={() => fields.push(isField(field.itemField) ? '' : { id: `${Date.now()}` })}
                    >
                      + NEW
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>
  )
})

const Components: {
  [K in Field['_type']]: FC<InputProps<Extract<Field, { _type: K }>>>
} = {
  text: TextComponent,
  file: FileComponent,
  boolean: BooleanComponent,
  select: SelectComponent,
  number: NumberComponent,
  color: ColorComponent,
  date: DateInput,
  currency: CurrencyAmountComponent,
  datetime: DateTimeInput,
  socialMedia: SocialMediaComponent,
  time: TimeInput,
}

const InputPlaceholder = ({ field, size = 'md' }: { field: Field; size?: InputSize }) => {
  const { placeholder } = field

  const fontSize = useMemo(() => {
    switch (size) {
      case 'sm':
        return 0.9
      case 'md':
        return 1
      case 'lg':
        return 1.2
      default:
        return 1
    }
  }, [size])
  if (!placeholder) return null
  return (
    <Flex>
      <ShadowText style={{ fontSize: `${fontSize + 0.2}rem`, whiteSpace: 'nowrap' }}>{field.placeholder}</ShadowText>
    </Flex>
  )
}

export type InnerInputProps<T extends Field> = InputProps<T> & { stackProps?: StackProps, standalone?: boolean }
export const InnerInput = <T extends Field>(
  { stackProps, standalone, ...props }: InnerInputProps<T>,
  ref: ForwardedRef<InputRef>,
) => {
  const {
    field,
    meta: { error, active, touched },
    size = 'md',
  } = props
  const { colorMode } = useColorMode()
  const color = useMemo(() => {
    if (error && touched) return '#ff7777'
    if (active) return 'rgba(255,255,255,0.7)'
    return 'transparent'
  }, [error, active, touched])
  const styles = useMemo(() => {
    switch (field._type) {
      case 'text':
      case 'number':
        return {
          background: colorMode === 'light' ? 'blackAlpha.300' : 'whiteAlpha.300',
          boxShadow: `0 0 7px ${color}`,
        }
      default:
        return {}
    }
  }, [field, color, colorMode])

  const Component = Components[field._type] as FC<InputProps<T>>
  const isSwitchInput = useMemo(() => field._type === 'boolean' && field.type === 'switch', [field])
  const fontSize = useMemo(() => {
    switch (size) {
      case 'sm':
        return 0.9
      case 'md':
        return 1
      case 'lg':
        return 1.2
      default:
        return 1
    }
  }, [size])
  return (
    // @ts-ignore
    <Flex flexFlow='column' width='100%' py={1} px={2} {...stackProps}>
      {isSwitchInput || standalone ? null : <InputPlaceholder field={field} size={size} />}
      {field.label ? (
        <Flex>
          {typeof field.label === 'string' ? (
            <ShadowText
              style={{
                fontSize: `${fontSize}rem`,
                lineHeight: 1,
                color: '#dedede',
              }}
            >
              {field.label}
            </ShadowText>
          ) : (
            field.label
          )}
        </Flex>
      ) : null}
      <Flex width='100%' borderRadius={6} overflow='hidden' {...styles}>
        <Component ref={ref} {...props} />
      </Flex>
      <Collapse in={!!error && !!touched && !active}>
        <Text fontSize='sm' px={4} color='#ffaaaa'>
          {error}
        </Text>
      </Collapse>
    </Flex>
  )
}

export const Input = forwardRef<InputRef, InnerInputProps<Field>>(InnerInput as any)

const StandaloneInputInner = <F extends Field>(
  {
    onChange,
    value,
    field,
    onBlur,
    onFocus,
    error,
    focusOnMount,
    style,
    stackProps,
    size,
  }: {
    onChange: (v?: any) => void
    value?: any
    field: F
    onBlur?: () => void
    onFocus?: () => void
    style?: CSSProperties
    focusOnMount?: boolean
    size?: InputProps<F>['size']
    stackProps?: StackProps
    error?: string
  },
  ref: ForwardedRef<InputRef>,
) => {
  const [isActive, setActive] = useState(false)
  useEffect(() => {
    if (focusOnMount && ref) {
      (ref as MutableRefObject<InputRef | null>).current?.focus()
    }
  }, [focusOnMount, ref])
  return (
    <Input
      ref={ref}
      field={field}
      style={style}
      standalone
      size={size}
      stackProps={stackProps}
      input={{
        name: '',
        onChange: (e: any) => {
          if (e?.target) onChange(e.target?.value)
          else onChange(e)
        },
        value: value as any,
        onBlur: () => {
          setActive(false)
          if (onBlur) onBlur()
        },
        onFocus: () => {
          setActive(true)
          if (onFocus) onFocus()
        },
      }}
      meta={{ error, active: isActive }}
    />
  )
}

export const StandaloneInput = forwardRef(StandaloneInputInner)

export const Editable = <T extends Field>({
  field,
  value,
  size,
  horizontal,
  inputStackProps,
  onSubmit,
}: {
  field: T
  value: any
  inputStackProps?: StackProps
  horizontal?: boolean
  size?: InputProps<T>['size']
  onSubmit: (updated?: T['value']) => Promise<void>
}) => {
  const [curr, setCurr] = useState<T['value']>(value)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()
  const inputRef = useRef<InputRef | null>(null)
  const handleSubmit = useCallback((s?: Field['value']) => {
    setIsSubmitting(true)
    onSubmit(s === undefined ? curr : s)
      .then(() => {
        setIsEditing(false)
        setIsSubmitting(false)
      })
      .catch((err: any) => {
        console.log({ err })
        setIsSubmitting(false)
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }, [curr, onSubmit, toast])

  useEffect(() => {
    setCurr(value)
  }, [isEditing, value])

  const isSwitchInput = useMemo(() => field._type === 'boolean' && field.type === 'switch', [field])

  const dirty = useMemo(() => curr !== value, [curr, value])
  const formatter = useMemo(() => getFieldFormat(field), [field])
  const inputStyle = useInputStyle(size)
  return (
    <HStack borderRadius={6} spacing={0}>
      <Box flex={1}>
        {isEditing || isSwitchInput ? (
          <StandaloneInput
            focusOnMount
            field={field}
            onChange={isSwitchInput ? handleSubmit : setCurr}
            ref={inputRef}
            value={curr}
            stackProps={{
              flexFlow: horizontal ? 'row' : 'column',
              gap: horizontal ? 2 : 1,
              align: horizontal ? 'center' : 'flex-start',
              ...inputStackProps,
            }}
            size={size}
          />
        ) : (
          <Flex
            flexFlow={horizontal ? 'row' : 'column'}
            gap={horizontal ? 2 : 1}
            px={1}
            align={horizontal ? 'center' : 'flex-start'}
            {...inputStackProps}
          >
            <InputPlaceholder field={field} size={size} />
            <Text
              flex={1}
              px={1}
              borderRadius={4}
              color={value ? 'white' : 'whiteAlpha.600'}
              bg='whiteAlpha.200'
              style={inputStyle}
            >
              {formatter ? formatter(curr) : `${curr}`}
            </Text>
          </Flex>
        )}
      </Box>
      {!isSwitchInput ? (
        <Flex flexFlow={horizontal ? 'row' : 'column'}>
          {horizontal ? (
            <CollapseHorizontal width={58} active={isEditing && dirty}>
              <ResetButton onClick={() => setCurr(value)} size='xs' />
              <SaveButton isLoading={isSubmitting} onClick={() => handleSubmit()} ml={1} size='xs' />
            </CollapseHorizontal>
          ) : (
            <CollapseCenter in={isEditing && dirty}>
              <ResetButton onClick={() => setCurr(value)} size='xs' />
              <SaveButton isLoading={isSubmitting} onClick={() => handleSubmit()} ml={1} size='xs' />
            </CollapseCenter>
          )}
          <CollapseHorizontal width={30} active={isEditing}>
            <CloseButton
              size='xs'
              onClick={() => {
                setIsEditing(false)
                setCurr(value)
              }}
              shouldConfirm={dirty}
            />
          </CollapseHorizontal>
          <CollapseHorizontal width={35} active={!isEditing}>
            <Center py={1}>
              <TooltipIconButton
                onClick={() => {
                  setIsEditing(true)
                }}
                size='xs'
                label='EDIT'
                icon={<EditIcon />}
              />
            </Center>
          </CollapseHorizontal>
        </Flex>
      ) : (
        <CollapseHorizontal width={30} active={isSubmitting}>
            <CircularProgress size={5} isIndeterminate={isSubmitting}/>
          </CollapseHorizontal>
      )}
    </HStack>
  )
}

const FieldComponent = <F extends Field>({
  field,
  name,
  validate: shouldValidate,
}: {
  field: F
  name: string
  validate?: boolean
}) => {
  const validate = useMemo(() => {
    const fieldValidate = getFieldValidate(field)
    return (value: F['value']) => fieldValidate(field, value)
  }, [field])

  const format = useMemo(() => getFieldFormat(field), [field])
  const parse = useMemo(() => getFieldParse(field), [field])

  return (
    <FinalField<F['value'], HTMLInputElement, string>
      validate={shouldValidate ? validate : undefined}
      key={name}
      name={name}
      parse={parse}
      format={format}
      render={(props) => <Input field={field as Field} {...props} />}
    />
  )
}

const FormElementBody = ({ name, field, validate }: { name: string; field: IFormElement; validate?: boolean }) => {
  if (isField(field)) {
    return <FieldComponent key={name} name={name} field={field} validate={validate} />
  }
  return <FormElement validate={validate} key={name} name={name} field={field} />
}

export const FormElement = ({
  field,
  name,
  validate,
}: {
  field: FormProps['field']
  name?: string
  validate?: boolean
  isChild?: boolean
}) => {
  const fields = useMemo(() => {
    if (isField(field)) {
      return [{ name: name || 'value', field }]
    }
    if (isRecordField(field)) {
      return [{ name: name || 'value', field }]
    }
    return Object.entries(field.children).map(([childName, childField]) => ({
      name: `${name ? `${name}.` : ''}${childName}`,
      field: childField,
    }))
  }, [field, name])
  const displayedName = useMemo(() => (isField(field) || isRecordField(field) ? null : field.name), [field])
  const borderColor = useColorModeValue('#00000044', '#ffffff44')
  return (
    <VStack minW='300px' pl={1} borderLeft={`2px solid ${borderColor}`} align='flex-start' w='100%'>
      {displayedName ? (
        <ShadowText style={{ fontSize: '1.2rem', paddingLeft: '0.5rem' }}>{displayedName}</ShadowText>
      ) : null}
      {fields.map((f) => {
        const { condition } = f.field
        const body = condition ? (
          <Condition key={f.name} condition={condition} basePath={name || ''}>
            <FormElementBody name={f.name} field={f.field} validate={validate} />
          </Condition>
        ) : (
          <FormElementBody key={f.name} name={f.name} field={f.field} validate={validate} />
        )

        return (
          <Box key={f.name} w='100%'>
            {body}
          </Box>
        )
      })}
    </VStack>
  )
}

import {
  Box,
  Center, Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  getBroadcasterPointsBankCollectionPath,
  gradients,
  gradientToCssGradientProp,
  InfiniteScrollList,
  PointsBankAccount,
  toPlural,
  truncateNumber,
  WithId,
} from '@elan-twitch/shared'
import { Trophy } from 'icons/Trophy'
import { useContext, useMemo } from 'react'
import { useScreen } from '../../../store/useScreen'
import { BroadcasterContext } from '../../App/Broadcasters/context'
import { MobileDarkModeToggle } from '../../shared/ColorMode/DarkModeToggle'
import { InfiniteLoaderList } from '../../shared/DataList/InfiniteLoaderList'
import { ShadowText } from '../../shared/ShadowText'

const PointsBankAccountView = ({
  account,
  index,
}: {
  account: WithId<PointsBankAccount>
  index: number
}) => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { pointsBankAccountConfig } = broadcaster
  const { pointIconPath } = pointsBankAccountConfig || {}
  const {
    user_name, points, inventory,
  } = account || {}
  const numItems = useMemo(() => {
    const { items } = inventory || {}
    return Object.keys(items || {}).length || 0
  }, [inventory])
  return (
    <Box minW='0' w='100%' p={2}>
      <Flex
        minW='0'
        bg='blackAlpha.300'
        alignItems='center'
        cursor='pointer'
        boxShadow='0 0 2px transparent'
        _hover={{
          boxShadow: '0 0 4px #fafafa',
        }}
        transition='box-shadow 400ms'
        borderRadius='md'
        py={2}
        w='100%'
        px={3}
      >
        <Text color='white' textShadow='1px 1px 3px #000000cc'>#{index + 1}</Text>
        <Text minW='0' isTruncated pl={2} flex={1} color='#fafafa' textShadow='1px 1px 3px black' >
          {user_name}
        </Text>
        <Text
          fontFamily='Bebas Neue'
          textShadow='1px 1px 3px black'
          fontSize='xl'
          pos='relative'
          top='1px'
          lineHeight={1}
          color='gold'
        >
          {truncateNumber(points)}
        </Text>
        {pointIconPath ? (
          <Box
            w='1.5rem'
            ml={1}
            h='1.5rem'
            bgImage={`url(${pointIconPath})`}
            bgSize='cover'
            bgPosition='center'
            borderRadius='full'
          />
        ) : null}
        {
          numItems ? (
            <HStack spacing={0} ml={3}>
            <Trophy style={{ width: '24px' }} color='gold' />
            <Text
              fontFamily='Bebas Neue'
              textShadow='1px 1px 3px black'
              fontSize='xl'
              top='1px'
              lineHeight={1}
              color='gold'
              >
              x{numItems}
              </Text>
            </HStack>
          ) : null
        }
      </Flex>
    </Box>
  )
}

const getList = (basePath: string, broadcasterId: string): InfiniteScrollList<PointsBankAccount> => ({
  itemType: 'account',
  collectionPath: getBroadcasterPointsBankCollectionPath(broadcasterId),
  defaultFilters: [],
  basePath: `${basePath}/bank`,
  defaultSortKey: 'points',
  filterPresets: [],
  sortPresets: [
    {
      defaultDirection: 'desc',
      sortKey: 'points',
      label: 'Score',
    },
  ],
  getItemPath: (acc) => acc.user_name.toLowerCase(),
  RenderItem: ({ item, index }) => <PointsBankAccountView index={index} account={item} />,
  itemHeight: 50,
  noItemsMessage: 'No accounts yet',
})

export const PointsBankView = () => {
  const { broadcaster, basePath } = useContext(BroadcasterContext)
  const { _id: broadcasterId } = broadcaster || {}
  const { pointsBankAccountConfig } = broadcaster
  // const bankQuery = useMemo(
  //   () => query(
  //     getBroadcasterPointsBankCollection(broadcasterId),
  //     orderBy('points', 'desc'),
  //     limit(200),
  //   ),
  //   [broadcasterId],
  // )
  // const { data, isLoading } = useQuery(bankQuery)
  const list = useMemo(() => getList(basePath, broadcasterId), [broadcasterId, basePath])

  const height = useScreen((s) => s.height)
  const screenWidth = useScreen((s) => s.width)
  const width = useMemo(() => Math.min(screenWidth * 0.95, 600), [screenWidth])
  if (!broadcasterId) {
    return <Text>Error: missing broadcaster ID</Text>
  }
  if (!pointsBankAccountConfig) {
    return <Text>No points set up for {broadcasterId}</Text>
  }

  const pointName = pointsBankAccountConfig?.pointName
    ? toPlural(pointsBankAccountConfig?.pointName)
    : 'points'
  return (
    <Center
      bg={gradientToCssGradientProp(gradients.nymn)}
      position='absolute'
      top={0}
      left={0}
      w='100%'
      height='100%'
    >
      <VStack
        pointerEvents='auto'
        // opacity={isLoading ? 0 : 1}
        transition='opacity 400ms'
      >
        <ShadowText style={{ fontSize: '2rem' }}>
          {pointName.toUpperCase()}{' '}
        </ShadowText>
        {/* <Flex
          flex={1}
          flexFlow='row wrap'
          justifyContent='center'
          overflowY='auto'
        >
          {data?.map((account, index) => (
            <PointsBankAccountView
              index={index}
              key={account._id}
              account={account}
            />
          ))}
        </Flex> */}
      <InfiniteLoaderList
        height={height - 200}
        list={list}
        width={width * 0.9}
      />
      </VStack>
      <MobileDarkModeToggle/>
    </Center>
  )
}

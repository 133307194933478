import {
  AutomaticPointsAuction,
  Broadcaster,
  WithId,
} from '@elan-twitch/shared'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { usePointsAuction } from 'components/Widgets/Auction/hooks'
import {
  createContext, useContext, useMemo, useState,
} from 'react'

export type PointAuctionsContextData = {
  broadcaster: WithId<Broadcaster>
  editMode: null | 'load' | 'create'
  setEditMode: (mode: null | 'load' | 'create') => void
  auction: WithId<AutomaticPointsAuction> | null
  isLoading: boolean
}

export const useAuctionContextData = () => {
  const { broadcaster } = useContext(BroadcasterContext)

  const { data, isLoading } = usePointsAuction(broadcaster)
  const [editMode, setEditMode] = useState<null | 'load' | 'create'>(null)
  return useMemo<PointAuctionsContextData>(
    () => ({
      broadcaster,
      auction: data,
      editMode,
      setEditMode,
      isLoading,
    }),
    [broadcaster, data, isLoading, editMode],
  )
}
export const AuctionContext = createContext<PointAuctionsContextData>({
  broadcaster: {
    _id: '',
    name: '',
    twitchId: '',
  },
  auction: null,
  editMode: null,
  setEditMode: () => {},
  isLoading: false,
})
export const useAuctionContext = () => useContext(AuctionContext)
